import React, { useState, useEffect, useContext } from "react";
//import { useHistory } from "react-router-dom";
import RouterContext from "../../context/RouterContext";
import "../../App.css";
import {updateSedute} from "./Utils";
import {properties} from "utils/properties";
import { useNavigate } from "react-router-dom";

export default function MeetingList() {
    var dateStyle = {
        display: "flex",
        paddingLeft: "40px",        
    };

    const [meetingData, setMeetingData] = useState([]);    
    const { pageRouter, setPageRouter } = useContext(RouterContext);
    //const history = useHistory();
    const navigate = useNavigate();

    const updateUI = function(jsonData) {      
        var entireMeeting = Object.entries(jsonData);                    
        var orderMeeting = [];
        for (var i = entireMeeting.length - 1; i >= 0; i--) {
            var eachMeeting = {
                id: entireMeeting[i][0],
                order: entireMeeting[i][1]["order"],                
                date: entireMeeting[i][1]["dataSeduta"],                
                };        
            orderMeeting.push(eachMeeting);
        }        
        orderMeeting.sort((a, b) => a.order > b.order ? 1 : -1)
        setMeetingData(orderMeeting);
    }

    const doPostSedute2 = function() {
        //console.log("doing sedute post");
        var xhr2 = new XMLHttpRequest();                
        xhr2.open('POST', properties.productionUrl+'/rest/checkSeduteJsonCGPersone',true);
        xhr2.setRequestHeader("Content-type", "application/x-www-form-urlencoded");    
        xhr2.onload = function (e) {      
            if (xhr2.readyState === 4) {
                if (xhr2.status === 200) {                              
                    //console.log("OK DOPOSTSEDUTE",xhr2);
                    var responseText = xhr2.responseText;                    
                    var obj=JSON.parse(responseText);
                    //console.log("OK93",obj);          
                    if(obj.status==="204" ){ 
                        console.log("OK DOPOSTSEDUTE 204",xhr2);
                        const jsonListSedute = JSON.parse(localStorage.getItem("listSeduteCGPersone"));
                        updateUI(jsonListSedute);
                    }              
                    else{
                        //status 201
                        console.log("OK DOPOSTSEDUTE 201",xhr2);                            
                        try{
                            localStorage.setItem("listSeduteCGPersone", obj.content);
                            localStorage.setItem("listSeduteVersionCGPersone", obj.date);                                                                                                                                           
                        }
                        catch(error) {
                            console.log("LOCALSTORAGE ISSUE", error);
                        }  
                        
                        const jsonListSedute = JSON.parse(obj.content);
                        updateUI(jsonListSedute);
                    }
                } 
                else {
                    //console.error("Error 1", xhr.statusText);          
                    alert("Errore recuperando la seduta");  
                }
            }
        };

        xhr2.onerror = function (e) {
            console.error("Error 22", xhr2.statusText);
        };
        
        let urlEncodedData = null; //"idf=" + meetingId;
        if (localStorage.listSeduteCGPersone!=='' &&localStorage.listSeduteCGPersone!==undefined){            
            //console.log( "QUINDI00");
            const currentData=localStorage.getItem("listSeduteVersionCGPersone");        
            urlEncodedData = "date=" + currentData;                    
        }            
        xhr2.send(urlEncodedData);
    }

    useEffect(() => {        
        if(localStorage.getItem("new_ui")==="true"){
            /*history.push({                
                pathname: "/calendar_cg_persone",
                });*/
            navigate("/calendar_cg_persone");  
        }            
                                              
        if(process.env.NODE_ENV !=='development' && !window.cordova){
            
        //CHECK AUTHENTICATION
            var xhr = new XMLHttpRequest();                
            xhr.open('POST', properties.productionUrl+'/rest/ping',true);
            xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
    
            xhr.onload = function (e) {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {                                      
                        console.log("OK",xhr);                                                
                        updateSedute("CGPersone",doPostSedute2, updateUI);
                } else {
                    console.error("Error 1", xhr);     
                    localStorage.clear();               
                    //window.localStorage.clear();
                    console.log("QWERTY 1",localStorage.getItem("name"));
                    /*history.push({                
                    pathname: "/login",
                    });*/
                    navigate("/login");  
                }
            }
            };
    
            xhr.onerror = function (e) {
                console.error("Error 2", xhr);                                
                //window.localStorage.clear();
                localStorage.clear();       
                console.log("QWERTY 2",localStorage.getItem("name"));
                /*history.push({                
                    pathname: "/login",
                    });*/
                navigate("/login");  
            };
             
            let urlEncodedData = null; 
            xhr.send(urlEncodedData);
        //CHECK AUTHENTICATION
        }
        
        if (window.cordova)
        {
           updateSedute("CGPersone",doPostSedute2, updateUI);   
        }
                
        /*setPageRouter({
            router: history.location.pathname,
            homecolor: "#FF5A60",
            settingcolor: "#879497",
            homebordercolor: "#FF5A60",
            settingbordercolor: "#FFFFFF",
            hometitlecolor: "black",
            settingtitlecolor: "#879497",
        });*/
    }, []);

    const topicList = (id) => {
        console.log ("Before goingo to ...", id);
        /*history.push({
                    pathname: "/cg_persone/" + id,
                });                
        */
        navigate("/cg_persone/"+id);  
    };

    return (
        <div className="content">    
        {meetingData.map((item, index) => (
                <div className="scheda-item" style={{ borderRightColor: "#F0B323", }} > 
                <div
                    className="common-list"
                    key={index}
                    style={{
                                cursor: "pointer",
                            }}
                    onClick={() => topicList(item.id)}
                >
                    <div style={dateStyle}>
                        <p className= "textBlackSedute">
                            Seduta del {item.date}
                        </p>
                    </div>
                    <div
                        style={{
                            paddingRight: "25px",
                            color: "#879497",
                            display: "flex",
                        }}
                    >
                        {index === 0 ? (
                            <div
                                style={{
                                    background: "#F0B323",
                                    //background: "#30720f",
                                    marginRight: "40px",
                                    padding: "8px 10px",
                                    color: "white",
                                    borderRadius: "30px",
                                    fontSize: "13px",
                                }}
                            >
                                Nuova
                            </div>
                        ) : (
                            <></>
                        )}

                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-around",
                            }}
                        >
                            <span
                                className="dot"
                                style={{ marginBottom: "5px" }}
                            ></span>
                            <span className="dot"></span>
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <span
                                className="dot"
                                style={{ marginLeft: "3px" }}
                            ></span>
                        </div>
                    </div>
                </div>
                </div>
            ))}
        </div>
    );
}
