import React, { useEffect } from "react";
import axios from 'axios';
//import { useHistory } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import {ThreeDots} from 'react-loader-spinner';
import { toast } from 'react-toastify';
import {properties} from "utils/properties";

export default function AttachmentsDownloader() {    
    let {idDossier} = useParams();
    //const history = useHistory();
    const navigate = useNavigate();
    const resolveLast = function(){        
        axios.get(properties.productionUrl+'/rest/getLast?edited')
        .then(res => {        
            idDossier=res.data;
            //scaricaAllegati();        
            handleDownloads();
        })
    }

    const scaricaAllegati = function(){       
        let endpoint = properties.productionUrl+'/postIDSF?IDFDL='+idDossier;
        axios({
            method: 'get',
            url: endpoint
        })
        .then(function (response) {
            console.log(response);                      
            navigate("/fascicoli/"+idDossier,{ replace: true });  
        })
        .catch(function (response) {            
            console.log("CATCH",response);
        });

    }

    const handleDownloads = () => {
        //const issueName = `Seduta del ${format(new Date(_inviatationDate), 'dd-MM-yyyy')}`
        
        console.log("handleDownloads");                
        let endpoint = properties.productionUrl+"/rest/doCons";                        
        var bodyFormData=new FormData();                      
        bodyFormData.append('action', 'scaricaAllegati');    
        bodyFormData.append('idf', idDossier);    
        axios({
            method: 'post',
            url: endpoint,
            data: bodyFormData, 
            headers: {'Content-Type': 'multipart/form-data' }
        })
        .then(function (response) {            
            localStorage.removeItem("meeting");
            localStorage.removeItem("meetingContent");
            localStorage.removeItem("meetingVersion");            
            navigate("/fascicoli/"+idDossier,{ replace: true });  
        })
        .catch(function (response) {            
            toast.error("Si è verificato un errore pubblicando la seduta!");            
        });
    }








  useEffect(() => {    
    console.log(window.location.href); 
    console.log(idDossier);   
    if(window.location.href.endsWith("/last")||window.location.href.endsWith("/last/")){        
        console.log("ends with last");   
        resolveLast();
    }
    else{
        console.log("ends with an id");   
        //scaricaAllegati();
        handleDownloads();
    }

    }, []);





return (
  <div key={uuidv4()}> 
        
            <div key={uuidv4()} style={{ width:"25%",   display: "flex", 
            marginLeft: "auto", marginRight: "auto" }}> 
             <ThreeDots color="#30720f" height='350' width='150'/>
             </div> 

    </div>
    );
}
