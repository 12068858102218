import React, {useState, useEffect } from "react";
import logo from "../../assets/logo.png";
//import { useHistory } from "react-router-dom";
import "../../App.css";
import {properties} from "utils/properties";
import validator from 'validator';
import { useNavigate } from "react-router-dom";


export default function ResetStage2() {
    //const history = useHistory();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(1);
    const [token, setToken] = useState([]);
    
    function getQueryVariable(variable){
        var query = window.location.search.substring(1);
        
        var vars = query.split("&");        
        for (var i=0;i<vars.length;i++) {
            var pair = vars[i].split("=");            
            if(pair[0] == variable){return pair[1];}
        }
        return(false);
    }


    const doPost = function(token) {    
    var xhr = new XMLHttpRequest();        
    
    xhr.open('POST', properties.productionUrl+'/rest/tokenValidation',true);
    xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
    
    xhr.onload = function (e) {      
      if (xhr.readyState === 4) {
        if(xhr.status === 200) {                         
          var obj=JSON.parse(xhr.responseText);          
          if(obj.valid==="OK" ){
            console.log("OK9");
            setLoading(0);
          }
          else{
            setLoading(2); 
            alert("Il token utilizzato è scaduto o non valido. Si prega di riprovare la procedura di reset");
            /*history.push({           
                pathname: "/reset",
            });*/
            navigate("/reset");      
          }          
        } else {
          console.error("Error 18", xhr.statusText);          
          setLoading(2);
          alert("La validazione del token è fallita. Si prega di riprovare la procedura di reset");          
          /*history.push({                
                pathname: "/reset",
          });*/
          navigate("/reset");    
        }
      }
    };

    
    xhr.onerror = function (e) {
      console.error("Error 28", xhr.statusText);
    };
             
    let urlEncodedData = "token=" + token; 
    xhr.send(urlEncodedData);
  }


   const doPost2 = function(new_password) {    
    var xhr = new XMLHttpRequest();        
    
    xhr.open('POST', properties.productionUrl+'/rest/resetStage2',true);
    xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
    
    xhr.onload = function (e) {      
      if (xhr.readyState === 4) {
        if(xhr.status === 200) {               
          var obj=JSON.parse(xhr.responseText);          
          if(obj.reset==="OK" ){
            console.log("OK9");
            alert("Cambio password avvenuto correttamente. Può accedere con le nuove credenziali");
            /*history.push({              
                pathname: "/login",
            });*/
            navigate("/login");  
          }          
        } else {          
          alert("Reset password fallito");
          /*history.push({                
                pathname: "/reset",
          });*/
          navigate("/reset");    
        }
      }
    };

    
    xhr.onerror = function (e) {
      console.error("Error 2", xhr.statusText);
    };
             
    let urlEncodedData = "token=" + token+ "&password=" + new_password;     
    xhr.send(urlEncodedData);
  }




  const handleSubmit = e => {
    e.preventDefault();           
    if(!e.target.password.value) {
      alert("Password is required");
    } else if (!e.target.confirmPassword.value) {
      alert("Password is required");
    } else  
      {    
      if(e.target.confirmPassword.value ===e.target.password.value){            
        if (validator.isStrongPassword(e.target.password.value, {
          minLength: 8, minLowercase: 1,    
          minUppercase: 1, minNumbers: 1, minSymbols: 0    
        })) {
          doPost2(e.target.password.value);          
        } else {   
          alert("La password non rispetta i livelli minimi di sicurezza. Nello specifico è richiesto che la lunghezza della password sia di almeno 8 caratteri e che questa contenga: almeno 1 carattere maiuscolo, 1 carattere minuscolo ed una cifra");               
        }

        //doPost2(e.target.password.value);            
      }
      else
        alert("Le password inserite non corrispondono. Si prega di controllarle e riprovare.");
    } 
  };

useEffect(() => {
  var a=getQueryVariable("id");
  setToken(a);  
  doPost(a);
    
}, []);
  
return (         
      <div className="content" style = {{alignItems: "center", display: "flex", height: "100%",  justifyContent: "center"}}>
      {loading === 0 ? (
        <div className="AppLogin">
        <img src={logo} className="logo1" alt="Logo" />
        <form className="form1" onSubmit={handleSubmit}>
          <div className="input-group">
          <div style={{ maxWidth: "420px",  padding: "10px",width: "100%", alignItems: "center",margin: "auto"}}> 
          <p style={{ fontSize: "16px", letterSpacing: "1px", maxWidth: "420px",  
          width: "100%", alignItems: "center"}}> 
          Inserire la nuova password (lunghezza minima 8 caratteri).
          Confermarla e fare click sul pulsante Continua. </p>          
          </div>
          </div>
          <div className="input-group">
            <label htmlFor="password">Nuova Password</label>
            <input type="password" name="password" placeholder="*******"/>
          </div>
          <div className="input-group">
            <label htmlFor="confirmPassword">Conferma Nuova Password</label>
            <input type="password" name="confirmPassword" placeholder="*******"/>
          </div>
          <button className="button11">Continua</button>
          
        </form>        
      </div>      
      ): 
      (
      <div>
      <p> "Validazione token in corso..." </p>
       <p> {token} </p>
        </div> ) }
      </div>
    );
  }