import React, { useEffect } from "react";
import axios from 'axios';
//import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import {ThreeDots} from 'react-loader-spinner';
import {properties} from "utils/properties";
import { useNavigate } from "react-router-dom";

export default function Rigenerator() {    
    let {idDossier} = useParams();
    //const history = useHistory();
    const navigate = useNavigate();
    const resolveLast = function(){
        axios.get(properties.productionUrl+'/rest/getLast?edited')
        .then(res => {
        const lastId = res.data;
        idDossier=res.data;
        rigeneraSeduta();
        //console.log("resolveLast",lastId);
        //console.log("rl2",window.location.href);            
        })
    }

    const rigeneraSeduta = function(){
        
        axios.get(properties.productionUrl+'/postIDSF?IDFNOWS='+idDossier).then(response => {                        
            console.log("THEN",response);
            console.log("i should go first");
        }).catch(({response}) => {            
            console.log("CATCH",response);
            
        }).finally(() => {
            console.log("FINALLY",);
            console.log("i should go second");
            /*history.replace({                
                pathname: "/fascicoli/"+idDossier,
                });*/
                navigate("/fascicoli/"+idDossier,{ replace: true }); 
        });
    }


  useEffect(() => {    
    console.log(window.location.href); 
    console.log(idDossier);   
    if(window.location.href.endsWith("/last")||window.location.href.endsWith("/last/")){        
        console.log("ends with last");   
        resolveLast();            
    }
    else{
        console.log("ends with an id");   
        rigeneraSeduta();
    }

    }, []);





return (
  <div key={uuidv4()}> 
        
            <div key={uuidv4()} style={{ width:"25%",   display: "flex", 
            marginLeft: "auto", marginRight: "auto" }}> 
             <ThreeDots color="#30720f" height='350' width='150'/>
             </div> 

    </div>
    );
}
