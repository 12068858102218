import React, { useState, useRef, useEffect, useContext } from 'react'
import { Tree } from "@minoru/react-dnd-treeview";
import axios from 'axios'
import qs from 'qs'
import { toast } from 'react-toastify';
import RouterContext from "../context/RouterContext";
import { makeStyles } from '@material-ui/styles'
//import { useHistory, useParams } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import Box from "@material-ui/core/Box"
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import SweetAlert from "react-bootstrap-sweetalert";
import CircularProgress from '@material-ui/core/CircularProgress';
import { Fab, Tooltip } from '@material-ui/core/';
import SendIcon from '@material-ui/icons/Send';
import {properties} from "utils/properties";
import { useNavigate } from "react-router-dom";

import FileNode from "components/TreeViewSection/FileNodeCGFinanza"

import {
  getPathWithParentId,
  convertResToFilesTree,
  getOperationParams
} from "utils/utils"

import styles from "assets/jss/views/sessionManagerStyles"

const useStyles = makeStyles(styles)

export default function FileManager104() {
  const { pageRouter, setPageRouter } = useContext(RouterContext);
  const classes = useStyles();
  const [alert, setAlert] = useState(null);
  const treeRef = useRef(null);
  //const history = useHistory();
  const navigate = useNavigate();
  const [treeData, setTreeData] = useState([]);
  const [lockedFile, setLockedFile] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  let {idMeeting } = useParams();

  /******* Get Last Issue Begin ********/
  const fetchLastIssue = () => {
    const operationName = "getLastIssue"

    const operationParams = {
      app_user: "app",
      app_password: "meeting",
      operation: operationName
    };
       
    axios.post(properties.productionUrl+'/issueManagerCGFinanza', qs.stringify(operationParams))
      .then(res => {
        //console.log("FLI",res)
        setTreeData(convertResToFilesTree(res.data.dirTree));
        setLockedFile(res.data.lockedForFileInsert);
        if(res.data.lockedForFileInsert==false)
          showAlert();
        treeRef.current.openAll();
        //console.log("VER3",getInvitationDate(res.data.convocationDate));
        var a=res.data.formattedConvocationDate.split("/");
        /*if(a[0]<=9)
          a[0]="0"+a[0];
        if (a[1]<=9)
        a[1]="0"+a[1];*/
        if(a[0].length==1)
          a[0]="0"+a[0];
        if (a[1].length==1)
          a[1]="0"+a[1];
        console.log(a);        
        navigate("/files104/"+a[0]+"-"+a[1]+"-"+a[2],{ replace: true });   
      })
      .catch(err => {
        console.log(err)
      })
  }
  /******* Get Last Issue End ********/

  const handleIssueExistsStartingFromDateLabel = (selectedDate) => {    
    const issueName = 'Seduta del '+ selectedDate;    
    handleLoadIssue(issueName)
  
  }

  const showAlert = () => {
    setAlert(
      <SweetAlert
      warning
      style={{ display: "block", marginTop: "auto", marginBottom: "auto" }}
      title="La seduta non è ancora stata resa pubblica, pertanto, non è ancora possibile caricare documenti. Tornare alla gestione delle sedute?"
      //onConfirm={() => history.push("/folders104")}      
      onConfirm={() => navigate("/folders104")}      
      confirmBtnCssClass={classes.button + " " + classes.success}      
      confirmBtnText="Si"      
    >
    </SweetAlert>
    );
  };


  const handleLoadIssue = (issueName) => {    
    const operationName = "loadIssue";
    //console.log("HandleLoadIssue", issueName);
    console.log("handleIssue-1", issueName.length, issueName);
    const params = null;
    const operationParams = {
      app_user: "app",
      app_password: "meeting",
      operation: operationName,
      issueName: issueName,
      params: JSON.stringify(params)
    };
    
    axios.post(properties.productionUrl+'/issueManagerCGFinanza', qs.stringify(operationParams))
      .then(res => {
        //console.log("HANDLELOADISSUE",res);
        console.log("TEST",res.data.result);      
        setTreeData(convertResToFilesTree(res.data.result.dirTree));
        setLockedFile(res.data.result.lockedForFileInsert);
        //console.log("2",res.data.result.lockedForFileInsert);
        if(res.data.result.lockedForFileInsert==false){
          //console.log("3",res.data.result.lockedForFileInsert);
          showAlert();
        }        
        treeRef.current.openAll();
      })
      .catch(() => {
        toast.error("Failed to load issue")
      })
  }



  /*********** Add File Begin *************/
  const handleAddFile = (fileName, fileType, base64, md5hash, parentId) => {
    let paramClass = ''
    console.log("a1",fileType);
    switch (fileType) {
      case 'application/pdf':
        paramClass = 'filetypepdf'
        break;
      case 'application/msword':
        paramClass = 'filetypeword'
        break;
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        paramClass = 'filetypeword'
        break;
      case 'image/jpeg':
        paramClass = 'filetypeimg'
        break;
      case 'image/png':
        paramClass = 'filetypeimg'
        break;
      default:
        break;
    }

    const params = {
      "data": base64,
      "md5Hash": md5hash,
      "encoding": "base64",
      "childName": fileName,
      "path": getPathWithParentId(treeData, parentId),
      "attr": {
        "id": "newbornfile",
        "rel": "file",
        "class": paramClass,
        "flabel": "file",
        "ftype": fileType,
        "custom": "[]"
      },
      "pos": 0
    };
    const operationParams = getOperationParams("addFile", treeData[0].text, params)    
    axios.post(properties.productionUrl+'/fileMgrCGFinanza', qs.stringify(operationParams))
      .then(res => {
        console.log(res)
        setTreeData(convertResToFilesTree(res.data.result.updatedIssue.dirTree))
        treeRef.current.openAll();
        setInProgress(false)
      })
      .catch(err => {
        console.log(err)
        setInProgress(false)
        toast.error("Failed to add file")
      })
  }
  /*********** Add File End *************/

  const handlePublishing = (issueName) => {        
    console.log("handlePublishing", issueName);        
        
    let endpoint = properties.productionUrl+"/rest/goPub104";                        
    var bodyFormData=new FormData();              
    bodyFormData.append('issueName', 'Seduta del '+issueName);    
    axios({
        method: 'post',
        url: endpoint,
        data: bodyFormData, 
        headers: {'Content-Type': 'multipart/form-data' }
    })
    .then(function (response) {
      localStorage.removeItem("meeting");
      localStorage.removeItem("meetingContent");
      localStorage.removeItem("meetingVersion");
      setInProgress(false);        
    })
    .catch(function (response) {            
        toast.error("Si è verificato un errore pubblicando la seduta!");
        setInProgress(false);        
    });
  }
  


  const onFabClick= (e ) => {           
    //FAB aggiunta ambito
    console.log("OFC",e,idMeeting);        
    //console.log("referrer url",referrer);
    setInProgress(true);
    handlePublishing(idMeeting);          
  };

  

  /********** Delete File Begin **********/
  const handleDeleteFile = (id) => {
    const params = {
      "path": treeData.find(tree => tree.id === id).realPath,
      "childName": treeData.find(node => node.id === id).text,
      "isFile": true
    }
    const operationParams = getOperationParams("deleteFile", treeData[0].text, params)    
    axios.post(properties.productionUrl+'/fileMgrCGFinanza', qs.stringify(operationParams))
      .then(res => {
        console.log(res)
        setTreeData(convertResToFilesTree(res.data.result.dirTree))
      })
      .catch(() => {
        toast.error("Failed to delete")
      })
  }
  /********** Delete File End **********/

  /************* Rename File Begin **************/
  const handleRenameFile = (id, newName) => {
    const params = {
      "path": treeData.find(tree => tree.id === id).realPath,
      "oldName": treeData.find(node => node.id === id).text,
      "newName": newName,
      "isFile": true
    };
    const operationParams = getOperationParams("renameFile", treeData[0].text, params)    
    axios.post(properties.productionUrl+'/fileMgrCGFinanza', qs.stringify(operationParams))
      .then(res => {
        console.log(res)
        if (res.data.status === 'ok') {
          const updatedTreeData = treeData.map(node => {
            if (node.id === id) {
              return {
                ...node,
                id: res.data.result.newId,
                text: newName
              };
            } else {
              return {
                ...node
              }
            }
          })

          setTreeData(updatedTreeData);
        } else {
          toast.error("Failed to update")
          fetchLastIssue()
        }
      })
      .catch(err => {
        console.log(err)
        toast.error("Failed to update")
      })
  }
  /************* Rename File End **************/

  /*********** Move File Begin ***********/
  const handleMoveFile = (newTree, { dragSourceId, dropTargetId }) => {
    
    const targetNode = treeData.find(node => node.id === dropTargetId)
    const sourceNode = treeData.find(node => node.id === dragSourceId)
    console.log("moveFile",targetNode,sourceNode);
    if (sourceNode.data.isFolder) {
      return;
    }

    const params = {
      "frompath": treeData.find(node => node.id === dragSourceId).realPath,
      "topath": `${targetNode.realPath}${targetNode.realPath !== '' ? '/' : ''}${targetNode.text}`,
      "nodeName": treeData.find(node => node.id === dragSourceId).text,
      "position": 0,
      "isFile": true
    }
    const issueName = treeData.find(node => node.id === 1).text
    const operationParams = getOperationParams("moveFile", issueName, params)    
    axios.post(properties.productionUrl+'/fileMgrCGFinanza', qs.stringify(operationParams))
      .then(res => {
        console.log(res)
        if (res.data.status === 'ok') {
          const _newTree = newTree.map(node => {
            if (node.id === dragSourceId) {
              return {
                ...node,
                id: res.data.result.newNode.attr.id,
                realPath: getPathWithParentId(newTree, node.parent),
              };
            } else {
              return node
            }
          })
          setTreeData(_newTree)
        } else {
          toast.error("Error occured!")
        }
      })
      .catch(err => {
        console.log(err)
        toast.error("Error occured!")
      })
  }
  /*********** Move File Begin ***********/

  /*********** Move position ************/
  const handleMove = (direction, id) => {
    
    const sourceNode = treeData.find(node => node.id === id)
    const pos = treeData.filter(node => node.parent === sourceNode.parent).findIndex(node => node.id === id)
    console.log("init", id, treeData,sourceNode,pos)
    console.log("init2",treeData.filter(node => node.parent === sourceNode.parent))
    console.log("init3",treeData.filter(node => node.parent === sourceNode.parent).length)
    if ((pos === 0 && direction === 'up') || (direction === 'down' && pos === (treeData.filter(node => node.parent === sourceNode.parent).length - 1))) {      
      console.log("inside",treeData.filter(node => node.parent === sourceNode.parent));
      return;
    }
    
    const params = {
      "frompath": sourceNode.realPath,
      "topath": sourceNode.realPath,
      "nodeName": sourceNode.text,
      "position": direction === 'up' ? pos - 1 : pos + 1,
      "isFile": true
    }
    const operationParams = {
      app_user: "app",
      app_password: "meeting",
      operation: "moveFile",
      issueName: treeData[0].text,
      params: JSON.stringify(params)
    };    
    axios.post(properties.productionUrl+'/fileMgrCGFinanza', qs.stringify(operationParams))
      .then(res => {       
        if (res.data.result.status === 'ok') {
          fetchLastIssue()
        } else {
          toast.error('Failed to move')
        }
      })
      .catch(() => {
        toast.error('Failed to move')
      })
  }

  console.log(treeData)

  useEffect(() => {
    if(process.env.NODE_ENV !='development' ){
      console.log ("2");
  //CHECK AUTHENTICATION
  var xhr = new XMLHttpRequest();                
  xhr.open('POST', properties.productionUrl+'/rest/ping',true);
  xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");

  xhr.onload = function (e) {
      if (xhr.readyState === 4) {
          if (xhr.status === 200) {                    
              console.log("OK",xhr);      
          } else {
              console.error("Error 1", xhr);        
              localStorage.clear();            
              navigate("/login");
          }
      }
  };

  xhr.onerror = function (e) {
    console.error("Error 2", xhr);
    localStorage.clear();
    navigate("/login");
  };
       
  let urlEncodedData = null; 
  xhr.send(urlEncodedData);
  //CHECK AUTHENTICATION
  }
    if(idMeeting!=undefined){
      if(idMeeting=="last"){
        console.log("if",idMeeting);     
        fetchLastIssue();
      }
      else {        
          if(idMeeting.length==10){
            console.log("elseif",idMeeting);            
            handleIssueExistsStartingFromDateLabel(idMeeting);
        }    
        else{
          console.log("elseelse",idMeeting);
          fetchLastIssue();
        }
      }
    }
    else{
      console.log("altro else",idMeeting);
      fetchLastIssue();
    }
    console.log("UE",idMeeting);
    /*setPageRouter({
      router: history.location.pathname,
      homecolor: "#FF5A60",
      settingcolor: "#879497",
      homebordercolor: "#FF5A60",
      settingbordercolor: "#FFFFFF",
      hometitlecolor: "black",
      settingtitlecolor: "#879497",
        });*/
  }, []);

  return (    
    <div>
    {lockedFile ?(
    <div className="content">      
    <Tooltip title="Rendi Pubblico" placement="left">
    <Fab aria-label="add"                     
                onClick={(event) => {onFabClick(event) }}                
                style={{
                    margin: 0,
                    background: "#046A38",
                    top: 'auto',
                    right: 10,
                    bottom: 25,    
                    left: 'auto',
                    position: 'fixed',}}>                    
                    <SendIcon />                    
    </Fab>          
    </Tooltip>
      <Container maxWidth="xl">
        <Grid container spacing={2}  >
          <Grid item xs={12}  >
            <Box my={3}>
              <Typography className={classes.fontBold} variant="body1" style={{textAlign: "left",paddingBottom:"0px"}}>
                Per caricare i contenuti dellla seduta, aggiungi i file transcinadoli direttamente nelle cartelle:
              </Typography>
            </Box>
                    
            <Box className={classes.bgWhite} style={{paddingTop:"10px", paddingLeft:"20px", borderRadius: "4px", border: "1px solid rgb(196,196,196)",}}>
              <Tree
                ref={treeRef}
                tree={treeData}
                rootId={0}
                initialOpen={true}
                insertDroppableFirst={false}
                sort={false}
                render={(node, options) => (
                  <FileNode
                    node={node}
                    {...options}
                    handleDeleteFile={handleDeleteFile}
                    handleAddFile={handleAddFile}
                    handleRenameFile={handleRenameFile}
                    showProgress={() => setInProgress(true)}
                    handleMove={handleMove}
                  />
                )}                
                onDrop={handleMoveFile}
                classes={{
                  root: classes.treeRoot,
                  draggingSource: classes.draggingSource,
                  dropTarget: classes.dropTarget
                }}
              />
            </Box>
          </Grid>
        </Grid>
        {
          inProgress && (
            <Box className={classes.processing}>
              <CircularProgress className={classes.circular} color="secondary" />
            </Box>
          )
        }
      </Container>
    </div>
    ):(   
      <div>
      {alert}        
      </div>
      )}
    </div>
  )
}