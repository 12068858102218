import {React,  useRef} from "react";
//import { useHistory } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import Calendar from 'rc-year-calendar';
import 'rc-year-calendar/locales/rc-year-calendar.it';
import RouterContext from "../../context/RouterContext";
import {updateSedute} from "./Utils";
import {properties} from "utils/properties";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { Fab, Tooltip } from '@material-ui/core/';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';


export default function CalendarView() {
  //const history = useHistory();
  const navigate = useNavigate();
  const [meetingData, setMeetingData] = useState([]);
  const [bothRoles, setBothRoles] = useState(false);
  const [threeRoles, setThreeRoles] = useState(false);
  const [secondLevelButtons, setSecondLevelButtons]= useState(false);    
  const { pageRouter, setPageRouter } = useContext(RouterContext);
  const [startingYear, setStartingYear]= useState(0);
  var lastMeeting= useRef(null);
  
  const updateUI = function(jsonData) {      
        console.log("UPDATEUI-CV");
        var entireMeeting = Object.entries(jsonData);                    
        var orderMeeting = [];
        var colore='#30720f';
        for (var i = entireMeeting.length - 1; i >= 0; i--) {          
            if(entireMeeting[i][1]["organo"]==="Consiglio")
                colore='#BF360C';
            else
                colore='#30720f';
            var dateLabel=entireMeeting[i][0].substring(4, 8).concat("-").concat(entireMeeting[i][0].substring(2, 4)).concat("-").concat(entireMeeting[i][0].substring(0, 2));
            var eachMeeting = {
                id: entireMeeting[i][0],
                name: 'Seduta del '.concat(entireMeeting[i][1]["dataSeduta"]),              
                location: entireMeeting[i][0],          
                startDate: new Date(dateLabel),
                endDate: new Date(dateLabel),
                color: colore,  
            };            
            orderMeeting.push(eachMeeting);            
            if(lastMeeting.current===undefined) {
                            //console.log("1",lastMeeting, eachMeeting.startDate);
                lastMeeting.current=eachMeeting.startDate;
            }
            else{
                if (lastMeeting.current < eachMeeting.startDate){                
                    lastMeeting.current=eachMeeting.startDate;              
                }
            }
        }      
        setStartingYear(lastMeeting.current.getFullYear());        
        var today={
            id: 0,
            name: 'Oggi',              
            location: '',          
            startDate: new Date(),
            endDate: new Date(),
            color: '#FF0000',                                          
        };
        orderMeeting.push(today);
        console.log("MEETING",orderMeeting);
        setMeetingData(orderMeeting);
  }
  
   const doPostSedute2 = function() {
        console.log("doing sedute post CV");
        var xhr2 = new XMLHttpRequest();                
        xhr2.open('POST', properties.productionUrl+'/rest/checkSeduteJson',true);
        xhr2.setRequestHeader("Content-type", "application/x-www-form-urlencoded");    
        xhr2.onload = function (e) {      
            if (xhr2.readyState === 4) {
                if (xhr2.status === 200) {                              
                    //console.log("OK92",xhr2);
                    var responseText = xhr2.responseText;                    
                    var obj=JSON.parse(responseText);
                    //console.log("OK93",obj, obj.status);          
                    if(obj.status==="204" ){                             
                        console.log("OK DOPOSTSEDUTE2 204",xhr2);                        
                        const jsonListSedute = JSON.parse(localStorage.getItem("listSedute"));
                        updateUI(jsonListSedute);
                        
                    }              
                    else{
                        //status 201
                        console.log("OK DOPOSTSEDUTE2 201",xhr2);  
                        
                        try{
                            localStorage.setItem("listSedute", obj.content);
                            localStorage.setItem("listSeduteVersion", obj.date);                                      
                        }
                        catch(error) {
                            console.log("LOCALSTORAGE ISSUE", error);
                        }                          
                        const jsonListSedute = JSON.parse(obj.content);
                        updateUI(jsonListSedute);                        
                    }
                } 
                else {                    
                    console.log("Errore 3: Recuperando la lista sedute o non presenti sedute CdG per il ruolo dell'utente");                      
                }
            }
        };

        xhr2.onerror = function (e) {
            console.error("Error 22", xhr2.statusText);
        };
        
        let urlEncodedData = null; //"idf=" + meetingId;
        if (localStorage.listSedute!=='' &&localStorage.listSedute!==undefined){            
            //console.log( "QUINDI00");
            const currentData=localStorage.getItem("listSeduteVersion");        
            urlEncodedData = "date=" + currentData;                    
        }       
        //console.log("CALVIEW",urlEncodedData);
        xhr2.send(urlEncodedData);
    }


 
    useEffect(() => {     
        console.log("USEEFFECT CV");    
        console.log("Arrivo qui", document.referrer);  
        if(localStorage.getItem("new_ui")=== undefined || localStorage.getItem("new_ui")=== null || localStorage.getItem("new_ui")=== "false"){        
            /*history.replace({                
                pathname: "/fascicoli",
                });*/
            navigate("/fascicoli",{ replace: true });  
        }              

        if (!window.cordova)
        {
          if(localStorage.getItem("ruolo")==="1"||localStorage.getItem("ruolo")==="2"){
              setBothRoles(true);
          }     
          if(localStorage.getItem("ruolo")==="12"||localStorage.getItem("ruolo")==="21"){
            setThreeRoles(true);
            }     
        }
        else {
          var onSuccess = function(response)
          {
            console.log("OK LOGIN 33");
            var obj=JSON.parse(response);
            var ruolo = obj.Ruolo;
        
            if(ruolo=="1"||ruolo=="2"){
                setBothRoles(true);
            }
            if(ruolo =="12" ||ruolo =="21" ){
                setThreeRoles(true);
            }
          }
        
          var onError = function() {}
        
          window.getInfo3(onSuccess, onError);
        }             

                           
        if(process.env.NODE_ENV !=='development' && !window.cordova){        
        //CHECK AUTHENTICATION
            var xhr = new XMLHttpRequest();                
            xhr.open('POST', properties.productionUrl+'/rest/ping',true);
            xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
    
            xhr.onload = function (e) {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {                         
                    console.log("OK",xhr);   
                    updateSedute("Comitato",doPostSedute2, updateUI);                               
                } else {
                    console.error("Error 1", xhr);   
                    localStorage.clear();                 
                    /*history.push({                
                    pathname: "/login",
                    });*/
                    navigate("/login");  
                }
            }
            };
    
            xhr.onerror = function (e) {
                console.error("Error 2", xhr);
                localStorage.clear();
                /*history.push({                
                    pathname: "/login",
                    });
                    */
                navigate("/login");  
            };
             
            let urlEncodedData = null; 
            xhr.send(urlEncodedData);
        //CHECK AUTHENTICATION
        }
        
        if (window.cordova)
        {
           updateSedute("Comitato",doPostSedute2, updateUI);   
        }
        /* 
        setPageRouter({
            router: history.location.pathname,
            homecolor: "#FF5A60",
            settingcolor: "#879497",
            homebordercolor: "#FF5A60",
            settingbordercolor: "#FFFFFF",
            hometitlecolor: "black",
            settingtitlecolor: "#879497",
        }); */
    }, []);


    const onFabClick= (e ) => {           
        //FAB aggiunta ambito
        console.log("OFC",e);            
        //navigate("/consiglio"); 
        navigate("/calendarConsiglio");         
      };

      const onFabClick2= (e ) => {                   
        console.log("OFC-2",e);            
        //navigate("/collegio");  
        navigate("/calendarCollegio");  
      };

   const topicList = (e) => {    
    console.log("called topic list CV",e);
    if(e.events.length>0){        
        console.log("TO BE INVESTIGATED",e);
        if (e.events[0].location!==null && e.events[0].location!==undefined && e.events[0].location!=="")
            if(e.events[0].color==='#BF360C'){
                /*history.push({
                    //pathname: "/consiglio/" + e.events[0].location+"/summary" ,
                    pathname: "/consiglio/" + e.events[0].location ,
             }); */
             navigate("/consiglio/"+ e.events[0].location );  
            }
            else{
               /* history.push({
                       pathname: "/fascicoli/" + e.events[0].location+"/summary" ,
                }); */
                navigate("/fascicoli/"+ e.events[0].location+"/summary" );
            }
      }
    };


 
  return (
    <div>
        {bothRoles ?(
         <Tooltip title="Consiglio Generale" placement="left">
            <Fab aria-label="add"                 
                //variant="contained" 
                onClick={(event) => {onFabClick(event) }}                
                style={{
                    margin: 0,
                    //background: "#30720f",
                    background: "#BF360C",
                    top: 'auto',
                    right: 10,
                    bottom: 25,
                    color: '0xFFFFFF',
                    left: 'auto',
                    position: 'fixed',}}>                    
                    <ArrowForwardIosIcon />                    
            </Fab>  
          </Tooltip>
       ):( <></>)}
           {threeRoles ?(
   <Tooltip title="Altri Organi" placement="left">
      <Fab aria-label="add"                 
          //variant="contained"           
          onClick={(event) => {setSecondLevelButtons(!secondLevelButtons) }}           
          style={{
              margin: 0,
              //background: "#30720f",
              background: "#CCCCCC",
              top: 'auto',
              //left: 10,
              right: 10,
              bottom: 25,
              color: '0xFFFFFF',
              left: 'auto',
              position: 'fixed',}}>                    
              <MoreHorizIcon />                  
      </Fab>  
    </Tooltip>
 ):( <></>)}
   {threeRoles && secondLevelButtons ? (
    <div> 
        <Tooltip title="Consiglio Generale" placement="left">
            <Fab aria-label="add2"                 
                  //variant="contained" 
                  size='medium'
                  onClick={(event) => {onFabClick(event) }}
                  
                  style={{
                      margin: 0,
                      background: "#BF360C",
                      top: 'auto',                     
                      right: 10,
                      bottom: 90,                      
                      left: 'auto',
                      position: 'fixed',}}>                    
                      <ArrowForwardIosIcon />                        
            </Fab>       
        </Tooltip>
        <Tooltip title="Collegio Sindacale" placement="left">
            <Fab aria-label="add3"                                   
                  size='medium'
                  onClick={(event) => {onFabClick2(event) }}                  
                  style={{
                      margin: 0,
                      background: "#666999",
                      top: 'auto',                     
                      right: 10,
                      bottom: 145,
                      //color: '0xFF0000',
                      left: 'auto',
                      position: 'fixed',}}>                    
                      <ArrowForwardIosIcon />
            </Fab>
        </Tooltip>       
                  </div> 
            ): (<></>)}


      <Calendar                    
          year= {startingYear}          
          language="it"    
          minDate= {new Date("2016/01/01")}       
            /*minDate= {new Date("2020/01/01")}*/        
            /*maxDate= {new Date("2022/12/31")}*/
          maxDate= {new Date().setDate(new Date().getDate()+180)}
          style= "background"
          enableContextMenu={true}          
          dataSource={meetingData}          
          onDayClick={e => topicList(e)}
          />
      </div>
  );
  
  }  
