import React from "react";
import logo from "../../assets/logo.png";
//import { useHistory } from "react-router-dom";
import "../../App.css";
import { useNavigate } from "react-router-dom";
import {properties} from "utils/properties";

export default function Reset() {
    //const history = useHistory();
    const navigate = useNavigate();
    const doPost = function(email) {    
    var xhr = new XMLHttpRequest();        
    
    xhr.open('POST', properties.productionUrl+'/rest/reset',true);
    xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
    
    xhr.onload = function (e) {      
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {   
        alert("Richiesta reset password gestita correttamente. Riceverà a breve una e-mail con le indicazioni per resettare la sua password");              
        /*history.push({
        
                pathname: "/login",
            });*/
            navigate("/login");    
        } else {
          console.error("Error 16", xhr.statusText);          
          alert("Reset Password fallito");  
        }
      }
    };

    
    xhr.onerror = function (e) {
      console.error("Error 26", xhr.statusText);
    };
          
   
    let urlEncodedData = "email=" + email; 
    xhr.send(urlEncodedData);
  }



  const handleSubmit = e => {
    e.preventDefault();
    
    if (!e.target.email.value) {
      alert("E' necessario inserire un indirizzo e-mail valido");
    }  
    else {
      doPost(e.target.email.value);
    } 
  };

  
  
return (   
      <div className="content" style = {{alignItems: "center", display: "flex", height: "100%",  justifyContent: "center"}}>

      <div className="AppLogin">
        <img src={logo} className="logo1" alt="Logo" />
        <form className="form1" onSubmit={handleSubmit}>
          <div className="input-group">
            <label htmlFor="username">Inserire l'indirizzo e-mail associato allo username di cui si vuole reimpostare la password</label>
            <input type="email" name="email" placeholder="e-mail" />
          </div>
          
          <button className="button11">Reset Password</button>
        </form>
        
      </div>
      </div>
    );
  }