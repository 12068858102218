import React, { useState, useEffect, useContext } from "react";
//import { useHistory, useParams } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import RouterContext from "../../context/RouterContext";
import { withStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import InfoIcon from "@material-ui/icons/Info";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import "../../App.css";
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import {ThreeDots} from 'react-loader-spinner';
import { Fab, Tooltip } from '@material-ui/core/';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import {updateSeduta} from "./Utils";
import {properties} from "utils/properties";

export default function Card() {
    
    let {idDossier, idScheda } = useParams();
    //path="/fascicoli/:idDossier/proposteintervento/schede/:id"
    const [loading, setLoading]= React.useState(true);  
    const [score, setScore] = useState([]);
    const [homeData, setHomeData] = useState([]);
    //const history = useHistory();
    const navigate = useNavigate();
    const { pageRouter, setPageRouter } = useContext(RouterContext);     
    const meetingId = idDossier;   
    var coloring="#00FF00";
    const [color, setColor] = useState([]);

    var dateStyle = {
        display: "flex",
        paddingLeft: "25px",
    };
    const id=idScheda;

    const onClickToUpdate= (e ) => {   
        if (!loading) { setLoading(true);} 
        console.log("octu",e);         
        e.stopPropagation(); 

        axios.get(properties.productionUrl+'/postIDSF?IDS='+idScheda).then(response => {                        
            console.log("THEN",response);
            //console.log("i should go first");
        }).catch(({response}) => {            
            console.log("CATCH",response);
            
        }).finally(() => {
            console.log("FINALLY",);
            //console.log("i should go second");
            /*history.replace({                
                pathname: "/fascicoli/"+idDossier,
                });
            */            
            window.location.reload(false);   
            //setLoading(false);
        });
        
    };

    const updateUI = function(jsonData) {
        const keyValuScores = jsonData;
        const homeFieldsItem = Object.entries(keyValuScores.components.home.fields);
        //console.log("HFI",homeFieldsItem);
        var homeItems = [];
        let obiettivo;
        for (var i = 0; i < homeFieldsItem.length; i++) {
            var firstItemCss = "0px";
            var itemBorderColor = "";

            if (i === 0) {
                firstItemCss = "8px";
            }
            if (
                homeFieldsItem[i][1]["componentRef"] ||
                (homeFieldsItem[i][1]["style"] === 0 &&
                    !homeFieldsItem[i][1]["secondValue"] &&
                    !homeFieldsItem[i][1]["comment"])
            ) {
                itemBorderColor = "1px solid #EFEFEF";
            }
            //console.log("home", homeFieldsItem[i]);
            //console.log("home1",keyValuScores);

            if (homeFieldsItem[i][1]["label"]==="Obiettivo") {
                obiettivo = homeFieldsItem[i][1]["value"];
            }
            var item = {
                componentRef: homeFieldsItem[i][1]["componentRef"],
                label: homeFieldsItem[i][1]["label"],
                value: homeFieldsItem[i][1]["value"],
                style: homeFieldsItem[i][1]["style"],
                second: homeFieldsItem[i][1]["secondValue"],
                comment: homeFieldsItem[i][1]["comment"],
                table: homeFieldsItem[i][1]["table"],
                itemcss: firstItemCss,
                bordercss: itemBorderColor,
            };
            if(item.table)
                item.table.shift();
            homeItems.push(item);
        }        
        setHomeData(homeItems);
     

        // Setting store for score
        var keyExist = keyValuScores.hasOwnProperty("scoreboard");
        if (keyExist) {
            setScore(keyValuScores.scoreboard.score1Level);
        }  
        setLoading(false);
    }

    const doPostSeduta2 = function() {
    
        var xhr2 = new XMLHttpRequest();                
        xhr2.open('POST', properties.productionUrl+'/rest/checkScheda',true);
        xhr2.setRequestHeader("Content-type", "application/x-www-form-urlencoded");    
        xhr2.onload = function (e) {      
            if (xhr2.readyState === 4) {
                if (xhr2.status === 200) {                              
                    console.log("OK92",xhr2);
                    var responseText = xhr2.responseText;                    
                    var obj=JSON.parse(responseText);
                    console.log("OK93",obj);          
                    if(obj.status==="204" ){ 
                        const SchedaInfo = JSON.parse(localStorage.getItem("schedaContent"));
                        updateUI(SchedaInfo);
                    }              
                    else{
                        //status 201
                        console.log("OK95",obj.date);

                        try{
                            localStorage.setItem("schedaContent", obj.content);
                            localStorage.setItem("scheda", idScheda);
                            localStorage.setItem("schedaVersion", obj.date);                                                                                                        
                        }
                        catch(error) {
                            console.log("LOCALSTORAGE ISSUE", error);
                        }                 
                        const SchedaInfo = JSON.parse(obj.content);
                        updateUI(SchedaInfo);
                    }
                } 
                else {
                    //console.error("Error 1", xhr.statusText);          
                    alert("Errore recuperando la scheda 1");  
                    /*history.replace({                                
                        pathname: "/editor",
                    });*/
                    navigate("/editor",{ replace: true });                      
                }
            }
        };

        xhr2.onerror = function (e) {
            console.error("Error 22", xhr2.statusText);
        };
    
        let urlEncodedData = "ids=" + idScheda;
        if (localStorage.scheda===idScheda){            
            console.log( "QUINDIC=00");
            const currentData=localStorage.getItem("schedaVersion");        
            urlEncodedData = urlEncodedData+ "&date=" + currentData;                    
        }    
        xhr2.send(urlEncodedData);
    }


    useEffect(() => {
        
        if(process.env.NODE_ENV !='development' && !window.cordova){
            console.log ("2");
        //CHECK AUTHENTICATION
        //var xhr = new XMLHttpRequest();                
        //xhr.open('POST', properties.productionUrl+'/rest/ping',true);
        //xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
    
        //xhr.onload = function (e) {
        //    if (xhr.readyState === 4) {
        //        if (xhr.status === 200) {                    
        //            console.log("OK",xhr);
                    //history.push({                
                    //pathname: "/login/",
                    //});
        //        } else {
        //            console.error("Error 1", xhr);   
        //            localStorage.clear();                 
        //            history.push({                
        //            pathname: "/login",
        //            });
        //        }
        //    }
        //};
    
        //xhr.onerror = function (e) {
        //  console.error("Error 2", xhr);
        //  localStorage.clear();
        //  history.push({                
        //            pathname: "/login",
        //            });
        //};
             
        //let urlEncodedData = null; 
        //xhr.send(urlEncodedData);
        //CHECK AUTHENTICATION
        } 

        updateSeduta(meetingId,doPostSeduta2,updateUI);

        /*setPageRouter({
            router: history.location.pathname,
            homecolor: "#879497",
            settingcolor: "#FF5A60",
            homebordercolor: "#FFFFFF",
            settingbordercolor: "#FF5A60",
            hometitlecolor: "#879497",
            settingtitlecolor: "black",
        });*/
    }, []);

    const BorderLinearProgress = withStyles((theme) => ({
        root: {
            height: 10,
            borderRadius: 5,
        },
        colorPrimary: {
            backgroundColor:
                theme.palette.grey[theme.palette.type === "light" ? 300 : 700],
        },
        bar: {
            borderRadius: 5,
            backgroundColor: "#5cb85c",
        },
    }))(LinearProgress);

    const openScoreBoard = (id) => {
       /* history.push({    
            pathname: "/schede/"+ idScheda + "/scoreboard",        
            //pathname: "/fascicoli/"+meetingId+"/proposteintervento/schede/"+ id+"/scoreboard" ,
        });*/
        navigate("/schede/"+ idScheda + "/scoreboard");  
    };

    const goFinalInfo = (refname) => {
        //console.log("called goFinalInfo");
        if (refname) {
            /*history.push({                
                pathname: "/schede/"+ idScheda + "/" + refname,
            //    pathname: "/fascicoli/"+meetingId+"/proposteintervento/schede/"+ id + "/" + refname,
            });*/
            navigate("/schede/"+ idScheda + "/" + refname);  
        }
    };

    return (        
        <div> 
        {loading ? (
            <div key={uuidv4()} style={{ width:"25%",   display: "flex", 
            marginLeft: "auto", marginRight: "auto" }}> 
             <ThreeDots color="#30720f" height='350' width='150'/>
             </div> 
        ):(
        <div className="content">
             <Tooltip title="Forza aggiornamento" placement="left">
             <Fab aria-label="add"                 
                //variant="contained" 
                onClick={(event) => {onClickToUpdate(event) }}
                
                style={{
                    margin: 0,
                    background: "#30720f",
                    top: 'auto',
                    right: 5,
                    bottom: 25,
                    //color: '0xFF0000',
                    left: 'auto',
                    position: 'fixed',}}>                    
                    <SystemUpdateAltIcon />
                    {/*<input id="fileupload00" type="file" name="file" ref={hiddenFileUpload2} onChange={(event)=> onFileChange2(event)} style={{ display: "none" }}/>*/}
                </Fab>   
                </Tooltip>       
        <div style={{
            marginLeft: "0px",
            width: "100%",
            height: "10px",
            //background: "#00FF00",
            //background: color,
            //borderBottomRightRadius: "8px",
            //borderTopRightRadius: "8px",
            }}> </div>
            <div>
                {homeData.length > 0 &&
                    homeData.map((item, index) => (                        
                        <div
                            className="common-list"
                            key={index}
                            style={{                                
                                margin: "0px",
                                borderRadius: "0px",
                                borderTopLeftRadius: item.itemcss,
                                borderTopRightRadius: item.itemcss,
                                borderBottom: item.bordercss,    
                                //paddingLeft: "30px",                            
                            }}
                            //onClick={() => goFinalInfo(item.componentRef)}
                        >
                        

                            {item.componentRef && !item.comment ? (
                                item.label === "Executive Summary" ? (
                                    //console.log("e21",item.value),
                                    //console.log("e22",item),
                                    //<div
                                    //    style={{
                                    //        textAlign: "left",
                                    //        paddingLeft: "25px",
                                    //    }}
                                    //>
                                    <div
                                        style={{                                            
                                            display:"inline",                                            
                                            padding: "0px 25px",
                                            margin: "20px 0px",
                                            textAlign: "left",
                                            cursor: "pointer",
                                        }}
                                        onClick={() =>
                                            goFinalInfo(item.componentRef)
                                        }
                                    >
                                        <p className="text importantText">
                                           {item.label}:
                                        </p>
                                       
                                        <div>
                                            <p className="text"
                                            style={{                                                
                                                whiteSpace: 'pre-wrap' }}
                                            dangerouslySetInnerHTML={{
                                                __html: item.value,
                                            }}
                                            />  
                                        </div>
                                     {item.table ? (
                            item.table.map((extraitem, index) => (
                            //console.log("uff",item),
                                <div
                                    key={index}
                                    style={{ paddingBottom: "20px" }}                                  
                                    >  
                                 <p className="text"                                            
                                            dangerouslySetInnerHTML={{
                                                __html: extraitem[3],
                                            }}
                                            />  

                                </div>


                            ))
                        ) : (
                            <></>
                        )}


                                    </div>


                                ) : (
                                

                                    <div
                                        style={{                                            
                                            display:"inline",                                            
                                            padding: "0px 25px",
                                            margin: "20px 0px",
                                            textAlign: "left",
                                            cursor: "pointer",
                                        }}
                                        onClick={() =>
                                            goFinalInfo(item.componentRef)
                                        }
                                    >
                                        <p className="text importantText textInLine ">
                                            {item.label}:
                                        </p>
                                        <p className="text normalTextInLine"
                                            dangerouslySetInnerHTML={{
                                                __html: item.value,
                                            }}
                                        />        
                                    </div>
                                )
                            ) : !item.componentRef &&
                              !item.comment &&
                              (item.style === 0 || item.style === 2) ? (
                              //console.log("e32",item.value),
                                item.label === "Executive Summary" ? (

                                    <div
                                        style={{
                                            textAlign: "left",
                                            paddingLeft: "25px",
                                            paddingRight: "25px",
                                        }}
                                    >
                                        <p className="text importantText">
                                            {item.label}:
                                        </p>                                        
                                        <div
                                            style={{ paddingBottom: "20px", whiteSpace: 'pre-wrap' }}     >                         
                                        <p className="text" 
                                            style={{                                                          
                                                whiteSpace: 'pre-wrap' 
                                            }}
                                             dangerouslySetInnerHTML={{
                                                __html: item.value,
                                            }}
                                        />
                                    </div>




                                        {item.table &&
                                            item.table.map(
                                                (extraitem, index) => (
                                                //console.log ("ei",extraitem),
                                                    <div
                                                        key={index}
                                                        style={{
                                                            paddingBottom:"20px",
                                                        }}
                                                        
                                                    >

                                                     <p className="text normalTextInLine" 
                                            style={{                                                          
                                                whiteSpace: 'pre-wrap' 
                                            }}
                                             dangerouslySetInnerHTML={{
                                                __html: extraitem[3],
                                            }}
                                        />
                                    </div>   

                                                )
                                            )}
                                    </div>
                                ) : item.label === "Descrizione" ? (  
                                    //console.log("e122",item.value),                              
                                    <div
                                        style={{
                                            textAlign: "left",
                                            padding: "0px 15px",
                                        }}
                                    >
                                        <p className="text importantText">
                                            {item.label}:
                                        </p>
                                        <p className="text"
                                            style={{                                                
                                                textAlign: "justify",
                                            }}
                                        >
                                            {item.value}
                                        </p>
                                    </div>
                                ) : (
                                    //console.log("boh", item),
                                    <div style={{ paddingLeft: "25px", 
                                    display:"inline", textAlign: "left",
                                      padding: "0px 25px",
                                    margin: "20px 0px",

                                }}>
                                        <p className="text importantText textInLine" >
                                            {item.label}:
                                        </p>
                                        <p className="text normalTextInLine" 
                                            style={{                                                
                                                paddingRight: "25px",
                                                whiteSpace: 'pre-wrap' 
                                            }}
                                             dangerouslySetInnerHTML={{
                                                __html: item.value,
                                            }}
                                        />
                                    </div>
                                )
                            ) : item.style && item.second ? (
                                //console.log("e39",item.value),
                                <div
                                    style={{
                                        //background: "#FEADB0",                                                
                                        background: "#a2ee7d",
                                        color: "#4B0307",
                                        textAlign: "left",
                                        padding: "0px 25px",

                                    }}
                                    className="company-info"
                                >
                                    <Accordion
                                        style={{
                                            //background: "rgb(254, 173, 176)",
                                            //background: "#FF0000",
                                            background: "#a2ee7d",
                                            boxShadow: "unset",                                            
                                        }}
                                       
                                    >
                                        <AccordionSummary
                                            expandIcon={
                                                <InfoIcon
                                                    style={{
                                                        //color: "rgb(75, 3, 7)",
                                                        color: "#30720f",
                                                        top: "-32px !important",
                                                        background:
                                                            "unset !important",
                                                    }}
                                                />
                                            }
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                            style={{
                                                userSelect: "text",
                                                padding: "0px",
                                                flexDirection: "unset",
                                            }}                                            
                                        >
                                            <Typography component={'div'}>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent:
                                                            "space-between",
                                                    }}
                                                >
                                                    <p className="text" >
                                                        {item.label}:
                                                    </p>
                                                </div>
                                                <p className="text"
                                                style={{ marginTop: "0px", whiteSpace: 'pre-wrap' }}>
                                                    {item.value}
                                                </p>
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails
                                            style={{ padding: "0px" }}
                                        >
                                            <Typography component={'div'}>
                                                <p className="text">
                                                    {item.label}
                                                </p>
                                                <p className="text">
                                                    Ruolo della compagnia:
                                                </p>
                                                <p className="text" style={{ marginTop: "0px", whiteSpace: 'pre-wrap' }}> {item.second}</p>
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                            ) : item.comment ? (
                                //console.log("e72",item.value),
                                //console.log("e73",item.comment),
                                <div
                                    style={{
                                        textAlign: "left",
                                        padding: "0px 25px",
                                    }}
                                >
                                    <div>
                                        <p className="text importantText">
                                            {item.label}:
                                        </p>
                                        <p className="text"
                                            style={{                                                
                                                whiteSpace: 'pre-wrap' }}
                                            dangerouslySetInnerHTML={{
                                                __html: item.value,
                                            }}
                                        />    
                                           
                                    </div>
                                    <div
                                        style={{
                                            color: "#879497",
                                            padding: "0px 25px",
                                            //border: "1px solid #FEADB0",
                                            border: "1px solid #30720f",
                                            borderRadius: "20px",
                                            margin: "40px 0px",
                                        }}
                                    >
                                        <p className="text" style={{
                                            
                                            fontSize: "14px",
                                        }}>
                                            Commento:
                                        </p>
                                         <p className="text"

                                            style={{                                                
                                                whiteSpace: 'pre-wrap', fontSize: "14px" }}
                                            dangerouslySetInnerHTML={{
                                                __html: item.comment,
                                            }}
                                        />  
                                    </div>
                                </div>
                            ) : item.style &&
                              !item.comment &&
                              !item.secondValue &&
                              !item.componentRef ? (
                              //console.log("ho", item),
                              
                                <div
                                    style={{                                        
                                        //background: "#FEADB0",
                                        background: "#a2ee7d",
                                        color: "#4B0307",
                                        textAlign: "left",
                                        padding: "0px 25px",
                                        border: "1px solid rgb(239, 239, 239)",
                                        width: "100%",
                                    }}
                                >
                                
                                    <div
                                        style={{
                                           display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                   
                                        <p className="text">
                                            {item.label}: 
                                        </p>
                                    </div>
                                  
                                     <p className="text"
                                            style={{                                            
                                            whiteSpace: 'pre-wrap' }}
                                            dangerouslySetInnerHTML={{
                                                __html: item.value,
                                            }}

                                         
                                        />  
                                     
                                </div>
                            ) : (
                                <></>
                            )}
                            {item.componentRef ? (
                                //console.log ("cref", item),
                                <div
                                    style={{
                                        paddingRight: "25px",
                                        color: "#879497",
                                        display: "flex",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                        }}
                                    >
                                        <span
                                            className="dot"
                                            style={{ marginBottom: "5px" }}
                                        ></span>
                                        <span className="dot"></span>
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        <span
                                            className="dot"
                                            style={{ marginLeft: "3px" }}
                                        ></span>
                                    </div>
                                </div>
                            ) : (
                                <></>
                            )}
                        </div>
                    ))}

                {score.length ? (
                    <div
                        className="scoreboard"
                        onClick={() => openScoreBoard(id)}
                        style={{
                                //paddingLeft: "50px",
                                cursor: "pointer",
                            }}
                    >
                        <div
                            style={{

                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",                                
                            }}
                        >
                            <p className="text importantText">Scoreboard:</p>
                            <div
                                style={{
                                    color: "#879497",
                                    display: "flex",                                    
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",                                        
                                    }}
                                >
                                    <span
                                        className="dot"
                                        style={{ marginBottom: "5px", }}
                                    ></span>
                                    <span className="dot"></span>
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <span
                                        className="dot"
                                        style={{ marginLeft: "3px" }}
                                    ></span>
                                </div>
                            </div>
                        </div>
                        {score.map((item, index) => (
                            <div key={index}>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alingItems: "center",
                                    }}
                                >
                                    <p className="text">{item.label}</p>
                                    <p className="text">{item.number}/10</p>
                                </div>
                                <BorderLinearProgress
                                    variant="determinate"
                                    value={item.number * 10}
                                />
                            </div>
                        ))}
                    </div>
                ) : (
                    <></>
                )}
            </div>
        </div>
    )}</div>      
    );
}
