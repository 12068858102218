const customNodeStyles = () => ({
  root: {
    alignItems: "center",
    height: "36px",
    
    paddingInlineEnd: "8px",
    width: "80%",
    display: "flex",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)"
    }
  },
  expandIconWrapper: {
    alignItems: "center",
    fontSize: "0",
    cursor: "pointer",
    display: "flex",
    height: "24px",
    justifyContent: "center",
    width: "24px",
    transition: "transform linear 0.1s",
    transform: "rotate(0deg)"    
  },
  isOpen: {
    transform: "rotate(90deg)"
  },
  labelGridItem: {
    paddingInlineStart: "8px"
  },
  actionButton: {
    padding: "0 4px"
  },
  menuList: {
    maxWidth: "360px",
    minWidth: "150px"
  },
  labelTextField: {
   "& .MuiInputBase-input": {
     paddingTop: "6px",
     paddingBottom: "6px",
     background: "white",
     width:"600px",
     fontSize: "16px"
   } 
  },
  fileDropzone: {
    position: "absolute",
    width: "100%",
    height: '100%',
    top: '0',
    left: '0'   
  },
  fileNode: {
    minWidth: '250px',
    width: 'fit-content'
  }
})

export default customNodeStyles
