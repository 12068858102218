import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/styles'

import Typography from "@material-ui/core/Typography";
import { ArrowRight } from "@material-ui/icons";
import { useDragOver } from "@minoru/react-dnd-treeview";
import Popover from '@material-ui/core/Popover';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField'

import ClassificationMenu from "components/ClassificationMenu"

import TypeIcon from "./TypeIcon";

import styles from "assets/jss/components/customNodeStyles"

const useStyles = makeStyles(styles)

const CustomNode = (props) => {

  const classes = useStyles()

  const {
    handleDelete,
    handleAddNewNode,
    handleChangeText,
    handleChangeColor,
    newNodeId,
    lockedFile,
    isCreated,
    handleMove
  } = props

  const { id, droppable, data } = props.node;
  const indent = props.depth * 24;

  const [anchorEl, setAnchorEl] = useState(null);

  const [visibleInput, setVisibleInput] = useState(false)
  const [labelText, setLabelText] = useState('')

  const handleToggle = () => {
    // e.stopPropagation();
    props.onToggle(props.node.id);
  };

  const dragOverProps = useDragOver(id, props.isOpen, props.onToggle);

  const handleClick = (event) => {
    event.preventDefault();
    if (!lockedFile) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleUpdateText = (e) => {
    if (e.key === "Enter") {
      if (newNodeId === "") {
        setVisibleInput(false)
      }      
      handleChangeText(id, labelText)
    }
  }

  const open = Boolean(anchorEl);

  const handleMovePos = (direction) => {
    handleClose()
    handleMove(direction, id)
  }

  useEffect(() => {   
    if (newNodeId === id) {     
      setVisibleInput(true)
      setLabelText('')
    } else {
      setVisibleInput(false)
    }
    // setLabelText(props.node.text)
  }, [newNodeId, props.node.text])  

  // useEffect(() => {
  //   setLabelText(props.node.text)
  // }, [handleChangeText])

  return (
    <>
      <div
        className={`tree-node ${classes.root}`}
        style={{ paddingInlineStart: indent }}
        aria-controls={`menu-${id}`}
        aria-haspopup="true"
        onContextMenu={handleClick}
        {...dragOverProps}
      >
        <div
          className={`${classes.expandIconWrapper} ${props.isOpen ? classes.isOpen : ""
            }`}
        >
          {props.node.droppable && (
            <div
            onClick={handleToggle}
            >
              <ArrowRight />
            </div>
          )}
        </div>
        <div>
          <TypeIcon
            droppable={droppable}
            fileType={data?.fileType}
            folderType={data?.color}
            other={props.node}
          />
        </div>
        <div className={classes.labelGridItem}>
          {
            visibleInput ? (
              <TextField
                id={`text-field-${id}`}
                variant="outlined"
                value={labelText}
                onChange={(e) => setLabelText(e.target.value)}
                className={classes.labelTextField}
                onKeyPress={handleUpdateText}
                autoFocus
              />
            ) : (
              <Typography style={{fontSize: "16px" }} variant="body2">{props.node.text}</Typography>
            )
          }
        </div>
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          className={classes.menuList}
        >
          <ListItem button onClick={() => { handleAddNewNode(id); handleClose() }}>
            <ListItemText primary="Crea" />
          </ListItem>
          {
            id !== 1 && (
              <>
                <ListItem button onClick={() => { setLabelText(props.node.text); setVisibleInput(true); handleClose(); }}>
                  <ListItemText primary="Rinomina" />
                </ListItem>
                <ListItem button onClick={() => handleDelete(id)}>
                  <ListItemText primary="Elimina" />
                </ListItem>
                <ClassificationMenu handleChange={(_color) => { handleChangeColor(id, _color); handleClose(); }} />
                <ListItem button onClick={() => handleMovePos('up')}>
                  <ListItemText primary="Sposta su" />
                </ListItem>
                <ListItem button onClick={() => handleMovePos('down')}>
                  <ListItemText primary="Sposta giù" />
                </ListItem>
              </>
            )
          }

        </List>
      </Popover>
    </>
  )
}

export default CustomNode
