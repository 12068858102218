import React, { useState, useCallback, useEffect } from "react";
import { makeStyles } from '@material-ui/styles'
import { useDropzone } from 'react-dropzone'
import { useDragOver } from "@minoru/react-dnd-treeview";

import * as CryptoJS from 'crypto-js'
import {properties} from "utils/properties";
import { ArrowRight } from "@material-ui/icons";

import Typography from "@material-ui/core/Typography";
import Popover from '@material-ui/core/Popover';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField'
import Box from "@material-ui/core/Box"

import TypeIcon from "./TypeIcon";

import styles from "assets/jss/components/customNodeStyles"

const useStyles = makeStyles(styles)

const FileNode = (props) => {

  const classes = useStyles()

  const {
    handleDeleteFile,
    handleAddFile,
    handleRenameFile,
    showProgress,
    handleMove
  } = props

  const { id, droppable, data } = props.node;
  const indent = props.depth * 24;

  const [anchorEl, setAnchorEl] = useState(null);

  const [visibleInput, setVisibleInput] = useState(false)
  const [labelText, setLabelText] = useState(props.node.text)

  const handleToggle = (e) => {
    console.log("FN HTOGGLE", e);
    e.stopPropagation();
    props.onToggle(props.node.id);
  };

  const dragOverProps = useDragOver(id, props.isOpen, props.onToggle);

  const handleClick = (event) => {
    console.log("FN HCLICK", event);
    event.preventDefault();
    if (props.node.data.isFile) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleUpdateText = (e) => {
    if (e.key === "Enter") {
      setVisibleInput(false)
      handleRenameFile(id, labelText)
    }
  }

  const open = Boolean(anchorEl);

  /******* File Dropzone Begin *********/
  const onDrop = useCallback(acceptedFiles => {
    console.log("FN ON DROP", acceptedFiles);
    showProgress()
    const fileName = acceptedFiles[0].name
    const fileType = acceptedFiles[0].type
    const reader = new FileReader()
    reader.readAsArrayBuffer(acceptedFiles[0]);
    reader.onload = () => {
      var wordArray = CryptoJS.lib.WordArray.create(reader.result);
      var md5 = CryptoJS.MD5(wordArray).toString();
      var base64 = CryptoJS.enc.Base64.stringify(wordArray);
      handleAddFile(fileName, fileType, base64, md5, props.node.id)
    }
  }, [])

  const handlePreviewFile = () => {
    const url = properties.productionUrl+`/fileManager/openFileCGCultura.php?file=${props.node.realPath}/${props.node.text}`
    window.open(url);
  }

  const handleMovePos = (direction) => {
    console.log("FN HMP", direction);
    handleClose()
    handleMove(direction, id)
  }

  const { getRootProps, getInputProps } = useDropzone({ onDrop })

  /********* File Dropzone End **********/

  useEffect(() => {
    setLabelText(props.node.text)
  }, [props.node.text])

  useEffect(() => {
    setLabelText(props.node.text)
  }, [handleRenameFile])

  return (
    <>
      <div
        className={`tree-node ${classes.root} ${classes.fileNode}`}
        style={{ paddingInlineStart: indent }}
        aria-controls={`menu-${id}`}
        aria-haspopup="true"
        onContextMenu={handleClick}
        {...dragOverProps}
      >
        <div
          className={`${classes.expandIconWrapper} ${props.isOpen ? classes.isOpen : ""
            }`}
        >
          {props.node.droppable && (
            <div onClick={handleToggle}>
              <ArrowRight />
            </div>
          )}
          {
            !props.node.droppable && (
              <div>
                <ArrowRight />
              </div>
            )
          }
        </div>
        <div>
          <TypeIcon
            droppable={droppable}
            fileType={data?.fileType}
            folderType={data?.color}
          />
        </div>
        <Box className={classes.labelGridItem} position="relative">
          {
            visibleInput ? (
              <TextField
                id={`text-field-${id}`}
                variant="outlined"
                value={labelText}
                onChange={(e) => setLabelText(e.target.value)}
                className={classes.labelTextField}
                onKeyPress={handleUpdateText}
                autoFocus
              />
            ) : (
              <Typography style={{fontSize: "16px" }} variant="body2">{props.node.text}</Typography>
            )
          }
          {
            props.node.data.isFolder && (
              <Box {...getRootProps()} className={classes.fileDropzone}>
                <input {...getInputProps()} />
              </Box>
            )
          }
        </Box>
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          className={classes.menuList}
        >
          <ListItem button onClick={() => { handlePreviewFile(); handleClose(); }}>
            <ListItemText primary="Apri" />
          </ListItem>
          <ListItem button onClick={() => { setVisibleInput(true); handleClose(); }}>
            <ListItemText primary="Rinomina" />
          </ListItem>
          <ListItem button onClick={() => { handleDeleteFile(id); handleClose() }}>
            <ListItemText primary="Elimina" />
          </ListItem>
          <ListItem button onClick={() => handleMovePos('up')}>
            <ListItemText primary="Sposta su" />
          </ListItem>
          <ListItem button onClick={() => handleMovePos('down')}>
            <ListItemText primary="Sposta giù" />
          </ListItem>
        </List>
      </Popover>
    </>
  )
}

export default FileNode
