import React, { useState, useEffect, useContext } from "react";
//import { useParams, useHistory } from "react-router-dom";
import { useParams} from "react-router-dom";
import RouterContext from "../../context/RouterContext";
import {updateSeduta} from "./Utils";
import {properties} from "utils/properties";
import { useNavigate } from "react-router-dom";

export default function SpecificExtra() {
    let { idDossier, id, type, item_id } = useParams();
    //console.log("SpecificExtra",idDossier, id, type, item_id);    
    const [extraData, setExtraData] = useState([]);
    const { pageRouter, setPageRouter } = useContext(RouterContext);
    //const history = useHistory();    
    const navigate = useNavigate();
    const meetingId = idDossier;


    const updateUI = function(jsonData) {                    
        const keySubArgument = Object.entries(jsonData)[5][1];        
        const valueSubArgument = Object.entries(keySubArgument);        
        var index16;
        valueSubArgument.forEach( (entry, index1) => {
                let key= entry[0];
                //let value= entry[1];
                if(key===id) {                                        
                    index16=index1;
                    console.log("index",entry, index16);
                }                                                
        }); 
        
        const arrExactData = Object.entries(valueSubArgument[index16][1]);
        var arrCardData;        
        var itemIds = [];

        if (type == "container") {
            for (var i = 0; i < arrExactData.length; i++) {
                if (arrExactData[i][0] === "containers") {
                    arrCardData = arrExactData[i][1][item_id]["cardIds"];           
                }
            }

            for (var ii = 0; ii < arrCardData.length; ii++) {
                var itemId = {
                    type: arrCardData[ii]["type"],
                    item_id: arrCardData[ii]["item_id"],
                };
                itemIds.push(itemId);
            }
        } else {
            for (var i = 0; i < arrExactData.length; i++) {
                if (arrExactData[i][0] === "cardIds") {
                    for (var j = 0; j < arrExactData[i][1].length; j++) {
                        itemIds.push(arrExactData[i][1][j]["item_id"]);
                    }
                }
            }
        }
        //console.log("Today ItemIds",itemIds);
        var objCard = Object.entries(jsonData)[4][1];
        var arrCard = Object.entries(objCard);
        var cardLength = arrCard.length;
        var schedaItems = [];

        for (var k = 0; k < itemIds.length; k++) {
            var schedaItem = [];
            if (itemIds[k]["type"] === "scheda") {
                for (var m = 0; m < cardLength; m++) {
                    if (itemIds[k]["item_id"] == arrCard[m][0]) {
                        var arrSearchCard = Object.entries(arrCard[m][1]);
                        var finalCard = Object.entries(
                            arrSearchCard[arrSearchCard.length - 1][1]
                        );
                        var finalCardItemSize = finalCard[0][1].length;
                        var argomento = "";
                        var sotto = "";
                        var init = "";
                        var po = "";
                        var rs= "";
                        var local = "";
                        var price = "";
                        var settore="";
                        var schedaID = arrCard[m][0];
                        var tableItems = [];
                        for (var h = 0; h < finalCardItemSize; h++) {
                            if (
                                finalCard[0][1][h].label === "Argomento" ||
                                finalCard[0][1][h].label === "Obiettivo"
                            ) {
                                argomento = finalCard[0][1][h].value;
                            }
                            if (finalCard[0][1][h].label === "Settore") {
                                settore = finalCard[0][1][h].value;
                            }
                            if (
                                finalCard[0][1][h].label ===
                                    "Sotto-Argomento" ||
                                finalCard[0][1][h].label === "Missione"
                            ) {
                                //26/03/2023 per evitare di avere la label missione= "convenzioni, etc"
                                if(finalCard[0][1][h].value !="CONVENZIONI, PROGETTI SPECIALI, ULTERIORI PRATICHE E DOCUMENTI")
                                //26/03/2023
                                    sotto = finalCard[0][1][h].value;
                            }
                            if (
                                finalCard[0][1][h].label === "Iniziativa" ||
                                finalCard[0][1][h].label ===
                                    "Denominazione iniziativa"
                            ) {
                                init = finalCard[0][1][h].value;
                            }
                            if (finalCard[0][1][h].label === "Progetto Operativo" ||
                                finalCard[0][1][h].label === "Progetto operativo")
                            {
                                 po = finalCard[0][1][h].value;
                                 //console.log("po",po);
                            }
                            if (finalCard[0][1][h].label === "Ragione Sociale" ||
                                finalCard[0][1][h].label === "Ragione sociale") {
                                    rs = finalCard[0][1][h].value;
                            }
                            if (finalCard[0][1][h].label === "Localizzazione") {
                                local = finalCard[0][1][h].value;
                            }
                            if (
                                finalCard[0][1][h].label ===
                                    "Importo proposto" ||
                                finalCard[0][1][h].label === "Costo"
                            ) {
                                price = finalCard[0][1][h].value;
                            }
                            if (finalCard[0][1][h].label === "così ripartito") {
                                for (
                                    var t = 1;
                                    t < finalCard[0][1][h].table.length;
                                    t++
                                ) {
                                    var tableItem = [];
                                    tableItem = {
                                title: finalCard[0][1][h].table[t][3],
                                content: finalCard[0][1][h].table[t][4],
                                content2: finalCard[0][1][h].table[t][5],
                            };
                                    tableItems.push(tableItem);
                                }
                            }
                        }
                        schedaItem = {
                            argomento: argomento,
                            sotto: sotto,
                            init: init,
                            po: po,
                            rs: rs,
                            local: local,
                            price: price,
                            color: valueSubArgument[index16][1].color,
                            id: schedaID,
                            table: tableItems,
                            type: "scheda",
                            settore: settore,
                        };
                        schedaItems.push(schedaItem);
                        //console.log("Today SchedaItems",schedaItems);
                    }
                }
            } else {
                
                for (var i = 0; i < arrExactData.length; i++) {
                    if (arrExactData[i][0] === "premesse") {
                     
                      schedaItem = {
                        value: arrExactData[i][1][itemIds[k]["item_id"]].value,
                        url: arrExactData[i][1][itemIds[k]["item_id"]].url,
                        label: arrExactData[i][1][itemIds[k]["item_id"]].label,
                        type: "premesse",
                        color: valueSubArgument[index16][1].color,
                        table: [],
                    };
                    schedaItems.push(schedaItem);         
                    }
                }
            }
        }
        setExtraData(schedaItems);                
    }


    const doPostSeduta2 = function() {
    //console.log("doing sedute post");
    var xhr2 = new XMLHttpRequest();                
    xhr2.open('POST', properties.productionUrl+'/rest/checkSeduta',true);
    xhr2.setRequestHeader("Content-type", "application/x-www-form-urlencoded");    
    xhr2.onload = function (e) {      
      if (xhr2.readyState === 4) {
        if (xhr2.status === 200) {                              
            //console.log("OK92",xhr2);
            var responseText = xhr2.responseText;                    
            var obj=JSON.parse(responseText);
            console.log("OK93",obj);          
            if(obj.status==="204" ){ 
                console.log("OK DOPOSTSEDUTE2 204",xhr2);  
                const MeetingInfo = JSON.parse(localStorage.getItem("meetingContent"));                        
                updateUI(MeetingInfo);
            }              
            else{
                //status 201
                console.log("OK DOPOSTSEDUTE2 201",xhr2); 
                
                try{
                    localStorage.setItem("meetingContent", obj.content);
                    localStorage.setItem("meeting", meetingId);
                    localStorage.setItem("meetingVersion", obj.date);                                                     
                }
                catch(error) {
                    console.log("LOCALSTORAGE ISSUE", error);
                }  

                const MeetingInfo = JSON.parse(obj.content);
                updateUI(MeetingInfo);
            }
        } 
        else {
          console.error("Error 1", xhr2.statusText);          
          alert("Errore recuperando la seduta");  
        }
      }
    };

    xhr2.onerror = function (e) {
      console.error("Error 22", xhr2.statusText);
    };
    
    let urlEncodedData = "idf=" + meetingId;
    if (localStorage.meeting===meetingId){            
        console.log( "QUINDICI00");
        const currentData=localStorage.getItem("meetingVersion");        
        urlEncodedData = urlEncodedData+ "&date=" + currentData;                    
        }    
    xhr2.send(urlEncodedData);
}




    useEffect(() => {
        if(process.env.NODE_ENV !='development' && !window.cordova){            
        //CHECK AUTHENTICATION
        var xhr = new XMLHttpRequest();                
        xhr.open('POST', properties.productionUrl+'/rest/ping',true);
        xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
    
        xhr.onload = function (e) {
            if (xhr.readyState === 4) {
                if (xhr.status === 200) {                    
                    console.log("OK",xhr);
                    updateSeduta(meetingId,doPostSeduta2,updateUI);
                } else {
                    console.error("Error 1", xhr);    
                    localStorage.clear();                
                    /*history.push({                
                    pathname: "/login",
                    });*/
                    navigate("/login");  
                }
            }
        };
    
        xhr.onerror = function (e) {
          console.error("Error 2", xhr);
          localStorage.clear();
          /*history.push({                
                    pathname: "/login",
                    });*/
            navigate("/login");  
        };
             
        let urlEncodedData = null; 
        xhr.send(urlEncodedData);
        //CHECK AUTHENTICATION
        }
        
        if (window.cordova)
        {
            updateSeduta(meetingId,doPostSeduta2,updateUI);
        }
        
        /*setPageRouter({
            router: history.location.pathname,
            homecolor: "#879497",
            settingcolor: "#FF5A60",
            homebordercolor: "#FFFFFF",
            settingbordercolor: "#FF5A60",
            hometitlecolor: "#879497",
            settingtitlecolor: "black",
        }); */
    }, []);

    const onScheda = (id, type, url) => {
        if (type === "scheda") {
            /*history.push({               
                pathname: "/fascicoli/" + meetingId+"/proposteintervento/schede/"+ id,   
            });*/
            navigate("/fascicoli/" + meetingId+"/proposteintervento/schede/"+ id);  
        } else {
            window.open(url);
        }
    };
    return (
        <div className="content">
            {extraData.map((item, index) => (
                <div
                    className="scheda-item"
                    style={{
                        borderRightColor: item.color, cursor: "pointer",
                        paddingBottom: "1px",
                    }}
                    key={index}
                    onClick={() => onScheda(item.id, item.type, item.url)}
                >
                    <div
                        className="common-list"
                        key={index}
                        style={{
                            position: "relative",
                            alignItems: "unset",
                            display: "unset",
                            position: "unset",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                        >
                            <div>
                                {item.type === "premesse" ? (
                                    <p className= "textBlackSedute "
                                        style={{
                                            paddingLeft: "50px",
                                            //fontSize: "18px",
                                            //fontWeight: "bold",
                                        }}
                                    >
                                        {item.label} {item.value}
                                    </p>
                                ) : (
                                    <>
                                        <div
                                            style={{
                                                paddingLeft: "30px",
                                                textAlign: "left",
                                            }}
                                        >
                                            {item.argomento ? (
                                            <p className= "textGreySedute "
                                                style={{
                                                    //fontWeight: "bold",
                                                    //color: "#879497",
                                                    paddingLeft: "20px",
                                                    marginBottom: "0px",
                                                }}
                                            >
                                                Obiettivo:{" "}
                                                <span className= "textBlackSedute "
                                                    //style={{
                                                    //    fontWeight: "bold",
                                                    //    color: "black",
                                                    //}}
                                                >
                                                    {item.argomento}
                                                </span>
                                            </p>
                                            ): (<></> )}

                                            {item.settore ? (
                                            <p className= "textGreySedute "
                                                style={{
                                                    //fontWeight: "bold",
                                                    //color: "#879497",
                                                    paddingLeft: "20px",
                                                    marginBottom: "0px",
                                                }}
                                            >
                                                Settore:{" "}
                                                <span className= "textBlackSedute "
                                                    //style={{
                                                    //    fontWeight: "bold",
                                                    //    color: "black",
                                                    //}}
                                                >
                                                    {item.settore}
                                                </span>
                                            </p>
                                            ): (<></> )}
                                            
                                            {item.sotto ? (
                                            <p className= "textGreySedute "
                                                style={{
                                                    //fontWeight: "bold",
                                                    //color: "#879497",
                                                    paddingLeft: "20px",
                                                    marginBottom: "0px",
                                                }}
                                            >
                                                Missione:{" "}
                                                <span className= "textBlackSedute "
                                                    //style={{
                                                    //    fontWeight: "bold",
                                                    //    color: "black",
                                                    //}}
                                                >
                                                    {item.sotto}
                                                </span>
                                            </p>
                                            ): (<></>)}
                                        </div>
                                        <div
                                            style={{                                                
                                                textAlign: "left",
                                                paddingRight: "100px",
                                                paddingLeft: "50px",
                                                marginTop: "16px",
                                                marginBottom: "16px",
                                            }}
                                        >
                                            <div  style={{
                                                display: "inline",                                                                                     
                                            }}>
                                                {item.init ? (
                                                    <p className= "textInLine textGreySedute"
                                                    style={{
                                                        //fontWeight: "bold",
                                                        //color: "#879497",
                                                        marginBottom: "0px",
                                                        //display: "inline",                                                
                                                       

                                                    }}
                                                    >
                                                        Denominazione Iniziativa:&nbsp;
                                                    </p>
                                                ) : (
                                                    <p className= "textInLine textGreySedute"
                                                    style={{
                                                        //fontWeight: "bold",
                                                        //color: "#879497",
                                                        marginBottom: "0px",
                                                        //display: "inline",
                                                       
                                                    }}
                                                    >
                                                        Progetto Operativo:&nbsp;
                                                    </p>
                                                )}
                                                {item.init ? (
                                                    <p className= "textInLine textBlackSedute"
                                                        style={{
                                                            //fontWeight: "bold",
                                                            //color: "black",
                                                            marginBottom: "0px",
                                                            //display: "inline",
                                                       
                                                        }}
                                                    >
                                                        {item.init}
                                                    </p>
                                                ) : (
                                                    <p className= "textInLine textBlackSedute"
                                                        style={{
                                                            //fontWeight: "bold",
                                                            //color: "black",
                                                            marginBottom: "0px",
                                                            //display: "inline",
                                                          
                                                        }}
                                                    >
                                                        {item.po}
                                                    </p>
                                                    )}
                                            </div>
                                              {item.rs ? (
                                                <p className= "textGreySedute"
                                                    //style={{
                                                    //    fontWeight: "bold",
                                                    //    color: "#879497",
                                                    //    textAlign: "left",
                                                    //}}
                                                >
                                                    Ragione Sociale:{" "}
                                                    <span className= "textBlackSedute"
                                                        //style={{ 
                                                        //    fontWeight: "bold",
                                                        //    color: "black",
                                                        //}}
                                                    >
                                                {item.rs}
                                            </span>
                                                </p>
                                            ) : (
                                                <></>
                                            )}
                                            <p className= "textGreySedute"
                                                //style={{
                                                //    fontWeight: "bold",
                                                //    color: "#879497",
                                                //    textAlign: "left",
                                                //}}
                                            >
                                                Localizzazione:{" "}
                                                <span className= "textBlackSedute"
                                                    //style={{
                                                    //    fontWeight: "bold",
                                                    //    color: "black",
                                                    //}}
                                                >
                                                    {item.local}
                                                </span>
                                            </p>
                                            
                                              
                                              {item.price ? (
                                                <p className= "textGreySedute"
                                                //style={{
                                                //    fontWeight: "bold",
                                                //    color: "#879497",
                                                //    textAlign: "left",
                                                //}}
                                            >
                                            Importo proposto:{" "}
                                                <span className= "textBlackSedute"
                                                    //style={{
                                                    //    fontWeight: "bold",
                                                    //    color: "black",
                                                    //}}
                                                >
                                                    {item.price}
                                            </span>
                                            </p>                                                            
                                    ) : (
                                    <></>
                                    )}    
                                        </div>
                                    </>
                                )}

                                {/*  */}
                            </div>

                            <div
                                style={{
                                    color: "#879497",
                                    display: "flex",
                                    alignItems: "center",
                                    height: "100%",
                                }}
                            >
                                <div
                                    style={{
                                        paddingRight: "25px",
                                        color: "#879497",
                                        display: "flex",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                        }}
                                    >
                                        <span
                                            className="dot"
                                            style={{ marginBottom: "5px" }}
                                        ></span>
                                        <span className="dot"></span>
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        <span
                                            className="dot"
                                            style={{ marginLeft: "3px" }}
                                        ></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {item.table.length ? (
                        <p className= "textGreyTable "
                            style={{
                                //fontWeight: "bold",
                                //color: "black",
                                //textAlign: "left",
                                paddingLeft: "50px",
                            }}
                        >
                            Cosi Ripartito:
                        </p>
                    ) : (
                        <></>
                    )}
                     {item.table.map((itemlist, index) =>
                            index % 2 === 0 ? (
                                <div
                                    style={{
                                        textAlign: "left",
                                        paddingLeft: "50px",
                                        color: "#879497",
                                        //fontWeight: "bold",
                                        background: "#EFEFF6",
                                    }}
                                >
                                    <p className= "textGreyTable"
                                        style={{
                                            display: "inline-block",
                                            width: "20%",
                                        }}
                                    >
                                        {itemlist.title}
                                    </p>
                                    <p className= "textGreyTable "
                                    style={{ display: "inline-block",width: "25%" }}>
                                        {itemlist.content}
                                    </p>
                                    <p className= "textGreyTable "
                                    style={{ display: "inline-block", width: "25%" }}>
                                        {itemlist.content2}
                                    </p>
                                </div>
                            ) : (
                                <div
                                    style={{
                                        textAlign: "left",
                                        paddingLeft: "50px",
                                        color: "#879497",
                                        //fontWeight: "bold",
                                    }}
                                >
                                    <p className= "textGreyTable "
                                        style={{
                                            display: "inline-block",
                                            width: "20%",
                                        }}
                                    >
                                        {itemlist.title}
                                    </p>
                                      <p className= "textGreyTable "
                                      style={{ display: "inline-block",width: "25%" }}>
                                        {itemlist.content}
                                    </p>
                                    <p className= "textGreyTable "
                                    style={{ display: "inline-block", width: "25%" }}>
                                        {itemlist.content2}
                                    </p>
                                </div>
                            )
                        )}
                </div>
            ))}
        </div>
    );
}
