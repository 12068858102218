import React, { Component,useEffect } from "react";
//import { useHistory, useParams, } from "react-router-dom";
import { useParams, } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { makeStyles } from '@material-ui/styles';
import Grid from "@material-ui/core/Grid";
import {FormControlLabel} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import DateFnsUtils from '@date-io/date-fns';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import {ThreeDots} from 'react-loader-spinner';
import SweetAlert from "react-bootstrap-sweetalert";
import CircularProgress from "@material-ui/core/CircularProgress";
import styles from "assets/jss/views/sessionManagerStyles2"
import "../../App.css";
import { format } from 'date-fns';
import Select from 'react-select';
import { List } from 'react-virtualized';
import itLocale from "date-fns/locale/it";
import {properties} from "utils/properties";
import Container from '@material-ui/core/Container';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
  } from '@material-ui/pickers';
import { getJsonDate} from "utils/utils2";
import { Badge } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Typography from '@material-ui/core/Typography';
import {updateSedute} from "./Utils";

const useStyles = makeStyles(styles);

export default function Presence2() {
  //const history = useHistory();
  const navigate = useNavigate();
  const [alert, setAlert] = React.useState(null)
  const classes = useStyles();
  let {idDossier} = useParams();
  const [loading, setLoading]= React.useState(true);
  const [loading2, setLoading2]= React.useState();
  const [disabled, setDisabled]= React.useState(false);
  const [ComitatoList, setComitatoList] = React.useState([]);
  const [ConsiglioList, setConsiglioList] = React.useState([]);
  const [RevisoriList, setRevisoriList] = React.useState([]);
  const [ComitatoPresenze, setComitatoPresenze] = React.useState([]);
  const [ConsiglioPresenze, setConsiglioPresenze] = React.useState([]);
  const [orderMeeting, setOrderMeeting] = React.useState([]);
  const [RevisoriPresenze, setRevisoriPresenze] = React.useState([]);
  const [meetingList, setMeetingList]=React.useState([]);
  const [availableDates, setAvailableDates] = React.useState([]);
  const [verbaleDate, setVerbaleDate] = React.useState(null);

  let redirectId="current";


  const organiValues =[
    {value: "10", label:"Comitato di Gestione"},
    {value: "20", label:"Consiglio Generale"},
    {value: "30", label:"Collegio Sindacale"}
    //{value: "30", label:"Altri Orgai"}
];

  const MenuList = props => {
    const rows = props.children;
    const rowRenderer = ({ key, index, isScrolling, isVisible, style }) => (
      <div key={key} style={style}>{rows[index]}</div>
    );

    return (
      <List
        style={{ width: '100%' }}
        width={300}
        height={300}
        rowHeight={30}
        rowCount={rows.length}
        rowRenderer={rowRenderer}
      />
    )
  }

  const customStyles = {
    control: base => ({
      ...base,
      textAlign: 'left',
      height: 55,
      minHeight: 55
    })
  };

  function handleChange1(event, idDossier) {
    if (event && event.value){
       //console.log(event, idMeeting);
       console.log("P3",event, idDossier);
       if( event.value=="10"){
            //COMITATO DI GESTIONE
            console.log("GO TOWARDS COMITATO DI GESTIONE");
            //history.push({
              navigate("/presenze");
       }
       else{
            if( event.value=="20"){
              //CONSIGLIO GENERALE
              console.log("REMAINS ON COLLEGIO SINDACALE");              
            }
            else{
              if( event.value=="30"){
                //COLLEGIO SINDACALE
                console.log("GO TOWARDS CONSIGLIO GENERALE");
                //history.push({
                  navigate("/presenze3");
              }
            }
        }
     }
  }

  const showAlert = () => {
    console.log("showing alert");
    setAlert(
      <SweetAlert
      warning
      style={{ display: "block", marginTop: "auto", marginBottom: "auto" }}
      title={"Impossibile accedere al foglio delle presenze per la seduta " + idDossier}
      text="ciao"
      //onConfirm={() => { history.go(-1) }}
      //onCancel={() => { history.go(-1) }}
      onConfirm={() => { navigate(-1) }}
      onCancel={() => { navigate(-1) }}
      confirmBtnCssClass={classes.button + " " + classes.success}
      //confirmBtnCssClass={classes.button + " " + classes.danger}
      confirmBtnText="Indietro"
      //cancelBtnText="No"
      //showCancel
    >
    </SweetAlert>
    );
};

  const handleSubmit = e => {
    e.preventDefault();
    //console.log(e);
    setLoading(true);
    let endpoint = properties.productionUrl+"/rest/updatePresenzeFieldConsiglio";
    var bodyFormData=new FormData();
    bodyFormData.append('idf', idDossier);
    bodyFormData.append('role', 'submit');
    bodyFormData.append('index', 1);
    bodyFormData.append('value', 1);

    if (!window.cordova)
    {
        axios({
                method: 'post',
                url: endpoint,
                data: bodyFormData,
                headers: {'Content-Type': 'multipart/form-data' }
            })
            .then(function (response) {
                console.log(response);
                setRevisoriList(response.data.Revisori);
                setComitatoList(response.data.Comitato);
                setConsiglioList(response.data.Consiglio);
                setComitatoPresenze(response.data.presenzeComitato);
                setConsiglioPresenze(response.data.presenzeConsiglio);
                setRevisoriPresenze(response.data.presenzeRevisori);
                setDisabled(response.data.Submitted)
                setLoading(false);
            })
            .catch(function (response) {
                console.log("Si è verificato un errore aggiornando le presenze!");
            });

    }
    else {
      var onSuccess = function(r)
      {
        var response = JSON.parse(r);
        console.log(response);
        setRevisoriList(response.Revisori);
        setComitatoList(response.Comitato);
        setConsiglioList(response.Consiglio);
        setComitatoPresenze(response.presenzeComitato);
        setConsiglioPresenze(response.presenzeConsiglio);
        setRevisoriPresenze(response.presenzeRevisori);
        setDisabled(response.Submitted)
        setLoading(false);
      }

      var onError = function() {}

      const bodyasString = new URLSearchParams(bodyFormData).toString();

      window.updatePresenzeConsiglio(bodyasString,onSuccess, onError);
    }

};




  const getPresenze = (issueName) => {
    //const issueName = `Seduta del ${format(new Date(_inviatationDate), 'dd-MM-yyyy')}`

    //console.log("getPresenze", issueName);
    if (!window.cordova)
    {

          let endpoint = properties.productionUrl+"/rest/getPresenzeConsiglio";
          var bodyFormData=new FormData();
          //console.log("H20",issueName);
          bodyFormData.append('idf', issueName);
          axios({
              method: 'post',
              url: endpoint,
              data: bodyFormData,
              headers: {'Content-Type': 'multipart/form-data' }
          })
          .then(function (response) {
              console.log("GBC", response.data);
              setRevisoriList(response.data.Revisori);
              setComitatoList(response.data.Comitato);
              setConsiglioList(response.data.Consiglio);
              setComitatoPresenze(response.data.presenzeComitato);
              setConsiglioPresenze(response.data.presenzeConsiglio);
              setRevisoriPresenze(response.data.presenzeRevisori);
              setDisabled(response.data.Submitted);
              console.log("AQUI",issueName);
              if(issueName.length==8){
                  var dateTmp=issueName.substring(2,4)+"/"+issueName.substring(0,2)+"/"+issueName.substring(4,8);
                  setVerbaleDate(dateTmp);
              }
              else
                  setVerbaleDate(issueName);
              //setBarAvailable(true);
             // formatDate(idDossier)
              setLoading(false);
              //setDataBar(response.data.barChart.datasets);
              setAlert(null);
          })
          .catch(function (error) {
              if (error.response) {
                //console.log(error.response.data);
                if(error.response.status===404){
                  //10102022
                  idDossier=issueName;
                  //10102022
                  setLoading(false);
                  showAlert();
              }

              }
          }


          );
    }
    else {
          var onSuccess = function(r)
          {
            console.log('notte' + r);
            var tm2=r;
            tm2.replaceAll("\n1","");
            tm2=tm2.replaceAll("\n","");
            var response = JSON.parse(tm2);
            console.log("GBC", response.data);
            setRevisoriList(response.Revisori);
            setComitatoList(response.Comitato);
            setConsiglioList(response.Consiglio);
            setConsiglioPresenze(response.presenzeConsiglio);
            setComitatoPresenze(response.presenzeComitato);
            setRevisoriPresenze(response.presenzeRevisori);
            setDisabled(response.Submitted);
            console.log("AQUI",issueName);
            if(issueName.length==8){
                var dateTmp=issueName.substring(2,4)+"/"+issueName.substring(0,2)+"/"+issueName.substring(4,8);
                setVerbaleDate(dateTmp);
            }
            else
                setVerbaleDate(issueName);
            //setBarAvailable(true);
           // formatDate(idDossier)
            setLoading(false);
            //setDataBar(response.data.barChart.datasets);
            setAlert(null);
          }

          var onError = function() {}

          window.getPresenzeConsiglio(issueName,onSuccess, onError);
    }
}

function handleChange3(event, role, id, index) {
    //console.log("WERW",event.target, id);
    setLoading2(id);
    let val=0;
    if(event.target.checked)
        val=1

    let endpoint = properties.productionUrl+"/rest/updatePresenzeFieldConsiglio";
    var bodyFormData=new FormData();
    bodyFormData.append('idf', idDossier);
    bodyFormData.append('role', role);
    bodyFormData.append('index', index);
    bodyFormData.append('value', val);

    if (!window.cordova)
    {

      axios({
              method: 'post',
              url: endpoint,
              data: bodyFormData,
              headers: {'Content-Type': 'multipart/form-data' }
          })
          .then(function (response) {
              //console.log(response);
              setRevisoriList(response.data.Revisori);
              setComitatoList(response.data.Comitato);
              setComitatoPresenze(response.data.presenzeComitato);
              setConsiglioList(response.data.Consiglio);
              setConsiglioPresenze(response.data.presenzeConsiglio);
              setRevisoriPresenze(response.data.presenzeRevisori);
              setDisabled(response.data.Submitted)
              setLoading2();
          })
          .catch(function (response) {
            console.log("si èverificato un errore aggiornando il foglio presenze");
          });
      }
      else {

        var onSuccess = function(r)
        {
          //console.log(response);
          var response = JSON.parse(r);
          setRevisoriList(response.Revisori);
          setComitatoList(response.Comitato);
          setComitatoPresenze(response.presenzeComitato);
          //setConsiglioList(response.data.Consiglio);
          setConsiglioList(response.Consiglio);
          //setConsiglioPresenze(response.data.presenzeConsiglio);
          setConsiglioPresenze(response.presenzeConsiglio);
          setRevisoriPresenze(response.presenzeRevisori);
          setDisabled(response.Submitted)
          setLoading2();
        }

        var onError = function() {}

        const bodyasString = new URLSearchParams(bodyFormData).toString();

        window.updatePresenzeConsiglio(bodyasString,onSuccess, onError);

      }

  }


//04042022
function formatDate(date){
    return date.substring(0,2)+"/"+date.substring(2,4)+"/"+date.substring(4,8);
}



const resolveCurrent2 = function(jsonList){
  console.log("RC",jsonList);
  var entireMeeting = Object.entries(jsonList);
  var orderMeeting = [];
  for (var i = entireMeeting.length - 1; i >= 0; i--) {
      var eachMeeting = {
          id: entireMeeting[i][0],
          order: entireMeeting[i][1]["order"],
          date: entireMeeting[i][1]["dataSeduta"],
          };
      orderMeeting.push(eachMeeting);
      //console.log("11",eachMeeting);
      if(eachMeeting.order=="0"){
        idDossier=eachMeeting.id;
        redirectId=eachMeeting.id;
        //history.replace({
          navigate("/presenze2/"+redirectId,{ replace: true });
        //idDossier=redirectId;
        getPresenze(idDossier);
      }
      setMeetingList(orderMeeting);
      //console.log("then",entireMeeting,orderMeeting);
  }

}

const doPostSedute2 = function() {
  console.log("doing sedute post111");
  var xhr2 = new XMLHttpRequest();
  xhr2.open('POST', properties.productionUrl+'/rest/checkSeduteJsonConsiglio',true);
  xhr2.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
  xhr2.onload = function (e) {
      if (xhr2.readyState === 4) {
          if (xhr2.status === 200) {
              var responseText = xhr2.responseText;
              var obj=JSON.parse(responseText);
              var jsonListSedute;
              if(obj.status==="204" ){
                console.log("OK DOPOSTSEDUTE2 204",xhr2);
                  jsonListSedute = JSON.parse(localStorage.getItem("listSeduteConsiglio"));
              }
              else{
                  //status 201
                  console.log("OK DOPOSTSEDUTE2 201",xhr2);
                  console.log("OK95",obj.date);

                  try{
                    localStorage.setItem("listSeduteConsiglio", obj.content);
                    localStorage.setItem("listSeduteVersionConsiglio", obj.date);
                  }
                  catch(error) {
                    console.log("LOCALSTORAGE ISSUE", error);
                  }
                  jsonListSedute = JSON.parse(obj.content);
              }
          //
          var entireMeeting = Object.entries(jsonListSedute);
          var meeting = [];
          var meetingDates=[];
          for (var i = entireMeeting.length - 1; i >= 0; i--) {
              var dateLabel=entireMeeting[i][0].substring(4, 8).concat("-").concat(entireMeeting[i][0].substring(2, 4)).concat("-").concat(entireMeeting[i][0].substring(0, 2));
              var eachMeeting = {
                  id: entireMeeting[i][0],
                  name: dateLabel.replace("/","-"),
                  location: entireMeeting[i][0],
                  startDate: dateLabel.replace("/",""),
                  color: '#30720f',
              };
              meeting.push(eachMeeting);
              meetingDates.push(eachMeeting.startDate);
          }
          console.log("AvailableDates",meetingDates);
          setAvailableDates(meetingDates);
          setOrderMeeting(meeting);
          console.log("2231",meeting);
          console.log("2232",meetingDates);
          //
          if(window.location.href.endsWith("/current")||window.location.href.endsWith("/current/")){
            resolveCurrent2(jsonListSedute);
          }
          else{
            if(idDossier!=null){
                console.log("IDS:"+idDossier);
                if(idDossier.length==8)
                    getPresenze(idDossier);
                else
                    resolveCurrent2(jsonListSedute);
            }
            else
                console.log("IDS is null");
                resolveCurrent2(jsonListSedute);
          }
        }
          else {
              console.error("Error 1", xhr2.statusText);
              alert("Errore recuperando la lista delle sedute");
          }
      }
  };

  xhr2.onerror = function (e) {
      console.error("Error 22", xhr2.statusText);
  };

  let urlEncodedData = null; //"idf=" + meetingId;
  if (localStorage.listSeduteConsiglio!=='' &&localStorage.listSeduteConsiglio!==undefined){
      const currentData=localStorage.getItem("listSeduteVersionConsiglio");
      urlEncodedData = "date=" + currentData;
  }
  xhr2.send(urlEncodedData);
}

const handleDateChange = (date) => {
     var rr=format(new Date(date), 'ddMMyyyy');
     //history.replace({
      navigate("/presenze2/"+rr, { replace: true });
     setLoading(true);
     getPresenze(rr);
    // let lastDate = invitationDate;
     //setInvitationDate(date);
     //setVerbaleDate(date);
     //getPresenze()
    // handleIssueExists(date, lastDate);
  };


  const updateUI = function(jsonData) {
          var entireMeeting = Object.entries(jsonData);
          var meeting = [];
          var meetingDates=[];
          for (var i = entireMeeting.length - 1; i >= 0; i--) {
              var dateLabel=entireMeeting[i][0].substring(4, 8).concat("-").concat(entireMeeting[i][0].substring(2, 4)).concat("-").concat(entireMeeting[i][0].substring(0, 2));
              var eachMeeting = {
                  id: entireMeeting[i][0],
                  name: dateLabel.replace("/","-"),
                  location: entireMeeting[i][0],
                  startDate: dateLabel.replace("/",""),
                  color: '#30720f',
              };
              meeting.push(eachMeeting);
              meetingDates.push(eachMeeting.startDate);
          }
          console.log("AvailableDates",meetingDates);
          setAvailableDates(meetingDates);
          setOrderMeeting(meeting);
          console.log("2231",meeting);
          console.log("2232",meetingDates);
          //
          if(window.location.href.endsWith("/current")||window.location.href.endsWith("/current/")){
            resolveCurrent2(jsonData);
          }
          else{
            if(idDossier!=null){
                console.log("IDS:"+idDossier);
                if(idDossier.length==8)
                    getPresenze(idDossier);
                else
                    resolveCurrent2(jsonData);
            }
            else
                console.log("IDS is null");
                resolveCurrent2(jsonData);
          }
  }


useEffect(() => {
  if(process.env.NODE_ENV !=='development' && !window.cordova){

    //CHECK AUTHENTICATION
        var xhr = new XMLHttpRequest();
        xhr.open('POST', properties.productionUrl+'/rest/ping',true);
        xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");

        xhr.onload = function (e) {
            if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                    //doPostSedute();
                console.log("OK",xhr);
                doPostSedute2();
            } else {
                console.error("Error 1", xhr);
                localStorage.clear();
                //history.push({
                  navigate("/login");
            }
        }
        };

        xhr.onerror = function (e) {
            console.error("Error 2", xhr);
            localStorage.clear();
            //history.push({
              navigate("/login");
        };

        let urlEncodedData = null;
        xhr.send(urlEncodedData);
    //CHECK AUTHENTICATION
    }
    //doPostSedute2();

    if (window.cordova)
    {
        var onSuccess = function(data) {
          var obj = JSON.parse(data);
          var sedute = JSON.parse(obj.content);
          console.log(sedute);
          updateUI(sedute);
        }

        var onError = function() {
           //alert("Impossibile scaricare l'elenco delle sedute, si prega di riprovare");
        }

        window.checkSeduteJsonConsiglio(onSuccess, onError);
    }
  /*if(window.location.href.endsWith("/current")||window.location.href.endsWith("/current/")){
    console.log("ends with current");
    doPostSedute2();
  }
  else{
    console.log("ends with an id");
    getPresenze(idDossier);
  }
  */
  //getPresenze(idDossier)
},
   []);


  return (
    <div key={uuidv4()}>
    {loading ? (
      <div key={uuidv4()} style={{ width:"25%",   display: "flex",
      marginLeft: "auto", marginRight: "auto" }}>
       <ThreeDots color="#30720f" height='350' width='150'/>
       </div>
      ):(
        <div className="content">
            <Container maxWidth="xl">
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={itLocale}>
            <Grid container spacing={2}>
              <Grid item xs={8} sm={6} md={8}>
                <Box display="flex" alignItems="center" height="100%">
                  <Typography variant="body1" className={classes.fontBold}>
                    Organo relativo alla riunione:
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={4} sm={6} md={4}>
                <Select key={uuidv4()}
                    isSearchable= {false}
                    component={{MenuList}}
                    fullWidth
                    //style={{background: 'red', textAlign:'left'}}
                    styles={customStyles}
                    //ref={el => itemsRef.current[i] =el}
                    //onChange={(event) => handleChange1(event, idMeeting)}
                    onChange={(event) => handleChange1(event, idDossier)}
                    defaultValue={{
                      label: "Consiglio Generale",
                      value: "20"
                    }}
                    options={organiValues}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,
                        primary25: '#a2ee7d',
                        primary: 'black',
                      },
                    })}

                  />
              </Grid>
              <Grid item xs={8} sm={6} md={8}>
                    <Box display="flex" alignItems="center" height="100%">
                    <Typography variant="body1" className={classes.fontBold}>
                    Data della riunione:
                    </Typography>
                    </Box>
              </Grid>
              <Grid item xs={4} sm={6} md={4}>
                    <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="normal"
                        id="date"
                        value={verbaleDate}
                        //value="2022-09-09"
                        onChange={handleDateChange}
                        autoOk={true}
                        KeyboardButtonProps={{
                        'aria-label': 'change date',
                        }}
                        className={classes.datePicker}
                        renderDay={(day, selectedDate, isInCurrentMonth, dayComponent) => {
                        return <Badge className={availableDates.includes(getJsonDate(day)) ? classes.existDate : classes.nonExist}>{dayComponent}</Badge>;

                        }}
                    />
              </Grid>
              <Grid item xs={12}>
                <form className="form1" onSubmit={handleSubmit}>
                    <div className="input-group">
                        <div style={{ maxWidth: "100%",  paddingTop: "10px",width: "100%", alignItems: "left",margin: "auto"}}>
                        <Typography variant="body1" className={classes.fontBold} style={{textAlign: "left",paddingBottom:"5px",paddingTop:"15px"}}>
                        Elenco dei partecipanti alla seduta del Consiglio Generale del {formatDate(idDossier)}:
                        </Typography>

                        {/**     <p style={{ fontSize: "16px", letterSpacing: "1px", fontWeight: "bold", width: "100%", alignItems: "left",textAlign:"center",paddingTop: "0px"}}>
                                Elenco dei partecipanti alla seduta del Comitato di Gestione del {formatDate(idDossier)}:
                            </p>
                        */}
                               <div className="common-list" key={uuidv4()} style = {{alignItems: "center", display: "block", height: "100%", paddingTop:"20px", justifyContent: "center"}}>
                                <p style={{ fontSize: "16px", letterSpacing: "1px", width: "100%", textAlign: "left", paddingLeft:"40px"}}>
                                    Componenti Consiglio Generale
                                </p>
                                {ConsiglioList.map((item, index) => (
                                    <Grid container spacing={3} alignItems="stretch">
                                        <Grid item xs={12} sm={5}>
                                            <p style={{ fontSize: "16px", letterSpacing: "1px", width: "100%", textAlign: "left", paddingLeft:"60px"}}>
                                            {item} </p>
                                        </Grid>

                                        <Grid item xs={12} sm={3}>
                                            <FormControlLabel key={uuidv4()} style={{paddingLeft:"20px", color:"black"}}control={<Checkbox key={uuidv4()} style ={{color: "#BF360C"}} disabled={disabled} checked={(ConsiglioPresenze[index]=== 1) ?? false}/>} label="Presente" onChange={(event) => handleChange3(event, "consiglio",item, index)}/>
                                        </Grid>
                                        <Grid item xs={12} sm={1}>
                                            {loading2==item ? (
                                                <div style={{marginRight: "20px",marginTop:"5px"}}>
                                                <CircularProgress size={22} color= "success" />
                                                </div>
                                            ):( <></> )}
                                        </Grid>
                                    </Grid>
                                ))}
                                <br />
                            </div>
                            <div className="common-list" key={uuidv4()} style = {{alignItems: "center", display: "block", height: "100%", paddingTop:"20px", justifyContent: "center"}}>
                                <p style={{ fontSize: "16px", letterSpacing: "1px", width: "100%", textAlign: "left", paddingLeft:"40px"}}>
                                    Componenti Comitato di Gestione
                                </p>
                                {ComitatoList.map((item, index) => (
                                    <Grid container spacing={3} alignItems="stretch">
                                        <Grid item xs={12} sm={5}>
                                            <p style={{ fontSize: "16px", letterSpacing: "1px", width: "100%", textAlign: "left", paddingLeft:"60px"}}>
                                            {item} </p>
                                        </Grid>

                                        <Grid item xs={12} sm={3}>
                                            <FormControlLabel key={uuidv4()} style={{paddingLeft:"20px", color:"black"}}control={<Checkbox key={uuidv4()} style ={{color: "#BF360C"}} disabled={disabled} checked={(ComitatoPresenze[index]=== 1) ?? false}/>} label="Presente" onChange={(event) => handleChange3(event, "comitato",item, index)}/>
                                        </Grid>
                                        <Grid item xs={12} sm={1}>
                                            {loading2==item ? (
                                                <div style={{marginRight: "20px",marginTop:"5px"}}>
                                                <CircularProgress size={22} color= "success" />
                                                </div>
                                            ):( <></> )}
                                        </Grid>
                                    </Grid>
                                ))}
                                <br />
                            </div>
                            <div className="common-list" key={uuidv4()} style = {{alignItems: "center", display: "block", height: "100%", paddingTop:"20px", justifyContent: "center"}}>
                                <p style={{ fontSize: "16px", letterSpacing: "1px", width: "100%", textAlign: "left", paddingLeft:"40px"}}>
                                Componenti Collegio Sindacale
                                </p>
                                {RevisoriList.map((item, index) => (
                                    <Grid container spacing={3} alignItems="stretch">
                                        <Grid item xs={12} sm={5}>
                                        <p style={{ fontSize: "16px", letterSpacing: "1px", width: "100%", textAlign: "left", paddingLeft:"60px"}}>
                                        {item} </p>
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            <FormControlLabel key={uuidv4()} style={{paddingLeft:"20px", color:"black"}}control={<Checkbox key={uuidv4()} style ={{color: "#BF360C"}} disabled={disabled} checked={(RevisoriPresenze[index]=== 1) ?? false}/>} label="Presente" onChange={(event) => handleChange3(event, "revisori",item, index)}/>
                                        </Grid>
                                        <Grid item xs={12} sm={1}>
                                        {loading2==item ? (
                                                <div style={{marginRight: "20px",marginTop:"5px"}}>
                                                <CircularProgress size={22} color= "success" />
                                                </div>
                                            ):( <></> )}
                                        </Grid>
                                    </Grid>
                                ))}
                            </div>
                        </div>
                        <br />
                        {!disabled ? (
                        <button className="button154" disabled={disabled} >Invia lista partecipanti</button>
                        ):(<></>)}
                        <br /><br />
                    </div>
                </form>
            </Grid>
            </Grid>

            </MuiPickersUtilsProvider>
            </Container>
      </div>
      )}

            {alert}

      </div>
  );

  }
