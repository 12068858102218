export const CreateIssuePostBody = (formData) => {
  const long_options = { year: 'numeric', month: 'long', day: 'numeric' };
  const options = { year: 'numeric', month: 'numeric', day: 'numeric' };

  var convDate = new Date(formData.convocationDate);
  var invDate = new Date(formData.invitationDate);

  var params = {
    lockedForFileInsert: false,
    invitationDate: invDate.toLocaleDateString('it-IT', long_options),
    convocationDate: convDate.toLocaleDateString('it-IT', long_options),
    convocationTime: formData.convocationTime,
    formattedConvocationDate: convDate.toLocaleDateString('it-IT', options),
    presidentName: formData.presidentName,
    recipients: formData.recipients,
    cdgMembers: formData.cdgMembers,
    auditors: formData.auditors,
    dirTree: null
  };

  var operationParams =
  {
    app_user: "app",
    app_password: "meeting",
    operation: "createIssue",
    issueName: "Seduta del " + convDate.toLocaleDateString('es-CL', options),
    params: JSON.stringify(params)
  };

  return operationParams;
}

export const checkDuplicatedNode = (trees, id, nodeName) => {
  const parentId = trees.find(node => node.id === id).parent
  const childrenWithSameParent = trees.filter(tree => tree.parent === parentId)  
  return childrenWithSameParent.find(child => (child.text === nodeName) && (child.id !== id)) ? true : false
}

export const getPathWithParentId = (trees, id, path = "") => {
  const node = trees.find(node => node.id === id)
  if (node.parent === 0) {
    return node.text + path
  } else {
    const _path = '/' + node.text + path
    const _id = node.parent
    return getPathWithParentId(trees, _id, _path)
  }
}

export const getInvitationDate = (dateStr) => {
  const spanishMonthList = {
    "gennaio": 1,
    "febbraio": 2,
    "marzo": 3,
    "aprile": 4,
    "maggio": 5,
    "giugno": 6,
    "luglio": 7,
    "agosto": 8,
    "settembre": 9,
    "ottobre": 10,
    "novembre": 11,
    "dicembre": 12
  }

  //return `${dateStr.split(' ')[2]}-${spanishMonthList[dateStr.split(' ')[1]]}-${parseInt(dateStr.split(' ')[0]) < 10 ? '0' + dateStr.split(' ')[0] : dateStr.split(' ')[0]}`
  return `${dateStr.split(' ')[2]}/${spanishMonthList[dateStr.split(' ')[1]]}/${parseInt(dateStr.split(' ')[0]) < 10 ? '0' + dateStr.split(' ')[0] : dateStr.split(' ')[0]}`
}

let tree_list = []

const getTreeData = (child, parentId, realPath) => {
  console.log("GTD01",child, parentId, realPath);
  if (child.attr.rel !== 'file') {
    tree_list.push(
      {
        id: child.attr.id,
        text: child.nameLabel,
        droppable: true,
        parent: parentId,
        realPath: realPath,
        data: {
          color: child.attr.flabel
        }
      }
    )
  }


  if (child.children) {
    child.children.forEach(node => {
      return getTreeData(node, child.attr.id, `${realPath}/${child.nameLabel}`)
    })
  }
}

export const convertResToTree = (dirTree) => {
  console.log("CRTT71",dirTree);
  const rootTree = {
    id: 1,
    parent: 0,
    droppable: true,
    text: dirTree.data.data,
    realPath: ""
  }
  tree_list = [rootTree]
  console.log("21",tree_list);
  dirTree.data.children.forEach(child => {
    getTreeData(child, 1, dirTree.data.data)
  })

  return tree_list
}

/************ convert res to trees for second page ***************/
let files_tree_list = []

const getFileTreeData = (child, parentId, realPath) => {
  console.log("GFTD41",child, parentId, realPath);
  files_tree_list.push(
    {
      id: child.attr.id,
      text: child.nameLabel,
      droppable: child.attr.rel !== 'file',
      parent: parentId,
      realPath: realPath,
      data: {
        color: child.attr.flabel,
        isFolder: child.attr.rel !== 'file',
        isFile: child.attr.rel === 'file',
        fileType: child.attr.ftype ?? ''
      }
    }
  )

  if (child.children) {
    child.children.forEach(node => {
      return getFileTreeData(node, child.attr.id, `${realPath}/${child.nameLabel}`)
    })
  }
}

export const convertResToFilesTree = (dirTree) => {

  const rootTree = {
    id: 1,
    parent: 0,
    droppable: true,
    text: dirTree.data.data,
    realPath: "",
    data: {
      "isFolder": true
    }
  }
  files_tree_list = [rootTree]
  dirTree.data.children.forEach(child => {
    console.log("CRTFT-0",child)
    getFileTreeData(child, 1, dirTree.data.data)
  })
  console.log("CRTFT",files_tree_list);
  return files_tree_list
}


export const getOperationParams = (operationName, issueName, params) => {
  return {
    app_user: "app",
    app_password: "meeting",
    operation: operationName,
    issueName: issueName,
    params: JSON.stringify(params)
  };
}

export const getJsonDate = (dateStr) => {
  const year = new Date(dateStr).getFullYear()
  const month = new Date(dateStr).getMonth() + 1
  const day = new Date(dateStr).getDate()
  return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`
}
