import React, { useState, useEffect, useContext, useRef } from "react";
//import { useHistory } from "react-router-dom";
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import "../../App.css";
//import { useNavigate } from "react-router-dom";

export default function TestPDF() {
  //const history = useHistory();

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

 

  return (
    <div className="content" style = {{alignItems: "center", display: "flex", height: "100%",  justifyContent: "center"}}>
    <Document   
      file={{
            url: 'http://dff.nic.in/BRICS/images/Sample%20Loreum%20Ipsum.pdf',
            httpHeaders: {'Access-Control-Allow-Origin': '*'} , withCredentials:false }}
      
      onLoadSuccess={onDocumentLoadSuccess}      
      //onLoadError={(error) => console.log("Inside Error", error)}
    >
      
    </Document>
   
     
     
      </div>
  );
  
  }  
