import React from "react";
import { makeStyles } from '@material-ui/styles'

import classNames from "classnames";

import FolderIcon from "@material-ui/icons/Folder";
//import MenuBook from "@material-ui/icons/MenuBook";
import Event from "@material-ui/icons/Event";
import { AiOutlineFilePdf, AiOutlineFileWord, AiOutlineFileImage,AiOutlineFilePpt,AiOutlineFileExcel } from "react-icons/ai";

import styles from "assets/jss/components/typeIconStyles"

const useStyles = makeStyles(styles)

const TypeIcon = (props) => {

  const classes = useStyles()

  const { folderType } = props

  if (props.droppable) {
    //console.log("DROPPABLE",props);   
    if(props.folderType==undefined){ //DEVO DIFFERENZIARE PER CAPIRE SE ROOT O MENO
      //console.log("-DROPPABLE",props);        
      //return <Event fontSize="large"/>;
      return <Event />;
    }
    else  
      //return <FolderIcon fontSize="large" className={classNames({[classes[folderType]]: folderType})} />;
    return <FolderIcon className={classNames({[classes[folderType]]: folderType})} />;
  }

  switch (props.fileType) {
    case "application/pdf":
      //return <AiOutlineFilePdf size="24px"/>;
      return <AiOutlineFilePdf />;
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      //return <AiOutlineFileWord size="24px"/>;
      return <AiOutlineFileWord/>;
    case "application/msword":
      //return <AiOutlineFileWord size="24px"/>;
      return <AiOutlineFileWord />;
    case "application/vnd.ms-excel":
      //return <AiOutlineFileExcel size="24px"/>;
      return <AiOutlineFileExcel/>;
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      //return <AiOutlineFileExcel size="24px"/>;
      return <AiOutlineFileExcel/>;
    case "image/jpeg":
      //return <AiOutlineFileImage size="24px"/>;
      return <AiOutlineFileImage/>;
    case "image/png":
      //return <AiOutlineFileImage size="24px"/>;
      return <AiOutlineFileImage/>;
    case "image/x-png":
      //return <AiOutlineFileImage size="24px"/>;
      return <AiOutlineFileImage />;
    case "image/gif":
      //return <AiOutlineFileImage size="24px"/>;
      return <AiOutlineFileImage />;
    case "image/bmp":
      //return <AiOutlineFileImage size="24px"/>;
      return <AiOutlineFileImage />;
    case "image/tiff":
      //return <AiOutlineFileImage size="24px"/>;
      return <AiOutlineFileImage />;
    case "application/vnd.ms-powerpoint":
      //return <AiOutlineFilePpt size="24px"/>;
      return <AiOutlineFilePpt />;
    case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
      //return <AiOutlineFilePpt size="24px"/>;
      return <AiOutlineFilePpt/>;

    default:
      return null;
  }
};

export default TypeIcon
