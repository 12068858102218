import React from 'react'

import { makeStyles } from '@material-ui/styles'
import { green, yellow, brown, orange, purple, grey } from '@material-ui/core/colors';

import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

import Popover from '@material-ui/core/Popover'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import styles from "assets/jss/components/classificationMenuStyles"

const useStyles = makeStyles(styles)

const ClassificationMenu = (props) => {

  const classes = useStyles()

  const { handleChange } = props

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <ListItem button onClick={handleClick}>
        <ListItemText primary="Classifica" />
      </ListItem>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          className={classes.menuList}
        >          
          <ListItem button onClick={() => {handleClose(); handleChange("std_collegio")}}>
            <FiberManualRecordIcon style={{color: "#666999"}} />
            <ListItemText primary="Predefinito" />
          </ListItem>                  
          <ListItem button onClick={() => {handleClose(); handleChange("hidden_collegio")}}>
            <FiberManualRecordIcon style={{color: "#444444"}} />
            <ListItemText primary="Nascosto" />
          </ListItem>          
        </List>
      </Popover>
    </>
  )
}

export default ClassificationMenu
