import { green } from '@material-ui/core/colors';

const sessionManagerStyles104 = () => ({  
  fontBold: {
    fontWeight: "bold"
  },
  bgWhite: {
    backgroundColor: "white"
  },
  bgGreen: {
    backgroundColor: `${green[400]} !important`,
    color: "white"
  },
  treeRoot: {
    height: "100%",
    listStyle: "none",
    paddingLeft: "0",
    "& ul": {
      listStyle: "none",
      paddingLeft: "0"
    }
  },
  draggingSource: {
    opacity: "0.3"
  },
  dropTarget: {
    backgroundColor: "#e8f0fe"
  },
  button: {
    border: "none",
    cursor: "pointer",
    margin: "0.3125rem 1px",
    padding: "12px 30px",
    position: "relative",
    fontSize: "12px",
    minWidth: "auto",
    minHeight: "auto",
    textAlign: "center",
    transition: "box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1)",
    fontWeight: "400",
    lineHeight: "1.42857143",
    whiteSpace: "nowrap",
    willChange: "box-shadow, transform",
    touchAction: "manipulation",
    borderRadius: "3px",
    letterSpacing: "0",
    textTransform: "uppercase",
    verticalAlign: "middle",
    color: "white",
    //color: "black",
    //backgroundColor:"red",
    textDecoration: "none"
  },
  danger: {
    boxShadow: "0 14px 26px -12px rgb(244 67 54 / 42%), 0 4px 23px 0px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(244 67 54 / 20%)",
    backgroundColor: "#f44336",
  },
  success: {
    backgroundColor: "#4caf50"
  },
  datePicker: {
    width: "100%",
    "& .MuiInputBase-root": {
      height: "56px",
      border: '1px solid rgba(0, 0, 0, 0.23)',
      borderRadius: "4px",
      backgroundColor: "white",
      //color:"red",
      paddingLeft: "14px"
    },
    "& .MuiInput-underline:before": {
      borderBottom: "0 !important"
    },
    "& .MuiInput-underline:after": {
      borderBottom: "0"
    }
  },
  processing: {
    position: "fixed",
    top: "0",
    left: "0",
    width: "100vw",
    height: "100vh",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  circular: {
    zIndex: "3"
  },
  existDate: {    
    "& .MuiButtonBase-root": {
      //backgroundColor: "#006600",
      backgroundColor: "#046A38",
      color: "white"
    }
  }
})

export default sessionManagerStyles104
