import React from 'react';
import ReactDOM from 'react-dom';
import { install } from 'resize-observer';
import App from './App';
import reportWebVitals from './reportWebVitals';

import 'assets/css/index.css';

if (!window.ResizeObserver) install();

ReactDOM.render(  
  <App />,
  document.getElementById('root')
  
);

reportWebVitals();
