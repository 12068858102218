import { green, yellow, brown, orange, purple, grey } from '@material-ui/core/colors';

const typeIconStyles = () => ({
  assets: {
    color: green[500]
  },
  red: {
    //color: "#f50057" OK
    color: "#E80000"
  },
  green: {
    //color: green[500] OK
    color: "#00CC33"
  },
  blue: {
    //color: "#2196f3" OK
    color: "#C2EFFF"
  },
  yellow: {
    //color: yellow['A200'] OK
    color:"#FFE118"
  },
  brown: {
    //color: brown[500] OK
    color: "#8b4513"
  },
  orange: {
    //color: orange['A700'] OK
    color: "#E98624"
  },
  purple: {
    //color: purple['A400'] OK
    color:"#9370db"
  },
  hidden: {
    //color: grey[900]
    color:"#444444"
  },
  std: {
    //color: grey[500] OK
    color: "#CCCCCC"
  },
  root: {
    //color: grey[500] OK
    color: "#FFFFFF"
  },
  std_consiglio:{
    //color: "#F0F38D"
    color: "#BF360C"
  },
  hidden_consiglio: {    
    color:"#444444"
  },
  std_collegio:{
    //color: "#F0F38D"
    color: "#666999"
  },
  hidden_collegio: {    
    color:"#444444"
  },
  std_cgpianeta:{    
    color: "#008FD1"
  },
  std_cgcultura:{    
    color: "#ce0f69"
  },
  std_cgpersone:{    
    color: "#F0B323"
  },
  std_cgfinanza:{    
    color: "#046A38"
  },
  std_cgstatuto:{    
    color: "#CCCCCC"
  },
})

export default typeIconStyles
