import React, { useState, useEffect, useRef, useContext } from 'react'
//import { useHistory, useParams } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import qs from 'qs'
import RouterContext from "../context/RouterContext";
import { Tree } from "@minoru/react-dnd-treeview";
import { toast } from 'react-toastify';
import SweetAlert from "react-bootstrap-sweetalert";
import { format } from 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { List } from 'react-virtualized';
import { useNavigate } from "react-router-dom";
import { Fab, Tooltip } from '@material-ui/core/';
import SendIcon from '@material-ui/icons/Send';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import itLocale from "date-fns/locale/it";
import { v4 as uuidv4 } from 'uuid';
import Select from 'react-select';

import { makeStyles } from '@material-ui/styles'
import {properties} from "utils/properties";
import Box from "@material-ui/core/Box"
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete'
import { Badge } from "@material-ui/core";

import CustomNode from "components/TreeViewSection/CustomNode101"

import {
  CreateIssuePostBody,
  getPathWithParentId,
  checkDuplicatedNode,
  getInvitationDate,
  convertResToTree,
  getJsonDate
} from "utils/utils"

import styles from "assets/jss/views/sessionManagerStyles101"

const useStyles = makeStyles(styles)

export default function SessionManager() {
  const { pageRouter, setPageRouter } = useContext(RouterContext);
  const classes = useStyles()
  //const history = useHistory();
  const navigate = useNavigate();
  const treeRef = useRef(null);
  let {idMeeting } = useParams();
  const inputElement = useRef(null);
  const [alert, setAlert] = useState(null)
  const [lockedFile, setLockedFile] = useState(false)

  const [invitationDate, setInvitationDate] = useState(null);
  const [convocationDate, setConvocationDate] = useState(null);
  const [presidentName, setPresidentName] = useState("");
  const [convocationTime, setConvocationTime] = useState('');
  const [recipients, setRecipients] = useState([])
  const [auditors, setAuditors] = useState([])

  const [treeData, setTreeData] = useState([])
  const [newNodeId, setNewNodeId] = useState("")
  const [availableDates, setAvailableDates] = useState([])
  const [isCreated, setIsCreated] = useState(false)

  const [segreteria, setSegreteria] = useState(false)
  const organiValues =[ 
    {value: "101", label:"Commissione Pianeta"},
    {value: "102", label:"Commissione Cultura"} ,
    {value: "103", label:"Commissione Persone"},
    {value: "104", label:"Commissione Finanza"},
    {value: "105", label:"Commissione Statuto"},
    //{value: "111", label:"Commissione XXX"}
];

  const MenuList = props => {
    const rows = props.children;
    const rowRenderer = ({ key, index, isScrolling, isVisible, style }) => (
      <div key={key} style={style}>{rows[index]}</div>
    );
  
    return (
      <List
        style={{ width: '100%' }}
        width={300}
        height={300}
        rowHeight={30}
        rowCount={rows.length}
        rowRenderer={rowRenderer}
      />
    )
  }

  const customStyles = {
    control: base => ({
      ...base,
      textAlign: 'left',
      height: 55,
      minHeight: 55
    })
  };
 

const onFabClick= (e ) => {           
  //FAB aggiunta ambito
  console.log("OFC",e);
  handleMakePublic();    
};

const onFabClick2= (e ) => {           
  //FAB aggiunta ambito
  console.log("OFC2",e);
  //history.push('/fileMgr101');
  navigate('/fileMgr101');
};




const onFabClick3= (e ) => {           
  //FAB aggiunta ambito
  //WORD
  console.log("OFC3",e);
  if (e.target.tagName.toLowerCase() == 'a')
  return;
  handleRenderWord(inputElement);
  
};


function handleChange1(event, idMeeting) {
  if (event && event.value)
  {
     console.log(event, idMeeting);
     if( event.value=="101"){
          //Commissione Pianeta
          console.log("STAY ON PIANETA");         
     }
     else if( event.value=="102"){
            //Commissione Cultura
            console.log("GO TOWARDS CULTURA");
            navigate("/folders102");
        }
     else if( event.value=="103"){
          //Commissione Persone
          console.log("GO TOWARDS PERSONE");        
          navigate("/folders103");
     }
     else if( event.value=="104"){
        //Commissione Finanza
        console.log("GO TOWARDS FINANZA");
        navigate("/folders104");
     }
     else if( event.value=="105"){
        //Commissione Statuto
        console.log("GO TOWARDS STATUTO");        
        navigate("/folders105");
     }          
   }
}


const handleCreateIssue = (_inviatationDate) => {
    const values = {
      invitationDate: _inviatationDate,
      convocationDate: convocationDate,
      convocationTime: convocationTime,
      presidentName: presidentName,
      recipients: recipients,      
      auditors: auditors,
    }
    console.log(values)    
    axios.post(properties.productionUrl+'/issueManagerCGPianeta', qs.stringify(CreateIssuePostBody({
      invitationDate: convocationDate,
      convocationDate: format(new Date(_inviatationDate), 'yyyy-MM-dd'),
      convocationTime: convocationTime,
      presidentName: presidentName,
      recipients: recipients,
      auditors: auditors
    })))
      .then(res => {
        console.log(res.data)
        if (res.data.status === 'ok') {
          setTreeData([
            {
              "id": 1,
              "parent": 0,
              "droppable": true,
              "text": res.data.result.issueName,
              "realPath": ""
            }
          ])
          toast.success("Nuova seduta creata correttamente")
          setLockedFile(res.data.result.lockedForFileInsert)
        } else {
          setTreeData([])
        }
        setAlert(null)
      })
      .catch(err => {
        console.log(err)
        setTreeData([])
      })
  }

  const confirmCreateIssue = (_inviatationDate, lastDate) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "auto", marginBottom: "auto" }}
        title="Vuoi davvero creare una nuova seduta?"
        onConfirm={() => handleCreateIssue(_inviatationDate)}
        onCancel={() => { setAlert(null); setInvitationDate(lastDate) }}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText="Si"
        cancelBtnText="No"
        showCancel
      >
      </SweetAlert>
    );
  };

  const fetchLastIssue = () => {
    const operationName = "getLastIssue"

    const operationParams =
    {
      app_user: "app",
      app_password: "meeting",
      operation: operationName
    };
    //axios.post(`${process.env.REACT_APP_PROXY_URL}`, qs.stringify(operationParams))
    axios.post(properties.productionUrl+'/issueManagerCGPianeta', qs.stringify(operationParams))
      .then(res => {
        console.log(res)
        setInvitationDate(getInvitationDate(res.data.convocationDate))
        setPresidentName(res.data.presidentName)
        setConvocationDate(getInvitationDate(res.data.invitationDate))
        setConvocationTime(res.data.convocationTime.replace('.', ':'))
        setRecipients(res.data.recipients)        
        setAuditors(res.data.auditors)
        
        console.log("SM",res.data.dirTree,convertResToTree(res.data.dirTree));
        setTreeData(convertResToTree(res.data.dirTree))
        setLockedFile(res.data.lockedForFileInsert)
        treeRef.current.openAll();
        setNewNodeId("");
        //console.log("VER2",new Date(res.data.convocationDate).toLocaleDateString('it-IT'));        
        //console.log("VER1",res.data.convocationDate, new Date(res.data.convocationDate));
        console.log("VER3",getInvitationDate(res.data.convocationDate));
        var a=res.data.formattedConvocationDate.split("/");
        /*if(a[0]<=9)
          a[0]="0"+a[0];
        if (a[1]<=9)
        a[1]="0"+a[1];*/
        if(a[0].length==1)
          a[0]="0"+a[0];
        if (a[1].length==1)
          a[1]="0"+a[1];
        console.log(a);
        navigate("/folders101/"+a[0]+"-"+a[1]+"-"+a[2],{ replace: true });          
      })
      .catch(err => {
        console.log(err)
      })
  }

  const handleDropTree = (newTree, { dragSourceId, dropTargetId }) => {

    const targetNode = treeData.find(node => node.id === dropTargetId)

    const operationName = "updateIssue";
    const params = {
      "operation": "MOVE",
      "args": {
        "frompath": treeData.find(node => node.id === dragSourceId).realPath,
        "topath": `${targetNode.realPath}${targetNode.realPath !== '' ? '/' : ''}${targetNode.text}`,
        "nodeName": treeData.find(node => node.id === dragSourceId).text,
        "position": 0
      }
    }
    const operationParams = {
      app_user: "app",
      app_password: "meeting",
      operation: operationName,
      issueName: treeData[0].text,
      params: JSON.stringify(params)
    };

    //axios.post(`${process.env.REACT_APP_PROXY_URL}`, qs.stringify(operationParams))
    axios.post(properties.productionUrl+'/issueManagerCGPianeta', qs.stringify(operationParams))
      .then(res => {
        console.log(res)
        if (res.data.status === 'failure') {
          toast.error("Error occured!")
        }
        fetchLastIssue()
      })
      .catch(err => {
        console.log(err)
        toast.error("Error occured!")
        fetchLastIssue()
      })

  }

  const handleDeleteTree = (id) => {

    const operationName = "deleteChild";
    const params = {
      "path": treeData.find(tree => tree.id === id).realPath,
      "childName": treeData.find(node => node.id === id).text
    };
    const operationParams = {
      app_user: "app",
      app_password: "meeting",
      operation: operationName,
      issueName: treeData[0].text,
      params: JSON.stringify(params)
    };

    //axios.post(`${process.env.REACT_APP_PROXY_URL}`, qs.stringify(operationParams))
    axios.post(properties.productionUrl+'/issueManagerCGPianeta', qs.stringify(operationParams))
      .then((res) => {
        console.log("SM2",res.data, res.data.result.dirTree, convertResToTree(res.data.result.dirTree));
        setTreeData(convertResToTree(res.data.result.dirTree))
      })
      .catch((err) => {
        console.log(err)
        toast.error("Failed to delete")
      })
  }

  const handleCreateTree = (parentId) => {
    // if (checkDuplicatedNode(treeData, parentId, "ODG")) {
    //   toast.error("Failed to add new child!")
    //   return;
    // }
    //console.log("ERE",parentId, treeData)
    var cc="std_cgpianeta";
    for (var j = 0; j < treeData.length; j++) {
      if (treeData[j]["id"] === parentId && treeData[j]["id"]!= 1 ) {
        cc=treeData[j].data.color;   
      }
    }
    

    setIsCreated(true)
    const params = {
      "path": getPathWithParentId(treeData, parentId),
      //"childName": "ODG",
      "childName": "   ",
      "attr": { "rel": "hidden_folder", "flabel": cc, "custom": "[]" },
      "pos": null
    };
    const operationParams = {
      app_user: "app",
      app_password: "meeting",
      operation: "addChild",
      issueName: treeData[0].text,
      params: JSON.stringify(params)
    };
    console.log("handleCreateTree",operationParams,parentId, treeData);
    //axios.post(`${process.env.REACT_APP_PROXY_URL}`, qs.stringify(operationParams))
    axios.post(properties.productionUrl+'/issueManagerCGPianeta', qs.stringify(operationParams))
      .then(res => {
        console.log("QWE",res.data)
        setNewNodeId(res.data.result.newChild.attr.id)
        setTreeData(convertResToTree(res.data.result.updatedIssue.dirTree))
        treeRef.current.openAll();
      })
      .catch(() => {
        toast.error("Server Error")
      })
  }

  const handleChangeText = (id, value) => {

    if(value.includes("/")){
      //toast.error("Non è possibile utilizzare il carattere / nel nome di una cartella");
      setAlert(
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "auto", marginBottom: "auto" }}
          title="Non è possibile utilizzare il carattere / nel nome di una cartella"
          onConfirm={() =>  setAlert(null)}
          confirmBtnCssClass={classes.button + " " + classes.success}
          cancelBtnCssClass={classes.button + " " + classes.danger}
          confirmBtnText="Ok" 
        >
        </SweetAlert>
      );
      setNewNodeId(id)
      return;
    }

    if (checkDuplicatedNode(treeData, id, value)) {
      toast.error("Error occured!")
      setNewNodeId(id)
      return;
    }

    const operationName = "updateIssue";
    const params = {
      "operation": "RENAME",
      "args": {
        "path": treeData.find(tree => tree.id === id).realPath,
        "oldName": treeData.find(node => node.id === id).text,
        "newName": value
      }
    };
    const operationParams = {
      app_user: "app",
      app_password: "meeting",
      operation: operationName,
      issueName: treeData[0].text,
      params: JSON.stringify(params)
    };
    console.log("handleChangeText",operationParams,params, treeData, id, value);
    //axios.post(`${process.env.REACT_APP_PROXY_URL}`, qs.stringify(operationParams))
    axios.post(properties.productionUrl+'/issueManagerCGPianeta', qs.stringify(operationParams))
      .then(res => {
        console.log(res)
        if (res.data.status === 'ok') {
          fetchLastIssue()
        }      
      })
      .catch((err) => {
        console.log(err)
        setNewNodeId("")
        toast.error("Failed to update")
        fetchLastIssue()
      })
  }

  const handleChangeColor = (id, color) => {

    const operationName = "updateIssue";
    const params = {
      "operation": "UPDATE",
      "args": {
        "path": treeData.find(tree => tree.id === id).realPath,
        "updatechildren": true,
        "nodeName": treeData.find(tree => tree.id === id).text,
        "attr": {
          "flabel": color,
          "rel": `${color}_folder`
        }
      }
    };
    const operationParams = {
      app_user: "app",
      app_password: "meeting",
      operation: operationName,
      issueName: treeData[0].text,
      params: JSON.stringify(params)
    };

    console.log("233233233",params, operationParams);
    
  
    axios.post(properties.productionUrl+'/issueManagerCGPianeta', qs.stringify(operationParams))
      .then(res => {
        console.log(res)
        if (res.data.status === "ok") {
          const selectedItem = treeData.find(item => item.id === id)
          const newTree = treeData.map(node => {
            if (node.id === id || node.parent === id || node.realPath.includes(`${selectedItem.realPath}/${selectedItem.text}`)) {
              return {
                ...node,
                data: {
                  ...node.data,
                  color: color
                }
              }
            }

            return node;
          })

          setTreeData(newTree);
        } else {
          toast.error("Error occured!")
        }
      })
      .catch(err => {
        console.log(err)
        toast.error("Error occured!")
      })
  }

  const handleLoadIssue = (issueName) => {
    const operationName = "loadIssue";
    console.log("HandleLoadIssue", issueName);
    console.log("handleIssue-1", issueName.length, issueName);
    const params = null;
    const operationParams = {
      app_user: "app",
      app_password: "meeting",
      operation: operationName,
      issueName: issueName,
      params: JSON.stringify(params)
    };
  
    axios.post(properties.productionUrl+'/issueManagerCGPianeta', qs.stringify(operationParams))
      .then(res => {
        console.log("HANDLELOADISSUE",res);
        console.log("TEST",res.data.result);
        setInvitationDate(getInvitationDate(res.data.result.convocationDate));
        setPresidentName(res.data.result.presidentName);
        setConvocationDate(getInvitationDate(res.data.result.invitationDate));
        setConvocationTime(res.data.result.convocationTime.replace('.', ':'));
        setRecipients(res.data.result.recipients);        
        setAuditors(res.data.result.auditors);
        setTreeData(convertResToTree(res.data.result.dirTree));
        setLockedFile(res.data.result.lockedForFileInsert);
        treeRef.current.openAll();
      })
      .catch(() => {
        toast.error("Failed to load issue")
      })
  }

  const handleIssueExists = (_inviatationDate, lastDate) => {
    
    const operationName = "issueExists";
    const params = null;
    const issueName = `Seduta del ${format(new Date(_inviatationDate), 'dd-MM-yyyy')}`
    console.log("handleIssueExists", _inviatationDate, lastDate,issueName);
    console.log("handleIssueExists-1", issueName.length, issueName);
    const operationParams = {
      app_user: "app",
      app_password: "meeting",
      operation: operationName,
      issueName: issueName,
      params: JSON.stringify(params)
    };

    //axios.post(`${process.env.REACT_APP_PROXY_URL}`, qs.stringify(operationParams))
    axios.post(properties.productionUrl+'/issueManagerCGPianeta', qs.stringify(operationParams))
      .then(res => {
        console.log(res);
        if(segreteria){
          console.log("into segreteria");
          if (res.data.result) {
            console.log("into1");
            handleLoadIssue(issueName);
          } else {          
              console.log("into else");
              confirmCreateIssue(_inviatationDate, lastDate);
          }
        }
        else{
          console.log("into not segreteria");
          console.log(res);
          if (res.data.result &&res.data.status!='failure') {
              console.log("into 11");
              handleLoadIssue(issueName);
          }
          else{
              setInvitationDate(lastDate);
              handleInvitationDateGoBack(lastDate);
          }
        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  const handleIssueExistsStartingFromDateLabel = (selectedDate) => {
    
    const operationName = "issueExists";
    const params = null;
    const issueName = 'Seduta del '+ selectedDate;
    console.log("handleIssueExists-2", selectedDate, issueName);
    console.log("handleIssueExists-22", issueName.length, issueName);
    const operationParams = {
      app_user: "app",
      app_password: "meeting",
      operation: operationName,
      issueName: issueName,
      params: JSON.stringify(params)
    };

    //axios.post(`${process.env.REACT_APP_PROXY_URL}`, qs.stringify(operationParams))
    axios.post(properties.productionUrl+'/issueManagerCGPianeta', qs.stringify(operationParams))
      .then(res => {
        console.log(res);
        if (res.data.result) {
          handleLoadIssue(issueName)
        } 
      })
      .catch(err => {
        console.log(err)
      })      
  }



  const handleLockIssueForFileInsert = () => {
    const operationName = "lockIssueForFileInsert";
    var issueName = treeData[0].text;
    const params = null
    const operationParams = {
      app_user: "app",
      app_password: "meeting",
      operation: operationName,
      issueName: issueName,
      params: JSON.stringify(params)
    };
  
    axios.post(properties.productionUrl+'/issueManagerCGPianeta', qs.stringify(operationParams))
      .then(res => {
        console.log(res)
        setLockedFile(true)
        toast.success("Issue locked successfully!")
      })
      .catch(err => {
        console.log(err)
        toast.error("Failed to lock issue")
      })
  }

  const handleRenderWord = (inputElement) => {
    const operationName = "renderIssue";
    var issueName = treeData[0].text;
    const params = null
    const operationParams = {
      app_user: "app",
      app_password: "meeting",
      operation: operationName,
      issueName: issueName,
      params: JSON.stringify(params)
    };

    axios.post(properties.productionUrl+'/issueManagerCGPianeta', qs.stringify(operationParams))
      .then(res => {
        console.log("renderWord Result" + JSON.stringify(res));
        
        if (res.data.status === "ok" && res.data.result.docHref != undefined)
        {
            console.log("href" + res.data.result.docHref);
            
            inputElement.current.href=properties.productionUrl+res.data.result.docHref;
            inputElement.current.click();
        }
        else {
            toast.error("Errore nella generazione dell'indice");
        }
      })
      .catch(err => {
        console.log(err)
        toast.error("Errore nella generazione dell'indice");
      })
  }

  const handleMakePublic = () => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "auto", marginBottom: "auto" }}
        title="Attenzione"
        onConfirm={() => { handleLockIssueForFileInsert(); setAlert(null) }}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText="Si"
        cancelBtnText="No"
        showCancel
      >
        <div>
          <Typography variant="body1">
            la seduta verrà resa disponibile per l'aggiunta dei file e non sarà più possibile modificarne la struttura.
          </Typography>         
          <Typography>Continuare?</Typography>
        </div>
      </SweetAlert>
    );
  }

  const handleUpdateIssue = (e) => {

    const values = {
      invitationDate: invitationDate,
      convocationDate: convocationDate,
      convocationTime: convocationTime,
      presidentName: presidentName,
      recipients: recipients,      
      auditors: auditors,
      [e.target.name]: e.target.value
    }

    const long_options = { year: 'numeric', month: 'long', day: 'numeric' };

    const operationName = "updateIssue";
    const params = {
      "operation": "UPDATEINFO",
      "args": {
        ...values,
        "convocationTime": values.convocationTime.replace(':', '.'),
        "invitationDate": new Date(values.convocationDate).toLocaleDateString('it-IT', long_options),
        "convocationDate": new Date(values.invitationDate).toLocaleDateString('it-IT', long_options)
      }
    };

    const operationParams = {
      app_user: "app",
      app_password: "meeting",
      operation: operationName,
      issueName: treeData[0].text,
      params: JSON.stringify(params)
    };
    
    axios.post(properties.productionUrl+'/issueManagerCGPianeta', qs.stringify(operationParams))
      .then(res => {
        console.log(res)
        toast.success("Issue updated")
      })
      .catch(err => {
        console.log(err)
        toast.error("Failed to updated")
      })

  }

  const handleChangePresidentName = (e) => {
    console.log("HCPN",e);    
      handleUpdateIssue(e)    
  }

  const handleInvitationDateGoBack = (date) => {
    var rr=format(new Date(date), 'dd-MM-yyyy');    
    navigate("/folders101/"+rr,{ replace: true }); 
  };


  const handleInvitationDateChange = (date) => {
    var rr=format(new Date(date), 'dd-MM-yyyy');
    navigate("/folders101/"+rr,{ replace: true }); 
    let lastDate = invitationDate;
    setInvitationDate(date);
    handleIssueExists(date, lastDate);
  };

  const handleConvocationDateChange = (date) => {
    setConvocationDate(date)
    handleUpdateIssue({ target: { name: 'convocationDate', value: date } })
  }

  const getAvailableIssueDates = () => {
    const operationName = "getAvailableIssueDates";
    const operationParams = {
      app_user: "app",
      app_password: "meeting",
      operation: operationName
    };
    
    axios.post(properties.productionUrl+'/issueManagerCGPianeta', qs.stringify(operationParams))
      .then(res => {
        if (!!res.data) {
          console.log("init",res.data);
          const _availableDates = res.data.map(node => {
            if (node.includes('_')) {
              return `${node.substring(0, 4)}-${node.substring(4, 6)}-${node.substring(6, 8)}`
            } else {
              return `${node.split('-')[2]}-${node.split('-')[1]}-${node.split('-')[0]}`
            }
          })
          console.log("AvailableDates",_availableDates);
          setAvailableDates(_availableDates)
        } else {
          setAvailableDates([])
        }
        
      })
      .catch(() => {
        setAvailableDates([])
      })
  }




  const handleMove = (direction, id) => {   
   
    const sourceNode = treeData.find(node => node.id === id)
    const pos = treeData.filter(node => node.parent === sourceNode.parent).findIndex(node => node.id === id)    
    if ((pos === 0 && direction === 'up') || (direction === 'down' && pos === (treeData.filter(node => node.parent === sourceNode.parent).length - 1))) {      
      return;
    }
    const params = {
      "operation": "MOVE",
      "args": {
        "frompath": sourceNode.realPath,
        "topath": sourceNode.realPath,
        "nodeName": sourceNode.text,
        "position": direction === 'up' ? pos - 1 : pos + 1
      }
    }
    const operationParams = {
      app_user: "app",
      app_password: "meeting",
      operation: "updateIssue",
      issueName: treeData[0].text,
      params: JSON.stringify(params)
    };
    
    axios.post(properties.productionUrl+'/issueManagerCGPianeta', qs.stringify(operationParams))
      .then(res => {
        console.log(res)
        if (res.data.result.status === 'ok') {
          fetchLastIssue()
        } else {
          toast.error('Failed to move')
        }
      })
      .catch(() => {
        toast.error('Failed to move')
      })
  }


  //
  function handleEditableBlur(event) {    
    console.log("BLUR",event);                        
    handleChangePresidentName(event);    
}
  
    
function handleKeyPress(event){  
    console.log("KP",event);    
    if(event.key === 'Enter'){
      console.log(event);
      event.target.blur();       
  }
}

const doPost2 = function() {
  
  var xhr2 = new XMLHttpRequest();
  xhr2.open('POST', properties.productionUrl+'/rest/getInfo3',true);
  xhr2.setRequestHeader("Content-type", "application/x-www-form-urlencoded");

  xhr2.onload = function (e) {
    if (xhr2.readyState === 4) {
      if (xhr2.status === 200) {
        var responseText = xhr2.responseText;
        //console.log("OK LOGIN 33");
        var obj=JSON.parse(responseText);
        var ruolo = obj.Ruolo;

        if(ruolo=="12"){
          setSegreteria(true);
        }
        else
          setSegreteria(false);
      } else {
        console.error("Error 13", xhr2.statusText);                
      }
    }
  };

  xhr2.onerror = function (e) {
    console.error("Error 1121", xhr2.statusText);
  };

  let urlEncodedData = null;
  xhr2.send(urlEncodedData);
}



  useEffect(() => {
    if(process.env.NODE_ENV !='development' ){
      console.log ("2");
  //CHECK AUTHENTICATION
  var xhr = new XMLHttpRequest();                
  xhr.open('POST', properties.productionUrl+'/rest/ping',true);
  xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");

  xhr.onload = function (e) {
      if (xhr.readyState === 4) {
          if (xhr.status === 200) {                    
              console.log("OK",xhr);             
          } else {
              console.error("Error 1", xhr);   
              localStorage.clear();
              navigate("/login");
          }
      }
  };

  xhr.onerror = function (e) {
    console.error("Error 2", xhr);
    localStorage.clear();             
    navigate("/login");
  };
       
  let urlEncodedData = null; 
  xhr.send(urlEncodedData);
  //CHECK AUTHENTICATION
  }
  
  if (!window.cordova){
      doPost2();
  }
  else {
      var onSuccess = function(response) {
          console.log("OK LOGIN 33");
          var obj=JSON.parse(response);
          var ruolo = obj.Ruolo;
          if(ruolo=="12"){
            setSegreteria(true);
          }
          else
            setSegreteria(false);
      }
      var onError = function() {}
      window.getInfo3(onSuccess, onError);
    }

    if(idMeeting!=undefined){
      if(idMeeting=="last"){
        console.log("if",idMeeting);     
        fetchLastIssue();
      }
      else {        
          if(idMeeting.length==10){
            console.log("elseif",idMeeting);            
            handleIssueExistsStartingFromDateLabel(idMeeting);
        }    
        else{
          console.log("elseelse",idMeeting);
          fetchLastIssue();
        }
      }
    }
    else{
      console.log("altro else",idMeeting);
      fetchLastIssue();
    }

    getAvailableIssueDates();
    /*setPageRouter({
      router: history.location.pathname,
      homecolor: "#FF5A60",
      settingcolor: "#879497",
      homebordercolor: "#FF5A60",
      settingbordercolor: "#FFFFFF",
      hometitlecolor: "black",
      settingtitlecolor: "#879497",
        }); */
  }, []) 

  return (
     <div className="content">
       
       {!lockedFile ?(
         <Tooltip title="Rendi Pubblico" placement="left">
            <Fab aria-label="add"                                 
                onClick={(event) => {onFabClick(event) }}                
                style={{
                    margin: 0,
                    background: "#0084d5",
                    top: 'auto',
                    right: 10,
                    bottom: 25,                    
                    left: 'auto',
                    position: 'fixed',}}>                    
                    <SendIcon />                    
            </Fab>  
          </Tooltip>
       ):(
         <div>
           <Tooltip title="Gestione dei file" placement="left">
              <Fab aria-label="add"                 
              //variant="contained" 
              onClick={(event) => {onFabClick2(event) }}                
              style={{
                  margin: 0,                  
                  background: "#A2EE7D",
                  top: 'auto',
                  right: 10,
                  bottom: 25,                  
                  left: 'auto',
                  position: 'fixed',}}>                    
                  <AttachFileIcon />                    
              </Fab>  
            </Tooltip>
          
        </div>
       )

       }                 
      <Container maxWidth="xl">

        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={itLocale}>
          <Grid container spacing={2}>
                
              <Grid item xs={12} sm={6} md={8}>
              <Box display="flex" alignItems="center" height="100%">
                <Typography variant="body1" className={classes.fontBold}>
                  Organo relativo alla riunione:
                </Typography>
              </Box>
            </Grid>
            {segreteria ?(
            <Grid item xs={12} sm={6} md={4}>             
               <Select key={uuidv4()}     
                  isSearchable= {false}                                  
                  component={{MenuList}}  
                  fullWidth                  
                  styles={customStyles}                  
                  onChange={(event) => handleChange1(event, idMeeting)}
                  defaultValue={{
                    label: "Commissione Pianeta",
                    value: "101"                    
                  }}     
                  options={organiValues}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: '#a2ee7d',
                      primary: 'black',
                    },
                  })}                  
                /> 
            </Grid>
            ):(
              <Grid item xs={12} sm={6} md={4}>             
               <Select key={uuidv4()}     
                  isSearchable= {false}  
                  isDisabled={true}                      
                  component={{MenuList}}  
                  fullWidth                  
                  styles={customStyles}                  
                  onChange={(event) => handleChange1(event, idMeeting)}
                  defaultValue={{
                    label: "Commissione Pianeta",
                    value: "101"                    
                  }}     
                  options={organiValues}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: '#a2ee7d',
                      primary: 'black',
                    },
                  })}                  
                /> 
            </Grid>
            )}


            <Grid item xs={12} sm={6} md={8}>
              <Box display="flex" alignItems="center" height="100%">
                <Typography variant="body1" className={classes.fontBold}>
                  Data della riunione:
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
             
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                id="invitationDate"
                value={invitationDate}
                onChange={handleInvitationDateChange}
                autoOk={true}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                className={classes.datePicker}
                renderDay={(day, selectedDate, isInCurrentMonth, dayComponent) => {
                  return <Badge className={availableDates.includes(getJsonDate(day)) ? classes.existDate : classes.nonExist}>{dayComponent}</Badge>;
                }}
              />
             
            </Grid>

            <Grid item xs={12} sm={6} md={8}>
              <Box display="flex" alignItems="center" height="100%">
                <Typography variant="body1" className={classes.fontBold}>
                  Ora della riunione:
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}> 
              <TextField
                variant="outlined"
                fullWidth
                type="time"
                name="convocationTime"
                className={classes.bgWhite}
                value={convocationTime}
                onChange={(e) => { setConvocationTime(e.target.value); handleUpdateIssue(e) }}
                disabled={lockedFile}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={8}>
              <Box display="flex" alignItems="center" height="100%">
                <Typography variant="body1" className={classes.fontBold}>
                  Data della convocazione:
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                id="convocationDate"
                value={convocationDate}
                onChange={handleConvocationDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                autoOk={true}
                disabled={lockedFile}
                className={classes.datePicker}
              />
            </Grid>
                        
            <Grid item xs={12} >
             
              <Typography variant="body1" className={classes.fontBold} style={{textAlign: "left",paddingBottom:"5px",paddingTop:"15px"}}>
                Componenti Commisione Pianeta:
              </Typography>

              <Autocomplete
                multiple
                id="tags-filled"                
                options={[]}                
                getOptionLabel={option => option.title}
                freeSolo
                value={recipients}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip variant="outlined" style= {{fontSize: 16}} label={option} {...getTagProps({ index })} />
                  ))
                }
                onChange={(_, value) => {
                  setRecipients(value);
                  handleUpdateIssue({ target: { name: 'recipients', value: value } })
                }}
                
                disabled={lockedFile}
                renderInput={(params) => (
                  <TextField
                    {...params}                                          
                    variant="outlined"
                    className={classes.bgWhite}                
                  />
                
                )}
              />

            </Grid>
          
            <Grid item xs={12}>
              <Typography variant="body1" className={classes.fontBold} style={{textAlign: "left",paddingBottom:"5px",paddingTop:"10px"}}>
                Altri Partecipanti:
              </Typography>
              <Autocomplete
                multiple
                id="tags-filled"
                options={[]}
                freeSolo
                value={auditors}
                onChange={(_, value) => {
                  setAuditors(value);
                  handleUpdateIssue({ target: { name: 'auditors', value: value } })
                }
                }
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip variant="outlined" style= {{fontSize: 16}} label={option} {...getTagProps({ index })} />
                  ))
                }
                disabled={lockedFile}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    className={classes.bgWhite}
                  />
                )}
              />
            </Grid>

            

            <Grid item xs={12}>
              <Box mt={4}>
                <Typography variant="body1" className={classes.fontBold} style={{textAlign: "left",paddingBottom:"5px"}}>
                  Edita la struttura del documento spostando e creando le cartelle:
                </Typography>
              </Box>
              <Box>
                <Box className={classes.bgWhite} style={{paddingTop:"10px", paddingLeft:"20px", borderRadius: "4px", border: "1px solid rgb(196,196,196)",}}>
                  <Tree
                    ref={treeRef}
                    tree={treeData}

                    rootId={0}
                    initialOpen={true}
                    sort={false}
                    render={(node, options) => (
                      <CustomNode
                        node={node}
                        {...options}
                        handleDelete={handleDeleteTree}
                        handleAddNewNode={handleCreateTree}
                        handleChangeText={handleChangeText}
                        handleChangeColor={handleChangeColor}
                        newNodeId={newNodeId}
                        lockedFile={lockedFile}
                        isCreated={isCreated}
                        handleMove={handleMove}
                      />
                    )}
                    onDrop={handleDropTree}
                    classes={{
                      root: classes.treeRoot,
                      draggingSource: classes.draggingSource,
                      dropTarget: classes.dropTarget
                    }}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </MuiPickersUtilsProvider>
        {alert}
      </Container>
      </div>
  );
}


