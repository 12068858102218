import {React,  useRef} from "react";
//import { useHistory } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import Calendar from 'rc-year-calendar';
import { useNavigate } from "react-router-dom";
import 'rc-year-calendar/locales/rc-year-calendar.it';
import RouterContext from "../../context/RouterContext";
import {updateSedute} from "./Utils";
import {properties} from "utils/properties";
import { Fab, Tooltip } from '@material-ui/core/';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

export default function CalendarView() {
  //const history = useHistory();
  const navigate = useNavigate();

  const [meetingData, setMeetingData] = useState([]);
  const [bothRoles, setBothRoles] = useState(false);
  const [threeRoles, setThreeRoles] = useState(false);
  const [secondLevelButtons, setSecondLevelButtons]= useState(false);   
  const { pageRouter, setPageRouter } = useContext(RouterContext);
  const [startingYear, setStartingYear]= useState(0);
  var lastMeeting= useRef(null);
  
  const updateUI = function(jsonData) {     
        console.log("OK UPDATEUI",jsonData); 
        var entireMeeting = Object.entries(jsonData);                    
        var orderMeeting = [];
        var colore='#30720f';
        for (var i = entireMeeting.length - 1; i >= 0; i--) {    
            //console.log("77",entireMeeting[i][1]["organo"]);                 
            colore='#CCCCCC';            
            var dateLabel=entireMeeting[i][0].substring(4, 8).concat("-").concat(entireMeeting[i][0].substring(2, 4)).concat("-").concat(entireMeeting[i][0].substring(0, 2));
            var eachMeeting = {
                id: entireMeeting[i][0],
                name: 'Seduta del '.concat(entireMeeting[i][1]["dataSeduta"]),              
                location: entireMeeting[i][0],          
                startDate: new Date(dateLabel),
                endDate: new Date(dateLabel),
                color: colore,  
            };            
            orderMeeting.push(eachMeeting);            
            if(lastMeeting.current===undefined) {
                //console.log("1",lastMeeting, eachMeeting.startDate);
                lastMeeting.current=eachMeeting.startDate;
            }
            else{
                if (lastMeeting.current < eachMeeting.startDate){                
                    lastMeeting.current=eachMeeting.startDate;              
                }
            }
        }      
        setStartingYear(lastMeeting.current.getFullYear());        
        var today={
            id: 0,
            name: 'Oggi',              
            location: '',          
            startDate: new Date(),
            endDate: new Date(),
            color: '#FF0000',                                          
        };
        orderMeeting.push(today);
        console.log("MEETING",orderMeeting);
        setMeetingData(orderMeeting);
  }
  

  const doPostSedute2 = function() {
    //console.log("doing sedute post");
    var xhr2 = new XMLHttpRequest();                
    xhr2.open('POST', properties.productionUrl+'/rest/checkSeduteJsonCGStatuto',true);
    xhr2.setRequestHeader("Content-type", "application/x-www-form-urlencoded");    
    xhr2.onload = function (e) {      
        if (xhr2.readyState === 4) {
            if (xhr2.status === 200) {                              
                //console.log("OK DOPOSTSEDUTE",xhr2);
                var responseText = xhr2.responseText;                    
                var obj=JSON.parse(responseText);
                //console.log("OK93",obj);          
                if(obj.status==="204" ){ 
                    console.log("OK DOPOSTSEDUTE 204",xhr2);
                    const jsonListSedute = JSON.parse(localStorage.getItem("listSeduteCGStatuto"));
                    console.log("OK DOPOSTSEDUTE 204",jsonListSedute);
                    updateUI(jsonListSedute);
                }              
                else{
                    //status 201
                    console.log("OK DOPOSTSEDUTE 201",xhr2);                            
                    try{
                        localStorage.setItem("listSeduteCGStatuto", obj.content);
                        localStorage.setItem("listSeduteVersionCGStatuto", obj.date);                                                                                                                                           
                    }
                    catch(error) {
                        console.log("LOCALSTORAGE ISSUE", error);
                    }  
                    
                    const jsonListSedute = JSON.parse(obj.content);
                    console.log("OK DOPOSTSEDUTE 201",jsonListSedute);
                    updateUI(jsonListSedute);
                }
            } 
            else {
                //console.error("Error 1", xhr.statusText);          
                alert("Errore recuperando la seduta");  
            }
        }
    };

    xhr2.onerror = function (e) {
        console.error("Error 22", xhr2.statusText);
    };
    
    let urlEncodedData = null; //"idf=" + meetingId;
    if (localStorage.listSeduteCGStatuto!=='' &&localStorage.listSeduteCGStatuto!==undefined){            
        //console.log( "QUINDI00");
        const currentData=localStorage.getItem("listSeduteVersionCGStatuto");        
        urlEncodedData = "date=" + currentData;                    
    }            
    xhr2.send(urlEncodedData);
}
  
    useEffect(() => {     
              
        if(localStorage.getItem("new_ui")=== undefined || localStorage.getItem("new_ui")=== null || localStorage.getItem("new_ui")=== "false"){        
            /*history.replace({                
                pathname: "/cg_statuto",
                });*/
            navigate("/cg_statuto",{ replace: true });  
        }              
                                                   
        if(process.env.NODE_ENV !=='development' && !window.cordova){        
        //CHECK AUTHENTICATION
            var xhr = new XMLHttpRequest();                
            xhr.open('POST', properties.productionUrl+'/rest/ping',true);
            xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
    
            xhr.onload = function (e) {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {                         
                    console.log("OK",xhr);   
                    updateSedute("CGStatuto",doPostSedute2, updateUI);                               
                } else {
                    console.error("Error 1", xhr);   
                    localStorage.clear();                 
                    /*history.push({                
                    pathname: "/login",
                    });*/
                    navigate("/login");  
                }
            }
            };
    
            xhr.onerror = function (e) {
                console.error("Error 2", xhr);
                localStorage.clear();
                /*history.push({                
                    pathname: "/login",
                    });*/
                navigate("/login");  
            };
             
            let urlEncodedData = null; 
            xhr.send(urlEncodedData);
        //CHECK AUTHENTICATION
        }
        
        if (window.cordova)
        {
           updateSedute("CGStatuto",doPostSedute2, updateUI);   
        }
         /* 
        setPageRouter({
            router: history.location.pathname,
            homecolor: "#FF5A60",
            settingcolor: "#879497",
            homebordercolor: "#FF5A60",
            settingbordercolor: "#FFFFFF",
            hometitlecolor: "black",
            settingtitlecolor: "#879497",
        });*/
    }, []);

   const topicList = (e) => {    
    
    if(e.events.length>0){        
        console.log("TO BE INVESTIGATED",e);
        if (e.events[0].location!==null && e.events[0].location!==undefined && e.events[0].location!=="")            
            /*history.push({                    
                    pathname: "/cg_statuto/" + e.events[0].location ,
             });             */
             navigate("/cg_statuto/"+e.events[0].location);  
      }
    };
 
  return (
    <div>        
      <Calendar                    
          year= {startingYear}          
          language="it"    
          minDate= {new Date("2016/01/01")}       
            /*minDate= {new Date("2020/01/01")}*/        
            /*maxDate= {new Date("2022/12/31")}*/
          maxDate= {new Date().setDate(new Date().getDate()+180)}
          style= "background"
          enableContextMenu={true}          
          dataSource={meetingData}          
          onDayClick={e => topicList(e)}
          />
      </div>
  );
  
  }  
