import React, { useState, useEffect, useContext } from "react";
//import { useHistory, useParams } from "react-router-dom";
import {useParams } from "react-router-dom";
import RouterContext from "../../context/RouterContext";
import "../../App.css";
import { IconButton, Fab, Tooltip } from '@material-ui/core/';
import CircularProgress from "@material-ui/core/CircularProgress";
import * as Icons from '@material-ui/icons/';
import AddIcon from '@material-ui/icons/Add';
import AttachmentIcon from '@material-ui/icons/Attachment';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import PublishIcon from '@material-ui/icons/Publish';
import UpdateIcon from '@material-ui/icons/Update';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from "react-router-dom";
import {ThreeDots} from 'react-loader-spinner';
import { List } from 'react-virtualized';
import Select from 'react-select';
import {properties} from "utils/properties";

import ContentEditable from 'react-contenteditable';

export default function UpdateAttachments() {    
    let { idDossier } = useParams();
    const [loadingGen, setLoadingGen] = React.useState(false);   
    const [AttachmentData, setAttachmentData] = useState([]);
    const { pageRouter, setPageRouter } = useContext(RouterContext);
    //const history = useHistory();    
    const navigate = useNavigate();
    const [additionalData, setAdditionalData] = useState();    
    const meetingId = idDossier ;
    const [somethingChange, setSomethingChange]= useState("");   
    const [loading, setLoading]= React.useState(true);    
    const [secondLevelButtons, setSecondLevelButtons]= React.useState(false);    
    const itemsRef = React.useRef([]);
    
    const hiddenFileUpload2 = React.useRef(null);
    
    const manyItems = [...new Array(900)].map((_, i) => ({
        //value: i,
        value: `${i}`,
        //label: `something${i}`,
        label: `${i}`,    
      }));

    const fromSettoreCodiceToColor = function(codice) {
        if (codice>=80 && codice<90)  {//PIANETA
            return ("#0084d5");
        }
        else{
            if (codice>=70 && codice<80)  {//Cultura
                return ("#ce0f69");                
            }
            else{
                if (codice>=60 && codice<70)  {//Persone
                    return ("#F0B323");
                }
                else {
                    if (codice==100)  {//Box Neutro
                        return ("#DDDDDD");
                    }
                    else{
                        if (codice>=90 && codice <=95) //Direzioni
                            return ("#FFD480");   
                        else{
                            if (codice==98) {//In evidenza
                            return ("#046A38");
                            }
                            else //non dovrebbe mai capitare
                                return ("#000000");   
                        }
                    }
                }            
            }
        }
    }   

    function convertIdToMissione(idi) {
        //console.log("22222",idi);
        if ((idi != undefined) && (idi != '') &&(idi != ' ')){
            //return missioniValues.find(o => o.value === idi).label;        
            var returned= missioniValues.find(o => o.value === idi); 
            if(returned != undefined)
              return returned.label;        
            else
              return "Non Valorizzato";            
        }
    }

    const missioniValues =[        
        {value: "62", label:"Abitare"},
        {value: "63", label:"Lavoro"},
        {value: "64", label:"Educazione"},
        {value: "65", label:"Comunita'"},
        {value: "66", label:"Inclusione"},
        {value: "67", label:"PERSONE"},
        {value: "68", label:"Risorse Libere Persone"},
        {value: "70", label:"Attrattivita'"},
        {value: "71", label:"Competenze"},
        {value: "72", label:"Custodia"},
        {value: "73", label:"Partecipazione"},
        {value: "74", label:"CULTURA"},
        {value: "75", label:"Risorse Libere Cultura"},
        {value: "80", label:"Ricerca"},
        {value: "81", label:"Innovazione"},
        {value: "82", label:"Benessere"},
        {value: "83", label:"Internazionalizzazione"},
        {value: "84", label:"Ambiente"},
        {value: "85", label:"PIANETA"},
        {value: "86", label:"Risorse Libere Pianeta"},
        {value: "90", label:"DIREZIONE PIANIFICAZIONE, STUDI E VALUTAZIONE"},
        {value: "95", label:"DIREZIONE INNOVAZIONE DI IMPATTO"},
        //{value: "98", label:"IN EVIDENZA"},
        {value: "98", label:"INDICE E SITUAZIONE FONDI"},
        //{value: "100",label:"PROGETTI SPECIALI / INTERSETTORIALI E PRATICHE AGGIUNTIVE"},            
        {value: "100", label:"CONVENZIONI, PROGETTI SPECIALI, ULTERIORI PRATICHE E DOCUMENTI"},  
        {value: "1", label:"Ricerca e Istruzione Superiore"},
        {value: "5", label:"Patrimonio Artistico"},
        {value: "6", label:"Arte, Attivita' e Beni Culturali"},
        {value: "7", label:"Attivita' Culturali"},
        {value: "9", label:"Sanita'"},
        {value: "10", label:"Filantropia e Territorio"},
        {value: "11", label:"Politiche Sociali"},
        {value: "12", label:"Innovazione Culturale"},
        {value: "19", label:"Volontariato"},
        {value: "21", label:"Protocollo Intesa A.C.R.I."},
        {value: "25", label:"Lasciti e Donazioni"},
        {value: "35", label:"Programma Torino e le Alpi"},
        {value: "40", label:"Programma Polo del '900"},
        {value: "45", label:"Programma ZeroSei"},
        {value: "50", label:"Programma Housing"},
        {value: "55", label:"Sperimentazione e Attuazione Piano Strategico"},
        {value: "57", label:"Innovazione di Impatto"},
        {value: "59", label:"Programma Interational Affairs"}      
    ];

    const doPostNoUpdate = function(scheda, campo, valore,item) {        
        var xhr = new XMLHttpRequest();        
        xhr.open('POST', properties.productionUrl+'/rest/updateDLFields',true);
        xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
        xhr.onload = function (e) {      
            if (xhr.readyState === 4) {
                if (xhr.status === 200) {                              
                    console.log("OK72",xhr);
                    //var responseText = xhr.responseText;    
                    doPostSedute2();
                    console.log("called 2");
                    }                                    
                else {                
                    alert("Errore aggiornando received");  
                }
            }
        };

    
        xhr.onerror = function (e) {
            console.error("Error 2", xhr.statusText);
        };
          
        let urlEncodedData = "idf=" + idDossier + "&url=" + b64EncodeUnicode(scheda)+ "&field=" + campo+ "&value=" + b64EncodeUnicode(valore); 
        console.log(urlEncodedData);
        xhr.send(urlEncodedData);
    }


    const doPost = function(scheda, campo, valore,item) {        
        var xhr = new XMLHttpRequest();        
        xhr.open('POST', properties.productionUrl+'/rest/updateDLFields',true);
        xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
        xhr.onload = function (e) {      
            if (xhr.readyState === 4) {
                if (xhr.status === 200) {                              
                    console.log("OK72",xhr);
                    var responseText = xhr.responseText;                    
                    var obj=JSON.parse(responseText);
                    console.log("OK73",obj);                      
                    const temp1= Object.entries(obj);        
                    var temp3 = [];
                    temp1.forEach( entry => {
                        let key= entry[0];
                        //let value= entry[1];
                        if(key==='File') {                                                            
                            //console.log("Step1", entry[1],new Date().getTime());
                            //console.log("Step2",entry);
                            entry[1].forEach( entry2 => {
                                //console.log("BOTCH",entry2);
                                //if(entry2.Tipologia==="Documento libero" || entry2.Tipologia==="Documento Libero"){
                                    temp3.push(entry2);
                                //}                       
                            });  
                        }                
                     }); 
                     if(temp3!=null && temp3 != undefined){
                        console.log("Step3", temp3);
                        //setReceivedeFiles(temp3);
                        items=AttachmentData;
                        //let items2 = JSON.parse(JSON.stringify(AttachmentData));
                        console.log("22",AttachmentData);
                        //console.log("23",items2);
                        temp3.forEach( entryTemp3 => {                              
                                items.forEach( entryItems => {                                        
                                    if(entryTemp3.Urls!==undefined){
                                        entryTemp3.Urls.map((entryItem32, index32) => {
                                            if(entryItem32.toLowerCase()=== entryItems.name.toLowerCase()){
                                                //console.log("311", entryTemp3.Url.substr(entryTemp3.Url.lastIndexOf("/")+1), entryItems.name);
                                                //console.log("312",entryTemp3);
                                                //console.log("317",entryItems, entryItem32);
                                                if(entryTemp3.IdentificativoSezione==="00")
                                                    entryTemp3.IdentificativoSezione="98";
                                                entryItems.mission= entryTemp3.IdentificativoSezione;
                                                entryItems.order=entryTemp3.Ordine;
                                                //console.log("QAZ+",entryTemp3.Ordine);
                                                //if(entryTemp3.Ordine==""){
                                                //    console.log("QAZ_1+",entryTemp3.Ordine);                                            
                                                //    entryItems.order=0;
                                                //}
                                                //else{
                                                //    console.log("QAZ_2+",entryTemp3.Ordine);
                                                //    entryItems.order=entryTemp3.Ordine;
                                                //}
                                                entryItems.desc=entryTemp3.Descrizione;
                                                entryItems.color=fromSettoreCodiceToColor(entryTemp3.IdentificativoSezione);
                                                entryItems.type=entryTemp3.TipoRiga;
                                                if(entryItems.type!=="DL")
                                                    entryItems.color="#FFFFFF";
                                            }
                                        });                                                                         
                                    }    
                                                                                     
                                });                                                  
                         });
                        //console.log("2+",items);
                        //setSomethingChange(item.name);   
                        items.sort(function(a, b) {
                            var keyA = new Date(a.order),
                              keyB = new Date(b.order);
                            
                            if (keyA < keyB) return -1;
                            if (keyA > keyB) return 1;
                            return 0;
                          });
                          
                        console.log("21-10-22_2+",items);
                        setAttachmentData(items); 
                        console.log("21",loadingGen,item.loading);
                        item.loading=false; 
                        setLoadingGen(false);

                        items.forEach( entry => {                              
                            if(entry.name=== scheda){                                        
                                var itemX = {                        
                                    idSection: entry.mission,
                                    order: entry.order,                                                        
                                };   
                                setAdditionalData(itemX);                                
                            }
                    });                                           
                    }        
            
                } 
                else {                
                    alert("Errore recuperando la seduta raw richiesta 5");
                    /*history.replace({                                
                      pathname: "/init",
                  });*/
                  navigate("/init",{ replace: true });  

                }
            }
        };

    
        xhr.onerror = function (e) {
            console.error("Error 2", xhr.statusText);
        };
          
        let urlEncodedData = "idf=" + idDossier + "&url=" + b64EncodeUnicode(scheda)+ "&field=" + campo+ "&value=" + b64EncodeUnicode(valore); 
        console.log(urlEncodedData);
        xhr.send(urlEncodedData);
    }

    /*const StyledTextField = withStyles({
        root: {
          "& label": {
            
            transformOrigin: "top right",
              right: "0",
              left: "auto" 
          }
        }
      })(TextField);
      */

    function handleChange1(event, id, item) {
        if (event && event.value){
            if (!loadingGen) { item.loading=true; setLoadingGen(true);} 
            console.log(event);
            console.log(event.label);
            //console.log(event.target.value);
            //console.log(event.target);
            console.log(id);
        //console.log(event.target.value);
        //console.log(event.target);
        //console.log(id);
        }
        //doPost(id, "SettoreCodice", event.target.value);  
        console.log("HC1",item.name, event.value,item);
        doPost(item.name, "SettoreCodice", event.value,item);      
      }

    function handleChange2(event, id,item) {
        
        if (event && event.value){
            if (!loadingGen) { item.loading=true; setLoadingGen(true);} 
        //console.log(event.target.value);
        //console.log(event.target);
        //console.log(id);
        console.log(event);
        //console.log(event.target.value);
        //console.log(event.target);
        console.log(id);
        console.log(item);
        }
        console.log("HC2",item.name, event.value,item);
        doPost(item.name, "Ordine", event.value,item);    

    }
    
    /*function handleEditableChange(event, id, item) {                        
            console.log("CHANGE",event.target.value,id,item);                        
            console.log(id.name);
      }
    */

    function handleEditableBlur(event, item) {       
        if(event.target.outerText.endsWith(".pdf")||event.target.outerText.endsWith(".ppt")||event.target.outerText.endsWith(".pptx")||event.target.outerText.endsWith(".doc")||event.target.outerText.endsWith(".docx")
        ||event.target.outerText.endsWith(".xls")||event.target.outerText.endsWith(".xlsx"))                 {
            console.log("BLUR",event.target.outerText, item);                        
            //console.log(item.name);
            if (!loadingGen) { item.loading=true; setLoadingGen(true);} 
            doPostNoUpdate(item.name, "Titolo", event.target.outerText,item);      
            console.log(item.name, "Titolo", event.target.outerText,item);
        }
        else{
            alert("ERRORE: Manca l'estensione del file");     
        }
        
    }

    function handleKeyPress(event){
        if(event.keyCode === 13){
        event.target.blur();       
        }
    }

    const updateUI = function(jsonData) {      
        var alfa=jsonData.split(/\r?\n/);
        for(var d=1; d< alfa.length;d++) {                        
            var beta= alfa[d].split(' ');
            beta=beta.filter(String);
            var val="";
            for (var r=7;r < beta.length;r++){
                val=val+" "+beta[r];
            }
            val=val.trim();
            var found=false;
            
            if(beta[7]!= undefined) {
                items.forEach( entry => {                                       
                    if(val.startsWith(entry.name)) {  //già presente tra gli items -> si tratta di un aggiornamento già effettuato
                        found=true;
                        entry.updated= entry.updated+1;
                        //entry.color= "#FF0000";
                    }
                });              
                if(found===false){
                    var item = {
                        name: val,
                        lastUpdate: beta[5]+" "+beta[6],
                        updated: 0,
                        color: "#000000",
                        size: beta[4],
                        loading: false,
                        mission: "",                        
                        order: 0,
                        desc: "",                        
                    };            
                    items.push(item);
                //console.log("PR",item);
                }                            
            } //if undefined
                        
        } //fine for                    
        console.log("1 UPDATE UI",items);
        //setAttachmentData(items);
        doPostSeduta113(items);
    }

    var items = [];

    const doPostSedute2 = function() {
        console.log("doing sedute post");
        var xhr2 = new XMLHttpRequest();                
        
        xhr2.open('POST', properties.productionUrl+'/php/core/rest/test01.php',true);
        xhr2.setRequestHeader("Content-type", "application/x-www-form-urlencoded");    
        xhr2.onload = function (e) {
            if(xhr2.readyState === 4) {
                if (xhr2.status === 200) {                              
                    console.log("OK92",xhr2);
                    var responseText = xhr2.responseText;                                        
                    //console.log("ok",responseText);
                    // eslint-disable-next-line eqeqeq
                    if(responseText!="\n\nnull"){
                    var obj=JSON.parse(responseText);
                    console.log("OBJ ON DOPOSTSEDUTE2",obj);
                    updateUI(obj);         
                    }

                } 
                else {
                    //console.error("Error 1", xhr.statusText);          
                    alert("Errore recuperando la lista degli allegati. Verifica che esista un fascicolo in preview e che siano già stati scaricati gli allegati.");  
                   /* history.replace({                                
                        pathname: "/init",
                    });*/
                    navigate("/init",{ replace: true });  
                }
            }
        };

        xhr2.onerror = function (e) {
            console.error("Error 22", xhr2.statusText);
        };
               
        let urlEncodedData = "idf=" + meetingId;                 
        xhr2.send(urlEncodedData);
    }
        
 


    useEffect(() => {              
        if(process.env.NODE_ENV !=='development'){            
        //CHECK AUTHENTICATION
            var xhr = new XMLHttpRequest();                
            xhr.open('POST', properties.productionUrl+'/rest/ping',true);
            xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
    
            xhr.onload = function (e) {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {                    
                        //doPostSedute(); 
                    console.log("OK",xhr);
                    //history.push({                
                    //pathname: "/login/",
                    //});
                } else {
                    console.error("Error 1", xhr);    
                    localStorage.clear();                
                    /*history.push({                
                    pathname: "/login",
                    });*/
                    navigate("/login");  
                }
            }
            };
    
            xhr.onerror = function (e) {
                console.error("Error 2", xhr);
                localStorage.clear();
                /*history.push({                
                    pathname: "/login",
                    });*/
                    navigate("/login");  
            };
             
            let urlEncodedData = null; 
            xhr.send(urlEncodedData);
        //CHECK AUTHENTICATION
        }
                

        
        doPostSedute2();

/*        setPageRouter({
            router: history.location.pathname,
            homecolor: "#FF5A60",
            settingcolor: "#879497",
            homebordercolor: "#FF5A60",
            settingbordercolor: "#FFFFFF",
            hometitlecolor: "black",
            settingtitlecolor: "#879497",
        });*/
    }, []);

     const openPdf = (name) => {
        console.log("item1111111");        
        var url=properties.productionUrl+"/fileManager/files/premesse/"+idDossier+"/"+name;        
        window.open(url+"?dt="+(new Date()).getTime());        
    };

    const test=(index) => {
       /*  //devo disabilitare l'apertura del pdf se ci sono le informazioni da modificare altrimenti apro il pdf        
        
        console.log("2322",openPdfI);
        //if(openPdfI[index]===undefined)
            
        var itemX = {
            name: index,                        
            pdffable: false,                                
        };   
        setopenPdfI(itemX);


        console.log("2323",itemsRef.current[index]);
        //document.getElementById('pdf'.index).onclick = '';
        itemsRef.current[index].disabled=true;
        console.log("2324"); */
    } 


//REVISIT
const doPostSeduta113 = function(items) {
    //setSomethingChange("");
    
    var xhr2 = new XMLHttpRequest();                
    xhr2.open('GET', properties.productionUrl+'/rest/getFileSedutaReceived?idf='+meetingId,true);
    xhr2.setRequestHeader("Content-type", "application/x-www-form-urlencoded");    
    xhr2.onload = function (e) {      
      if (xhr2.readyState === 4) {
        if (xhr2.status === 200) {                              
            console.log("OK92",xhr2);
            var responseText = xhr2.responseText;                    
            var obj=JSON.parse(responseText);
            console.log("OK93",obj);          
            console.log("Step1", new Date().getTime());    
            const temp1= Object.entries(obj);        
            var temp3 = [];
            temp1.forEach( entry => {
                let key= entry[0];
                //let value= entry[1];
                if(key==='File') {                                                            
                    //console.log("Step1", entry[1],new Date().getTime());
                    //console.log("Step2",entry);
                    entry[1].forEach( entry2 => {
                        //console.log("BOTCH",entry2);
                        //if(entry2.Tipologia==="Documento libero" || entry2.Tipologia==="Documento Libero"){
                            temp3.push(entry2);
                        //}                       
                    });  
                }                
             }); 
             if(temp3!=null && temp3 != undefined){
                console.log("TEMP3", temp3);
                //setReceivedeFiles(temp3);
                
                temp3.forEach( entryTemp3 => {                              
                        items.forEach( entryItems => {    
                           if(entryTemp3.Urls!==undefined && entryTemp3.Urls!==[]){
                               //console.log("WW",entryTemp3.Urls);
                                entryTemp3.Urls.map((entryItem32, index32) => {
                                    //console.log("WW1",entryItem32,entryItems);
                                    if(entryItem32.toLowerCase()=== entryItems.name.toLowerCase()){                                        
                                        //console.log("318",entryItems, entryItem32);
                                        if (entryTemp3.IdentificativoSezione==="00")
                                            entryTemp3.IdentificativoSezione="98";
                                        entryItems.mission= entryTemp3.IdentificativoSezione;
                                        //console.log("QAZ",entryTemp3.Ordine);
                                        //if(entryTemp3.Ordine==""){
                                        //    console.log("QAZ_1",entryTemp3.Ordine);  
                                        //    entryItems.order=0;
                                        //}
                                        //else{
                                        //    console.log("QAZ_2",entryTemp3.Ordine);  
                                        //    console.log("QAZ",entryTemp3.Ordine);
                                            entryItems.order=entryTemp3.Ordine;
                                        //}
                                        entryItems.desc=entryTemp3.Descrizione;
                                        entryItems.color=fromSettoreCodiceToColor(entryTemp3.IdentificativoSezione);
                                        entryItems.type=entryTemp3.TipoRiga;
                                        if(entryItems.type!=="DL")
                                            entryItems.color="#FFFFFF";
                                    }
                                });                                                                         
                            }    
                           
                       
                        //setAdditionalData(itemX);
                        //setSomethingChange(name);   
                        });                                                  
                 });
                //console.log("2",items);
                //setSomethingChange("");   
                items.sort(function(a, b) {
                    var keyA = new Date(a.order),
                      keyB = new Date(b.order);
                    
                    if (keyA < keyB) return -1;
                    if (keyA > keyB) return 1;
                    return 0;
                  });
                  
                console.log("21-10-22_2",items);
                setAttachmentData(items); 
                setLoading(false);
            }       
        } 
        else {
          //console.error("Error 1", xhr.statusText);          
          alert("Errore recuperando la seduta raw richiesta 3");
          /*history.replace({                                
            pathname: "/init",
        });*/
        navigate("/init");  
        }      
      }
    };

    xhr2.onerror = function (e) {
      console.error("Error 22", xhr2.statusText);
    };
    
    let urlEncodedData = null;    
    xhr2.send(urlEncodedData);    
};

//REVISIT



    
const getAdditionalData = function(name) {
    console.log("called gad",name, AttachmentData);  
    //setSomethingChange("");        
    AttachmentData.forEach( entry => {      
            //console.log("test",entry,name);  
            if(entry.name=== name){                
                //console.log("found Anto",entry);
                var itemX = {
                    //name: entry.name,
                    //desc: entry.Descrizione,
                    idSection: entry.mission,
                    order: entry.order,                    
                    //loading: false
                };   
                setAdditionalData(itemX);
                if(somethingChange===name)
                    setSomethingChange("");
                else    
                    setSomethingChange(name);   
            }
    });    
}



    const showAttributes = (event,item) => {
        console.log("ShowAttributes",item);   
        getAdditionalData(item.name);        
        console.log("Update requested2",new Date().getTime());  
    }    

    function b64EncodeUnicode(str) {
        // first we use encodeURIComponent to get percent-encoded UTF-8,
        // then we convert the percent encodings into raw bytes which
        // can be fed into btoa.
        return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
            function toSolidBytes(match, p1) {
                return String.fromCharCode('0x' + p1);
        }));
    }

    const deleteFile = (event,item) => {
        console.log("Delete file",item);   
        //getAdditionalData(item.name);        
        //console.log("Update requested2",new Date().getTime());  
        if (!loadingGen) { item.loading=true; setLoadingGen(true);}         
        let endpoint = properties.productionUrl+"/php/core/rest/deleteAttachment.php";                        
        var bodyFormData=new FormData();
                    
        
        bodyFormData.append('idf', idDossier);
        bodyFormData.append('Descrizione', b64EncodeUnicode(item.name));
        axios({
            method: 'post',
            url: endpoint,
            data: bodyFormData, 
            headers: {'Content-Type': 'multipart/form-data' }
        })
        .then(function (response) {
                //handle success
            //choosenItem.loading=false;
            item.loading=false;
            setLoadingGen(false);
            console.log(response);            
            doPostSedute2();
        })
        .catch(function (response) {
                //handle error
            console.log(response);
        });

    }  

    
    const topicList3 = (e, id) => {
        console.log("TL3", somethingChange, id);
        e.preventDefault();
        e.stopPropagation();
        openPdf(id);               
    };


    const topicList2 = (e, id) => {
        console.log("TL2", somethingChange, id);
        e.preventDefault();
        //console.log("ew",e, choosen);        
        //e.stopPropagation();
        if(somethingChange!==id)
            openPdf(id);             
        //console.log(somethingChange, id);
        
    };

    const onFileChange= (e,item ) => {   
        //console.log("ew3",e); 
        //console.log("ew4",item);
        //if (!loadingGen) { choosenItem.loading=true; setLoadingGen(true);} 
        if (!loadingGen) { item.loading=true; setLoadingGen(true);} 
        e.stopPropagation(); 
        let endpoint = properties.productionUrl+"/php/core/rest/updateAttachment.php";                
        let file=e.target.files[0];
        var bodyFormData=new FormData();
            
        bodyFormData.append('UploadedFile', file);
        //bodyFormData.append('fileName', choosenItem.name);
        bodyFormData.append('fileName', item.name);
        bodyFormData.append('idf', idDossier);

        axios({
            method: 'post',
            url: endpoint,
            data: bodyFormData, 
            headers: {'Content-Type': 'multipart/form-data' }
        })
        .then(function (response) {
                //handle success
            //choosenItem.loading=false;
            item.loading=false;
            setLoadingGen(false);
            console.log(response);
            e.target.files=null;
            e.target.value=null;
            doPostSedute2();
        })
        .catch(function (response) {
                //handle error
            console.log(response);
        });
    };





    const onFileChange2= (e ) => {   
        console.log("ew2",e); 
        if (!loading) { setLoading(true);} 
        e.stopPropagation(); 
        let endpoint = properties.productionUrl+"/php/core/rest/updateAttachment.php";                
        let file=e.target.files[0];
        var bodyFormData=new FormData();
        //console.log("cire",file);
        bodyFormData.append('UploadedFile', file);
        bodyFormData.append('fileName', file.name);
        bodyFormData.append('idf', idDossier);

        axios({
            method: 'post',
            url: endpoint,
            data: bodyFormData, 
            headers: {'Content-Type': 'multipart/form-data' }
        })
        .then(function (response) {
            //handle success
            //choosenItem.loading=false;
            setSecondLevelButtons(!secondLevelButtons);
            setLoading(false);
            console.log(response);
            //e.target.files=null;
            //e.target.value=null;
            doPostSedute2();
        })
        .catch(function (response) {
                //handle error
            console.log(response);
        });
    };

    

    const passing2 = (event) => {        
        console.log("passing2",event);
        //event.stopPropagation();
        //event.stopImmediatePropagation();
        //event.nativeEvent.stopImmediatePropagation();
        hiddenFileUpload2.current.files=null;
        console.log("42",hiddenFileUpload2);
        //hiddenFileUpload2.current.focus();
        hiddenFileUpload2.current.click();
        //console.log("ew",e, choosen);        
        //e.stopPropagation();
        
    };

   

const MenuList = props => {
    const rows = props.children;
    const rowRenderer = ({ key, index, isScrolling, isVisible, style }) => (
      <div key={key} style={style}>{rows[index]}</div>
    );
  
    return (
      <List
        style={{ width: '100%' }}
        width={300}
        height={300}
        rowHeight={30}
        rowCount={rows.length}
        rowRenderer={rowRenderer}
      />
    )
  }
  
  const customStyles = {
    menu: (css) => ({
        ...css,
        width: 400,               
      }),
      control: (css) => ({ ...css, display: "inline-flex " }),
    
  }

  const disableNewlines = event => {
    const keyCode = event.keyCode || event.which

    if (keyCode === 13) {
      event.returnValue = false
      if (event.preventDefault) event.preventDefault()
    }
  }
  
  const pasteAsPlainText = event => {
    event.preventDefault()
    const text = event.clipboardData.getData('text/plain')
    document.execCommand('insertHTML', false, text)
  }

  const highlightAll = () => {
    setTimeout(() => {
      document.execCommand('selectAll', false, null)
    }, 0)
  }


    return (
        <div key={uuidv4()}> 
        {loading ? (
            <div key={uuidv4()} style={{ width:"25%",   display: "flex", 
            marginLeft: "auto", marginRight: "auto" }}> 
             <ThreeDots color="#30720f" height='350' width='150'/>
             </div> 
        ):(

        
        <div className="content">       
            <Tooltip title="Aggiungi nuovo" placement="left">
            <Fab aria-label="add"                 
                //variant="contained" 
                onClick={(event) => {setSecondLevelButtons(!secondLevelButtons) }}                
                style={{
                    margin: 0,
                    background: "#30720f",
                    top: 'auto',
                    right: 5,
                    bottom: 25,
                    //color: '0xFF0000',
                    left: 'auto',
                    position: 'fixed',}}>                    
                    <AddIcon />                 
                </Fab>          
            </Tooltip>
            {secondLevelButtons ? (
                <div> 
                  <Tooltip title="Documento libero" placement="left">
                  <Fab aria-label="add2"                 
                  //variant="contained" 
                  size='medium'
                  onClick={(event) => {passing2(event) }}
                  
                  style={{
                      margin: 0,
                      background: "#A2EE7D",
                      top: 'auto',
                     
                      right: 10,
                      bottom: 90,
                      //color: '0xFF0000',
                      left: 'auto',
                      position: 'fixed',}}>                    
                      <AttachmentIcon />
                      <input id="fileupload00" type="file" name="file" ref={hiddenFileUpload2} onChange={(event)=> onFileChange2(event)} style={{ display: "none" }}/>                                                
                  </Fab>       
                  </Tooltip>
                  <Tooltip title="Pratica fuori sacco" placement="left">
                  <Fab aria-label="add3"                 
                  //variant="contained" 
                  size='medium'
                  onClick={(event) => {passing2(event) }}
                  disabled= {true} 
                  style={{
                      margin: 0,
                      background: "#A2EE7D",
                      top: 'auto',
                     
                      right: 10,
                      bottom: 145,
                      //color: '0xFF0000',
                      left: 'auto',
                      position: 'fixed',}}>                    
                      <CreditCardIcon />                      
                  </Fab>
                  </Tooltip>       
                  </div> 
            ): (<></>)}
          


            {AttachmentData.map((item, index) => ( 
            item.type!=="AL1" && item.type!=="AL2" && new RegExp("\\d{10}","i").test(item.name)!==true?(
             <div className="scheda-item" style={{ borderRightColor: item.color, cursor: "pointer", }} key={uuidv4()} >    
                <div className="common-list" key={index} style={{cursor: "pointer",}}>
                                
                <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", paddingLeft: "30px",paddingBottom:"10px"}} >                                        
                        <div ref={el => itemsRef.current[index] =el} style={{paddingLeft: "30px", textAlign: "left",}} onClick={(event) => topicList2(event, item.name)}>
                    {item.type==="DL" ? (                            
                            <div>
                            {somethingChange===item.name ? (                            
                                <div style={{ display: "flex", paddingLeft: "0px",paddingTop: "20px", paddingBottom:"10px", margin:"0px"}} key={uuidv4()}>  
                                    <p className= "textGreySedute" style={{ margin: "0px",marginTop:"7px"}} onClick={(event) => topicList3(event, item.name)}>Documento Libero:  </p> 
                                    <ContentEditable                                
                                        html={item.name}                                    
                                        className="content-editable"
                                        onKeyPress={disableNewlines}
                                        onPaste={pasteAsPlainText}
                                        onFocus={highlightAll}
                                        onBlur={(event) => handleEditableBlur(event, item)}
                                        //onChange={(event) => handleEditableChange(event, item)}
                                        onKeyDown={(event) => handleKeyPress(event)}
                                        style={{ marginLeft: "10px", padding:"5px", 
                                        borderColor: "hsl(0, 0%, 80%)", borderRadius: "0px", borderStyle:"solid", borderWidth:"1px"}}
                                    />  
                                </div>
                                   ):(    
                                <div style={{ display: "flex", paddingLeft: "0px",paddingTop: "20px", paddingBottom:"10px", margin:"0px"}} key={uuidv4()}>  
                                    <p className= "textGreySedute" style={{ margin: "0px",}}>Documento Libero:  </p> 
                                    <ContentEditable
                                        html={item.name}                                    
                                        className="content-editable"
                                        onKeyPress={disableNewlines}
                                        onPaste={pasteAsPlainText}
                                        onFocus={highlightAll}
                                        disabled={true}
                                        style={{ paddingLeft: "10px"}}
                                        //onChange={this.handleContentEditableUpdate}
                                    />
                                </div>                
                               )}                            
                            </div>
                            ): item.type!=undefined ? (
                                
                            <div style={{ display: "flex", paddingLeft: "0px",paddingTop: "20px", paddingBottom:"10px", margin:"0px"}} key={uuidv4()}>  
                            <p className= "textGreySedute" style={{ margin: "0px",marginTop:"0px"}}> Relazione d'ambito:{" "} </p>
                            {somethingChange===item.name ? (
                             <ContentEditable                                
                                html={item.name}                                    
                                className="content-editable"
                                onKeyPress={disableNewlines}
                                onPaste={pasteAsPlainText}
                                onFocus={highlightAll}
                                //onChange={(event) => handleEditableChange(event, item)}
                                style={{color: "red"}}
                            />                              
                                ):(  
                                    <ContentEditable
                                    html={item.name}                                    
                                    className="content-editable"
                                    //onKeyPress={disableNewlines}
                                    //onPaste={pasteAsPlainText}
                                    //onFocus={highlightAll}
                                    disabled={true}
                                    //onChange={this.handleContentEditableUpdate}
                                  />                                    
                            )}                            
                            </div>
                            ):( 
                            <div> 
                                {somethingChange===item.name ? (
                                    <div style={{ display: "flex", paddingLeft: "0px",paddingTop: "20px", paddingBottom:"10px", margin:"0px"}} key={uuidv4()}>  
                                        <p className= "textGreySedute" style={{ margin: "0px",marginTop:"7px"}}>Documento Libero:  </p> 
                                        <ContentEditable                                
                                            html={item.name}                                    
                                            className="content-editable"
                                            onKeyPress={disableNewlines}
                                            onPaste={pasteAsPlainText}
                                            onFocus={highlightAll}
                                            //onChange={(event) => handleEditableChange(event, item)}
                                            onBlur={(event) => handleEditableBlur(event, item)}                                        
                                            onKeyDown={(event) => handleKeyPress(event)}
                                            style={{ marginLeft: "10px", padding:"5px", 
                                            borderColor: "hsl(0, 0%, 80%)", borderRadius: "0px", borderStyle:"solid", borderWidth:"1px"}}
                                        />  
                                    </div>):( 
                                    <div style={{ display: "flex", paddingLeft: "0px",paddingTop: "20px", paddingBottom:"10px", margin:"0px"}} key={uuidv4()}>  
                                        <p className= "textGreySedute" style={{ margin: "0px",}}>Documento Libero:  </p> 
                                        <ContentEditable
                                            html={item.name}                                    
                                            className="content-editable"
                                            onKeyPress={disableNewlines}
                                            onPaste={pasteAsPlainText}
                                            onFocus={highlightAll}
                                            disabled={true}
                                            style={{ paddingLeft: "10px"}}
                                            //onChange={this.handleContentEditableUpdate}
                                        />
                                    </div>    )}                            
                            </div>
                            )}
                            {/*<p className= "textBlackSedute" style={{ margin: "0px",}}>{item.name}
                            </p>
                            */}
                            

                            <div style={{ paddingLeft: "0px",paddingTop: "5px", paddingBottom:"10px",paddingRight:"20px", margin:"0px"}} onClick={(event) => topicList3(event, item.name)} key={uuidv4()}>  
                            <p className= "textGreySedute" style={{ margin: "0px",}}>
                                Dimensione:{" "}
                                <span className= "textBlackSedute"> {item.size}
                                </span>
                            </p>
                            </div>
                            <div style={{ paddingLeft: "0px",paddingTop: "5px", paddingBottom:"10px",paddingRight:"20px", margin:"0px"}} onClick={(event) => topicList3(event, item.name)} key={uuidv4()}>  
                            <p className= "textGreySedute" style={{ margin: "0px",}}>
                                Modificato:{" "}
                                <span className= "textBlackSedute"> {item.lastUpdate}
                                </span>
                            </p>
                            </div>    
                        </div>
                        
                        {somethingChange===item.name ? (               
                            <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", paddingLeft: "30px", marginTop:"0px", }} >                    
                            <div style={{ display: "flex",paddingLeft: "0px",paddingTop: "0px", paddingBottom:"5px",paddingRight:"20px", margin:"0px",minWidth: "500px"}} key={uuidv4()}>  
                             <label key={uuidv4()} className= "textGreySedute" style={{ marginTop:"10px", paddingTop:"0px",paddingRight:"5px"}}>     Missione/Settore: {" "}                                                                                                                                      
                          
                            </label> 
                      
                                 <Select key={uuidv4()}                                        
                                    component={{MenuList}}                                                                                                                                          
                                    defaultValue={{
                                                  label: convertIdToMissione( additionalData.idSection),
                                                  value: additionalData.idSection
                                                }}                                    
                                    onChange={(event) => handleChange1(event, additionalData.idSection, item)}
                                    options={missioniValues} 
                                    styles={customStyles}                                    
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 0,
                                        colors: {
                                          ...theme.colors,
                                          primary25: '#a2ee7d',
                                          primary: 'black',
                                        },
                                      })}/>

                              
                            </div>
                            <div style={{ display: "flex", paddingLeft: "0px",paddingTop: "0px", paddingBottom:"10px",paddingRight:"20px", margin:"0px",}} key={uuidv4()}>  
                            <label key={uuidv4()} className= "textGreySedute" style={{ marginTop:"10px", paddingTop:"0px",paddingRight:"5px",}}>  Ordine:{" "}                                          
                     
                        </label>                       
                                
                                <Select key={uuidv4()}    
                                    value={{
                                        label: additionalData.order,
                                        value: additionalData.order
                                      }}
                                    component={{MenuList}}                                                                    
                                    //defaultValue={{
                                    //          label: additionalData.order,
                                    //          value: additionalData.order
                                    //        }}
                                    onChange={(event) => handleChange2(event, additionalData.order,item, )}
                                    options={manyItems} 
                                    //styles={customStyles}                                    
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 0,
                                        colors: {
                                          ...theme.colors,
                                          primary25: '#a2ee7d',
                                          primary: 'black',
                                        },
                                      })}
                                    />
                                

                                      
                            </div>          
                            </div>
                                ): <></>}                        
                    </div>
                    
                    <div style={{ paddingRight: "0px", color: "#ff0000",display: "flex",}} >         
                    <div style={{ paddingRight: "0px", color: "#879497", display: "flex",}} >         
                          {item.loading ? (
                             <div style={{marginRight: "20px",}}>
                            <CircularProgress size={42} color= 'secondary' sx={{ color: 'green', position: 'absolute',top: -6,
                                left: -6, zIndex: 1,}}/>
                            </div>
                          ):( <></> )}                        

                          {item.updated > 0 ? (
                            <div
                                style={{                                    
                                    background: "#a2ee7d",
                                    marginRight: "10px",
                                    //padding: "10px 8px",
                                    padding: "15px 8px",
                                    color: "black",                                    
                                    borderRadius: "5px",
                                    fontSize: "13px",
                                }}
                            >
                                Aggiornato
                            </div>
                        ) : (
                            <></>
                        )}


                  
                    <input id={"file-upload"+index} type="file" onChange={(event)=> onFileChange(event,item)} style={{ display: "none" }} />    
                    <label htmlFor={"file-upload"+index}>      
                    <Tooltip title="Aggiorna Documento Libero" placement="bottom">
                       <IconButton color="primary" aria-label="upload" component="span" style={{ color: "black", background: "#FFFFFF",  }}  >
                        <UpdateIcon />                        
                        </IconButton>                        
                    </Tooltip>                         
                    </label>
                    
                    
                    <Tooltip title="Elimina Documento Libero" placement="bottom">
                    <IconButton color="primary" aria-label="upload" component="span" style={{ color: "black", background: "#FFFFFF",  }}  onClick={(event) => {deleteFile(event,item); }}>
                        <DeleteForeverIcon />                        
                    </IconButton>                           
                    </Tooltip>
                    

                                                                  
                        
                    </div>
                    <div style={{ paddingLeft: "0px", paddingRight: "20px", color: "#879497", }} >      
                    {item.type==="DL" ? (  
                        <Tooltip title="Espandi" placement="bottom">
                            <IconButton color="primary" aria-label="upload" component="span" style={{ color: "black", background: "#ffffff",}} onClick={(event) => {test(index);showAttributes(event,item); }}>
                                {React.createElement(Icons['MoreHoriz'])}
                            </IconButton>
                        </Tooltip>  ) : item.type!=undefined ? (      
                            <Tooltip title="Espandi" placement="bottom">
                                <IconButton disabled={true} color="primary" aria-label="upload" component="span" style={{ color: "grey", background: "#ffffff",}} onClick={(event) => {test(index);showAttributes(event,item); }}>
                                {React.createElement(Icons['MoreHoriz'])}
                                </IconButton>
                            </Tooltip>                  
                        )
                        :(
                            <Tooltip title="Espandi" placement="bottom">                
                                <IconButton color="primary" aria-label="upload" component="span" style={{ color: "black", background: "#ffffff",}} onClick={(event) => {test(index); showAttributes(event,item); }}>
                                {React.createElement(Icons['MoreHoriz'])}
                                </IconButton>
                            </Tooltip>                  
                        )}
                    </div>
                    </div>                   
                </div>                
            
            </div>
            ):(<></>)
            ))}
   
        </div>

    )}</div>    
    );
}
