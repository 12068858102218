import React, { useState, useEffect, useContext } from "react";
//import { useHistory, useParams, useLocation } from "react-router-dom";
import { useLocation } from "react-router-dom";
import {isMobile} from 'react-device-detect';
import { useNavigate } from "react-router-dom";
import RouterContext from "../../context/RouterContext";
import "../../App.css";
import { IconButton, Radio, FormControl, FormControlLabel, RadioGroup, FormLabel,Fab, Tooltip } from '@material-ui/core/';
import CircularProgress from "@material-ui/core/CircularProgress";

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import {ThreeDots} from 'react-loader-spinner';
import { List } from 'react-virtualized';
import Select,{ components }  from 'react-select';
import SearchBar from "material-ui-search-bar";
import Typography from "@material-ui/core/Typography";
import {properties} from "utils/properties";
import {getQueryString, getQueryStringValue, setQueryStringValue,setQueryStringWithoutPageReload } from "./queryString";


export default function UpdateAttachments() {    
    const { searchQS } = useLocation();   
    const { pageRouter, setPageRouter } = useContext(RouterContext);
    //const history = useHistory(); 
    const navigate = useNavigate();   
    const [resultData, setResultData] = useState([]);
    const [occurrences, setOccurrences]=useState();    
    const [loading, setLoading]= React.useState(true);
    const [loading2, setLoading2]= React.useState(false);
    const [viewVerbali,setViewVerbali]= React.useState(true);
    const [viewError,setViewError]= React.useState(true);
    const [viewResults,setViewResults]= React.useState(true);
    const [viewButtons,setViewButtons]= React.useState(true);
    const [viewPrevious,setViewPrevious]= React.useState(true);
    const [viewNext,setViewNext]= React.useState(true);    
    const [page, setPage] = useState(0);
    const [sort, setSort] = useState(0);
    const [search, setSearch]=React.useState("");    
    const [searchValue, setSearchValue] = useState('');

    const OptionWithIcon = ({ innerProps, label, data }) => (
        <div {...innerProps} style={{ display: 'flex', alignItems: 'center' }}>
              <span>{label}</span>
              <span style={{ marginLeft: '5px', paddingTop: '5px' }}>{data.icon}</span>      
      </div>
      );

    var toBeRemoved=true;
    var pag2="";
    var tmpValue="";

    var dateStyle = {
        display: "flex",
        paddingLeft: "40px",
    };
    function b64EncodeUnicode(str) {
        // first we use encodeURIComponent to get percent-encoded UTF-8,
        // then we convert the percent encodings into raw bytes which
        // can be fed into btoa.
        return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
            function toSolidBytes(match, p1) {
                return String.fromCharCode('0x' + p1);
        }));
    }

    const handleSelect = (event) => {
        console.log("handleSelect", event);   
        setPage(0); 
        if (event && event.value){
            console.log("HS1",event.value);                       
            setSort(event.value);

            handleSearchWithParams(0,search,event.value);
        }
    }

    const clearAll=()=>{
        setResultData([]);
        setViewResults(false);
        setViewButtons(false);
        setViewError(false);
        setLoading2(false);
    }

    const updatePage = () => {
        //console.log("updatePage called");
        pag2=parseInt(page)-10;
        setPage(pag2);        
        setSearchValue(search);        
        //console.log("PREV",pag2,search);        
        //handleSearchWithParams(pag2,search);
        handleSearchWithParams(pag2,search,sort);
    }
    const updatePageNext = () => {
        //console.log("updatePageNext called");
        pag2=parseInt(page)+10;
        setPage(pag2);        
        setSearchValue(search);
        //console.log("NEXT",pag2,search);
        //handleSearchWithParams(pag2,search);
        handleSearchWithParams(pag2,search,sort);
    }


    const handleSearch2 = (searching) => {
        console.log("handleSearch2", searching);
        setSearchValue(searching);
        
        //RIPULISCO LA SITUAZIONE DI EVENTUALI PRECEDENTI RICHIESTE
        setResultData([]);
        setViewResults(false);
        setViewButtons(false);
        setViewError(false);
        setLoading2(true);
        
        var search21=searching;

        //setQueryStringValue("p","0");
        var qsAnto="?p=0";

        var qsvSort=getQueryStringValue("s");
        console.log("QSVSORT",qsvSort, sort);
        if(qsvSort==undefined||qsvSort==""){
            //setQueryStringValue("s","0");            
            setSort("0");
            qsvSort=0;
        }
        qsAnto=qsAnto+"&s="+qsvSort;
        //setQueryStringValue("q",search21);
        qsAnto=qsAnto+"&q="+search21;

        console.log(qsAnto);
        setQueryStringWithoutPageReload(qsAnto);

        var search3=searching;
        if(searching.includes(" ")){
            search3=searching.replaceAll(" ", " +");
            search3="+".concat(search3);
        }

        setSearch(searching);
        setPage("0");
        let endpoint = properties.productionUrl+"/rest/doSearch";
        var bodyFormData=new FormData();
        bodyFormData.append('action', 'searchVerbali');
        bodyFormData.append('query', b64EncodeUnicode(search3));
        bodyFormData.append('start', "0");
        bodyFormData.append('sort', qsvSort);
        
        if (!window.cordova){
            axios({
                method: 'post',
                url: endpoint,
                data: bodyFormData,
                headers: {'Content-Type': 'multipart/form-data' }
            })
            .then(function (response) {          
                var tm2=response.data;
                tm2=tm2.replaceAll("\n1","");
                tm2=tm2.replaceAll("\n","");                
                var obj=JSON.parse(tm2);                
                var all=false;
                if(obj.response.start>=10){
                    setViewPrevious(true);
                    all=true;
                }
                else
                    setViewPrevious(false);
                if(obj.response.start>=(obj.response.numFound-10))
                    setViewNext(false);
                else{
                    setViewNext(true);
                    all=true;
                }
                if(all)
                    setViewButtons(true);
                else
                    setViewButtons(false);
                                
                var results = [];
                for (var i = 0; i < obj.response.docs.length; i++) {                    
                    var a=JSON.stringify(obj.highlighting[obj.response.docs[i].id]._text_);
                    a=a.trim();                        
                    a=a.replaceAll("],","");                    
                    a=a.replace(/\\n/g," ");
                    a=a.replace(/[\n\r]/g," ");
                    a=a.replace(/\n/g," ");
                    a=a.replaceAll("{","");
                    a=a.replaceAll("[","");
                    a=a.replaceAll("]","");
                    a=a.replaceAll("}","");
                    a=a.replaceAll(":\"",": ");
                    a=a.replaceAll("\"","");
                    
                    var dseduta= obj.response.docs[i].id;
                    dseduta=dseduta.substring(dseduta.indexOf("SedutaDel")+9);
                    dseduta=dseduta.substring(0,dseduta.indexOf("/"));
                    var aaa=obj.response.docs[i].id.substring(obj.response.docs[i].id.lastIndexOf("/")+1);
                    var file=aaa.substring(0,aaa.lastIndexOf("-page"));                        
                    var page=aaa.substring(aaa.lastIndexOf("-page")+5,aaa.lastIndexOf("."));                    
                    var title_a="Verbale della seduta del "+file.substring(9,11)+"/"+file.substring(7,9)+"/"+file.substring(3,7);

                    var eachResult = {
                        denominazione: obj.response.docs[i].id,                       
                        dataSeduta: dseduta,
                        idRichiesta: obj.response.docs[i].IdRichiesta,
                        title: title_a,                        
                        ipotesi: "Pagina "+page,                                                                   
                        matching: a,
                    };
                    results.push(eachResult);                    
                }
                console.log("POAT",results);
                setResultData(results);
                setOccurrences(obj.response.numFound);
                setViewResults(true);                
                setLoading2(false);
            })
            .catch(function (response) {                
                setViewError(true);
                setLoading2(false);
            });
          }
          else
          {
                 var onSuccess = function(response){                   
                   var tm2=response;
                   tm2=tm2.replaceAll("\n1","");
                   tm2=tm2.replaceAll("\n","");                   
                   var obj=JSON.parse(tm2);                   
                   var all=false;
                   if(obj.response.start>=10){
                       setViewPrevious(true);
                       all=true;
                   }
                   else
                       setViewPrevious(false);
                   if(obj.response.start>=(obj.response.numFound-10))
                       setViewNext(false);
                   else{
                       setViewNext(true);
                       all=true;
                   }
                   if(all)
                       setViewButtons(true);
                   else
                       setViewButtons(false);
                                      
                   var results = [];
                   for (var i = 0; i < obj.response.docs.length; i++) {                   
                        var a=JSON.stringify(obj.highlighting[obj.response.docs[i].id]._text_);
                        a=a.trim();                           
                        a=a.replaceAll("],","");
                        a=a.replace(/\\n/g," ");
                        a=a.replace(/[\n\r]/g," ");
                        a=a.replace(/\n/g," ");
                        a=a.replaceAll("{","");
                        a=a.replaceAll("[","");
                        a=a.replaceAll("]","");
                        a=a.replaceAll("}","");
                        a=a.replaceAll(":\"",": ");
                        a=a.replaceAll("\"","");
                        
                        var dseduta= obj.response.docs[i].id;
                        dseduta=dseduta.substring(dseduta.indexOf("SedutaDel")+9);
                        dseduta=dseduta.substring(0,dseduta.indexOf("/"));
                        var aaa=obj.response.docs[i].id.substring(obj.response.docs[i].id.lastIndexOf("/")+1);
                        var file=aaa.substring(0,aaa.lastIndexOf("-page"));                           
                        var page=aaa.substring(aaa.lastIndexOf("-page")+5,aaa.lastIndexOf("."));
                        
                        var title_a="Verbale della seduta del "+file.substring(9,11)+"/"+file.substring(7,9)+"/"+file.substring(3,7);

                        var eachResult = {
                           denominazione: obj.response.docs[i].id,                                               
                           dataSeduta: dseduta,
                           idRichiesta: obj.response.docs[i].IdRichiesta,
                           title: title_a,                           
                           ipotesi: "Pagina "+page,                                                                            
                           matching: a,
                       };
                           results.push(eachResult);
                   }
                   console.log("POAT2",results);
                   setResultData(results);
                   setOccurrences(obj.response.numFound);
                   setViewResults(true);                   
                   setLoading2(false);
                 }
                 var onError = function() {}
                 const bodyasString = new URLSearchParams(bodyFormData).toString();
                 window.search(bodyasString,onSuccess, onError);
          }
    }

const openPdf = (type, file, page) => {

    var aa=properties.productionUrl+"/fileManager/files/verbali/"
    aa=aa.concat(file);
    if (isMobile) {
        //console.log("OPDF","mobile");
        aa=aa.concat("#page").concat(page);
    }
    else{
        //console.log("OPDF","not mobile");
        aa=aa.concat("#page=").concat(page);
    }
    if (type === "verbale") {
        window.open(aa);
    }

};


//const handleSearchWithParams = (p,q) => {
const handleSearchWithParams = (p,q,s) => {
    //RIPULISCO LA SITUAZIONE DI EVENTUALI PRECEDENTI RICHIESTE
    setResultData([]);
    setViewResults(false);
    setViewButtons(false);
    setViewError(false);
    setLoading2(true);

    console.log("handling search with params",p,q,s);

    //setQueryStringValue("p",p);
    //setQueryStringValue("q",q);
    //setQueryStringValue("s",s);

    var qsAnto="?p="+p;
    qsAnto=qsAnto+"&s="+s;  
    qsAnto=qsAnto+"&q="+q;
      
    var var2=false;
    if(qsAnto!=getQueryString())
        setQueryStringWithoutPageReload(qsAnto);

    console.log("QSANTO",qsAnto, getQueryString(),var2);
    //setQueryStringWithoutPageReload(qsAnto);



    //console.log("WWWWWWWW", search21);
    var search3=q;
    if(q.includes(" ")){
        search3=q.replaceAll(" ", " +");
        search3="+".concat(search3);
    }
    //console.log("handling search2", search3);
    //console.log("p vale",p);
    setSearch(q);
    setPage(p);
    //console.log("1",search21);
    //console.log("2",search2);
    //console.log("3",search3);    
    let endpoint = properties.productionUrl+"/rest/doSearch";
    var bodyFormData=new FormData();    
    bodyFormData.append('action', 'searchVerbali');    
    bodyFormData.append('query', b64EncodeUnicode(search3));    
    bodyFormData.append('start', p);
    bodyFormData.append('sort', s);
    if (!window.cordova){
        axios({
            method: 'post',
            url: endpoint,
            data: bodyFormData,
            headers: {'Content-Type': 'multipart/form-data' }
        })
        .then(function (response) {
            //console.log("RRR",response);            
            //console.log("RRR2",response.data);
            var tm2=response.data;
            tm2=tm2.replaceAll("\n1","");
            tm2=tm2.replaceAll("\n","");            
            var obj=JSON.parse(tm2);            
            //console.log("RRR3",obj);
            //console.log("RRR3",response.data.response);;
            //console.log("RRR4",obj.response);
            //console.log("RRR5",obj.highlighting);
            //console.log("#FOUND",obj.response.numFound);
            //console.log("#START",obj.response.start);
            var all=false;            
            if(obj.response.start>=10){
                setViewPrevious(true);
                all=true;
            }
            else
                setViewPrevious(false);
            if(obj.response.start>=(obj.response.numFound-10))
                setViewNext(false);
            else{
                setViewNext(true);
                all=true;
            }

            if(all)
                setViewButtons(true);
            else
                setViewButtons(false);

            //console.log("PRIMO1",obj.response.docs[0]);
            var id2=obj.response.docs[0].id;
            //console.log("PRIMO1-1",id2);
            //console.log("PRIMO2",obj.highlighting[id2]);
            //const temp1= Object.entries(obj);
            //console.log("RRR6",temp1);
            //console.log("RRR4",response.data.highlighting);
            var results = [];
            for (var i = 0; i < obj.response.docs.length; i++) {
                var a=JSON.stringify(obj.highlighting[obj.response.docs[i].id]._text_);
                //console.log("WQAWSS",a);
                a=a.trim();
                a=a.replaceAll("],","");
                a=a.replace(/\\n/g," ");
                a=a.replace(/[\n\r]/g," ");
                a=a.replace(/\n/g," ");                
                a=a.replaceAll("{","");
                a=a.replaceAll("[","");
                a=a.replaceAll("]","");
                a=a.replaceAll("}","");
                a=a.replaceAll(":\"",": ");
                a=a.replaceAll("\"","");
                //console.log("WERS",a);
                var dseduta= obj.response.docs[i].id;
                dseduta=dseduta.substring(dseduta.indexOf("SedutaDel")+9);
                dseduta=dseduta.substring(0,dseduta.indexOf("/"));
                //console.log("32321",dseduta);
                var aaa=obj.response.docs[i].id.substring(obj.response.docs[i].id.lastIndexOf("/")+1);
                var file=aaa.substring(0,aaa.lastIndexOf("-page"));                
                var page=aaa.substring(aaa.lastIndexOf("-page")+5,aaa.lastIndexOf("."));                
                var title_a="Verbale della seduta del "+file.substring(9,11)+"/"+file.substring(7,9)+"/"+file.substring(3,7);
                //console.log("frei",title);
                var eachResult = {
                    denominazione: obj.response.docs[i].id,                                
                    dataSeduta: dseduta,
                    idRichiesta: obj.response.docs[i].IdRichiesta,                    
                    ipotesi: "Pagina "+page,                                        
                    matching: a,
                    title: title_a,
                };
                results.push(eachResult);                    
            }
            console.log("POAT3",results);
            setResultData(results);
            setOccurrences(obj.response.numFound);
            setViewResults(true);            
            setLoading2(false);
        })
        .catch(function (response) {
            //toast.error("Si è verificato un errore pubblicando la seduta!");
            //console.log("Esticazzi pensa", "ci sia stato un errore");
            setViewError(true);
            setLoading2(false);
        });
      }
      else {

            var onSuccess = function(response){
              //console.log('aa');
              //console.log(response);
              var tm2=response;
              tm2=tm2.replaceAll("\n1","");
              tm2=tm2.replaceAll("\n","");
              var obj=JSON.parse(tm2);
              var all=false;
              if(obj.response.start>=10){
                  setViewPrevious(true);
                  all=true;
              }
              else
                  setViewPrevious(false);
              if(obj.response.start>=(obj.response.numFound-10))
                  setViewNext(false);
              else{
                  setViewNext(true);
                  all=true;
              }

              if(all)
                  setViewButtons(true);
              else
                  setViewButtons(false);

              //var id2=obj.response.docs[0].id;
              var results = [];
              for (var i = 0; i < obj.response.docs.length; i++) {
                  var a=JSON.stringify(obj.highlighting[obj.response.docs[i].id]._text_);
                  //console.log("WQAWSS",a);
                  a=a.trim();
                  a=a.replaceAll("],","");
                  a=a.replace(/\\n/g," ");
                  a=a.replace(/[\n\r]/g," ");
                  a=a.replace(/\n/g," ");              
                  a=a.replaceAll("{","");
                  a=a.replaceAll("[","");
                  a=a.replaceAll("]","");
                  a=a.replaceAll("}","");
                  a=a.replaceAll(":\"",": ");
                  a=a.replaceAll("\"","");
                  //console.log("WERS",a);
                  var dseduta= obj.response.docs[i].id;
                  dseduta=dseduta.substring(dseduta.indexOf("SedutaDel")+9);
                  dseduta=dseduta.substring(0,dseduta.indexOf("/"));
                  //console.log("32321",dseduta);

                  var aaa=obj.response.docs[i].id.substring(obj.response.docs[i].id.lastIndexOf("/")+1);
                  var file=aaa.substring(0,aaa.lastIndexOf("-page"));                  
                  var page=aaa.substring(aaa.lastIndexOf("-page")+5,aaa.lastIndexOf("."));
                  //console.log("frei",file);
                  var title_a="Verbale della seduta del "+file.substring(9,11)+"/"+file.substring(7,9)+"/"+file.substring(3,7);
                  //console.log("frei",title);
                  var eachResult = {
                      denominazione: obj.response.docs[i].id,                                            
                      dataSeduta: dseduta,
                      idRichiesta: obj.response.docs[i].IdRichiesta,                      
                      ipotesi: "Pagina "+page,                                            
                      matching: a,
                      title: title_a,
                  };
                  results.push(eachResult);                      
              }

              console.log("POAT1",results);
              setResultData(results);
              setOccurrences(obj.response.numFound);
              setViewResults(true);              
              setLoading2(false);
            }

            var onError = function() {}
            const bodyasString = new URLSearchParams(bodyFormData).toString();
            window.search(bodyasString,onSuccess, onError);
      }
}


function handleChange1(event, role) {
    //console.log("T1T2T3",event.target, role);
    var qst=getQueryStringValue("q");
    var sr1=getQueryStringValue("s");
    //console.log("QS",);
    if(role==="ve"){
        if(qst!=undefined){
            //history.push({
                /*navigate({
                pathname: "/searchV",
                //search:"?p=0&q="+qst
                search:"?p=0&s="+sr1+"&q="+qst
                });*/
                navigate("/searchV"+"?p=0&s="+sr1+"&q="+qst);
        }
        else{
            //history.push({
                navigate("/searchV");
        }
    }
    if(role==="ai"){
        if(qst!=undefined){
            //history.push({
                /*navigate({
                    pathname: "/search",
                    //search:"?p=0&q="+qst
                    search:"?p=0&s="+sr1+"&q="+qst
                    }); */
            navigate("/search"+"?p=0&s="+sr1+"&q="+qst);
        }
        else{
            //history.push({
                navigate("/search");
        }

    }
    if(role==="pr"){
        if(qst!=undefined){
            //history.push({
               /* navigate({
                    pathname: "/searchP",
                    //search:"?p=0&q="+qst
                    search:"?p=0&s="+sr1+"&q="+qst
                    });*/
            navigate("/searchP"+"?p=0&s="+sr1+"&q="+qst);    
        }
        else{
            //history.push({
                navigate("/searchP");
        }

    }

}

useEffect(() => {
        if(process.env.NODE_ENV !=='development' && !window.cordova){
        //CHECK AUTHENTICATION
            var xhr = new XMLHttpRequest();
            xhr.open('POST', properties.productionUrl+'/rest/ping',true);
            xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");

            xhr.onload = function (e) {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        console.log("OK",xhr);
                } else {
                    console.error("Error 1", xhr);
                    localStorage.clear();
                    //history.push({
                    navigate("/login");
                }
            }
            };

            xhr.onerror = function (e) {
                console.error("Error 2", xhr);
                localStorage.clear();
                //history.push({
                navigate("/login");
            };


            let urlEncodedData = null;
            xhr.send(urlEncodedData);
        //CHECK AUTHENTICATION
        }

        //console.log("HI there");
        //console.log("QQW",history.action, history);
        //console.log("sex",window.location.search);
        var pag1=getQueryStringValue("p");
        if(pag1==undefined)
            pag1=0;
        //console.log("UseEffectPage1",pag1);
        if(pag1!=undefined){            
            pag2=pag1;
        }
        setPage(pag2);

        var s1=getQueryStringValue("s");        
        if(s1==undefined)
            s1=1;        
        if(isNaN(s1)){            
            s1=1;
        }
        setSort(s1);

        var qs1=getQueryStringValue("q");
        console.log("QS1 and S1",qs1, s1);
        if(qs1!=undefined){
            if(qs1.includes("%20")){
                setSearchValue(qs1.replaceAll("%20"," "))
                //handleSearchWithParams(pag2,qs1.replaceAll("%20"," "));             
                if(isNaN(s1)){
                    console.log("1- NAN S1",qs1, s1);
                    handleSearchWithParams(pag2,qs1.replaceAll("%20"," "),"1");
                }
                else{
                    console.log("1- !NAN S1",qs1, s1);
                    handleSearchWithParams(pag2,qs1.replaceAll("%20"," "),s1);
                }
            }
            else {             
                setSearchValue(qs1);
                //handleSearchWithParams(pag2,qs1);
                if(isNaN(s1)){
                    console.log("2- NAN S1",qs1, s1);
                    handleSearchWithParams(pag2,qs1,"1");
                }
                else{
                    console.log("2- !NAN S1",qs1, s1);
                    handleSearchWithParams(pag2,qs1,s1);
                }
            }            
        }
        else{
            //console.log("otto",qs1, undefined);
            clearAll();
        }

        //console.log("sette",setQueryStringValue("q","suca"));
        setViewVerbali(true);        
        setViewError(false);
        setViewResults(false);
        setViewButtons(false);
        setLoading(false);

        /*setPageRouter({
            router: history.location.pathname,
            homecolor: "#FF5A60",
            settingcolor: "#879497",
            homebordercolor: "#FF5A60",
            settingbordercolor: "#FFFFFF",
            hometitlecolor: "black",
            settingtitlecolor: "#879497",
        });*/
    }, []);




    const topicList = (item) => {                
        var aaa=item.denominazione.substring(item.denominazione.lastIndexOf("/")+1);
        //console.log("EHI",aaa);
        var file=aaa.substring(0,aaa.lastIndexOf("-page"));        
        file=file.concat(".pdf");
        var page=aaa.substring(aaa.lastIndexOf("-page")+5,aaa.lastIndexOf("."));
        openPdf("verbale",file,page);
        //console.log("EHI",file,page);
    };


    const customStyles = {
        control: (provided) => ({
          ...provided,
          border: 'none', // Remove border
          boxShadow: 'none', // Remove shadow
          backgroundColor: '#f5f5f5',
          textAlign:'right',
          alignItems:'right',
          //width: '200px',
          width: '100%',
        }),
        indicatorSeparator: () => ({
          display: 'none', // Remove the indicator separator
        }),
        dropdownIndicator: () => ({
          display: 'none', // Remove the dropdown arrow
        }),
        menu: (provided) => ({
          ...provided,
          width: '200px', 
          //width: '100%', 
          backgroundColor: '#f5f5f5',
          textAlign:'right',
          alignItems:'right',
        }),
        singleValue: (provided) => ({
            ...provided,        
            textAlign: 'right', // Align text to the right
          }),
      };
    
      
      const options = [
        { value: '0', label: 'Pertinenza', icon: <KeyboardArrowDownIcon fontSize="medium"/> },
        { value: '1', label: 'Pertinenza', icon: <KeyboardArrowUpIcon fontSize="medium"/> },
        { value: '2', label: 'Data', icon: <KeyboardArrowDownIcon fontSize="medium"/> },
        { value: '3', label: 'Data', icon: <KeyboardArrowUpIcon fontSize="medium"/> },
      ];
    
      //const defaultValue = options[0];
      const defaultValue = sort;
    
      
      function formatOptionLabel({ label, icon }) {
        return (
          <div style={{ display: 'flex', alignItems: 'center', textAlign:'right' }}>
            <span>{label}</span>
            <span style={{ marginLeft: '5px', paddingTop: '5px' }}>{icon}</span>      
          </div>
        );
      }


    return (
        <div key={uuidv4()}>
        {loading ? (
            <div key={uuidv4()} style={{ width:"25%",   display: "flex",
            marginLeft: "auto", marginRight: "auto" }}>
             <ThreeDots color="#30720f" height='350' width='150'/>
             </div>
        ):(
        <div className="content" >
         <SearchBar      style={{margin: '0 auto', marginTop: '30px', maxWidth: 600}}    
            value={searchValue}
            placeholder="Cerca"    
            onChange={(newValue) => tmpValue=newValue}    
            onRequestSearch={() => handleSearch2(tmpValue)}
            onCancelSearch={() => setSearchValue('')}    
            autoFocus />

  {viewVerbali ?(
      <div style={{alignItems: "left", paddingTop:"5px",paddingLeft:"10px", textAlign:"left", margin: '0 auto', maxWidth: 600}} >
          <FormControl>
                {/*<FormLabel id="demo-radio-buttons-group-label">Supporto di ricerca</FormLabel>*/}
                <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="verbali"
                        name="radio-buttons-group">
                    <FormControlLabel value="json" onChange={(event) => handleChange1(event, "ai")} control={<Radio size="small" style ={{color: "#30720f"}}/>} label=
                     {<Typography style={{fontSize: "14px"}}> Attività Istituzionale</Typography>}>
                                            </FormControlLabel>
                    <FormControlLabel value="premesse" onChange={(event) => handleChange1(event, "pr")} control={<Radio size="small" style ={{color: "#30720f"}}/>} label=
                     {<Typography style={{fontSize: "14px"}}> Documenti di supporto (Attività Istituzionale)</Typography>}>
                                            </FormControlLabel>
                    <FormControlLabel value="verbali" onChange={(event) => handleChange1(event, "ve")} control={<Radio  size="small" style ={{color: "#30720f"}}/>} label=
                    {<Typography style={{fontSize: "14px"}}> Verbali</Typography>}>
                    </FormControlLabel>
                </RadioGroup>
            </FormControl>
      </div>
    ):(
    <></>
    )
  }
 {loading2 ? (
    <div style={{marginRight: "20px",}}>
   <CircularProgress size={42} style={{color: '#30710F'}}  sx={{  position: 'absolute',top: 0, left: -6, zIndex: 1,}}/>
   </div>
 ):( <></> )}

  {viewError ?(
      <div style={{alignItems: "left", paddingTop:"5px",paddingLeft:"5px", textAlign:"left", margin: '0 auto', maxWidth: 600}} >
           <div className="content"  >
          <p> La ricerca di {search} non ha prodotto risultati</p>
          </div>
      </div>
    ):(
    <></>
    )
  }
  {viewResults ?(
      <div style={{alignItems: "left", paddingTop:"5px",paddingLeft:"5px", textAlign:"left", margin: '0 auto'}} >

          <div className="content" style={{paddingBottom:"0px", paddingLeft:"0px", paddingRight:"0px"}} >
          <div style={{display: "flex", paddingLeft:"0px", textAlign:"left", margin: '0 auto'}} >            
          <p> Risultati relativi a {search} (Circa {occurrences} risultati)</p>
          {toBeRemoved ?(
          <div style={{ paddingTop:"6px", textAlign:"right", marginLeft: "auto", marginRight: "0"}} >
            <Select styles={customStyles} 
                id="select01"
                isSearchable={false} // Disable search functionality
                blurInputOnSelect // Prevent input focus on select                                
                options={options}
                defaultValue ={options[getQueryStringValue("s")]}
                components={{
                    Option: OptionWithIcon,
                  }}            
                formatOptionLabel={formatOptionLabel}    
                onChange={(event) => handleSelect(event)}
            />
            </div>
          ):(<></>)}
            </div>
          {resultData.map((item, index) => (
                <div
                    className="common-list"
                    key={index}
                    style={{
                                cursor: "pointer",
                            }}
                    onClick={() => topicList(item)}
                >

            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", }} key={uuidv4()} >
                <div style={{ paddingLeft: "30px", textAlign: "left", paddingRight:"30px"}} key={uuidv4()}>
                            <div className= "textBlackSedute" style={{ paddingRight: "10px",paddingLeft: "10px",paddingTop: "20px", paddingBottom:"10px", fontWeight: "bold"}} key={uuidv4()}>
                                <label key={uuidv4()}>
                                {item.title} -- {" "}
                                </label>
                                <span className= "textBlackSedute">
                                                {item.ipotesi}
                                            </span>
                            </div>
                            <div className= "textBlackSedute" style={{ paddingRight: "10px",paddingLeft: "10px",paddingTop: "10px", paddingBottom:"20px"}} key={uuidv4()}>
                                <label key={uuidv4()}>
                                            Seduta del{" "}
                                            <span className= "textBlackSedute" dangerouslySetInnerHTML={{
                                                __html: item.dataSeduta +" -- "+ item.matching,}}/>
                                </label>
                                </div>
                </div>
                    </div>
                </div>



            ))}

        </div>
      </div>
    ):(
    <></>
    )
  }
{viewButtons ?(
    <div style={{
        display: "flex",
        alignItems: "center",
        width:"25%",
        marginLeft: "auto", marginRight: "auto",
        paddingTop:"0px", marginTop:"0px"
     }}>
       {viewPrevious ?(
        <div  onClick={updatePage}>
        <p style={{ cursor: "pointer", color: "#000000", paddingRight:"20px" }}>
           Precedenti
        </p>
        </div>
       ):(<></>)}

        <div  >
        <p  style={{ color: "#000000" }}>
        Risultati
        </p>
        </div>

       {viewNext ?(
        <div onClick={updatePageNext}>
        <p  style={{ cursor: "pointer", color: "#000000", paddingLeft:"20px" }}>
           Successivi
        </p>
        </div>
       ):(<></>)}
    </div>
    ):(
    <></>
    )
  }

        </div>

    )}</div>
    );
}
