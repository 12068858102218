import React, { useEffect } from "react";
import axios from 'axios';
//import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import {ThreeDots} from 'react-loader-spinner';
import {properties} from "utils/properties";
import { useNavigate } from "react-router-dom";

export default function LastHandler() {    

    //const history = useHistory();
    const navigate = useNavigate();
    const resolveLast = function(){
        console.log("resolveLast");

        if (!window.cordova)
        {
                axios.get(properties.productionUrl+'/rest/getLast?edited')
                .then(res => {
                console.log("resolveLast",res.data);
                if(res.data==undefined){
                    /*history.push({                
                        pathname: "/error/200",
                        });*/
                        navigate("/error/200");  
                }
                if(res.data=="\n\n" || res.data==""){
                    //GOTO ERRO PAGE
                    /*history.push({                
                        pathname: "/error/200",
                        });*/
                        navigate("/error/200");  
                }
                    
                const lastId = res.data;
                
                console.log("r12121",window.location.href, lastId);
                if(window.location.href.endsWith("/fascicoli/last/summary")||window.location.href.endsWith("/fascicoli/last/summary")){
                    /*history.replace({                
                    pathname: "/fascicoli/"+lastId+"/summary",
                    });*/
                    navigate("/fascicoli/"+lastId+"/summary",{ replace: true });  
                }
                if(window.location.href.endsWith("/fascicoli/last")||window.location.href.endsWith("/fascicoli/last/")){
                    /*history.replace({                
                    pathname: "/fascicoli/"+lastId,
                    });*/
                    navigate("/fascicoli/"+lastId,{ replace: true });  
                }
                if(window.location.href.endsWith("/updating/last")||window.location.href.endsWith("/updating/last/")){
                    /*history.replace({                
                    pathname: "/updating/"+lastId,
                    });*/
                    navigate("/updating/"+lastId,{ replace: true });  
                }
                if(window.location.href.endsWith("/updatingAmbiti/last")||window.location.href.endsWith("/updatingAmbiti/last/")){
                    /*history.replace({                
                    pathname: "/updatingAmbiti/"+lastId,
                    });*/
                    navigate("/updatingAmbiti/"+lastId,{ replace: true });  
                }
                if(window.location.href.endsWith("/raw/last")||window.location.href.endsWith("/raw/last/")){
                    /*history.replace({                
                    pathname: "/raw/"+lastId,
                    });*/
                    navigate("/raw/"+lastId,{ replace: true });  
                }
            }).catch(function (res) {            
                /*history.push({                
                    pathname: "/error/200",
                    });*/
                    navigate("/error/200");  

            });
        }else{
            console.log("ELSE 11222");
            var onSuccess = function (data)
            {
                  console.log("resolveLast 11222",data);
                  if(data==undefined){
                    navigate("/error/200");
                  }
                  if(data=="\n\n" || data==""){
                      //GOTO ERRO PAGE
                      navigate("/error/200");
                  }

                  const lastId = data;
                  let newUI=false;
                  if(localStorage.getItem("new_ui")=== undefined || localStorage.getItem("new_ui")=== null || localStorage.getItem("new_ui")=== "false"){        
                    newUI=false;
                  }
                  else{
                    newUI=true;
                  }

                  console.log("r 1122",window.location.href);
                  if(newUI){
                    navigate("/fascicoli/"+lastId+"/summary",{ replace: true } );
                }
                if(window.location.href.endsWith("/fascicoli/last")||window.location.href.endsWith("/fascicoli/last/"))
                    navigate("/fascicoli/"+lastId+"/summary",{ replace: true } );
                else
                    navigate("/fascicoli/"+lastId,{ replace: true });    
                  /*if(window.location.href.endsWith("/fascicoli/last/summary")||window.location.href.endsWith("/fascicoli/last/summary")){
                      navigate("/fascicoli/"+lastId+"/summary",{ replace: true } );
                  }
                  if(window.location.href.endsWith("/fascicoli/last")||window.location.href.endsWith("/fascicoli/last/")){
                      navigate("/fascicoli/"+lastId,{ replace: true });
                  }*/
                  //navigate("/fascicoli/"+lastId,{ replace: true });
            };

            var onFailure = function()
            {

            };

            window.getLast(onSuccess, onFailure);


        }
}


  useEffect(() => {      
      //console.log("RRRRRr");
      resolveLast();      
    }, []);





return (
  <div key={uuidv4()}> 
        
            <div key={uuidv4()} style={{ width:"25%",   display: "flex", 
            marginLeft: "auto", marginRight: "auto" }}> 
             <ThreeDots color="#30720f" height='350' width='150'/>
             </div> 

    </div>
    );
}
