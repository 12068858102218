import React, { useState, useEffect, useContext } from "react";
//import { useHistory, useParams } from "react-router-dom";
import { useParams } from "react-router-dom";
import RouterContext from "../../context/RouterContext";
import {updateSeduta} from "./Utils";
import { useNavigate } from "react-router-dom";
import "../../final.css";
import {properties} from "utils/properties";

export default function FinalEnd() {
    //path="/fascicoli/:idDossier/proposteintervento/schede/:id/:refname"
    let { idDossier, id, refname } = useParams();
    const [finalInfo, setFinalInfo] = useState([]);
    //const history = useHistory();
    const navigate = useNavigate();
    const { pageRouter, setPageRouter } = useContext(RouterContext);    
    const meetingId = idDossier;
    
    const [color, setColor] = useState([]);
    
    const updateUI = function(jsonData) {            
        
        const keyValuScores = Object.entries(jsonData)[4][1];
    
        if (typeof keyValuScores[id] == 'undefined' || keyValuScores[id] == null) {
            alert("La scheda cercata non è presente all'interno del fascicolo");  
            /*history.replace({                                
                pathname: "/init",
            });*/
            navigate("/init",{ replace: true });   
            return;
        }

        const homeFieldsItem = Object.entries(keyValuScores[id].components.home.fields);
    
        const cardList = Object.entries(jsonData)[4][1][id]["components"][refname]["fields"];
        //console.log("qq40",cardList);
        const finalList = Object.entries(cardList);
        //console.log("qq41",finalList);
        let obiettivo;
        var items = [];
        //const keyValuScores = Object.entries(jsonData)[4][1];
        //console.log("qq42",keyValuScores[id]);
        //const homeFieldsItem = Object.entries(keyValuScores[id].components.home.fields);
        for (var i1 = 0; i1 < homeFieldsItem.length; i1 ++) {            
            if (homeFieldsItem[i1][1]["label"]==="Obiettivo") {
                obiettivo = homeFieldsItem[i1][1]["value"];
            }
        }        
        
        for (var i = 0; i < finalList.length; i++) {            
            var item = {
                label: finalList[i][1]["label"],
                value: finalList[i][1]["value"],
                style: finalList[i][1]["style"],
                second: finalList[i][1]["secondValue"],
                comment: finalList[i][1]["comment"],
                reftype: refname,
                table: finalList[i][1]["table"],
            };
            //console.log("tre",item);
            items.push(item);
        }
        //console.log("qc46", obiettivo);       
        console.log("Q1Q3",items); 
        setFinalInfo(items);

        const temp1 = Object.entries(jsonData);
        //console.log("qq46", temp1);
        let entireSpecificList;
        temp1.forEach( entry => {
                let key= entry[0];
                let value= entry[1];                               
                if(key==='sectors') {                                        
                    entireSpecificList=value;                                        
                  //console.log("qq-2",entry);
                }                
        });
        
        const temp3 = Object.values(entireSpecificList);
        temp3.forEach( entry => {                          
                if(entry["label"]===obiettivo){                    
                    //coloring= entry["color"];
                    setColor(entry["color"]);
                }                
        });
                
        //console.log("COLOR IS", color);      
    }

    const doPostSeduta2 = function() {
        //console.log("doing sedute post");
        var xhr2 = new XMLHttpRequest();                
        xhr2.open('POST', properties.productionUrl+'/rest/checkSeduta',true);
        xhr2.setRequestHeader("Content-type", "application/x-www-form-urlencoded");    
        xhr2.onload = function (e) {      
            if (xhr2.readyState === 4) {
                if (xhr2.status === 200) {                              
                    //console.log("OK92",xhr2);
                    var responseText = xhr2.responseText;                    
                    var obj=JSON.parse(responseText);
                    console.log("OK93",obj);          
                    if(obj.status==="204" ){ 
                        console.log("OK DOPOSTSEDUTE2 204",xhr2);  
                        const MeetingInfo = JSON.parse(localStorage.getItem("meetingContent"));
                        updateUI(MeetingInfo);
                    }              
                    else{
                        //status 201
                        console.log("OK DOPOSTSEDUTE2 201",xhr2);  
                        try{
                            localStorage.setItem("meetingContent", obj.content);   
                            localStorage.setItem("meeting", meetingId);
                            localStorage.setItem("meetingVersion", obj.date);                                                                  
                        }
                        catch(error) {
                            console.log("LOCALSTORAGE ISSUE", error);
                        }
                
                        const MeetingInfo = JSON.parse(obj.content);
                        updateUI(MeetingInfo);
                    }
                } 
                else {
                    console.error("Error 1", xhr2.statusText);          
                    alert("Errore recuperando la seduta");  
                }
            }
        };

        xhr2.onerror = function (e) {
            console.error("Error 22", xhr2.statusText);
        };
    
        let urlEncodedData = "idf=" + meetingId;
        if (localStorage.meeting===meetingId){            
            //console.log( "QUINDICI00");
            const currentData=localStorage.getItem("meetingVersion");        
            urlEncodedData = urlEncodedData+ "&date=" + currentData;                    
        }    
        xhr2.send(urlEncodedData);
    }

    useEffect(() => {
        if(process.env.NODE_ENV !=='development' && !window.cordova){
            
        //CHECK AUTHENTICATION
        var xhr = new XMLHttpRequest();                
        xhr.open('POST', properties.productionUrl+'/rest/ping',true);
        xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
    
        xhr.onload = function (e) {
            if (xhr.readyState === 4) {
                if (xhr.status === 200) {                    
                    console.log("OK",xhr);
                    updateSeduta(meetingId,doPostSeduta2,updateUI);
                } else {
                    console.error("Error 1", xhr);     
                    localStorage.clear();               
                    /*history.push({                
                    pathname: "/login",
                    });*/
                    navigate("/login");  
                }
            }
        };
    
        xhr.onerror = function (e) {
          console.error("Error 2", xhr);
          localStorage.clear();
          /*history.push({                
                    pathname: "/login",
                    });*/
                    navigate("/login");  
        };
             
        let urlEncodedData = null; 
        xhr.send(urlEncodedData);
        //CHECK AUTHENTICATION
        }
        
        if (window.cordova)
        {
            updateSeduta(meetingId,doPostSeduta2,updateUI);
        }
                
        /*setPageRouter({
            router: history.location.pathname,
            homecolor: "#879497",
            settingcolor: "#FF5A60",
            homebordercolor: "#FFFFFF",
            settingbordercolor: "#FF5A60",
            hometitlecolor: "#879497",
            settingtitlecolor: "black",
        });*/
    }, []);

    return (
        <div className="content">
        <div style={{
            marginLeft: "0px",
            width: "100%",
            height: "10px",            
            //background: color,            
            }}> </div>       
            {finalInfo.map((item, index) =>
                item.reftype !== "relazioneannotazioni" ? (
                    item.table && item.label !== "Allegati" ? (
                         <div
                            className="common-list"
                            key={index}
                            style={{                                
                                margin: "0px",
                                borderRadius: "0px",                                
                                //paddingLeft: "30px",                            
                            }}                         
                        >
                    {/* <li className={[activeClass, data.klass, "main-class"].join(' ')} />*/}

                            <table className={[item.label, 'table1'].join(' ')} style={{background: "white",  borderBottom: "1px solid rgb(239, 239, 239)", }}>
                                <tr>                  
                                    { item.value ? (   
                                    //console.log("trena",item.value),               
                                    <td colSpan="4" style={{background: "white" }}>
                                        <div
                                        style={{                                    
                                        textAlign: "left",
                                        //marginTop: "0px",
                                        paddingTop: "0px", 

                                        //color: "rgb(254, 173, 176)",
                                        color: "#a2ee7d",
                                        //fontWeight: "bold", 
                                        whiteSpace: 'pre-wrap',                                       
                                        }}>                                                                          <p className="text importantText"
                                                style={{
                                                marginTop: "0px",
                                                
                                            }}
                                            dangerouslySetInnerHTML={{
                                                __html: item.label+ " "+ item.value,
                                            }}
                                            />                                                    
                                       </div>
                                        
                                    </td>
                                    ):(                                     

                                    <td colSpan="4" style={{background: "white" }}>
                                        <div
                                        style={{                                    
                                        textAlign: "left",  
                                        paddingTop: "10px",                                      
                                        //color: "rgb(254, 173, 176)",
                                        //fontWeight: "bold",                                          
                                        whiteSpace: 'pre-wrap',                              
                                        }}>                                                                    
                                            <p className="text importantText" 
                                            style={{
                                                marginTop: "0px",
                                                
                                            }}
                                            dangerouslySetInnerHTML={{
                                                __html: item.label,
                                            }}
                                            />
                                       </div>
                                        
                                    </td>
                                    )}
                                </tr>


                                {item.table.map((subitem, index) =>
                                    index !== 0 || subitem[2] === "DATA" ? (
                                        //console.log("sub",subitem),
                                        <tbody>
                                        <tr>
                                            { subitem[1] === "2" && subitem[3] ?
                                            (
                                               //console.log("sub1",subitem), 
                                            <td 
                                            style={{
                                                textAlign: "left",
                                                fontSize: "16px",
                                                color: "#879497",
                                                fontWeight:"normal",
                                                background: "white", 
                                                //background: "red", 
                                                }}>{subitem[3]}
                                            </td>
                                            ) : [ subitem[1] === "0" && subitem[3] ? (
                                            //console.log("sub2",subitem),
                                            
                                            <td style={{ 
                                                textAlign: "left",
                                                fontSize: "16px",
                                                //background: "#ffeeef", 
                                                background: "#bcf3a1",
                                                //color: "#4B0307", 
                                                color: "#000000", 
                                                //fontWeight:"bold", 
                                                fontWeight:"normal",
                                                paddingTop: "15px"}}> {subitem[3]}</td>
                                            ): [ subitem[1] === "" && subitem[3] && subitem[2]!== "STRUCT" ? (
                                            //console.log("sub3",subitem),
                                            <td style={{                                                 
                                                //background: "#ffeeef", 
                                                background: "#bcf3a1",
                                                fontSize: "16px",
                                                //color: "#4B0307", 
                                                color: "#000000", 
                                                //fontWeight:"bold", 
                                                fontWeight:"normal",
                                                paddingTop: "15px"}}> {subitem[3]}</td>
                                            ): [ subitem[3] && subitem[2]!== "STRUCT" ? 
                                            (
                                            //console.log("sub4",subitem),
                                            <td style={{
                                                textAlign: "left",
                                                fontSize: "16px",
                                                background: "white", 
                                                fontWeight:"normal", 
                                                //color: "#4B0307", 
                                                color: "#000000", 
                                                 }}>{subitem[3]}</td>
                                            ) : [subitem[0]=== "-99" || subitem[2]=== "HEAD" ? 
                                            (
                                            //console.log("sub5",subitem),
                                            <td style={{ 
                                                textAlign: "left",
                                                fontSize: "16px",
                                                //background: "#ffeeef",
                                                background: "#bcf3a1",
                                                //color: "#4B0307", 
                                                color: "#000000", 
                                                //fontWeight:"bold", 
                                                fontWeight:"normal",
                                                paddingTop: "15px"}}>{subitem[3]}</td>
                                            ): 
                                             (null)]
                                            ]]]}        

                                            { subitem[1] === "2" && subitem[4] ?
                                            (

                                            <td style={{
                                                color: "#879497",
                                                fontSize: "16px",
                                                background: "white",
                                                fontWeight:"normal", }}>{subitem[4]}</td>
                                            ) : [ subitem[1] === "0" && subitem[4] ? (
                                            
                                            <td style={{ 
                                                //background: "#ffeeef", 
                                                background: "#bcf3a1",
                                                fontSize: "16px",
                                                //color: "#4B0307", 
                                                color: "#000000", 
                                                //fontWeight:"bold", 
                                                fontWeight:"normal",
                                                paddingTop: "15px"}}>{subitem[4]}</td>
                                            ): [ subitem[1] === "" && subitem[4] && subitem[2]!== "STRUCT" ? (
                                            
                                            <td style={{ 
                                                //background: "#ffeeef", 
                                                background: "#bcf3a1",
                                                //color: "#4B0307", 
                                                fontSize: "16px",
                                                color: "#000000", 
                                                //fontWeight:"bold", 
                                                fontWeight:"normal",
                                                paddingTop: "15px"}}>{subitem[4]}</td>
                                            ): [ subitem[4] && subitem[2]!== "STRUCT" ? 
                                            (
                                            <td style={{
                                                background: "white", 
                                                //color: "#4B0307", 
                                                fontSize: "16px",
                                                color: "#000000", 
                                                fontWeight:"normal", }}>{subitem[4]}</td>
                                            ) : 
                                            [ subitem[0]=== "-99" || subitem[3]=== "Questionario di approfondimento" ||subitem[2] === "HEAD" ? 
                                            (
                                            //console.log("qui", subitem),
                                            <td style={{ 
                                                //background: "#ffeeef", 
                                                background: "#bcf3a1",
                                                //color: "#4B0307", 
                                                fontSize: "16px",
                                                color: "#000000", 
                                                //fontWeight:"bold", 
                                                fontWeight:"normal",
                                                paddingTop: "15px"}}>{subitem[4]}</td>
                                            ): (null)]
                                            ]]]}                                                 
                                                   { subitem[1] === "2" && subitem[5] ?
                                            (
                                           

                                            <td style={{
                                                color: "#879497",
                                                fontSize: "16px",
                                                background: "white",
                                                fontWeight:"normal", }}>{subitem[5]}</td>
                                            ) : [ subitem[1] === "0" && subitem[5] ? (
                                           
                                            <td style={{ 
                                                //background: "#ffeeef", 
                                                background: "#bcf3a1",
                                                //color: "#4B0307", 
                                                color: "#000000", 
                                                fontSize: "16px",
                                                //fontWeight:"bold", 
                                                fontWeight:"normal",
                                                paddingTop: "15px"}}>{subitem[5]}</td>
                                            ): [ subitem[1] === "" && subitem[5] && subitem[2]!== "STRUCT" ? (
                                           
                                            <td style={{ 
                                                //background: "#ffeeef", 
                                                background: "#bcf3a1",
                                                //color: "#4B0307", 
                                                fontSize: "16px",
                                                color: "#000000", 
                                                //fontWeight:"bold", 
                                                fontWeight:"normal",
                                                paddingTop: "15px"}}>{subitem[5]}</td>
                                            ): [ subitem[5] && subitem[2]!== "STRUCT" ? 
                                            (
                                            <td style={{
                                                //color: "#4B0307", 
                                                color: "#000000", 
                                                fontSize: "16px",
                                                fontWeight:"normal",
                                                background: "white", }}>{subitem[5]}</td>
                                            ) : [  subitem[0]=== "-99"  || (subitem[2] === "HEAD" && subitem[3] !== "Domanda") ? 
                                            (
                                            <td style={{ 
                                                //background: "#ffeeef", 
                                                background: "#bcf3a1",
                                                //color: "#4B0307", 
                                                color: "#000000", 
                                                fontSize: "16px",
                                                //fontWeight:"bold", 
                                                fontWeight:"normal",
                                                paddingTop: "15px"}}>{subitem[5]}</td>
                                            ): (null)]
                                            ]]]}                               


                                            { subitem[1] === "2" && subitem[6] ?
                                            (                                           
                                            <td style={{
                                                color: "#879497",
                                                background: "white",
                                                fontSize: "16px",
                                                fontWeight:"normal", }}>{subitem[6]}</td>
                                            ) : [ subitem[1] === "0" && subitem[6] ? (
                                           
                                            <td style={{ 
                                                //background: "#ffeeef", 
                                                background: "#bcf3a1",
                                                //color: "#4B0307", 
                                                fontSize: "16px",
                                                color: "#000000", 
                                                //fontWeight:"bold", 
                                                fontWeight:"normal",
                                                paddingTop: "15px"}}>{subitem[6]}</td>
                                            ): [ subitem[1] === "" && subitem[6] && subitem[2]!== "STRUCT" ? (
                                           
                                            <td style={{ 
                                                //background: "#ffeeef", 
                                                background: "#bcf3a1",
                                                //color: "#4B0307", 
                                                color: "#000000", 
                                                fontSize: "16px",
                                                //fontWeight:"bold", 
                                                fontWeight:"normal",
                                                paddingTop: "15px"}}>{subitem[6]}</td>
                                            ): [ subitem[6] && subitem[2]!== "STRUCT" ? 
                                            (
                                            <td style={{
                                                //color: "#4B0307", 
                                                color: "#000000", 
                                                fontSize: "16px",
                                                background: "white",
                                                fontWeight:"normal", }}>{subitem[6]}</td>
                                            ) : [ subitem[6] && subitem[0]=== "-99" ? 
                                            (
                                            <td style={{ 
                                                //background: "#ffeeef", 
                                                background: "#bcf3a1",
                                                //color: "#4B0307", 
                                                color: "#000000", 
                                                fontSize: "16px",
                                                //fontWeight:"bold", 
                                                fontWeight:"normal",
                                                paddingTop: "15px"}}>{subitem[6]}</td>
                                            ):
                                            (null)]
                                            ]]]}
                                            
                                            
                                            
                                        </tr>
                                        </tbody>
                                    ) : (
                                        <></>
                                    )
                                )}                                
                            </table>
                            {item.comment ? (  
                            //console.log("cmt4", item.comment),                              
                                <div
                                    style={{                                        
                                        textAlign: "left",
                                        background: "white",
                                        padding: "25px 25px",
                                    }}
                                >
                                    
                                    <div
                                        style={{
                                            background: "white",
                                            //color: "#879497",
                                            padding: "0px 25px",
                                            //border: "1px solid #FEADB0",
                                            border: "1px solid #30720f",
                                            borderRadius: "20px",
                                            margin: "0px 0px",
                                        }}
                                    >
                                        <p className="text notImportantText">
                                            Commento:
                                        </p>
                                        <p className="text notImportantText">{item.comment}</p>
                                    </div>
                                </div>
                            ): (
                            <></>
                            )}
                        </div>

                    ) : item.label === "Allegati" ? (
                        //console.log("cmt7", item.value),
                        <div
                            style={{
                                textAlign: "left",
                                paddingLeft: "25px",
                                paddingRight: "25px",
                                background: "white",
                                paddingTop: "20px",
                                borderBottom: "1px solid rgb(239, 239, 239)",
                            }}
                            key={index}
                        >
                            <p className="text importantText"
                                style={{
                                    //color: "rgb(254, 173, 176)",
                                    //fontWeight: "bold",
                                    marginTop: "0px",
                                }}
                            >
                                {item.label}:
                            </p>
                            <div
                                style={{
                                    //paddingBottom: "20px",
                                    //color: "rgb(75, 3, 7)",
                                    color: "#30720f",
                                    whiteSpace: 'pre-wrap',
                                }}>                                
                                 <p className="text"
                                            dangerouslySetInnerHTML={{
                                                __html: item.value,
                                            }}
                                        />  

                            </div>
                            {item.table ? (
                                item.table.map((extraitem, index) =>(    

                                index !== 0 && extraitem[3] !== 'Nome Allegato'? (
                                //console.log(extraitem[3]),
                                    <div
                                        key={index}
                                        style={{
                                            //paddingBottom: "20px",
                                            //color: "rgb(75, 3, 7)",
                                            color: "#30720f",
                                        }}>

                                         <p className="text"
                                            dangerouslySetInnerHTML={{
                                                __html: extraitem[3],
                                            }}
                                        />  
                                    </div>
                                        ) : (
                                            <></>
                                        )
                                ))
                            ) : (
                                <></>
                            )}
                        </div>
                    ): item.comment ? (
                        //console.log("cmt1", item.value),
                                <div
                                    style={{
                                textAlign: "left",
                                paddingLeft: "25px",
                                paddingRight: "25px",
                                background: "white",
                                paddingTop: "20px",                                
                                borderBottom: "1px solid rgb(239, 239, 239)",
                                    }}
                                >                                
                                    <div>
                                        <p className="text importantText"
                                        //style={{ color: "#FEADB0" }}
                                        >
                                            {item.label}:
                                        </p>
                                        <p className="text"
                                            style={{
                                            //    color: "#4B0307",
                                            whiteSpace: 'pre-wrap' }}
                                            dangerouslySetInnerHTML={{
                                                __html: item.value,
                                            }}

                                         
                                        />                                         
                                    </div>
                                    <div
                                        style={{
                                            color: "#879497",
                                            padding: "0px 25px",
                                            //border: "1px solid #FEADB0",
                                            border: "1px solid #30720f",
                                            borderRadius: "20px",
                                            margin: "40px 0px",
                                        }}
                                    >
                                        <p className="text notImportantText"
                                        //style={{ fontWeight: "bold" }}
                                        >
                                            Commento:
                                        </p>                                        

                                        <p className="text notImportantText"
                                        style={{whiteSpace: 'pre-wrap' }}
                                            dangerouslySetInnerHTML={{
                                                __html: item.comment,
                                            }}
                                        />    
                                    </div>
                                </div>
                            )



                     : item.style === 1  ? (
                            //console.log ("s1", item.value),
                            <div
                                    style={{
                                        //background: "#FEADB0",
                                        background: "#a2ee7d",
                                        color: "#4B0307",
                                        textAlign: "left",
                                        padding: "0px 25px",
                                        border: "1px solid rgb(239, 239, 239)",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <p className="text"
                                            //style={{
                                            //    fontWeight: "bold",
                                            //}}
                                        >
                                            {item.label}: 
                                        </p>
                                    </div>
                                    <p className="text">{item.value}</p>
                                </div>
                             ) : (
                            //console.log("sew",item.value),
                                                <div
                            style={{
                                textAlign: "left",
                                paddingLeft: "25px",
                                paddingRight: "25px",
                                background: "white",
                                paddingTop: "20px",
                                borderBottom: "1px solid rgb(239, 239, 239)",
                            }}
                            key={index}
                            >
                            <p className="text importantText"
                                style={{
                                    //color: "rgb(254, 173, 176)",
                                    //fontWeight: "bold",
                                    marginTop: "0px",
                                }}
                            >                            
                                {item.label}:
                            </p>

                            <div
                                style={{
                                    paddingBottom: "20px",
                                    //color: "rgb(75, 3, 7)",
                                    color: "#30720f",
                                    whiteSpace: 'pre-wrap',
                                }} > 
                                <p className="text"
                                    dangerouslySetInnerHTML={{
                                                __html: item.value,
                                            }}
                                        />
                            </div>
                            </div>
                                            )



                        
                    
                ) : (
                //console.log("fre",item.value),
                    <div
                        style={{
                            textAlign: "left",
                            paddingLeft: "25px",
                            paddingRight: "25px",
                            background: "white",
                            paddingTop: "20px",
                            borderBottom: "1px solid rgb(239, 239, 239)",
                        }}
                        key={index}
                    >
                        <p className="text importantText"
                            style={{
                                //color: "rgb(254, 173, 176)",
                                //fontWeight: "bold",
                                marginTop: "0px",
                            }}
                        >
                            {item.label}:
                        </p>
                        <div
                            style={{
                                paddingBottom: "20px",
                                //color: "rgb(75, 3, 7)",
                                //color: "#30720f",
                                whiteSpace: 'pre-wrap',
                            }}
                            dangerouslySetInnerHTML={{
                                __html: item.value,
                            }}
                        />
                        {item.table ? (
                            item.table.map((extraitem, index) => (
                            //console.log("uff",item),
                                <div
                                    key={index}
                                    style={{ paddingBottom: "20px" }}
                                    dangerouslySetInnerHTML={{
                                        __html: extraitem[3],
                                    }}
                                />
                            ))
                        ) : (
                            <></>
                        )}
                    </div>
                )
            )}
        </div>
    );
}
