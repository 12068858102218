import React, { useState, useEffect, useContext, useRef } from "react";
//import { useHistory, useParams } from "react-router-dom";
import { useParams } from "react-router-dom";
import { IconButton, Fab } from '@material-ui/core/';
import AddIcon from '@material-ui/icons/SettingsBackupRestore';
import RouterContext from "../../context/RouterContext";
import Grid from "@material-ui/core/Grid";
import { Doughnut, Chart } from 'react-chartjs-2';
import { defaults } from 'react-chartjs-2';
import { Chart as ChartJS} from 'chart.js';
import { v4 as uuidv4 } from 'uuid';
import {ThreeDots} from 'react-loader-spinner';
import axios from 'axios';
import {getElementsAtEventForMode} from 'react-chartjs-2';
import { Bar } from 'react-chartjs-2';
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import InfoIcon from "@material-ui/icons/Info";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {updateSeduta} from "./Utils";
import {properties} from "utils/properties";
import { useNavigate } from "react-router-dom";

import {    
    CategoryScale,
    LinearScale,
    //BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
  
  
  
  ChartJS.register(
    CategoryScale,
    LinearScale,
    //BarElement,
    Title,
    Tooltip,
    Legend
  );


const useStyles = makeStyles((theme) => ({
    root: {
        background: "#f5f5f5",
        padding: "30px 5%",
        minHeight: "calc(100vh - 136px)",
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: "100%",
        //flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    }));

    var missioni=0;
    const numFormatter= (num) =>{
        //function numFormatter(num) {
            if(num > 999 && num < 1000000){
                return (num/1000).toFixed(1) + 'K'; // convert to K for number from > 1000 < 1 million 
            }else if(num >= 1000000){
                return (num/1000000).toFixed(1) + 'M'; // convert to M for number from > 1 million 
            }else if(num < 900){
                return num; // if value < 1000, nothing to do
            }
        }

        var dataBarGeneral = {
            labels: [                        
              ],
              datasets: [       
                {
                  label: "Totale Stanziato",
                  backgroundColor: "#A2EE7D",
                  borderColor: "green",
                  borderWidth: 1,
                  data: [],                  
                  stack: 0
                },
                {
                    label: "Budget",          
                    backgroundColor: "#30720E",
                    borderColor: "#006600",
                    borderWidth: 1,
                    data: [],                    
                    stack: 0
                },
                {
                  label: "Risorse da Altri Esercizi",
                  backgroundColor: "#C0C0C0",
                  borderColor: "green",
                  borderWidth: 1,
                  data: []                  
                }
              ]
        };

        var dataBarCultura= {
            labels: [],
              datasets: [       
                {
                  label: "Totale Stanziato",
                  backgroundColor: "#A2EE7D",
                  borderColor: "green",
                  borderWidth: 1,
                  data: [],                 
                  stack: 0
                },
                {
                    label: "Budget",          
                    backgroundColor: "#30720E",
                    borderColor: "#006600",
                    borderWidth: 1,
                    data: [],                 
                    stack: 0
                },
                {
                  label: "Risorse da Altri Esercizi",
                  backgroundColor: "#C0C0C0",
                  borderColor: "green",
                  borderWidth: 1,
                  data: []                 
                }
              ]
        };
    
        var dataBarPianeta= {
            labels: [],
              datasets: [       
                {
                  label: "Totale Stanziato",
                  backgroundColor: "#A2EE7D",
                  borderColor: "green",
                  borderWidth: 1,
                  data: [],                 
                  stack: 0
                },
                {
                    label: "Budget",          
                    backgroundColor: "#30720E",
                    borderColor: "#006600",
                    borderWidth: 1,
                    data: [],                 
                    stack: 0
                },
                {
                  label: "Risorse da Altri Esercizi",
                  backgroundColor: "#C0C0C0",
                  borderColor: "green",
                  borderWidth: 1,
                  data: []                 
                }
              ]
        };
        var dataBarPersone= {
              labels: [],
              datasets: [       
                {
                  label: "Totale Stanziato",
                  backgroundColor: "#A2EE7D",
                  borderColor: "green",
                  borderWidth: 1,
                  data: [],                 
                  stack: 0
                },
                {
                    label: "Budget",          
                    backgroundColor: "#30720E",
                    borderColor: "#006600",
                    borderWidth: 1,
                    data: [],                 
                    stack: 0
                },
                {
                  label: "Risorse da Altri Esercizi",
                  backgroundColor: "#C0C0C0",
                  borderColor: "green",
                  borderWidth: 1,
                  data: []                 
                }
              ]
        };

export default function TopicList6() {        
    const [topicList, setTopicList] = useState([]);
    const [loading, setLoading]= React.useState(true);    
    const [barAvailable, setBarAvailable]= React.useState(false);    
    const classes = useStyles();
    //const history = useHistory();
    const navigate = useNavigate();
    const [anno, setAnno]= React.useState();   
    const [labelFondi, setLabelFondi]= useState("Attività Istituzionale"); 
    let { idDossier } = useParams();    
    const { pageRouter, setPageRouter } = useContext(RouterContext);
    const [aiList, setAiList] = useState([]);
    const [allList, setAllList] = useState([]);
    const [dataBar, setDataBar] = useState();
    
    const meetingId = idDossier ;
    
    const LegendClickHandler = function (e, legendItem, legend) {
       //e.stopImmediatePropagation();
    };

    var optionsBar = {
        plugins:{
            responsive: true,
            legend: {                
                position: "bottom",
                onClick: LegendClickHandler,                
                labels: {                
                    font: {
                        size: 14
                    }
                }                
            },
            /*title: {
                display: true,
                text: "Situazione Fondi",                
                padding: 20 //default is 10
            },*/ 
            tooltip: {
                callbacks: {
                    label: function(tooltipItem) {                        
                        var label = tooltipItem.dataset.label;
                        var total = tooltipItem.raw;                                             
                        
                        if (label === 'Deliberato' ||label === 'Budget') {                        
                            total = tooltipItem.raw +dataBar.datasets[0].data[tooltipItem.dataIndex];                        
                            total=total.format(2, 3, '.', ',')
                            return label + ': ' +'€' + total;    
                        }                        
                        total=total.format(2, 3, '.', ',')
                        return label + ': ' +'€' + total;                        
                    }
                }    
            }
        },
            scales: {
            //yAxes: [{
                x:{
                    ticks: {                        
                        font: {
                            size: 14
                        }
                    }
                },
                y: {
                    //type: 'logarithmic',
                    ticks: {
                        beginAtZero: true,
                        font: {
                            size: 14
                        },
                        callback: function(value, index, ticks) {                        
                            value=numFormatter(value);
                            return '€' + value;
                            }
                    }
                }
            },
            onClick: function(evt, element) {
                if (element.length > 0) {                  
                  //console.log("W2W",dataBar.labels[element[0].index]) ;
                  if(dataBar.labels[element[0].index]=="Cultura"){                      
                    missioni=1;                    
                    setDataBar(dataBarCultura);
                    //setLabelFondi("Cultura");
                    //labelFondi="Cultura";
                    //setShowFab(true);
                  }
                  else if(dataBar.labels[element[0].index]=="Persone"){
                    missioni=1;                    
                    setDataBar(dataBarPersone);
                    //setLabelFondi("Persone");
                    //labelFondi="Persone";
                    //setShowFab(true);
                  }
                  else if(dataBar.labels[element[0].index]=="Pianeta"){
                    missioni=1;                    
                    setDataBar(dataBarPianeta);
                    //setLabelFondi("Pianeta");
                    //labelFondi="Pianeta";
                    //setShowFab(true);
                  }
                  else{
                      missioni=0;                      
                      setDataBar(dataBarGeneral);
                      //setLabelFondi("Attività Istituzionale");
                      //labelFondi="Attività Istituzionale";
                      //setShowFab(false);
                  }                  
                }
              }
     };
     
    Number.prototype.format = function(n, x, s, c) {
        var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '€') + ')',
            num = this.toFixed(Math.max(0, ~~n));
        
        return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','));
    };

  

    const getBarCharData = (issueName) => {     
        
        //console.log("getBarCharData", issueName);        
            
        let endpoint = properties.productionUrl+"/rest/getBarChartJson";                        
        var bodyFormData=new FormData();          
        //console.log("H20",issueName);    
        bodyFormData.append('idf', issueName);    
        axios({
            method: 'post',
            url: endpoint,
            data: bodyFormData, 
            headers: {'Content-Type': 'multipart/form-data' }
        })
        .then(function (response) {
            //console.log("GBC", response.data);
            //dataBar.labels=response.data.labels;        
            //dataBar.datasets[0].data=response.data.Stanziato;
            //dataBar.datasets[1].data=response.data.Deliberato;
            //dataBar.datasets[2].data=response.data.RisorseAltriEsercizi;
            dataBarGeneral.labels=response.data.labels;        
            dataBarGeneral.datasets[0].data=response.data.Stanziato;
            dataBarGeneral.datasets[1].data=response.data.Deliberato;
            dataBarGeneral.datasets[2].data=response.data.RisorseAltriEsercizi;            
            
            dataBarCultura.labels=response.data.Missioni[0].labels;        
            dataBarCultura.datasets[0].data=response.data.Missioni[0].Stanziato;
            dataBarCultura.datasets[1].data=response.data.Missioni[0].Deliberato;
            dataBarCultura.datasets[2].data=response.data.Missioni[0].RisorseAltriEsercizi;
            
            
            dataBarPersone.labels=response.data.Missioni[1].labels;             
            dataBarPersone.datasets[0].data=response.data.Missioni[1].Stanziato;
            dataBarPersone.datasets[1].data=response.data.Missioni[1].Deliberato;
            dataBarPersone.datasets[2].data=response.data.Missioni[1].RisorseAltriEsercizi;

            dataBarPianeta.labels=response.data.Missioni[2].labels;               
            dataBarPianeta.datasets[0].data=response.data.Missioni[2].Stanziato;
            dataBarPianeta.datasets[1].data=response.data.Missioni[2].Deliberato;
            dataBarPianeta.datasets[2].data=response.data.Missioni[2].RisorseAltriEsercizi;
            setDataBar(dataBarGeneral);                   
            setAnno(response.data.annoBudget);
            setBarAvailable(true);
            setLoading(false);                        
        })        
        .catch(function (error) {
            if (error.response) {              
              if(error.response.status===404){
                console.log("error not found");
                setLoading(false);
              }              
            }
        }
        );
    }

    const genData = () => ({
        labels: ['', '', '', '', '', ''],
        datasets: [
        {   
            label: 'Budget',
            data: [100, 0, 0, 0, 0, 0],
            backgroundColor: [
                '0xFFFFFF',
                '0xFFFFFF',
                '0xFFFFFF',
                '0xFFFFFF',
                '0xFFFFFF',
                '0xFFFFFF',
            ],
            borderColor: [
                '0xFFFFFF',
                '0xFFFFFF',
                '0xFFFFFF',
                '0xFFFFFF',
                '0xFFFFFF',
                '0xFFFFFF',
            ],
            borderWidth: 1,  
        },]
        });

    const [dataDonut, setDataDonut]=useState(genData());    
    const [toBePrinted, setToBePrinted]= useState();

    // Disable animating charts by default.
    defaults.animation = false;

    var optionsDon = {
    plugins: {
        legend: {
            display: false
         },        
        datalabels: {
            display: true,
            color: 'black',            
            textAlign: 'center',
            font: {
                      size: 14,
                      //weight: "bold"
            },     

       formatter: (value, ctx) => {
        let datasets = ctx.chart.data.datasets;

        if (datasets.indexOf(ctx.dataset) === datasets.length - 1) {
          let sum = datasets[0].data.reduce((a, b) => a + b, 0);
          let percentage = Math.round((value / sum) * 100) + "%";
          return percentage;
        } else {
          return 0;
        }
      },      
    }
  }
  
};
    
    function toCamelCase(inputArray) {
        let result = "";
        let currentStr = inputArray;
        let tempStr = currentStr.toLowerCase();
        tempStr = tempStr.substr(0, 1).toUpperCase() + tempStr.substr(1);                    
        return tempStr;
    }


    function updateDonutData(temp4){
        var _labels=[];
        var _backgroundColor=[];
        var _data=[];        
        var _ref=[];
        temp4.forEach( entry => {       
        if(entry.budget!== undefined){
            var _temp=entry.budget.replace(".", "");
            _temp=_temp.trim();
            _temp=_temp.replace(/[^\d,-]/g, '');  
            entry.budget=parseFloat(_temp);            
            }                              
                          
        if(entry.budget>0){   
             //console.log("entry",entry);  

            if (entry.obiettivo !== entry.mission)
                    _labels.push(entry.obiettivo + " " +entry.mission);
            else
                    _labels.push(entry.obiettivo);
            _ref.push(entry.ref);    
            _backgroundColor.push(entry.color);
            _data.push(entry.budget);                
            }
        //console.log("qq77", entry);
             
        }); 
        var data = {
            labels: _labels,
            datasets: [
            {
            label: 'Budget',         
            altro_val: _ref, 
            data: _data,
            backgroundColor: _backgroundColor,
            borderColor: _backgroundColor,
            borderWidth: 0,
            },],            
        };
        //console.log("data",data);
        return data;
    }


    function formatMoney(amount, decimalCount = 2, decimal = ",", thousands = ".") {
        try {
            decimalCount = Math.abs(decimalCount);
            decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
            const negativeSign = amount < 0 ? "-" : "";
            let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
            let j = (i.length > 3) ? i.length % 3 : 0;

            return negativeSign +
                (j ? i.substr(0, j) + thousands : '') +
                i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
                (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "")+ " €";
        } catch (e) { console.log(e) }
    };


    function checkAndUpdateMissioneLabel (label){
        if(label === "IN EVIDENZA")
            return "ELEMENTI PIU' RILEVANTI";
        if(label === "INDICE E SITUAZIONE FONDI")
            return "E SITUAZIONE FONDI";
        if(label === "PROGETTI SPECIALI / INTERSETTORIALI E PRATICHE AGGIUNTIVE")
            return "INTERSETTORIALI E PRATICHE AGGIUNTIVE";
        if(label ==="CONVENZIONI, PROGETTI SPECIALI, ULTERIORI PRATICHE E DOCUMENTI")
            return "PRATICHE E DOCUMENTI";
        if(label ==="DIREZIONE PIANIFICAZIONE, STUDI E VALUTAZIONE")
            return "PIANIFICAZIONE, STUDI E VALUTAZIONE";
        if(label ==="DIREZIONE INNOVAZIONE DI IMPATTO")
            return "INNOVAZIONE DI IMPATTO";
        return(label);
    }

    function updateOdgLabel (label){
        if(label === "hidden")
            return "Ordine del Giorno";
        if(label === "default")
            //return "Non Classificati";
            return "Altri punti di discussione";
        if(label ==="verbali")
            return "Verbali";
        if(label ==="comunicazioni")
            return "Comunicazioni";
        if(label ==="attivitaistituzionale")
            return "Attività Istituzionale";
        if(label ==="investimentipatrimoniali")
            return "Investimenti Patrimoniali";
        if(label ==="varie")
            return "Varie ed Eventuali";
        if(label ==="controlliperiodici")
            return "Controlli Periodici";
        if(label ==="personale")
            return "Personale e Organizzazione";
        return(label);
    }


    function getObiettivoFromMissione (mission){
        if(mission === "ABITARE")        
            return "PERSONE";
        if(mission === "LAVORO")
            return "PERSONE";
        if(mission === "EDUCAZIONE")  
            return "PERSONE";
        if(mission === "COMUNITA'")
            return "PERSONE";
        if(mission === "INCLUSIONE") 
            return "PERSONE";
        if(mission === "RISORSE LIBERE PERSONE")
            return "PERSONE";
        if(mission ===  "ATTRATTIVITA'")   
            return "CULTURA";
        if(mission ==="COMPETENZE")
            return "CULTURA";
        if(mission ==="CUSTODIA")
            return "CULTURA";
        if(mission ==="PARTECIPAZIONE")
            return "CULTURA";        
        if(mission ==="RISORSE LIBERE CULTURA")
            return "CULTURA";
        if(mission ==="RICERCA")
            return "PIANETA";
        if(mission ==="INNOVAZIONE")
            return "PIANETA";
        if(mission ==="BENESSERE")
            return "PIANETA";
        if(mission ==="INTERNAZIONALIZZAZIONE")
            return "PIANETA";
        if(mission ==="AMBIENTE")          
            return "PIANETA";
        if(mission ==="RISORSE LIBERE PIANETA")
            return "PIANETA";
        if(mission ==="PERSONE")
            return "PERSONE";
        if(mission ==="CULTURA")
            return "CULTURA";
        if(mission ==="PIANETA")
            return "PIANETA";        
        if(mission ==="IN EVIDENZA")
            return "IN EVIDENZA";
        if(mission === "INDICE E SITUAZIONE FONDI")
            return "INDICE";            
        if(mission ==="DIREZIONE PIANIFICAZIONE, STUDI E VALUTAZIONE")
            return "DIREZIONE";
        if(mission ==="DIREZIONE INNOVAZIONE DI IMPATTO")
            return "DIREZIONE";
        if(mission ==="CONVENZIONI, PROGETTI SPECIALI, ULTERIORI PRATICHE E DOCUMENTI")
            return "CONVENZIONI, PROGETTI SPECIALI, ULTERIORI";
        if(mission ==="PROGETTI SPECIALI / INTERSETTORIALI E PRATICHE AGGIUNTIVE")
            return "PROGETTI SPECIALI";
        return "";
    }

    function isMacroSector(mission){
        if((mission ==="attivitaistituzionali")|| (mission ==="innovazioneimpatto") || (mission ==="pianificazionestudi") ||(mission ==="praticheevidenza")||
            (mission ==="macroprosperita")||(mission ==="macropersonepace")||(mission ==="macropatrimonioparte"))
            return true;
        else{
            //GESTISCO SETTORI VECCHI CHE ASSOCIO AL CONCETTO DI MACROSETTORI START
            if((mission==="ricercaistruzione")|| (mission==="sanita")|| (mission==="patrimonioartistico")||(mission==="attivitaculturali")||
                (mission==="politichesociali")|| (mission==="volontariato")|| (mission==="torinoalpi")|| (mission==="polo900")||
                (mission==="primainfanzia")|| (mission==="arteattivitabeniculturali")|| (mission==="innovazioneculturale")||
                (mission==="filantropiaterritorio")|| (mission==="programmahousing")|| (mission==="lasciti")|| (mission==="programmiContainer")||
                (mission==="pianostrategico")|| (mission==="innoimpatto")|| (mission==="interaffairs"))
                return true;
            else
            //GESTISCO SETTORI VECCHI CHE ASSOCIO AL CONCETTO DI MACROSETTORI FINE
                return false;
            }
    }


    const updateUI = function(meetingData) {
                
        const keyTopicList = Object.entries(meetingData)[6][1];        
        const valueTopicList = Object.values(keyTopicList);
        
        const keyAi = Object.entries(meetingData)[5][1];
        const valueAi = Object.values(keyAi);
            
        var totalToBePrinted=0;
        var temp05_macro=[];
        var temp06_no_macro=[];
        //console.log("PRIMO",keyAi);
        for (const key in keyAi) {                     
            const val = keyAi[key];
            var temp3Item= [];            
            temp3Item.obiettivo= getObiettivoFromMissione(val.label);   
            temp3Item.obiettivo=toCamelCase(temp3Item.obiettivo);  
            temp3Item.mission=checkAndUpdateMissioneLabel(val.label);
            temp3Item.mission=toCamelCase(temp3Item.mission);
            temp3Item.budget=val.totale_complessivo;
            temp3Item.budgetEsclusivo=val.totale_complessivo;
            temp3Item.pratiche=val.numero_pratiche_totali;
            temp3Item.praticheEsclusive=val.numero_pratiche_totali;
            temp3Item.color=val.color;
            temp3Item.ref=key;
            //console.log("INV",temp3Item.ref, temp3Item);
            //console.log("item",temp3Item);
            if(isMacroSector(temp3Item.ref)){      
                //PER GESTIRE SETTORI VECCHI
                if(temp3Item.obiettivo=="")
                    temp3Item.obiettivo=temp3Item.mission;          
                //PER GESTIRE SETTORI VECCHI    
                if(temp3Item.budget!=undefined && temp3Item.pratiche!=0){
                    temp05_macro.push(temp3Item);
                    temp06_no_macro.push(temp3Item);
                }
            }
            else{        
                temp06_no_macro.push(temp3Item);
            }
        }
        console.log("data05",temp05_macro);
        console.log("data06",temp06_no_macro);

        for (var k = 0; k < temp05_macro.length; k++) {           
            var numero1=0;
            var budget_pratiche_settore= 0;
            //console.log("INV",temp05_macro[k].budget);
            if (temp05_macro[k].budget!== undefined){
                budget_pratiche_settore= temp05_macro[k].budget;      
                
                budget_pratiche_settore=budget_pratiche_settore.replace(".", "");
                budget_pratiche_settore=budget_pratiche_settore.replace(/[^\d,-]/g, '');                                
                budget_pratiche_settore= parseFloat(budget_pratiche_settore);        
                totalToBePrinted=totalToBePrinted + budget_pratiche_settore;
            }
            for (var j = 0; j < temp06_no_macro.length; j++) {   
                if ((temp06_no_macro[j].obiettivo!== undefined)&&(temp06_no_macro[j].mission!== undefined)){    
                    if((temp06_no_macro[j].obiettivo!==temp06_no_macro[j].mission) && (temp06_no_macro[j].obiettivo===temp05_macro[k].obiettivo)){
                        numero1=numero1+temp06_no_macro[j].pratiche;  
                        if (temp06_no_macro[j].budget!== undefined){
                           var tmp_budget=temp06_no_macro[j].budget.replace(".", "");
                            tmp_budget=tmp_budget.replace(/[^\d,-]/g, '');                                
                            budget_pratiche_settore= budget_pratiche_settore- parseFloat(tmp_budget);
                        //console.log("qq66",budget_pratiche_settore);
                        }      
                    }
                }                
            }
            //console.log("er", temp05_macro[k].obiettivo, numero1, temp05_macro[k].pratiche);        
            for (var j = 0; j < temp06_no_macro.length; j++) {   
                if ((temp06_no_macro[j].obiettivo!== undefined)&&(temp06_no_macro[j].mission!== undefined)){    
                    if((temp06_no_macro[j].obiettivo===temp06_no_macro[j].mission) && (temp06_no_macro[j].obiettivo===temp05_macro[k].obiettivo)){
                        temp06_no_macro[j].praticheEsclusive= temp06_no_macro[j].pratiche - numero1;  
                        if (temp06_no_macro[j].budget!== undefined){
                        temp06_no_macro[j].budgetEsclusivo=formatMoney(budget_pratiche_settore);                                   
                        } 
                    }
                }                
            }

            
        }          
        setAiList(temp05_macro);
        setAllList(temp06_no_macro);      
        if(totalToBePrinted!=0)
            setToBePrinted(formatMoney(totalToBePrinted));

        const temp1= Object.entries(keyTopicList);
        var temp2 = [];
        
        temp1.forEach( entry => {        
                let value= entry[1];
                
                let ke=entry[0];
                let done=false;                
                for (var k = 0; k < temp2.length; k++) {                                                
                        if (value.odgkey === temp2[k].odgkey) {
                            //console.log("qq79bis dentro");
                            //15/05/22
                            temp2[k].counter=temp2[k].counter+1;                            
                            //temp2[k].counter=temp2[k].counter+ value.cardIds.length;
                            //07102022
                            temp2[k].availableNotes=temp2[k].availableNotes+entry[1]["cardIds"].length;                            
                            //07102022
                            temp2[k].elements.push(ke);
                            done=true
                        }
                }
                
                if(done==false){
                    //console.log("qq180", value,entry);                                            
                    var tempItem2=[];
                    tempItem2.odgkey=value.odgkey;
                    tempItem2.label=updateOdgLabel(value.odgkey);
                    tempItem2.color=value.color;
                    //15/05/22
                    //tempItem2.counter=1;                    
                    //tempItem2.counter=0;
                    //07102022 INIZIO 
                    if(value.cardIds.length>0)
                        tempItem2.counter=1;
                    else
                        tempItem2.counter=0;
                    //07102022 FINE    
                    //tempItem2.counter=value.cardIds.length;

                    tempItem2.elements=[];                    
                    tempItem2.availableNotes=entry[1]["cardIds"].length;                                        
                    tempItem2.elements.push(entry[0]);
                    temp2.push(tempItem2);
                    console.log("qq181", temp2);
                }                      
        }); 
        console.log("WW",temp05_macro);
        setDataDonut(updateDonutData(temp05_macro));    
        setTopicList(temp2);
        
        if (window.cordova && window.downloadBarChart)
        {
            var onError = function()
            {
              setBarAvailable(false);
              setLoading(false);
            }

            var onSuccess = function(r)
            {
              var json = JSON.parse(r);
              console.log("GBC cordova", r);
              
              if (Object.keys(json).length != 0)
              {
                dataBarGeneral.labels=json.labels;        
                dataBarGeneral.datasets[0].data=json.Stanziato;
                dataBarGeneral.datasets[1].data=json.Deliberato;
                dataBarGeneral.datasets[2].data=json.RisorseAltriEsercizi;            
                
                dataBarCultura.labels=json.Missioni[0].labels;        
                dataBarCultura.datasets[0].data=json.Missioni[0].Stanziato;
                dataBarCultura.datasets[1].data=json.Missioni[0].Deliberato;
                dataBarCultura.datasets[2].data=json.Missioni[0].RisorseAltriEsercizi;
                
                
                dataBarPersone.labels=json.Missioni[1].labels;             
                dataBarPersone.datasets[0].data=json.Missioni[1].Stanziato;
                dataBarPersone.datasets[1].data=json.Missioni[1].Deliberato;
                dataBarPersone.datasets[2].data=json.Missioni[1].RisorseAltriEsercizi;

                dataBarPianeta.labels=json.Missioni[2].labels;               
                dataBarPianeta.datasets[0].data=json.Missioni[2].Stanziato;
                dataBarPianeta.datasets[1].data=json.Missioni[2].Deliberato;
                dataBarPianeta.datasets[2].data=json.Missioni[2].RisorseAltriEsercizi;
                setDataBar(dataBarGeneral);                   
                setAnno(json.annoBudget);
                setBarAvailable(true);
                setLoading(false); 
              }
              else {
                setBarAvailable(false);
                setLoading(false); 
              }                       
            }
            window.downloadBarChart(meetingId,onSuccess,onError);
        }
        else {
          getBarCharData(meetingId);
        }
    }


    const doPostSeduta2 = function() {
    //console.log("doing sedute post");
    var xhr2 = new XMLHttpRequest();                
    xhr2.open('POST', properties.productionUrl+'/rest/checkSeduta',true);
    xhr2.setRequestHeader("Content-type", "application/x-www-form-urlencoded");    
    xhr2.onload = function (e) {      
      if (xhr2.readyState === 4) {
        if (xhr2.status === 200) {                              
            //console.log("OK92",xhr2);
            var responseText = xhr2.responseText;                    
            var obj=JSON.parse(responseText);
            //console.log("OK93",obj);          
            if(obj.status==="204" ){ 
                console.log("OK DOPOSTSEDUTE2 204",xhr2);                        
                const MeetingInfo = JSON.parse(localStorage.getItem("meetingContent"));                        
                updateUI(MeetingInfo);
            }              
            else{
                //status 201
                //console.log("OK95",obj.date);
                console.log("OK DOPOSTSEDUTE2 201",xhr2);                       

                try{
                    localStorage.setItem("meetingContent", obj.content);
                    localStorage.setItem("meeting", meetingId);
                    localStorage.setItem("meetingVersion", obj.date);                                                                                                                                                                                        
                }
                catch(error) {
                    console.log("LOCALSTORAGE ISSUE", error);
                }   
                
                const MeetingInfo = JSON.parse(obj.content);                
                updateUI(MeetingInfo);
            }
        } 
        else {
          console.error("Error 1", xhr2.statusText);          
          //alert("Errore recuperando la seduta");  
          alert("Errore recuperando la seduta richiesta 1");
          /*history.replace({                                
            pathname: "/init",
        });*/
        navigate("/init",{ replace: true });  
        }
      }
    };

    xhr2.onerror = function (e) {
      console.error("Error 22", xhr2.statusText);
    };
    
    let urlEncodedData = "idf=" + meetingId;
    if (localStorage.meeting===meetingId){            
        //console.log( "QUINDICI00");
        const currentData=localStorage.getItem("meetingVersion");        
        urlEncodedData = urlEncodedData+ "&date=" + currentData;                    
        }    
    //console.log("TL6",urlEncodedData);    
    xhr2.send(urlEncodedData);
}


    

    useEffect(() => {
         if(process.env.NODE_ENV !='development' && !window.cordova){
            
        //CHECK AUTHENTICATION
        var xhr = new XMLHttpRequest();                
        xhr.open('POST', properties.productionUrl+'/rest/ping',true);
        xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
    
        xhr.onload = function (e) {
            if (xhr.readyState === 4) {
                if (xhr.status === 200) {                    
                    console.log("OK",xhr);
                    updateSeduta(meetingId,doPostSeduta2,updateUI);
                } else {
                    console.error("Error 1", xhr);   
                    localStorage.clear();                 
                    /*history.push({                
                    pathname: "/login",
                    });*/
                    navigate("/login");  
                }
            }
        };
    
        xhr.onerror = function (e) {
          console.error("Error 2", xhr);
          localStorage.clear();
          /*history.push({                
                    pathname: "/login",
                    });*/
            navigate("/login");  
        };
             
        let urlEncodedData = null; 
        xhr.send(urlEncodedData);
        //CHECK AUTHENTICATION
        }
        
        if (window.cordova)
        {
            updateSeduta(meetingId,doPostSeduta2,updateUI);
        }
        
        /*setPageRouter({
            router: history.location.pathname,
            homecolor: "#FF5A60",
            settingcolor: "#879497",
            homebordercolor: "#FF5A60",
            settingbordercolor: "#FFFFFF",
            hometitlecolor: "black",
            settingtitlecolor: "#879497",
        }); */
        //console.log("ww",dataDonut);
    }, []);

    const accordionClick = (ref) => {
        //console.log("Accordion Click", ref);
        if(ref.startsWith("macro")){
            
            /*history.push({
                pathname:"/fascicoli/"+ meetingId+"/proposteintervento/"+ ref,
                });*/
            navigate("/fascicoli/"+ meetingId+"/proposteintervento/"+ ref);  

        }
            else{
            /*history.push({
                pathname:"/fascicoli/"+ meetingId+"/proposteintervento/missioni/"+ ref,
                });
                */
                navigate("/fascicoli/"+ meetingId+"/proposteintervento/missioni/"+ ref);  
            }
        
    }

    const accordionSummaryClick = (obiettivo, ref) => {
        //console.log("Accordion Summary Click", ref, obiettivo);
        var counter=0;
        allList.forEach( entry => {          
            if(entry.obiettivo===obiettivo)
                counter=counter+1;  
        });
        
        if (counter <=1){
            //MODIFICA PER GESTIRE SETTORI VECCHI ED IN PARTICOALRE ATTITIVITA ISTITUAZIONALI
            //if(ref.startsWith("macro")||ref === 'attivitaistituzionali' || ref=== 'innovazioneimpatto' || ref==='pianificazionestudi'||ref==='praticheevidenza'){
                if(ref.startsWith("macro") || ref=== 'innovazioneimpatto' || ref==='pianificazionestudi'||ref==='praticheevidenza'){
            /*history.push({
                pathname:"/fascicoli/"+ meetingId+"/proposteintervento/"+ ref,
                });
                */
            navigate("/fascicoli/"+ meetingId+"/proposteintervento/"+ ref);      
        }
            else{
            /*history.push({
                pathname:"/fascicoli/"+ meetingId+"/proposteintervento/missioni/"+ ref,
                });*/
            navigate("/fascicoli/"+ meetingId+"/proposteintervento/missioni/"+ ref);      
            }
        }
        else{
             if(ref=== 'innovazioneimpatto' || ref==='pianificazionestudi'){
                /*history.push({
                pathname:"/fascicoli/"+ meetingId+"/proposteintervento/"+ ref,
                });*/
                navigate("/fascicoli/"+ meetingId+"/proposteintervento/"+ ref);      
            }
        }
    
    }


    const topicArgument = (index,counter,elements,availableNotes) => {
        console.log("rec-index",index);
        console.log("rec-index",counter);
        console.log("topicList",elements);        
        console.log("Notes",availableNotes);        
        /*15/05/2022     
         if(counter >1){
            history.push({
                pathname:"/fascicoli/"+ meetingId+"/preview/"+ index,
                });
         }
         else{
            if(availableNotes>0){
                history.push({
                    pathname:"/fascicoli/"+ meetingId+"/"+ elements[0],
                });
            }
            else
            {
                 alert("Non sono stati caricati approfondimenti per l'argomento selezionato.");                
            }
         }
         */
         if(availableNotes >0){
            /*history.push({
                pathname:"/fascicoli/"+ meetingId+"/preview/"+ index,
                });*/
                navigate("/fascicoli/"+ meetingId+"/preview/"+ index);      
         }
         else{
            alert("Non sono stati caricati approfondimenti per l'argomento selezionato.");                
           
         }
    };

    const onFabEvent= (e ) => {                   
        //console.log("ew2",e,dataBar); 
        missioni=0;
        setDataBar(dataBarGeneral);              
    };

    const chartRef = useRef();

    const onClick = (event: MouseEvent<HTMLCanvasElement>) => {
        const { current: chart } = chartRef;    
        //console.log("qq47", event);
        if (!chart) {
        return;
        }
        const points= chart.getElementsAtEventForMode(event, 'nearest', { intersect: true }, true);
        const firstPoint = points[0];
        var unouno=chart.data.datasets[0].altro_val[firstPoint.index];
        console.log("VALUE",unouno);        
        if((unouno==="ricercaistruzione")|| (unouno==="sanita")|| (unouno==="patrimonioartistico")||(unouno==="attivitaculturali")||
        (unouno==="politichesociali")|| (unouno==="volontariato")|| (unouno==="torinoalpi")|| (unouno==="polo900")||
        (unouno==="primainfanzia")|| (unouno==="arteattivitabeniculturali")|| (unouno==="innovazioneculturale")||
        (unouno==="filantropiaterritorio")|| (unouno==="programmahousing")|| (unouno==="lasciti")|| (unouno==="programmiContainer")||
        (unouno==="pianostrategico")|| (unouno==="innoimpatto")|| (unouno==="interaffairs")|| (unouno==="attivitaistituzionali")){
            /*history.push({
                pathname:"/fascicoli/"+ meetingId+"/proposteintervento/missioni/"+ chart.data.datasets[0].altro_val[firstPoint.index],
            });*/
            navigate("/fascicoli/"+ meetingId+"/proposteintervento/missioni/"+ chart.data.datasets[0].altro_val[firstPoint.index]);  
            

        }
        else{
           /*history.push({
                pathname:"/fascicoli/"+ meetingId+"/proposteintervento/"+ chart.data.datasets[0].altro_val[firstPoint.index],
            });*/
            navigate("/fascicoli/"+ meetingId+"/proposteintervento/"+ chart.data.datasets[0].altro_val[firstPoint.index]);  
        }
    };


    return (
        <div key={uuidv4()}> 
        {loading ? (
          <div key={uuidv4()} style={{ width:"25%",   display: "flex", 
          marginLeft: "auto", marginRight: "auto" }}> 
           <ThreeDots color="#30720f" height='350' width='150'/>
           </div> 
          ):(

         <div className="content21" >
         {barAvailable ? (   
         <Grid container spacing={3} alignItems="stretch">
         
        
         <Grid item xs={12} sm={4}>
            
            <p className= "textBlackSedute">
                        Argomenti di discussione
            </p>
            {topicList.map((item, index) => (
                <div
                    className="common-list2"
                    key={index}     

                    onClick={() => topicArgument(item.odgkey,item.counter,item.elements,item.availableNotes)}
                    style={{                    
                        cursor: "pointer",
                        paddingLeft: "20px",
                        height: "48px",
                        marginBottom: "10px",
                    }}
                >               
                    <div className="column" style={{  width: "75%", padding: "0px",}}>  
                    <p className= "textBlackSedute">
                        {item.label}
                    </p>
                    </div>
                    <div className="column" style={{  width: "15%", padding: "0px",}}>  
                    <p className= "textBlackSedute">
                        {item.counter}
                        
                    </p>
                    </div>
                    <div className="column" style={{  width: "5%", padding: "0px",}}>  
                    
                    <div
                            style={{
                                display: "flex",
                                marginLeft: "10px",
                                color:"black",
                                width: "15px",
                                height: "46px",
                                background: item.color,                                
                                alignItems: "center",
                                justifyContent: "center",
                                borderBottomRightRadius: "8px",
                                borderTopRightRadius: "8px",
                            }}
                        >
                        </div>
                    </div>

 
                </div>
            ))}
            <br />
            {dataDonut.labels.length>0 ?(                                
                <div>                
            <p className= "textBlackSedute">
                        Budget Delibera Odierna - Attività Istituzionale
            </p>
            <div className="chartContainer">
                <Doughnut data={dataDonut} plugins={[ChartDataLabels]} options = {optionsDon}
                onClick={onClick} ref={chartRef}/>
                
                <div className="chartInner">
                    <div className="textBlackBold">{toBePrinted}</div>
                </div>                
                </div>
            </div>
            ):(<></>)}            
            </Grid>
    
    <br /> 
    <Grid item xs={12} sm={8}>
            <p className= "textBlackSedute">
                        Situazione Fondi {anno} - {labelFondi}
            </p>
            {missioni==1 ?(
            <Fab aria-label="add" size="small"                
             //variant="contained" 
             onClick={(event) => {onFabEvent(event) }}
             
             style={{
                 margin: 0,
                 //background: "#30720f",
                 background: "#C0C0C0",
                 top: 'auto',
                 right: 80,
                 top: 150,
                 //color: '0xFF0000',
                 left: 'auto',
                 position: 'fixed',}}>                    
                 <AddIcon />
                 {/*<input id="fileupload00" type="file" name="file" ref={hiddenFileUpload2} onChange={(event)=> onFileChange2(event)} style={{ display: "none" }}/>*/}
             </Fab>  
             ):(<></>)}
         <Bar options={optionsBar} data={dataBar}>
               
             </Bar> 

         <br /> 
         <>
         {aiList.length>0 ? (                         
             console.log("AI",aiList),
             <p className= "textBlackSedute" style={{marginBottom:"20px"}}>
                 Delibera Odierna - Attività Istituzionale
            </p>
            ):( <p className= "textBlackSedute" style={{marginBottom:"0px", paddingLeft:"40px"}}>
            Non sono presenti Proposte di Intervento
            </p>
            )}
            {aiList.map((item, index) => (
                <div
                    className="common-list2"
                    key={index}     

                    style={{                    
                        cursor: "pointer",                             
                        background: "#f5f5f5",
                    }}
                >                                                
                {(item.mission === item.obiettivo) || (item.obiettivo ==='In evidenza') || (item.obiettivo ==='Indice') ||(item.obiettivo==='Convenzioni, progetti speciali, ulteriori') || (item.obiettivo ==='Progetti speciali') || (item.obiettivo ==='Direzione')? (  
                <div className="row" style={{   width: "100%",  marginBottom: "10px",}}>              
                <Accordion elevation={3} style={{  background: "#FFFFFF", boxShadow: "unset",  borderBottomRightRadius: "8px", borderTopRightRadius: "8px"}} >
                <AccordionSummary aria-controls="panel1a-content" style={{ padding: "0px", flexDirection: "row-reverse", height: "43px", }} >
                <Typography component={'div'} className={classes.heading} style={{ paddingLeft: "5px", margin: "0px", width: "100%"}} onClick={() => accordionSummaryClick(item.obiettivo, item.ref)}>                                        
                    <div className="column" style={{  width: "57%", padding: "0px",}}>    
                        <p className= "textBlackSedute" style={{paddingLeft:"20px"}}>
                            {   (item.ref === 'attivitaistituzionali' || item.ref=== 'innovazioneimpatto' || item.ref==='pianificazionestudi') ?(
                                 item.obiettivo + " "+ item.mission.toLowerCase() ): (item.obiettivo)
                            }
                        </p>
                    </div>                    
                    <div className="column" style={{  width: "30%", padding: "0px"}}>    
                        <p className= "textBlackSedute" style={{textAlign: 'center'}}>
                            { item.budget >0 ?(
                                formatMoney(item.budget)): (null)
                            }
                        </p>
                    </div>                    
                    <div className="column" style={{  width: "10%", padding: "0px",}}>    
                        <p className= "textBlackSedute">
                            {item.pratiche}
                        </p>
                    </div>                   
                    <div className="column" style={{ width: "3%", padding: "0px",}}>    
                         <div
                            style={{
                                marginTop: "5px",
                                marginLeft: "10px",
                                width: "15px",
                                height: "46px",
                                background: item.color,
                                borderBottomRightRadius: "8px",
                                borderTopRightRadius: "8px",                                                       
                            }}
                        ></div>
                    </div>                      
                </Typography>
                </AccordionSummary>           
                <AccordionDetails style={{ padding: "0px", width:"100%" }}  onClick={(event) => event.stopPropagation()}>
               
                     {allList.map((subitem, index2) => (                                
                                
                                <div key={index2} style={{ margin: "0px", width: "100%",        display: "flex",
                                            justifyContent: "space-between",
                                            alingItems: "center",}}>
                                 

                                 {(item.obiettivo===subitem.obiettivo && item.ref!=='attivitaistituzionali' && item.ref!=='praticheevidenza' && item.ref!== 'innovazioneimpatto'&& item.ref!=='pianificazionestudi' && (subitem.budgetEsclusivo!== '0.00 €'  && subitem.praticheEsclusive!==0)) ? (

                             <Typography component={'div'} className={classes.heading} style={{ paddingLeft: "40px", margin: "0px", width: "100%"}} onClick={() => accordionClick(subitem.ref)} >
                   
                    <div className="column" style={{  width: "57%", padding: "0px",}}>    
                        <p className= "textBlackSedute">
                            {subitem.mission}
                        </p>
                    </div>

                    <div className="column" style={{  width: "30%", padding: "0px"}}>    
                        <p className= "textBlackSedute" style={{textAlign:"center"}}>
                            { subitem.budgetEsclusivo !== undefined
                                  ?(
                                subitem.budgetEsclusivo): ('0,00 €')
                            }
                        </p>
                    </div>                    
                    <div className="column" style={{  width: "10%", padding: "0px",}}>    
                        <p className= "textBlackSedute">
                            {subitem.praticheEsclusive}
                        </p>
                    </div>                   
                    <div className="column" style={{ width: "3%", padding: "0px",}}>    
                         <div
                            style={{
                                marginTop: "5px",
                                marginLeft: "10px",
                                width: "15px",
                                height: "46px",
                                background: subitem.color,
                                borderBottomRightRadius: "8px",
                                borderTopRightRadius: "8px",                                                       
                            }}
                        ></div>
                    </div>                      
                </Typography>
                        ) : (
                            <></>
                        )}
                                    
                                    
                                </div>
                          
                            ))}
                


                </AccordionDetails>
                </Accordion>
                </div>  
                ): (<></>)}
                    
                </div>
            ))}
            </>
         </Grid>
                            
  
    
    <Grid item xs={12} sm={4}>
    
            </Grid>
   
            <Grid item xs={12} sm={8}>
            
            </Grid>
          
            </Grid>
         ):(
            <Grid container spacing={3} alignItems="stretch">
                   <Grid item xs={12} sm={4}>            
                        <p className= "textBlackSedute">
                                    Argomenti di discussione
                        </p>
                        {topicList.map((item, index) => (
                            <div
                                className="common-list2"
                                key={index}     

                                onClick={() => topicArgument(item.odgkey,item.counter,item.elements,item.availableNotes)}
                                style={{                    
                                    cursor: "pointer",
                                    paddingLeft: "40px",
                                    height: "48px",
                                    marginBottom: "10px",
                                }}
                            >                    
                                  <div className="column" style={{  width: "75%", padding: "0px",}}>  
                                    <p className= "textBlackSedute">
                                        {item.label}
                                    </p>
                                    </div>
                                    <div className="column" style={{  width: "15%", padding: "0px",}}>  
                                    <p className= "textBlackSedute">
                                        {item.counter}
                                    </p>
                                    </div>
                                    <div className="column" style={{  width: "5%", padding: "0px",}}>  
                                    
                                    <div
                                            style={{
                                                display: "flex",
                                                marginLeft: "10px",
                                                color:"black",
                                                width: "15px",
                                                height: "46px",
                                                background: item.color,                                
                                                alignItems: "center",
                                                justifyContent: "center",
                                                borderBottomRightRadius: "8px",
                                                borderTopRightRadius: "8px",
                                            }}
                                        >
                                    </div>
                                </div>
                            </div>
                        ))} 
                        <br/>
                        {dataDonut.labels.length>0 ?(                    
                            <div>
                            <p className= "textBlackSedute">
                                    Budget Delibera Odierna
                            </p>
                            <div className="chartContainer">
                            <Doughnut data={dataDonut} plugins={[ChartDataLabels]} options = {optionsDon}
                            onClick={onClick} ref={chartRef}/>
                            
                            <div className="chartInner">
                                <div className="textBlackBold">{toBePrinted}</div>
                            </div>                
                            </div>
                            </div>
                        ):(<></>)} 
                        <br/>
            </Grid>
            <Grid item xs={12} sm={8}>
            <>
            {aiList.length>0 ? (
                console.log("DDDEEEEE",aiList),
            <p className= "textBlackSedute" style={{marginBottom:"20px"}}>
                        Delibera Odierna - Attività Istituzionale
            </p>
            ):(<p className= "textBlackSedute" style={{marginBottom:"0px", paddingLeft:"40px"}}>
            Non sono presenti Proposte di Intervento
            </p>
            )}
            {aiList.map((item, index) => (
                
                <div
                    className="common-list2"
                    key={index}     

                    style={{                    
                        cursor: "pointer",                             
                        background: "#f5f5f5",
                    }}>
                {(item.mission === item.obiettivo) || (item.obiettivo ==='In evidenza') || (item.obiettivo ==='Indice')  || (item.obiettivo ==='Convenzioni, progetti speciali, ulteriori')||(item.obiettivo ==='Progetti speciali') || (item.obiettivo ==='Direzione')? (                                  
                <div className="row" style={{   width: "100%",  marginBottom: "10px",}}>              
                <Accordion elevation={3} style={{  background: "#FFFFFF", boxShadow: "unset",  borderBottomRightRadius: "8px", borderTopRightRadius: "8px"}} >
                <AccordionSummary aria-controls="panel1a-content" style={{ padding: "0px", flexDirection: "row-reverse", height: "43px", }} >
                <Typography component={'div'} className={classes.heading} style={{ paddingLeft: "5px", margin: "0px", width: "100%"}} onClick={() => accordionSummaryClick(item.obiettivo, item.ref)}>
                                        
                    <div className="column" style={{  width: "57%", padding: "0px",}}>    
                        <p className= "textBlackSedute" style={{paddingLeft:"20px"}}>
                            {   (item.ref === 'attivitaistituzionali' || item.ref=== 'innovazioneimpatto' || item.ref==='pianificazionestudi') ?(
                                 item.obiettivo + " "+ item.mission.toLowerCase() ): (item.obiettivo)
                            }
                        </p>
                    </div>
                    
                    <div className="column" style={{  width: "30%", padding: "0px"}}>    
                        <p className= "textBlackSedute" style={{textAlign: 'center'}}>
                            { item.budget >0 ?(
                                formatMoney(item.budget)): (null)
                            }
                        </p>
                    </div>                    
                    <div className="column" style={{  width: "10%", padding: "0px",}}>    
                        <p className= "textBlackSedute">
                            {item.pratiche}
                        </p>
                    </div>                   
                    <div className="column" style={{ width: "3%", padding: "0px",}}>    
                         <div
                            style={{
                                marginTop: "5px",
                                marginLeft: "10px",
                                width: "15px",
                                height: "46px",
                                background: item.color,
                                borderBottomRightRadius: "8px",
                                borderTopRightRadius: "8px",                                                      
                            }}
                        ></div>
                    </div>                      
                </Typography>
                </AccordionSummary>           
                <AccordionDetails style={{ padding: "0px", width:"100%" }}  onClick={(event) => event.stopPropagation()}>               
                     {allList.map((subitem, index2) => (                                
                                
                                <div key={index2} style={{ margin: "0px", width: "100%",        display: "flex",
                                            justifyContent: "space-between",
                                            alingItems: "center",}}>
                                 

                                 {(item.obiettivo===subitem.obiettivo && item.ref!=='attivitaistituzionali' && item.ref!=='praticheevidenza' && item.ref!== 'innovazioneimpatto'&& item.ref!=='pianificazionestudi' && (subitem.budgetEsclusivo!== '0.00 €'  && subitem.praticheEsclusive!==0)) ? (

                             <Typography component={'div'} className={classes.heading} style={{ paddingLeft: "40px", margin: "0px", width: "100%"}} onClick={() => accordionClick(subitem.ref)} >
                   
                    <div className="column" style={{  width: "57%", padding: "0px",}}>    
                        <p className= "textBlackSedute">
                            {subitem.mission}
                        </p>
                    </div>

                    <div className="column" style={{  width: "30%", padding: "0px"}}>    
                        <p className= "textBlackSedute" style={{textAlign:"center"}}>
                            { subitem.budgetEsclusivo !== undefined
                                  ?(
                                subitem.budgetEsclusivo): ('0,00 €')
                            }
                        </p>
                    </div>                    
                    <div className="column" style={{  width: "10%", padding: "0px",}}>    
                        <p className= "textBlackSedute">
                            {subitem.praticheEsclusive}
                        </p>
                    </div>                   
                    <div className="column" style={{ width: "3%", padding: "0px",}}>    
                         <div
                            style={{
                                marginTop: "5px",
                                marginLeft: "10px",
                                width: "15px",
                                height: "46px",
                                background: subitem.color,
                                borderBottomRightRadius: "8px",
                                borderTopRightRadius: "8px",                                                       
                            }}
                        ></div>
                    </div>                      
                </Typography>
                        ) : (
                            <></>
                        )}
                                    
                                    
                                </div>
                          
                            ))}
                


                </AccordionDetails>
                </Accordion>
                </div>  
                ): (<></>)}
                    
                </div>
            ))}
            </>
            </Grid>     
    <br /> 
            </Grid> 
         )}
        </div>
         )
        }
        </div>
    );
}
