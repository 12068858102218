import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
/*import {
  Typography, List, ListItem, ListItemIcon,
  ListItemText, TextField, Collapse, Menu, MenuItem
} from '@mui/material';
*/
import { Fab, Tooltip } from '@material-ui/core/';
import RefreshIcon from '@material-ui/icons/Refresh';
import {  Typography, List, ListItem, ListItemIcon,
  ListItemText, TextField, Collapse, Menu, MenuItem } from '@material-ui/core/';
//import { Folder, InsertDriveFile, CreateNewFolder } from '@mui/icons-material';
import Folder from '@material-ui/icons/Folder';
import InsertDriveFile from '@material-ui/icons/InsertDriveFile';
//import CreateNewFolder from '@material-ui/icons/CreateNewFolder';
//import DeleteIcon from '@mui/icons-material/Delete';
import DeleteIcon from '@material-ui/icons/Delete';
//import EditIcon from '@mui/icons-material/Edit';
import EditIcon from '@material-ui/icons/Edit';
//import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandLess from '@material-ui/icons/ExpandLess';
//import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandMore from '@material-ui/icons/ExpandMore';
import SweetAlert from "react-bootstrap-sweetalert";
import PublishIcon from '@material-ui/icons/Publish';
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';
import axios from 'axios';
import { AiFillFilePdf } from "react-icons/ai";
import { AiFillFileImage } from "react-icons/ai";
import { AiFillFile } from "react-icons/ai";
import { AiFillFilePpt } from "react-icons/ai";
import { AiFillFileWord } from "react-icons/ai"
import { AiFillFileExcel } from "react-icons/ai";

import { AiOutlineFilePdf } from "react-icons/ai";
import { AiOutlineFileImage } from "react-icons/ai";
import { AiOutlineFile } from "react-icons/ai";
import { AiOutlineFilePpt } from "react-icons/ai";
import { AiOutlineFileWord } from "react-icons/ai";
import { AiOutlineFileExcel } from "react-icons/ai";

import { AiFillFolderOpen } from "react-icons/ai";
import { AiFillFolder } from "react-icons/ai";
//import { properties } from "../utils/properties";
import {properties} from "utils/properties";
import {ThreeDots} from 'react-loader-spinner';
import { red } from '@material-ui/core/colors';
import { ConsoleView } from 'react-device-detect';

const FileExplorer = () => {
  const [fileTree, setFileTree] = useState({ folders: {}, files: [] });
  const [newFolderName, setNewFolderName] = useState('');
  const [currentDirectory, setCurrentDirectory] = useState('/');
  const [contextMenu, setContextMenu] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [openFolders, setOpenFolders] = useState({});
  const [draggedItem, setDraggedItem] = useState(null);  
  const [isEditing, setIsEditing] = useState(null); 
  const [editedName, setEditedName] = useState(''); 
  const [loading, setLoading]= useState(true);
  const [alert, setAlert] = useState(null);
  //const inputRef = useRef(null);
  
  const navigate = useNavigate();



  const showAlert = (message) => {
    setAlert(
      <SweetAlert
      warning
      closeOnClickOutside ={true}
      //showCancel ='true'
      showConfirm={false}
      style={{ display: "block", marginTop: "auto", marginBottom: "auto" }}
      //title="Esiste già un file con lo stesso nome, modificare il nome del file prima di caricarlo"
      title={message}
      onConfirm={() => setAlert(null)}           
      onCancel={() => setAlert(null)}           
      //confirmBtnText="Si"    
      //confirmBtnStyle={{fontSize:"24px",fontWeight:'bold'}}
      //cancelBtnText="No"    
      //cancelBtnStyle={{fontSize:"24px",fontWeight:'bold'}}
      //confirmBtnBsStyle ='success' 
      //btnSize='lg'
    >
    </SweetAlert>
    );
  };


  useEffect(() => {
    console.log("TEST");
    if(process.env.NODE_ENV !=='development' && !window.cordova){
            
      //CHECK AUTHENTICATION
          var xhr = new XMLHttpRequest();                
          xhr.open('POST', properties.productionUrl+'/rest/ping',true);
          xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
  
          xhr.onload = function (e) {
              if (xhr.readyState === 4) {
                  if (xhr.status === 200) {                                      
                    
              } else {
                  console.error("Error 1", xhr);     
                  localStorage.clear();               
                  //window.localStorage.clear();
                  console.log("QWERTY 1",localStorage.getItem("name"));
                  /*history.push({                
                  pathname: "/login",
                  });*/
                  navigate("/login");    
              }
          }
          };
  
          xhr.onerror = function (e) {
              console.error("Error 2", xhr);                                
              //window.localStorage.clear();
              localStorage.clear();       
              console.log("QWERTY 2",localStorage.getItem("name"));
              /*history.push({                
                  pathname: "/login",
                  });*/
                  navigate("/login");    
          };
           
          let urlEncodedData = null; 
          xhr.send(urlEncodedData);
      //CHECK AUTHENTICATION
      }
    fetchFiles();  
   
  }, [currentDirectory]);
/*
  const fetchFiles = async () => {
    try {      
      //const endpoint = `${properties.productionUrl}/php/core/rest/list.php`;
      const endpoint = `${properties.productionUrl}/php/core/rest/fmFunctions.php`;
      const formData = new FormData();              
      formData.append('action', "list");
      const response = await axios.post(endpoint,formData, { withCredentials: true });

      const data = response.data;
      
      if (data && typeof data === 'object') {
        //console.log("DATA",data);
        setFileTree(data);
        const initialOpenFolders = {};
        //console.log("DDD",data);
        const setInitialFolders = (node) => {
          Object.entries(node.folders).forEach(([folder,value]) => {
            //console.log("II",folder,value, value.fullPath);
            initialOpenFolders[value.fullPath] = true; 
            setInitialFolders(node.folders[folder]); 
          });
        };
        setInitialFolders(data);
        setOpenFolders(initialOpenFolders);
        setLoading(false);
      } else {
        console.error("Invalid data format");
      }
    } catch (error) {
      console.error("Error fetching files", error);
    }
    //console.log("FILETREE", fileTree);
    //console.log("OPENFOLDERS", openFolders);
  };
  */

  
  const fetchFiles = async () => {
    console.log("FECTCH FILES");
    const formData = new FormData();              
    formData.append('action', "list");
    if (!window.cordova){
        try {      
          //const endpoint = `${properties.productionUrl}/php/core/rest/list.php`;
          const endpoint = `${properties.productionUrl}/php/core/rest/fmFunctions.php`;
        
          const response = await axios.post(endpoint,formData, { withCredentials: true });

          const data = response.data;
          

          if (data && typeof data === 'object') {
            //console.log("DATA",data);
            setFileTree(data);
            const initialOpenFolders = {};
            //console.log("DDD",data);
            const setInitialFolders = (node) => {
              Object.entries(node.folders).forEach(([folder,value]) => {
                //console.log("II",folder,value, value.fullPath);
                initialOpenFolders[value.fullPath] = true; 
                setInitialFolders(node.folders[folder]); 
              });
            };
            setInitialFolders(data);
            setOpenFolders(initialOpenFolders);
            setLoading(false);
          } else {
            console.error("Invalid data format");
          }
        } catch (error) {
          console.error("Error fetching files", error);
        }
    }
    else{
      console.log("CORDOVA");
      const bodyasString = new URLSearchParams(formData).toString();
      console.log("SETTINGS",bodyasString);

      var onSuccess = function(response){      
        console.log("on success",response);
        var obj=JSON.parse(response);
        
        //const data = response.data;   
        console.log("on success2",obj);       
        //const data = obj.data;   
        const data = obj;   
        console.log("on success3",data);
        if (data && typeof data === 'object') {
          console.log("on success3","into");
          //console.log("DATA",data);
          setFileTree(data);
          const initialOpenFolders = {};
          //console.log("DDD",data);
          const setInitialFolders = (node) => {
            Object.entries(node.folders).forEach(([folder,value]) => {
              //console.log("II",folder,value, value.fullPath);
              initialOpenFolders[value.fullPath] = true; 
              setInitialFolders(node.folders[folder]); 
            });
          };
          setInitialFolders(data);
          setOpenFolders(initialOpenFolders);
          setLoading(false);  
      }
      else{
          console.error("Invalid data format");      
      }
    }
      var onError = function() {
          console.log("on error");
          setLoading(false);  
      }
      //const bodyasString = new URLSearchParams(bodyFormData).toString();
      
      window.getRepo(bodyasString,onSuccess, onError);
    }
  
    
  };


  const handleContextMenu = (event, item) => {
    event.preventDefault();
    setSelectedItem(item);
    setContextMenu({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
    });
  };

  const handleCloseContextMenu = () => {
    setContextMenu(null);
    setSelectedItem(null);
  };

  const handleDeleteFile = async () => {
    if (!selectedItem.name) return;
    console.log("HDFO",selectedItem.name, selectedItem);
    setLoading(true);
    const formData = new FormData();
        
    formData.append('dir', selectedItem.fullPath); 
    formData.append('id', selectedItem.id); 
    formData.append('action', "fileDelete"); 

    try {        
      //await axios.post(`${properties.productionUrl}/php/core/rest/deleteFolder.php`, formData, {
        await axios.post(`${properties.productionUrl}/php/core/rest/fmFunctions.php`, formData, {withCredentials: true}
      );
      fetchFiles(); 
      handleCloseContextMenu(); 
      setLoading(false);
    } catch (error) {
      //alert("Error deleting file.");
      showAlert("Errore cancellando il file selezionato");
      console.error("Error deleting file:", error);
      setLoading(false);
    }
  };


  const findFilePath = (tree, targetName, currentPath = []) => {
    
    if (tree.files && tree.files.some(file => file.name === targetName)) {
      return [...currentPath]; 
    }
  
    if (tree.folders && tree.folders.hasOwnProperty(targetName)) {
        return [...currentPath, targetName]; 
    }
    
    if (tree.folders) {
      for (let folderName in tree.folders) {
        const result = findFilePath(tree.folders[folderName], targetName, [...currentPath, folderName]);
        if (result) {
          return result;
        }
      }
    }
  
    return null; // return null if the file isn't found
  };

  const handleDeleteFolder = async () => {
    //console.log("HDF",selectedItem.fullPath);
    if (!selectedItem.name) return;
    console.log("HDFO",selectedItem.name, selectedItem);
    setLoading(true);
    const formData = new FormData();
    
    //formData.append('dir', pathToBeDeleted); 
    formData.append('dir', selectedItem.fullPath); 
    formData.append('id', selectedItem.id); 
    formData.append('action', "folderDelete"); 

    try {        
      //await axios.post(`${properties.productionUrl}/php/core/rest/deleteFolder.php`, formData, {
        await axios.post(`${properties.productionUrl}/php/core/rest/fmFunctions.php`, formData, {withCredentials: true}
      );
      fetchFiles(); // Refresh the file tree
      handleCloseContextMenu(); // Close the context menu after deletion
      setLoading(false);
    } catch (error) {
      showAlert("Errore cancellando la cartella: la cartella deve essere vuota per poter essere cancellata");
      //alert("Error deleting folder. It may not be empty.");
      console.error("Error deleting folder:", error);
      setLoading(false);
    }
  };
  

  const handleDragStart = (item) => {
    setDraggedItem(item);
  };

  const handleDrop = async (event, targetFolder) => {    
    setLoading(true);
    console.log("FILE DOPPED", event,targetFolder,draggedItem);
    event.preventDefault();
    if (!draggedItem) return;
    //console.log("2323",Array.from(event, targetFolder));
    //console.log("2324",Array.from(event.dataTransfer.files));    
    try {
        const formData = new FormData();
        formData.append('name', targetFolder.fullPath);
        formData.append('dir', draggedItem.fullPath);
        formData.append('id', draggedItem.id);  
        formData.append('action', "fileMove");     
        //await axios.post(`${properties.productionUrl}/php/core/rest/moveFile.php`, formData, {
        //    headers: { 'Content-Type': 'multipart/form-data' }
        
        await axios.post(`${properties.productionUrl}/php/core/rest/fmFunctions.php`, formData, {withCredentials: true}
        );                
        setDraggedItem(null);
        fetchFiles(); 
        handleCloseContextMenu(); 
        setLoading(false);
      } catch (error) {
        console.error("Error renaming item", error);
        showAlert("Esiste già un file con lo stesso nome nella cartella di destinazione, modificare il nome del file prima di caricarlo");
        setLoading(false);
      }          
  };

const handleMenuItemClick  = async function(e,item) {           
    //console.log("HMIC",item,e);
    //console.log("CD", currentDirectory,`${currentDirectory}${item.name}/`);
    const formData = new FormData();
    var cd=`${currentDirectory}${item.name}/`;    
    formData.append('folderName', 'Nuova Cartella');    
    formData.append('dir', item.fullPath);
    formData.append('id', item.id);
    formData.append('action', "folderCreate");
    try {
      //await axios.post(`${properties.productionUrl}/php/core/rest/createFolder.php`, formData, {
      //  headers: { 'Content-Type': 'multipart/form-data' }
      //});
      await axios.post(`${properties.productionUrl}/php/core/rest/fmFunctions.php`, formData, { withCredentials: true }
        );
      setNewFolderName('');
      fetchFiles();
      handleCloseContextMenu(); 
    } catch (error) {
      console.error("Error creating folder", error);
    }
    //setCurrentDirectory(`${currentDirectory}${item.name}/`);    
    //e.isDir, e.name
    e.stopPropagation();      
    //createFolder();
};

  const handleFolderClick = (folderName, fullPath, id) => {

    console.log("HANDLEFOLDERCLiK",folderName, fullPath, id, isEditing,openFolders);
    if (isEditing && isEditing.id === id &&isEditing.fullPath===fullPath) return;
    setOpenFolders((prevOpenFolders) => ({
      ...prevOpenFolders,
      [fullPath]: !prevOpenFolders[fullPath], 
    }));
  };

  const handleFileClick = (fullPath, id) => {
    console.log("HANDLEFileCLiK", fullPath, id);
    window.open(properties.productionUrl+"/fileManager/files/fm24"+fullPath);
    //let endpoint = properties.productionUrl+"/php/core/rest/downloadFile.php";    
    /*axios.get(endpoint, { withCredentials: true , 
      params: {
        id: id,
        filename: fullPath
      }
    })
      .then(function (response) {
        console.log(response);
        window.open(response,'_blank');
      })
      .catch(function (error) {
        console.log(error);
      });
    */
  };

const handleCloseEditing= (event) => {
  console.log("CALLED CLOSE EDITING", isEditing);
  event.stopPropagation();
  event.preventDefault();
  //isEditing?.id === file.id && isEditing?.fullPath===file.fullPath
  //if (selectedItem) {
  setIsEditing(null);      
  //  handleCloseContextMenu();      
  //}
};


  const handleRename = () => {
    if (selectedItem) {
      setIsEditing(selectedItem);  
      setEditedName(selectedItem.name);  
      handleCloseContextMenu();      
    }
  };

  const handleRenameFileSubmit  = async () => {    
    if (!editedName || editedName === isEditing.name) {
      setIsEditing(null);  
      return;
    }
    setLoading(true);
    console.log("1", editedName.split('.').pop())
    console.log("2", isEditing.fullPath.split('.').pop())
    if(editedName.split('.').pop()!=isEditing.fullPath.split('.').pop()){
      showAlert("Non è possibile cambiare l'estensione del file!");
      setIsEditing(null);  
      fetchFiles(); 
      setLoading(false);
      return;
    }
    try {
      const formData = new FormData();
      formData.append('name', editedName);
      formData.append('dir', isEditing.fullPath);
      formData.append('id', isEditing.id);
      formData.append('action', "fileRename");
      
      await axios.post(`${properties.productionUrl}/php/core/rest/fmFunctions.php`, formData, { withCredentials: true }
      );
      //await axios.post('/rename.php', formData);  // Post to your backend
      setIsEditing(null);  
      fetchFiles();  
      handleCloseContextMenu(); 
      setLoading(false);
    } catch (error) {
      console.error("Error renaming item", error);
      showAlert("Esiste già un file con il nome utilizzato, scegliere un altro nome di file e riprovare");      
      setIsEditing(null);              
      fetchFiles();  
      handleCloseContextMenu(); 
      setLoading(false);
    }
  };


  const onFileChange= (e,item ) => {   
    setLoading(true);
    console.log("ew3",e); 
    console.log("ew4",item);
    
    
    e.stopPropagation(); 
    //let endpoint = properties.productionUrl+"/php/core/rest/uploadFile.php";                
    let endpoint = properties.productionUrl+"/php/core/rest/fmFunctions.php";                
    let file=e.target.files[0];
    var bodyFormData=new FormData();
        
    console.log("PROAT",file, item.fullPath);
    bodyFormData.append('UploadedFile', file);    
    bodyFormData.append('dir', item.fullPath);
    bodyFormData.append('id', item.id);
    bodyFormData.append('action', "fileUpload");
    
    axios({
        method: 'post',
        url: endpoint,
        data: bodyFormData, 
        withCredentials:true,
        headers: { 'Content-Type': 'multipart/form-data' }
    })
    .then(function (response) {                  
        console.log(response);
        e.target.files=null;
        e.target.value=null;
        fetchFiles();
        handleCloseContextMenu(); 
        setLoading(false);
    })
    .catch(function (response) {       
        showAlert("Esiste già un file con lo stesso nome o si sta cercando di caricare un file non supportato!");
        console.log(response);
        handleCloseContextMenu(); 
        setLoading(false);
    });
    
};

  const handleRenameSubmit = async () => {
    //console.log("RENAMING AND SETLOADING");
    setLoading(true);
    if (!editedName || editedName === isEditing.name) {
      setIsEditing(null);
      setLoading(false);  
      return;
    }

    try {
        const formData = new FormData();
        formData.append('name', editedName);
        formData.append('dir', isEditing.fullPath);
        formData.append('id', isEditing.id);
        formData.append('action', "folderRename");
        
        await axios.post(`${properties.productionUrl}/php/core/rest/fmFunctions.php`, formData, { withCredentials: true }
        );

      setIsEditing(null); 
      fetchFiles();  
      handleCloseContextMenu(); 
      setLoading(false);
    } catch (error) {
      console.error("Error renaming item", error);
      showAlert("Esiste già una cartella con lo stesso nome, scegliere un altro nome di file e riprovare");      
      setIsEditing(null);              
      fetchFiles();  
      handleCloseContextMenu(); 
      setLoading(false);



    }
  };



  function timeConverter(UNIX_timestamp){
    var a = new Date(UNIX_timestamp * 1000);
    var months = ['Gennaio','Febbraio','Marzo','Aprile','Maggio','Giugno','Luglio','Agosto','Settembre','Ottobre','Novembre','Dicembre'];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();
    var hour = a.getHours();
    if(hour<10)
      hour="0"+hour;
    var min = a.getMinutes();
    if(min<10)
      min="0"+min;
    var sec = a.getSeconds();
    if(sec<10)
      sec="0"+sec;
    var time = date + ' ' + month + ' ' + year + ' ,' + hour + ':' + min + ':' + sec ;
    return time;
  }

  const renderFileTree = (node, parentPath = '', level = 0) => {
    

    const autoScroll = (event) => {
      //console.log("AUTOSCROLL",event,window);
      const scrollMargin = 150; 
      const scrollSpeed = 10;  
  
      const { clientY } = event; 
      //const { innerHeight } = window;
      const { innerHeight } = window; 
  
      //const distanceFromTop = clientY;
      //const distanceFromBottom = innerHeight - clientY;

      
      if (clientY < scrollMargin) {
        window.scrollBy(0, -scrollSpeed);
        //console.log("AUTOSCROLL",event,window);        

      }
      
      if (clientY > innerHeight - scrollMargin) {
        window.scrollBy(0, scrollSpeed);
        //console.log("AUTOSCROLL",event,window);
      }
      
      /*
      if (distanceFromTop < scrollMargin && scrollY > 0) {
        window.scrollBy(0, -scrollSpeed);
      }
  
      if (distanceFromBottom < scrollMargin && (scrollY + innerHeight) < document.body.scrollHeight) {
        window.scrollBy(0, scrollSpeed);
      } */ 
    };

    return (
      <>
        {node.files.map(file => (
           
          <ListItem
            key={file.name}            
            
            onContextMenu={(event) => handleContextMenu(event, file)}
            draggable
            onDragStart={() => handleDragStart(file)}
            onDragOver={autoScroll} 
            //onClick={() => (isEditing?.id === file.id && isEditing?.fullPath===file.fullPath)? undefined : handleFileClick(file.fullPath,file.id)}
            //onClick={() => (isEditing?.id === file.id && isEditing?.fullPath===file.fullPath)? handleCloseEditing(file) : handleFileClick(file.fullPath,file.id)}
            onClick={() => (isEditing?.id === file.id && isEditing?.fullPath===file.fullPath)? undefined : handleFileClick(file.fullPath,file.id)}
            //onClick={() => handleFileClick(file.fullPath,file.id)}
            style={{ paddingLeft: `${level * 30}px` , cursor: 'pointer' }}  // Indentation
          >
            <ListItemIcon onClick={(event) => handleCloseEditing(event)}>
            
              {file.fullPath.endsWith(".pdf")?(
                <AiOutlineFilePdf size='2.2em' color='#4A4C4D'/>
              )
              : file.fullPath.endsWith(".jpg") ||file.fullPath.endsWith(".jpeg")||file.fullPath.endsWith(".png")||file.fullPath.endsWith(".gif")?(                
                <AiOutlineFileImage size='2.2em' color='#4A4C4D'/>
              )
              : file.fullPath.endsWith(".ppt") ||file.fullPath.endsWith(".pptx")?(                                
                <AiFillFilePpt size='2.2em' color='#4A4C4D'/>
              )
              : file.fullPath.endsWith(".doc") ||file.fullPath.endsWith(".docx")?(                                                
                <AiOutlineFileWord size='2.2em' color='#4A4C4D'/>
              )
              : file.fullPath.endsWith(".xls") ||file.fullPath.endsWith(".xlsx")?(                                
                <AiOutlineFileExcel size='2.2em' color='#4A4C4D'/>
              )
              :(                
                <AiOutlineFile size='2.2em' color='#4A4C4D'/>
              )}              
            </ListItemIcon>
            
            {(isEditing?.id === file.id && isEditing?.fullPath===file.fullPath) ?(
              <TextField                            
                onFocus={event => {
                  event.target.select();
                }}        
                value={editedName}                   
                fullWidth                         
                variant="outlined"
                label="Nome File"
                onChange={(e) => setEditedName(e.target.value)}
                onBlur={handleRenameSubmit}
                onKeyPress={(e) => e.key === 'Enter' && handleRenameFileSubmit()}                
              />
            ) : (
              <ListItemText primary={file.name}  
                  secondary={timeConverter(file.id)}></ListItemText> 
            )}
          </ListItem>
        ))}

        {Object.keys(node.folders).map(key => {
          const folder = node.folders[key];
          //console.log("RFT23",node,key, folder,folder.fullPath, );
          return (
            <React.Fragment key={key}>
              <ListItem
                button
                onContextMenu={(event) => handleContextMenu(event, { name: key, isDir: true, fullPath: folder.fullPath, id: folder.id })}
                onClick={() => handleFolderClick(key, folder.fullPath,folder.id)}
                //onClick={() => (isEditing?.id === folder.id && isEditing?.fullPath===folder.fullPath)? handleCloseEditing() : handleFolderClick(key, folder.fullPath,folder.id)}
                onDragOver={(event) => {event.preventDefault(); autoScroll(event);} }
                onDrop={(event) => handleDrop(event, node.folders[key])}
                style={{ paddingLeft: `${level * 30}px` }}
              >
                <ListItemIcon onClick={(event) => handleCloseEditing(event)}>
                  {Object.keys(folder.folders).length >0 ||Object.keys(folder.files).length >0? (                  
                  <AiFillFolderOpen size='2.2em' color='#30720f'/>
                  ):(
                    <AiFillFolder size='2.2em' color='#30720f'/>
                  )}
                </ListItemIcon>
                {isEditing?.id === folder.id && isEditing?.fullPath===folder.fullPath ? (
                  <TextField     
                  onFocus={event => {
                    console.log("EVENT",event);                                   
                    event.target.select();
                  }}                     
                    value={editedName}
                    variant="outlined"
                    
                    label="Nome cartella"                    
                    onChange={(e) => setEditedName(e.target.value)}
                    onBlur={handleRenameSubmit}
                    onKeyPress={(e) => e.key === 'Enter' && handleRenameSubmit()}
                    fullWidth   
                  />
                ) : (
                  <ListItemText primary={key}  />
                
                )}
                {openFolders[folder.fullPath] ? <ExpandLess /> : <ExpandMore />}
              </ListItem>

              <Collapse in={openFolders[folder.fullPath]} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {renderFileTree(folder, `${parentPath}/${key}`, level + 1)}
                </List>
              </Collapse>
            </React.Fragment>
          );
        })}
      </>
    );
  };

  return (
    <div>
 {loading ? (
    <div style={{ width:"25%",   display: "flex",
        marginLeft: "auto", marginRight: "auto" }}>
         <ThreeDots color="#30720f" height='350' width='150'/>
    </div>
    ):(
    <div style={{ padding: '20px',margin:"40px", paddingLeft:"50px",paddingRight:"50px", borderRadius:"25px",
    border: "2px solid #73AD21"}}>   
      <Tooltip title="Aggiorna" placement="left">
            <Fab aria-label="add"                 
                //variant="contained" 
                onClick={(event) => {fetchFiles() }}                
                style={{
                    margin: 0,
                    background: "#30720f",
                    top: 'auto',
                    right: 5,
                    bottom: 25,
                    //color: '0xFF0000',
                    left: 'auto',
                    position: 'fixed',}}>                    
                    <RefreshIcon fontSize='large'/>                 
                </Fab>          
            </Tooltip> 
      <List >
        {renderFileTree(fileTree)}
      </List>

      <Menu
        sx={{padding:"20px"}}
        open={contextMenu !== null}
        onClose={handleCloseContextMenu}
        anchorReference="anchorPosition"
        anchorPosition={contextMenu ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined}
      >
          {selectedItem && selectedItem.isDir && (
        <MenuItem  styles={{backgroundColor:'#F3F3F3', width:'90%'}} onClick={(event)=>handleMenuItemClick(event,selectedItem)}> 
        <CreateNewFolderIcon fontSize="large" />
          
         <Typography variant="body1"  style={{  paddingLeft:"10px", fontSize: '1.1rem' }}>
         Crea Nuova Cartella
        </Typography>
         </MenuItem>  
        
        )}
            {selectedItem && selectedItem.isDir && (
            <div>
                <input id={"file-upload"+selectedItem.id} type="file" 
                onChange={(event)=> onFileChange(event,selectedItem)} 
                style={{ display: "none" }} 
                accept=".doc,.docx,.pdf,.xls,.xlsx,.gif,.png,.jpg,.jpeg"
                />    
                <label htmlFor={"file-upload"+selectedItem.id}>  
                <MenuItem  styles={{backgroundColor:'#F3F3F3', width:'90%'}} > <PublishIcon fontSize="large"/> 
                
                <Typography variant="body1"  style={{  paddingLeft:"10px", fontSize: '1.1rem' }}>
                Carica File 
                </Typography>
                </MenuItem>  
                </label>
            </div>    
        )}
        <MenuItem onClick={handleRename}>
          <EditIcon fontSize="large"/> 
          <Typography variant="body1"  style={{  paddingLeft:"10px", fontSize: '1.1rem' }}>
          Rinomina {selectedItem?.isDir ? 'Cartella' : 'File'}
          </Typography>
          
        </MenuItem>
        {selectedItem && selectedItem.isDir && (
          <MenuItem onClick={handleDeleteFolder}>
            <DeleteIcon fontSize="large"/> 
            <Typography variant="body1"  style={{  paddingLeft:"10px", fontSize: '1.1rem' }}>
              Elimina Cartella
            </Typography>
          </MenuItem>
        )}
        {selectedItem && !selectedItem.isDir && (
          <MenuItem onClick={handleDeleteFile}>
            <DeleteIcon fontSize="large"/> 
            <Typography variant="body1"  style={{  paddingLeft:"10px", fontSize: '1.1rem' }}>
              Elimina File
            </Typography>
          </MenuItem>
        )}
      </Menu>
    </div>)}
    <div>
        {alert}        
    </div>
    </div>
     
  );
};

export default FileExplorer;
