import {properties} from "utils/properties";


export  const doPostSeduta2 = function(updateUI, meetingId) {
        //console.log("Test Utils", process.env.REACT_APP_ELEGANZA);
	const MeetingInfo = JSON.parse(localStorage.getItem("meetingContent"));
        if (MeetingInfo)
		updateUI(MeetingInfo);

	var xhr2 = new XMLHttpRequest();
        xhr2.open('POST', properties.productionUrl+'/rest/checkSeduta',true);
        xhr2.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
        xhr2.onload = function (e) {
        if (xhr2.readyState === 4) {
            if (xhr2.status === 200) {
                console.log("OK92",xhr2);
                var responseText = xhr2.responseText;
                var obj=JSON.parse(responseText);
                console.log("OK93",obj);
                if(obj.status==="204" ){
                    const MeetingInfo = JSON.parse(localStorage.getItem("meetingContent"));
                    updateUI(MeetingInfo);
                }
                else{
                    //status 201
                    console.log("OK95",obj.date);

                    try{
                      localStorage.setItem("meetingContent", obj.content);
                      localStorage.setItem("meeting", meetingId);
                      localStorage.setItem("meetingVersion", obj.date);                                                                                                                                                                                                                                          
                    }
                    catch(error) {
                      console.log("LOCALSTORAGE ISSUE", error);
                    }   
                    const MeetingInfo = JSON.parse(obj.content);
                    updateUI(MeetingInfo);
                }
            }
            else {
                //console.error("Error 1", xhr.statusText);
                alert("Errore recuperando la seduta");
            }
        }
    };

    xhr2.onerror = function (e) {
      console.error("Error 22", xhr2.statusText);
    };

    let urlEncodedData = "idf=" + meetingId;
	if (localStorage.meeting===meetingId){
        console.log( "QUINDICI00");
        const currentData=localStorage.getItem("meetingVersion");
        urlEncodedData = urlEncodedData+ "&date=" + currentData;
        }
    xhr2.send(urlEncodedData);
}

export const updateSeduta = function(meetingId,doPostSeduta2,updateUI) {

  if (window.cordova)
  {
     var onSuccess = function(data) {
       window.sedute = {}
       window.sedute[meetingId] = JSON.parse(data);
       updateUI(window.sedute[meetingId]);
     }

     var onError = function() {
        alert("Impossibile scaricare la seduta, si prega di riprovare");
     }

     if (!window.sedute[meetingId]) {
        window.getSeduta(meetingId, onSuccess, onError);
     }
     else {
       updateUI(window.sedute[meetingId]);
     }

  }
  else
  {
     doPostSeduta2();
  }
}

export const updateSedute = function(organo,doPostSedute2,updateUI) {

  if (window.cordova)
  {
     var onSuccess = function(data) {
       var sedute = JSON.parse(data);
       updateUI(sedute);
     }

     var onError = function() {
        //alert("Impossibile scaricare l'elenco delle sedute, si prega di riprovare");
     }

     window.getDossiers(organo,onSuccess, onError);
  }
  else
  {
     doPostSedute2();
  }
}
