import React  from "react";
//import { useHistory } from "react-router-dom";
import logo from "../../assets/logo.png";
import "../../App.css";
import {properties} from "utils/properties";
import validator from 'validator';
import { useNavigate } from "react-router-dom";

export default function Modify() {
  //const history = useHistory();
  const navigate = useNavigate();
  const handleClick = e => {    
    e.preventDefault();
    /*history.push({               
                pathname: "/reset",
            });*/
    navigate("/reset");  
  };

  const handleSubmit = e => {    
    e.preventDefault();        
    if (!e.target.oldPassword.value) {
      alert("Username is required");
    } else if (!e.target.password.value) {
      alert("Password is required");
    } else if (!e.target.confirmPassword.value) {
      alert("Password is required");
    } else  
      {    
      if(e.target.confirmPassword.value ===e.target.password.value){       
        
        if (validator.isStrongPassword(e.target.password.value, {
          minLength: 8, minLowercase: 1,    
          minUppercase: 1, minNumbers: 1, minSymbols: 0    
        })) {
          doPost(e.target.oldPassword.value, e.target.password.value);                    
        } else {   
          alert("La password non rispetta i livelli minimi di sicurezza. Nello specifico è richiesto che la lunghezza della password sia di almeno 8 caratteri e che questa contenga: almeno 1 carattere maiuscolo, 1 carattere minuscolo ed una cifra");               
        }
        //doPost(e.target.oldPassword.value, e.target.password.value);                    
      }
      else
        alert("Le password inserite non corrispondono. Si prega di controllarle e riprovare.");
    } 
  };


  const doPost = function(old_pwd, pwd) {
    console.log(old_pwd, pwd);
    var xhr = new XMLHttpRequest();        
    


    xhr.open('POST', properties.productionUrl+'/rest/chgpwd',true);
    
    xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
    
    xhr.onload = function (e) {
    
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {                        
          /*history.push({                
                pathname: "/login",
          });*/
          navigate("/login");  
        } else {               
          alert("Cambio password fallito! Riprovare o contattare l'amministratore");  
          /*history.push({          
                pathname: "/login",});*/
          navigate("/login");  
        }
      }
    };

    
    xhr.onerror = function (e) {
      console.error("Error 2", xhr.statusText);
    };
          
   
    let urlEncodedData = "old_password=" + old_pwd + "&password=" + pwd;     
    xhr.send(urlEncodedData);
  }

 

  return (
    <div className="content" style = {{alignItems: "center", display: "flex", height: "100%",  justifyContent: "center"}}>

      <div className="AppLogin">
        <img src={logo} className="logo1" alt="Logo" />
        <form className="form1" onSubmit={handleSubmit}>
          <div className="input-group">
          <div style={{ maxWidth: "420px",  padding: "10px",width: "100%", alignItems: "center",margin: "auto"}}> 
          <p style={{ fontSize: "16px", letterSpacing: "1px", maxWidth: "420px",  
          width: "100%", alignItems: "center",
    }}> 
          Inserire la vecchia password e sceglierne una nuova (lunghezza minima 8 caratteri).
          Confermare la nuova password e fare click sul pulsante Continua. </p>
          </div>
            <label htmlFor="oldPassword">Vecchia Password</label>
            <input type="password" name="oldPassword" placeholder="*******" />
          </div>
          <div className="input-group">
            <label htmlFor="password">Nuova Password</label>
            <input type="password" name="password" placeholder="*******"/>
          </div>
          <div className="input-group">
            <label htmlFor="confirmPassword">Conferma Nuova Password</label>
            <input type="password" name="confirmPassword" placeholder="*******"/>
          </div>
          <button className="button11">Continua</button>
        </form>
        <button className="button12" onClick={handleClick}>
          Password dimenticata?
        </button>
      </div>
      </div>
  );
  
  }  