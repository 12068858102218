import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { makeStyles } from '@material-ui/styles'
//import { useHistory } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import {ThreeDots} from 'react-loader-spinner';
import { toast } from 'react-toastify';
import SweetAlert from "react-bootstrap-sweetalert";
import {properties} from "utils/properties";
import styles from "assets/jss/views/sessionManagerStyles"

const useStyles = makeStyles(styles)

export default function PubHandler() {    
    const [alert, setAlert] = useState(null);
    //const history = useHistory();
    const navigate = useNavigate();
    const classes = useStyles();
     
    const showAlert = () => {
        console.log("showing alert");
        setAlert(
          <SweetAlert
          warning
          style={{ display: "block", marginTop: "auto", marginBottom: "auto" }}
          title="Consolidare la seduta?"
          onConfirm={() => handlePublishing()}
          //onCancel={() => { history.go(-1) }}
          confirmBtnCssClass={classes.button + " " + classes.success}
          //cancelBtnCssClass={classes.button + " " + classes.danger}
          confirmBtnText="Si"
          //cancelBtnText="No"
          //showCancel
        >
        </SweetAlert>
        );
    };

    const handleisFreezed = () => {        
        
        console.log("handleisFreezed");        
        setAlert(null);    
        let endpoint = properties.productionUrl+"/rest/doCons";                        
        var bodyFormData=new FormData();                      
        bodyFormData.append('action', 'check');    
        axios({
            method: 'post',
            url: endpoint,
            data: bodyFormData, 
            headers: {'Content-Type': 'multipart/form-data' }
        })
        .then(function (response) {
            console.log(response);
            if(response.data.idf==undefined){
                //console.log(response.data.idf);
                //console.log(response.data.freezed);
                /*history.push({                
                    pathname: "/error/200",
                    });*/
                    navigate("/error/200"); 
            }
            else if(response.data.idf!=undefined && response.data.freezed!=undefined && response.data.freezed==false){
            //C'è un fascicolo non freezato
            /*history.push({                
                pathname: "/error/201",
                });*/
                navigate("/error/201");
            //history.replace({ pathname: "/fascicoli/",});
            }
            else if(response.data.idf!=undefined && response.data.freezed!=undefined && response.data.freezed==true){
                showAlert();
            }
        })
        .catch(function (response) {            
            toast.error("Si è verificato un errore pubblicando la seduta!");            
        });
    }



    const handlePublishing = () => {
        //const issueName = `Seduta del ${format(new Date(_inviatationDate), 'dd-MM-yyyy')}`
        
        console.log("handlePublishing");        
        setAlert(null);    
        let endpoint = properties.productionUrl+"/rest/doCons";                        
        var bodyFormData=new FormData();                      
        bodyFormData.append('action', 'consolida');    
        axios({
            method: 'post',
            url: endpoint,
            data: bodyFormData, 
            headers: {'Content-Type': 'multipart/form-data' }
        })
        .then(function (response) {            
            localStorage.removeItem("meeting");
            localStorage.removeItem("meetingContent");
            localStorage.removeItem("meetingVersion");
            //history.replace({ pathname: "/fascicoli/",});
            navigate("/fascicoli/",{ replace: true });
        })
        .catch(function (response) {            
            toast.error("Si è verificato un errore pubblicando la seduta!");            
        });
    }

   
    useEffect(() => {              
        handleisFreezed();
    }, []);





return (
  <div key={uuidv4()}>         
            <div key={uuidv4()} style={{ width:"25%",   display: "flex", 
            marginLeft: "auto", marginRight: "auto" }}> 
             <ThreeDots color="#30720f" height='350' width='150'/>
             </div> 

             <div>
                {alert}        
            </div>
    </div>
    );
}
