import {React,  useRef} from "react";
//import { useHistory, Link } from "react-router-dom";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import Calendar from 'rc-year-calendar';
import 'rc-year-calendar/locales/rc-year-calendar.it';
import RouterContext from "../../context/RouterContext";
import {updateSedute} from "./Utils";
import {properties} from "utils/properties";
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css'


export default function CalendarView() {
  //const history = useHistory();
  const navigate = useNavigate();
  const [meetingData, setMeetingData] = useState([]);
  const { pageRouter, setPageRouter } = useContext(RouterContext);
  const [startingYear, setStartingYear]= useState(0);
  var lastMeeting= useRef(null);
  var tooltip=null;
  
  const updateUI = function(jsonData) {      
        var entireMeeting = Object.entries(jsonData);                    
        var orderMeeting = [];
        var colore='#30720f';
        //"Entrambi", "Tetrambi","ConsColl","ComiColl"
        for (var i = entireMeeting.length - 1; i >= 0; i--) {          
            if(entireMeeting[i][1]["organo"]==="Consiglio")
                colore='#BF360C';
            else if(entireMeeting[i][1]["organo"]==="Entrambi")
                colore='#000000';
            else if(entireMeeting[i][1]["organo"]==="Tetrambi")
                colore='#FFFFFF';
            else if(entireMeeting[i][1]["organo"]==="ConsColl")
                colore='#FFFF00';
            else if(entireMeeting[i][1]["organo"]==="ComiColl")
                colore='#FF00FF';
            else if(entireMeeting[i][1]["organo"]==="Collegio")
                colore='#666999';
            else
                colore='#30720f';
            var dateLabel=entireMeeting[i][0].substring(4, 8).concat("-").concat(entireMeeting[i][0].substring(2, 4)).concat("-").concat(entireMeeting[i][0].substring(0, 2));
            var eachMeeting = {
                id: entireMeeting[i][0],
                name: 'Seduta del '.concat(entireMeeting[i][1]["dataSeduta"]),              
                location: entireMeeting[i][0],          
                startDate: new Date(dateLabel),
                endDate: new Date(dateLabel),
                color: colore,  
            };            
            orderMeeting.push(eachMeeting);            
            if(lastMeeting.current===undefined) {
                            //console.log("1",lastMeeting, eachMeeting.startDate);
                lastMeeting.current=eachMeeting.startDate;
            }
            else{
                if (lastMeeting.current < eachMeeting.startDate){                
                    lastMeeting.current=eachMeeting.startDate;              
                }
            }
        }      
        setStartingYear(lastMeeting.current.getFullYear());        
        var today={
            id: 0,
            name: 'Oggi',              
            location: '',          
            startDate: new Date(),
            endDate: new Date(),
            color: '#FF0000',                                          
        };
        orderMeeting.push(today);
        console.log("MEETING",orderMeeting);
        setMeetingData(orderMeeting);
  }
  
   const doPostSedute2 = function() {
        console.log("doing sedute post");
        var xhr2 = new XMLHttpRequest();                
        xhr2.open('POST', properties.productionUrl+'/rest/checkSeduteJson',true);
        xhr2.setRequestHeader("Content-type", "application/x-www-form-urlencoded");    
        xhr2.onload = function (e) {      
            if (xhr2.readyState === 4) {
                if (xhr2.status === 200) {                              
                    //console.log("OK92",xhr2);
                    var responseText = xhr2.responseText;                    
                    var obj=JSON.parse(responseText);
                    //console.log("OK93",obj, obj.status);          
                    if(obj.status==="204" ){                             
                        console.log("OK DOPOSTSEDUTE2 204",xhr2);                        
                        const jsonListSedute = JSON.parse(localStorage.getItem("listSedute"));
                        //updateUI(jsonListSedute);
                        doPostSedute4(jsonListSedute);
                    }              
                    else{
                        //status 201
                        console.log("OK DOPOSTSEDUTE2 201",xhr2);  
                        
                        try{
                            localStorage.setItem("listSedute", obj.content);
                            localStorage.setItem("listSeduteVersion", obj.date);                                      
                        }
                        catch(error) {
                            console.log("LOCALSTORAGE ISSUE", error);
                        }  
                        
                        const jsonListSedute = JSON.parse(obj.content);
                        //updateUI(jsonListSedute);
                        doPostSedute4(jsonListSedute);
                    }
                } 
                else {
                    //console.error("Error 1", xhr.statusText);          
                    //alert("Errore recuperando la seduta");  
                    console.log("Errore 3: Recuperando la lista sedute o non presenti sedute CdG per il ruolo dell'utente");  
                    doPostSedute4(null);
                }
            }
        };

        xhr2.onerror = function (e) {
            console.error("Error 22", xhr2.statusText);
        };
        
        let urlEncodedData = null; //"idf=" + meetingId;
        if (localStorage.listSedute!=='' &&localStorage.listSedute!==undefined){            
            //console.log( "QUINDI00");
            const currentData=localStorage.getItem("listSeduteVersion");        
            urlEncodedData = "date=" + currentData;                    
        }       
        //console.log("CALVIEW",urlEncodedData);
        xhr2.send(urlEncodedData);
    }

    function arrayUnique(array) {
        var a = array.concat();
        for(var i=0; i<a.length; ++i) {
            for(var j=i+1; j<a.length; ++j) {
                if(a[i] === a[j])
                    a.splice(j--, 1);
            }
        }
    
        return a;
    }
/*
    const doPostSedute4 = function(jsonList) {
        console.log("doing sedute post 4");        

        var xhr2 = new XMLHttpRequest();                
        xhr2.open('POST', properties.productionUrl+'/rest/checkSeduteJsonConsiglio',true);
        xhr2.setRequestHeader("Content-type", "application/x-www-form-urlencoded");    
        xhr2.onload = function (e) {      
            if (xhr2.readyState === 4) {
                if (xhr2.status === 200) {                              
                    //console.log("OK92",xhr2);
                    var responseText = xhr2.responseText;                    
                    var obj=JSON.parse(responseText);
                    //console.log("OK93",obj, obj.status);          
                    if(obj.status==="204" ){                             
                        console.log("OK DOPOSTSEDUTE4 204",xhr2);   
                        var mergedObject =undefined;                     
                        var jsonListSedute = JSON.parse(localStorage.getItem("listSeduteConsiglio"));
                        console.log("AQUI",jsonListSedute);
                        console.log("AQUI",jsonList);
                        console.log("AQUI",Object.keys(jsonListSedute).length,Object.keys(jsonList).length);
                        //console.log("AQUI",jsonListSedute.length,jsonList.length);
                        if(jsonListSedute!=undefined && jsonListSedute!=null &&jsonListSedute!=""){                           
                            var uno=Object.entries(jsonList);
                            var die=Object.entries(jsonListSedute);
                            console.log("1looking",uno);
                            console.log("2looking2",die);
                            console.log("3looking3",uno.length,die.length);
                            for(var i = 0, l = die.length; i < l; i++) {
                                for(var j = 0, ll = uno.length; j < ll; j++) {
                                    console.log("CHECK2", die[i] , uno[j]);
                                    if(die[i][1].dataSeduta === uno[j][1].dataSeduta) {
                                        console.log("found2");
                                        die[i][1].organo="Entrambi";                                                                                                               
                                    }                                   
                                }
                            }
                            jsonListSedute=Object.fromEntries(die);
                            console.log("PUS",jsonListSedute);


                            mergedObject = {
                                ...jsonList,
                                ...jsonListSedute
                              };
                            console.log("212",mergedObject);
                        }
                        else    
                            mergedObject= jsonList;
                        console.log("our",mergedObject);
                        //updateUI(jsonListSedute);
                        updateUI(mergedObject);
                    }              
                    else{
                        //status 201
                        console.log("OK DOPOSTSEDUTE4 201",xhr2);  
                        
                        try{
                            localStorage.setItem("listSeduteConsiglio", obj.content);
                            localStorage.setItem("listSeduteVersionConsiglio", obj.date);                                      
                        }
                        catch(error) {
                            console.log("LOCALSTORAGE ISSUE", error);
                        }  
                        
                        var jsonListSedute2 = JSON.parse(obj.content);
                        var mergedObject=undefined;
                        console.log("AQUI2",jsonListSedute2);
                        console.log("AQUI2",jsonList);
                        //console.log("AQUI22",jsonListSedute2.length,jsonList.length);
                        console.log("AQUI22",Object.keys(jsonListSedute2).length,Object.keys(jsonList).length);
                        if(jsonListSedute2!=undefined && jsonListSedute2!=null &&jsonListSedute2!=""){                         
                            var uno=Object.entries(jsonList);
                            var die=Object.entries(jsonListSedute2);
                            console.log("looking",uno);
                            console.log("looking2",die);
                            console.log("looking3",uno.length,die.length);
                            for(var i = 0, l = die.length; i < l; i++) {
                                for(var j = 0, ll = uno.length; j < ll; j++) {
                                    console.log("CHECK2", die[i] , uno[j]);
                                    if(die[i][1].dataSeduta === uno[j][1].dataSeduta) {
                                        console.log("found2");
                                        die[i][1].organo="Entrambi";                                                                              
                                    }                                   
                                }
                            }
                            jsonListSedute2=Object.fromEntries(die);
                            console.log("PUS",jsonListSedute2);
                            mergedObject = {
                                ...jsonList,
                                ...jsonListSedute2
                              };
                            console.log("412",mergedObject);
                        }
                            //jsonListSedute2= jsonListSedute2.concat(jsonList);
                        else    
                            //jsonListSedute2= jsonList;                        
                            mergedObject=jsonList;
                        //console.log("our",jsonListSedute2);
                        console.log("our",mergedObject);
                        //updateUI(jsonListSedute2);
                        updateUI(mergedObject);
                    }
                } 
                else {
                    //console.error("Error 1", xhr.statusText);          
                    //alert("Errore recuperando la lista sedute");  
                    var mergedObject=undefined;
                    console.log("Errore 2: Recuperando la lista sedute o non presenti sedute per il ruolo dell'utente");  
                    if(jsonList!=undefined && jsonList!=null &&jsonList!=""){                       
                        mergedObject = jsonList;
                        console.log("413",mergedObject);
                    }                        
                    else    
                        //jsonListSedute2= jsonList;                        
                        mergedObject=null;
                    //console.log("our",jsonListSedute2);
                    console.log("our",mergedObject);
                    //updateUI(jsonListSedute2);
                    updateUI(mergedObject);
                }
            }
        };

        xhr2.onerror = function (e) {
            console.error("Error 22", xhr2.statusText);
        };
        
        let urlEncodedData = null; //"idf=" + meetingId;
        if (localStorage.listSeduteConsiglio!=='' &&localStorage.listSeduteConsiglio!==undefined){            
            //console.log( "QUINDI00");
            const currentData=localStorage.getItem("listSeduteVersionConsiglio");        
            urlEncodedData = "date=" + currentData;                    
        }       
        //console.log("CALVIEW",urlEncodedData);
        xhr2.send(urlEncodedData);
    }
    */

    
//6
const doPostSedute6 = function(jsonList) {
    console.log("doing sedute post 6");        

    var xhr2 = new XMLHttpRequest();                
    xhr2.open('POST', properties.productionUrl+'/rest/checkSeduteJsonCollegio',true);
    xhr2.setRequestHeader("Content-type", "application/x-www-form-urlencoded");    
    xhr2.onload = function (e) {      
        if (xhr2.readyState === 4) {
            if (xhr2.status === 200) {                              
                //console.log("OK92",xhr2);
                var responseText = xhr2.responseText;                    
                var obj=JSON.parse(responseText);
                console.log("OK93",obj, obj.status);          
                if(obj.status==="204" ){                             
                    console.log("OK DOPOSTSEDUTE6 204",xhr2);   
                    var mergedObject =undefined;                     
                    var jsonListSedute = JSON.parse(localStorage.getItem("listSeduteCollegio"));
                    console.log("BRUT",jsonListSedute,jsonList);
                    if(jsonListSedute!=undefined && jsonListSedute!=null &&jsonListSedute!=""){                          
                        console.log("2COL",jsonListSedute, "33COL", jsonList);                        
                        
                        var uno=Object.entries(jsonList);
                        var die=Object.entries(jsonListSedute);
                        /*for(var i = 0, l = die.length; i < l; i++) {                               
                           if(die[i][1].order==0)
                           die[i][1].nuovo ="New";   
                        }
                        for(var i = 0, l = uno.length; i < l; i++) {                                
                            if(uno[i][1].order==0)
                           uno[i][1].nuovo ="New";   
                        }
                        
                        console.log("UnoUnoCOL",uno);
                        console.log("DueUnoCOL",die);
                        */
                        for(var i = 0, l = die.length; i < l; i++) {
                            for(var j = 0, ll = uno.length; j < ll; j++) {                        
                                if(die[i][1].dataSeduta === uno[j][1].dataSeduta) {
                                    //console.log("found2");
                                    if((uno[j][1]).organo=="Entrambi")
                                        die[i][1].organo="Tetrambi";
                                    if((uno[j][1]).organo=="Consiglio")
                                        die[i][1].organo="ConsColl";
                                    if((uno[j][1]).organo==undefined)
                                        die[i][1].organo="ComiColl";
                                    //die[i][1].organo="Entrambi";
                                    //if(uno[j][1].nuovo=="New")
                                    //    die[i][1].nuovo="New";
                                }                                   
                            }
                        }
                        jsonListSedute=Object.fromEntries(die);
                        jsonList=Object.fromEntries(uno);
                        console.log("PUS UNO COL",jsonListSedute);
                                                                            
                        mergedObject = {
                            ...jsonList,
                            ...jsonListSedute
                          };
                        console.log("212-COL",mergedObject);
                    }
                    else    
                        mergedObject= jsonList;
                    console.log("our coll",mergedObject);
                    
                    updateUI(mergedObject);
                }              
                else{
                    //status 201
                    console.log("OK DOPOSTSEDUTE4 201",xhr2);  
                    
                    try{
                        localStorage.setItem("listSeduteCollegio", obj.content);
                        localStorage.setItem("listSeduteVersionCollegio", obj.date);                                      
                    }
                    catch(error) {
                        console.log("LOCALSTORAGE ISSUE", error);
                    }  
                    
                    var jsonListSedute2 = JSON.parse(obj.content);
                    var mergedObject=undefined;
                    console.log("AQUI2",jsonListSedute2,jsonList);
                    if(jsonListSedute2!=undefined && jsonListSedute2!=null &&jsonListSedute2!=""){                            

                        var uno=Object.entries(jsonList);
                        var die=Object.entries(jsonListSedute2);
                        //console.log("Uno-",uno);
                        //console.log("Due-",die);
                        /*for(var i = 0, l = die.length; i < l; i++) {
                            //console.log("Due-------",die[i][1]);
                            if(die[i][1].order == 0)
                                die[i][1].nuovo = "New";                                    
                        }
                        for(var i = 0, l = uno.length; i < l; i++) {                                
                            if(uno[i][1].order == 0)
                                uno[i][1].nuovo = "New";    
                        }                            
                        console.log("Uno",uno);
                        console.log("Due",die);                            
                        */
                        for(var i = 0, l = die.length; i < l; i++) {
                            for(var j = 0, ll = uno.length; j < ll; j++) {
                                //console.log("CHECK2", die[i] , uno[j]);
                                if(die[i][1].dataSeduta === uno[j][1].dataSeduta) {
                                    //console.log("found2");
                                    //die[i][1].organo="Entrambi";                                           
                                    //
                                    if((uno[j][1]).organo=="Entrambi")
                                        die[i][1].organo="Tetrambi";
                                    if((uno[j][1]).organo=="Consiglio")
                                        die[i][1].organo="ConsColl";
                                    if((uno[j][1]).organo==undefined)
                                        die[i][1].organo="ComiColl";                                
                                    //if(uno[j][1].nuovo=="New")
                                    //    die[i][1].nuovo="New";
                                    //
                                }                                   
                            }
                        }
                        jsonListSedute2=Object.fromEntries(die);                            
                        jsonList=Object.fromEntries(uno);
                        console.log("PUS-COL",jsonListSedute2);
                        
                        mergedObject = {
                            ...jsonList,
                            ...jsonListSedute2
                          }; 
                          
                        console.log("412-COL",mergedObject);
                    }
                        //jsonListSedute2= jsonListSedute2.concat(jsonList);
                    else    
                        //jsonListSedute2= jsonList;                        
                        mergedObject=jsonList;
                    //console.log("our",jsonListSedute2);
                    console.log("our coll",mergedObject);
                    //updateUI(jsonListSedute2);
                    updateUI(mergedObject);
                }
            } 
            else {
                //console.error("Error 1", xhr.statusText);          
                //alert("Errore recuperando la lista sedute");  
                var mergedObject=undefined;
                console.log("Errore 23: Recuperando la lista sedute o non presenti sedute per il ruolo dell'utente");  
                if(jsonList!=undefined && jsonList!=null &&jsonList!=""){                       
                    mergedObject = jsonList;
                    console.log("413 COL",mergedObject);
                }                        
                else    
                    //jsonListSedute2= jsonList;                        
                    mergedObject=null;
                //console.log("our",jsonListSedute2);
                console.log("our coll 2",mergedObject);
                //updateUI(jsonListSedute2);
                updateUI(mergedObject);
            }
        }
    };

    xhr2.onerror = function (e) {
        console.error("Error 22", xhr2.statusText);
    };
    
    let urlEncodedData = null; //"idf=" + meetingId;
    if (localStorage.listSeduteCollegio!=='' &&localStorage.listSeduteCollegio!==undefined){            
        //console.log( "QUINDI00");
        const currentData=localStorage.getItem("listSeduteVersionCollegio");        
        urlEncodedData = "date=" + currentData;                    
    }       
    //console.log("CALVIEW",urlEncodedData);
    xhr2.send(urlEncodedData);
}
//6



    const doPostSedute4 = function(jsonList) {
        console.log("doing sedute post 4");        

        var xhr2 = new XMLHttpRequest();                
        xhr2.open('POST', properties.productionUrl+'/rest/checkSeduteJsonConsiglio',true);
        xhr2.setRequestHeader("Content-type", "application/x-www-form-urlencoded");    
        xhr2.onload = function (e) {      
            if (xhr2.readyState === 4) {
                if (xhr2.status === 200) {                              
                    //console.log("OK92",xhr2);
                    var responseText = xhr2.responseText;                    
                    var obj=JSON.parse(responseText);
                    //console.log("OK93",obj, obj.status);          
                    if(obj.status==="204" ){                             
                        console.log("OK DOPOSTSEDUTE4 204",xhr2);   
                        var mergedObject =undefined;                     
                        var jsonListSedute = JSON.parse(localStorage.getItem("listSeduteConsiglio"));
                        console.log("AQUI",jsonListSedute,jsonList);
                        if(jsonListSedute!=undefined && jsonListSedute!=null &&jsonListSedute!=""){                          
                            console.log("2",jsonListSedute, "33", jsonList);
                            //const tmp23=[...jsonListSedute, ...jsonList];
                            //console.log("1",tmp23);
                            
                            var uno=Object.entries(jsonList);
                            var die=Object.entries(jsonListSedute);
                            /*for(var i = 0, l = die.length; i < l; i++) {                               
                               if(die[i][1].order==0)
                               die[i][1].nuovo ="New";   
                            }
                            for(var i = 0, l = uno.length; i < l; i++) {                                
                                if(uno[i][1].order==0)
                               uno[i][1].nuovo ="New";   
                            }
                            */
                            //console.log("UnoUno",uno);
                            //console.log("DueUno",die);
                            //console.log("1looking",uno);
                            //console.log("2looking2",die);
                            //console.log("3looking3",uno.length,die.length);
                            for(var i = 0, l = die.length; i < l; i++) {
                                for(var j = 0, ll = uno.length; j < ll; j++) {
                                    //console.log("CHECK2", die[i] , uno[j]);
                                    if(die[i][1].dataSeduta === uno[j][1].dataSeduta) {
                                        //console.log("found2");
                                        die[i][1].organo="Entrambi";
                                        //if(uno[j][1].nuovo=="New")
                                        //    die[i][1].nuovo="New";
                                    }                                   
                                }
                            }
                            jsonListSedute=Object.fromEntries(die);
                            jsonList=Object.fromEntries(uno);
                            //console.log("PUS UNO",jsonListSedute);
                                                                                
                            mergedObject = {
                                ...jsonList,
                                ...jsonListSedute
                              };
                            console.log("212",mergedObject);
                        }
                        else    
                            mergedObject= jsonList;
                        console.log("our1",mergedObject);
                        if(localStorage.getItem("ruolo")=="12" ||localStorage.getItem("ruolo")=="21")
                            doPostSedute6(mergedObject);
                        else
                            updateUI(mergedObject);
                        //updateUI(mergedObject);
                    }              
                    else{
                        //status 201
                        console.log("OK DOPOSTSEDUTE4 201",xhr2);  
                        
                        try{
                            localStorage.setItem("listSeduteConsiglio", obj.content);
                            localStorage.setItem("listSeduteVersionConsiglio", obj.date);                                      
                        }
                        catch(error) {
                            console.log("LOCALSTORAGE ISSUE", error);
                        }  
                        
                        var jsonListSedute2 = JSON.parse(obj.content);
                        var mergedObject=undefined;
                        console.log("AQUI2",jsonListSedute2,jsonList);
                        if(jsonListSedute2!=undefined && jsonListSedute2!=null &&jsonListSedute2!=""){                            

                            var uno=Object.entries(jsonList);
                            var die=Object.entries(jsonListSedute2);
                            //console.log("Uno-",uno);
                            //console.log("Due-",die);
                            /*for(var i = 0, l = die.length; i < l; i++) {
                                //console.log("Due-------",die[i][1]);
                                if(die[i][1].order == 0)
                                    die[i][1].nuovo = "New";                                    
                            }
                            for(var i = 0, l = uno.length; i < l; i++) {                                
                                if(uno[i][1].order == 0)
                                    uno[i][1].nuovo = "New";    
                            }                            
                            console.log("Uno",uno);
                            console.log("Due",die);                            
                            */
                            for(var i = 0, l = die.length; i < l; i++) {
                                for(var j = 0, ll = uno.length; j < ll; j++) {
                                    //console.log("CHECK2", die[i] , uno[j]);
                                    if(die[i][1].dataSeduta === uno[j][1].dataSeduta) {
                                        //console.log("found2");
                                        die[i][1].organo="Entrambi";                                                                              
                                    }                                   
                                }
                            }
                            jsonListSedute2=Object.fromEntries(die);                            
                            jsonList=Object.fromEntries(uno);
                            console.log("PUS",jsonListSedute2);
                            
                            mergedObject = {
                                ...jsonList,
                                ...jsonListSedute2
                              }; 
                              
                            console.log("412",mergedObject);
                        }
                            //jsonListSedute2= jsonListSedute2.concat(jsonList);
                        else    
                            //jsonListSedute2= jsonList;                        
                            mergedObject=jsonList;
                        //console.log("our",jsonListSedute2);
                        console.log("our2",mergedObject);
                        //updateUI(jsonListSedute2);
                        if(localStorage.getItem("ruolo")=="12" ||localStorage.getItem("ruolo")=="21")
                            doPostSedute6(mergedObject);
                        else    
                            updateUI(mergedObject);
                        //updateUI(mergedObject);
                    }
                } 
                else {
                    //console.error("Error 1", xhr.statusText);          
                    //alert("Errore recuperando la lista sedute");  
                    var mergedObject=undefined;
                    console.log("Errore 2: Recuperando la lista sedute o non presenti sedute per il ruolo dell'utente");  
                    if(jsonList!=undefined && jsonList!=null &&jsonList!=""){                       
                        mergedObject = jsonList;
                        console.log("413",mergedObject);
                    }                        
                    else    
                        //jsonListSedute2= jsonList;                        
                        mergedObject=null;
                    //console.log("our",jsonListSedute2);
                    console.log("our3",mergedObject);
                    //updateUI(jsonListSedute2);
                    if(localStorage.getItem("ruolo")=="12" ||localStorage.getItem("ruolo")=="21")
                        doPostSedute6(mergedObject);
                    else
                        updateUI(mergedObject);
                }
            }
        };

        xhr2.onerror = function (e) {
            console.error("Error 22", xhr2.statusText);
        };
        
        let urlEncodedData = null; //"idf=" + meetingId;
        if (localStorage.listSeduteConsiglio!=='' &&localStorage.listSeduteConsiglio!==undefined){            
            //console.log( "QUINDI00");
            const currentData=localStorage.getItem("listSeduteVersionConsiglio");        
            urlEncodedData = "date=" + currentData;                    
        }       
        //console.log("CALVIEW",urlEncodedData);
        xhr2.send(urlEncodedData);
    }




    useEffect(() => {     
              
        if(localStorage.getItem("new_ui")=== undefined || localStorage.getItem("new_ui")=== null || localStorage.getItem("new_ui")=== "false"){        
            /*history.replace({                
                pathname: "/sedute",
                });*/
            navigate("/sedute",{ replace: true });
        }              
                           
        if(process.env.NODE_ENV !=='development' && !window.cordova){        
        //CHECK AUTHENTICATION
            var xhr = new XMLHttpRequest();                
            xhr.open('POST', properties.productionUrl+'/rest/ping',true);
            xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
    
            xhr.onload = function (e) {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {                         
                    console.log("OK",xhr);   
                    updateSedute("Entrambi",doPostSedute2, updateUI);                               
                } else {
                    console.error("Error 1", xhr);   
                    localStorage.clear();                 
                    /*history.push({                
                    pathname: "/login",
                    });*/
                    navigate("/login");
                }
            }
            };
    
            xhr.onerror = function (e) {
                console.error("Error 2", xhr);
                localStorage.clear();
                /*history.push({                
                    pathname: "/login",
                    });*/
                navigate("/login");
            };
             
            let urlEncodedData = null; 
            xhr.send(urlEncodedData);
        //CHECK AUTHENTICATION
        }
          
        /*setPageRouter({
            router: history.location.pathname,
            homecolor: "#FF5A60",
            settingcolor: "#879497",
            homebordercolor: "#FF5A60",
            settingbordercolor: "#FFFFFF",
            hometitlecolor: "black",
            settingtitlecolor: "#879497",
        });*/
    }, []);

const months = {
        /*January: "01",
        February: "02",
        March: "03",
        April: "04",
        May: "05",
        June: "06",
        July: "07",
        August: "08",
        September: "09",
        October: "10",
        November: "11",
        December: "12"*/
        Gennaio: "01",
        Febbraio: "02",
        Marzo: "03",
        Aprile: "04",
        Maggio: "05",
        Giugno: "06",
        Luglio: "07",
        Agosto: "08",
        Settembre: "09",
        Ottobre: "10",
        Novembre: "11",
        Dicembre: "12"
      };

const translateMonthToNumber= (mese) => {
        //console.log("TRANS", months[mese]);
        return months[mese];
};

const customDataSourceRenderer = (element, event, date) => {     
    //console.log("1", element);
    //console.log("2", event);
    //console.log("3", date); 
    //var dd = element.innerText;
    //console.log("pop", element.innerText);
    //element.style.color = "red";
    //element.style.background = "linear-gradient(110deg, #fdcd3b 60%, #ffed4b 60%)"
    //element.style.background = "linear-gradient(135deg, cyan 0%, yellow 40%)";
    //element.style.background = "linear-gradient(#e66465, #9198e5)"
    //var mm = element.parentNode.parentNode.parentNode.firstChild.firstChild.innerText;
    /*console.log( "hila", element.parentNode.parentNode.parentNode.firstChild.firstChild.innerText);*/
    //var m2 = translateMonthToNumber(mm);
    //var hila = element.parentNode.parentNode.parentNode.firstChild.firstChild.innerText.trim();
    //var aa = element.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.firstChild.firstChild.innerText;
    //console.log(aa.length);
    //aa= aa.substr(1,aa.length-2);
    //aa = aa.substr(aa.length / 2 - 2, 4);
    //aa = aa.trim();
    //var bb = aa.split(" ");

    //console.log("re", aa, bb.length);
    /*console.log("betr", element.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.firstChild.firstChild.getElementsByClassName("year-title")
     element.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode .firstChild.firstChild.innerText);*/
    
    //if (dd < 10) 
    //    dd = "0" + dd;
    //console.log(dd + m2 + aa);
    //var dataTre=dd + m2 + aa;
    //console.log("ASTA", dataTre, meetingData);
   /* var found = meetingData.find(function (element) {
        console.log("VICTORIA",element, dataTre);
        return element == dataTre;
        })
    */
   
    //var found=meetingData.find(item=> item.id==dataTre);
    
    //if(found !=undefined){
    //    console.log("here",found);
    console.log("HOP",date.length, date[0], date[0].color,date);

    if(date.length==1){        
        if(date[0].color=="#BF360C"){ //consiglio
            element.style.backgroundColor = "#BF360C";          
        }
        else if(date[0].color=="#30720f") {//comitato
            element.style.backgroundColor = "#30720f";        
        }
        else if(date[0].color=="#666999") {//collegio
            element.style.backgroundColor = "#666999";        
        }
        else if(date[0].color=="#FF0000") {// oggi
            element.style.backgroundColor = "#FF0000";        
        }
        else if(date[0].color=="#FFFF00") {// conscoll
            element.style.background =
            "linear-gradient(135deg, #BF360C 50%, #666999 50%)";        
        }
        else if(date[0].color=="#FF00FF") {// comicoll
            element.style.background =
            "linear-gradient(135deg, #30720f 50%, #666999 50%)";        
        }
        else if(date[0].color=="#000000") {// entrambi
            element.style.background =
            "linear-gradient(135deg, #BF360C 50%, #30720f 50%)";            
        }
        else{ //tetrambi
            element.style.background =
            "linear-gradient(135deg, #BF360C 33%, #30720f 33% 66%, #666999 66% 100%)";              
        }
    }
    else{ //c'è di mezzo "oggi"
        element.style.backgroundColor = "#FF0000";
    }  
 };

 const topic = (e) => { 
    /*history.push({
        
        pathname: "/consiglio/" ,
    });*/
        navigate("/consiglio"); 
    }


   const topicList = (e) => {    
    if (tooltip !== null) {
        tooltip.destroy();
        tooltip = null;
      }
    if(e.events.length>0){        
        console.log("TO BE INVESTIGATED",e);
        //
        /*
       
            
        else{ //tetrambi
            element.style.background =
            "linear-gradient(135deg, #BF360C 33%, #30720f 33% 66%, #666999 66% 100%)";              
        } */
        //
        if (e.events[0].location!==null && e.events[0].location!==undefined && e.events[0].location!=="")
            if(e.events[0].color==='#BF360C'){
               /* history.push({                    
                    pathname: "/consiglio/" + e.events[0].location ,
             }); */
                navigate("/consiglio/"+ e.events[0].location );

            }
            else if(e.events[0].color==='#666999'){
                /*history.push({                    
                    pathname: "/collegio/" + e.events[0].location ,
             }); */
                navigate("/collegio/"+ e.events[0].location );
            }
            else if(e.events[0].color==='#30720f'){
                /*history.push({                    
                    pathname: "/fascicoli/" + e.events[0].location+"/summary" ,
             }); */
                navigate("/fascicoli/"+ e.events[0].location+"/summary" );
            }
            else if(e.events[0].color==='#000000'){ //entrambi
                var content = '';                
                
                var a13="'/fascicoli/" + e.events[0].location+"/summary'";
                var a14= "'/consiglio/" + e.events[0].location+"'";                   
                content='<div  style="background: white; padding: 2px; display: block;">'+
                   '<div style="margin: 10px 0px">'+
                        '<div style="display: inline; webkit-border-radius: 4px;  moz-border-radius: 4px; border-radius: 4px; padding: 5px 12px; background-color: rgb(48, 114, 15); height:20px; width:20px; max-width:20px;">'+
                        '</div>'+
                        '<input type="button" style="display: inline; cursor: pointer; text-align:left; width:90%; background: white; border: 0px; font-size: 15px"  onclick="location.href='+a13+'" value="Comitato di Gestione"/>'+
                   '</div>'+
                   '<div style="margin: 10px 0px">'+
                        '<div style="display: inline; webkit-border-radius: 4px;  moz-border-radius: 4px; border-radius: 4px; padding: 5px 12px; background-color: rgb(191, 54, 12); height:20px; width:20px; max-width:20px;">'+
                        '</div>'+                            
                        '<input type="button" style="display: inline; cursor: pointer; width:90%; text-align:left; background: white; border: 0px; font-size: 15px" onclick="location.href='+a14+'" value="Consiglio Generale"/>'+
                    '</div>'+
                '</div>'                   
                //console.log("QQ2",content);                                
                if (tooltip != null) {
                  tooltip.destroy();
                  tooltip = null;
                }                
                tooltip = tippy(e.element, {
                    placement: 'right',
                    theme: 'personal',
                    trigger: 'manual',
                    content: content,
                    animateFill: false,
                    interactive: true,
                    animation: 'shift-away',
                    allowHTML: true,                        
                    arrow: true
                });
                tooltip.show();                
            }
            else if(e.events[0].color==='#FFFF00'){ //conscoll
                var content = '';                
                
                var a13="'/consiglio/" + e.events[0].location+"'";                   
                var a14= "'/collegio/" + e.events[0].location+"'";                   
                content='<div  style="background: white; padding: 2px; display: block;">'+
                   '<div style="margin: 10px 0px">'+
                        '<div style="display: inline; webkit-border-radius: 4px;  moz-border-radius: 4px; border-radius: 4px; padding: 5px 12px; background-color: rgb(191, 54, 12); height:20px; width:20px; max-width:20px;">'+
                        '</div>'+
                        '<input type="button" style="display: inline; cursor: pointer; text-align:left; width:90%; background: white; border: 0px; font-size: 15px"  onclick="location.href='+a13+'" value="Consiglio Generale"/>'+
                   '</div>'+
                   '<div style="margin: 10px 0px">'+
                        '<div style="display: inline; webkit-border-radius: 4px;  moz-border-radius: 4px; border-radius: 4px; padding: 5px 12px; background-color: rgb(102, 105, 153); height:20px; width:20px; max-width:20px;">'+
                        '</div>'+                            
                        '<input type="button" style="display: inline; cursor: pointer; width:90%; text-align:left; background: white; border: 0px; font-size: 15px" onclick="location.href='+a14+'" value="Collegio Sindacale"/>'+
                    '</div>'+
                '</div>'                                   
                if (tooltip != null) {
                  tooltip.destroy();
                  tooltip = null;
                }                
                tooltip = tippy(e.element, {
                    placement: 'right',
                    theme: 'personal',
                    trigger: 'manual',
                    content: content,
                    animateFill: false,
                    interactive: true,
                    animation: 'shift-away',
                    allowHTML: true,                        
                    arrow: true
                });
                tooltip.show();                
            }
            else if(e.events[0].color==='#FF00FF'){ //comicoll
                var content = '';                
                
                var a13="'/fascicoli/" + e.events[0].location+"/summary'";
                var a14= "'/collegio/" + e.events[0].location+"'";                   
                content='<div  style="background: white; padding: 2px; display: block;">'+
                   '<div style="margin: 10px 0px">'+
                        '<div style="display: inline; webkit-border-radius: 4px;  moz-border-radius: 4px; border-radius: 4px; padding: 5px 12px; background-color: rgb(48, 114, 15); height:20px; width:20px; max-width:20px;">'+
                        '</div>'+
                        '<input type="button" style="display: inline; cursor: pointer; text-align:left; width:90%; background: white; border: 0px; font-size: 15px"  onclick="location.href='+a13+'" value="Comitato di Gestione"/>'+
                   '</div>'+
                   '<div style="margin: 10px 0px">'+
                        '<div style="display: inline; webkit-border-radius: 4px;  moz-border-radius: 4px; border-radius: 4px; padding: 5px 12px; background-color: rgb(102, 105, 153); height:20px; width:20px; max-width:20px;">'+
                        '</div>'+                            
                        '<input type="button" style="display: inline; cursor: pointer; width:90%; text-align:left; background: white; border: 0px; font-size: 15px" onclick="location.href='+a14+'" value="Collegio Sindacale"/>'+
                    '</div>'+
                '</div>'                                   
                if (tooltip != null) {
                  tooltip.destroy();
                  tooltip = null;
                }                
                tooltip = tippy(e.element, {
                    placement: 'right',
                    theme: 'personal',
                    trigger: 'manual',
                    content: content,
                    animateFill: false,
                    interactive: true,
                    animation: 'shift-away',
                    allowHTML: true,                        
                    arrow: true
                });
                tooltip.show();                
            }
            else{ //TERTAMBI                                                     
                    var content = '';                       
                    var a13="'/fascicoli/" + e.events[0].location+"/summary'";
                    var a14= "'/consiglio/" + e.events[0].location+"'";
                    var a15="'/collegio/" + e.events[0].location+"'";
                                                                  
                    content='<div  style="background: white; padding: 2px; display: block;">'+
                       '<div style="margin: 10px 0px">'+
                            '<div style="display: inline; webkit-border-radius: 4px;  moz-border-radius: 4px; border-radius: 4px; padding: 5px 12px; background-color: rgb(48, 114, 15); height:20px; width:20px; max-width:20px;">'+
                            '</div>'+
                            '<input type="button" style="display: inline; cursor: pointer; text-align:left; width:90%; background: white; border: 0px; font-size: 15px"  onclick="location.href='+a13+'" value="Comitato di Gestione"/>'+
                       '</div>'+
                       '<div style="margin: 10px 0px">'+
                            '<div style="display: inline; webkit-border-radius: 4px;  moz-border-radius: 4px; border-radius: 4px; padding: 5px 12px; background-color: rgb(191, 54, 12); height:20px; width:20px; max-width:20px;">'+
                            '</div>'+                            
                            '<input type="button" style="display: inline; cursor: pointer; width:90%; text-align:left; background: white; border: 0px; font-size: 15px" onclick="location.href='+a14+'" value="Consiglio Generale"/>'+
                        '</div>'+
                        '<div style="margin: 10px 0px">'+
                            '<div style="display: inline; webkit-border-radius: 4px;  moz-border-radius: 4px; border-radius: 4px; padding: 5px 12px; background-color: rgb(102, 105, 153); height:20px; width:20px; max-width:20px;">'+
                            '</div>'+                            
                            '<input type="button" style="display: inline; cursor: pointer; width:90%; text-align:left; background: white; border: 0px; font-size: 15px" onclick="location.href='+a15+'" value="Collegio Sindacale"/>'+
                        '</div>'+
                    '</div>'                       
                    //console.log("QQ2",content);                                           
                    if (tooltip != null) {
                      tooltip.destroy();
                      tooltip = null;
                    }
                    
                    tooltip = tippy(e.element, {
                        placement: 'right',
                        theme: 'personal',
                        trigger: 'manual',
                        content: content,
                        animateFill: false,
                        interactive: true,
                        animation: 'shift-away',
                        allowHTML: true,                        
                        arrow: true
                    });
                    tooltip.show();                    
                }                
            }      
    };
 
  return (
    <div>          
    
      <Calendar                    
          year= {startingYear}          
          language="it"    
          minDate= {new Date("2016/01/01")}       
            /*minDate= {new Date("2020/01/01")}*/        
            /*maxDate= {new Date("2022/12/31")}*/
          maxDate= {new Date().setDate(new Date().getDate()+180)}
          style= "custom"
          enableContextMenu={true}                          
          customDataSourceRenderer={(element, event,date) => customDataSourceRenderer(element, event, date)}  
          dataSource={meetingData}  
          onDayClick={e => topicList(e)}
          />
      </div>
  );
  
  }  
