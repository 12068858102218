import React from "react";
//import { useHistory } from "react-router-dom";
import logo from "../../assets/logo.png";
import { useEffect } from "react";
import "../../App.css";
import {properties} from "utils/properties";
import { useNavigate } from "react-router-dom";

export default function Login() {
  //const history = useHistory();
  const navigate = useNavigate();
  
  const handleClick = e => {
    e.preventDefault();
    /*history.push({               
        pathname: "/reset",
    });*/
    navigate("/reset");        
  };

  const handleSubmit = e => {
    e.preventDefault();    
    if (!e.target.username.value) {
      alert("Username is required");
    } else if (!e.target.password.value) {
      alert("Password is required");
    } else  
      {    
      doPost(e.target.username.value, e.target.password.value);            
    } 
  };

  const doPost2 = function() {
    console.log("getting info");
    var xhr2 = new XMLHttpRequest();            
    //xhr2.open('POST', properties.productionUrl+'/rest/getInfo3',true);
    //xhr2.open('POST', properties.productionUrl+'/rest/getInfo4',true);
    //COMMISSIONI 17/08/2023
    //xhr2.open('POST', properties.productionUrl+'/rest/getInfo5',true);
    xhr2.open('POST', properties.productionUrl+'/rest/getInfo6',true);
    //17/08/2023
    xhr2.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
    
    xhr2.onload = function (e) {
      if (xhr2.readyState === 4) {
        if (xhr2.status === 200) {                              
          var responseText = xhr2.responseText;
          console.log("OK LOGIN 33");          
          var obj=JSON.parse(responseText);  
          console.log("GI6",obj);       
          localStorage.setItem("name", obj.Name);
          localStorage.setItem("genere", obj.Genere);
          localStorage.setItem("ruolo",obj.Ruolo);
          localStorage.setItem("indice",obj.Indice);
          //localStorage.setItem("commands", JSON.stringify(obj.Commands));          
          
          //COMMISSIONI 17/08/2023
          if(obj.CommandsCommissioni!=undefined && obj.CommandsCommissioni!=null){
            console.log("obj.commandsCommissioni",obj.CommandsCommissioni);
            localStorage.setItem("commandsCommissioni", JSON.stringify(obj.CommandsCommissioni));
          }
          //17/08/2023
          localStorage.setItem("commandsGeneral", JSON.stringify(obj.CommandsGeneral));          
          localStorage.setItem("commandsComitato", JSON.stringify(obj.CommandsComitato));          
          localStorage.setItem("commandsConsiglio", JSON.stringify(obj.CommandsConsiglio));          
          localStorage.setItem("commandsCollegio", JSON.stringify(obj.CommandsCollegio));          
        } else {            
          console.error("Error 13", xhr2.statusText);                  
          alert("Errore recuperando info utente");  
        }
      }
    };
    
    xhr2.onerror = function (e) {
      console.error("Error 1121", xhr2.statusText);
    };
             
    let urlEncodedData = null; 
    xhr2.send(urlEncodedData);
}

  const doPost = function(user, pwd) {
    localStorage.clear();
    console.log("doing first post");
    console.log("NOVEL2",Document.referrer);
    var xhr = new XMLHttpRequest();            
    //13/10/2022
    //xhr.open('POST', properties.productionUrl+'/rest/login',true);
    //xhr.open('POST', properties.productionUrl+'/logon',true);
    xhr.open('POST', properties.productionUrl+'/login2',true);
    //13/10/2022
    xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");

    xhr.onload = function (e) {    
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {         
        if(process.env.NODE_ENV !=='development' && !window.cordova){           
          doPost2();
        }
        else {
        localStorage.setItem("name", "Alberto");      
        localStorage.setItem("genere", "1");              
        }          
        console.log("NOVEL",Document.referrer);
        /*history.push({        
                //pathname: "/fascicoli",
                pathname: "/init",
            });*/
            navigate("/init");
        } else {
          console.error("Error 15", xhr.statusText);          
          alert("Login fallito: Username o password errati! Riprovare o contattare l'amministratore");  
        }
      }
    };

    //UPDATE `cdsp_users` SET `password`=PASSWORD('Pippo+123!') WHERE `username` ='dio2'
    
    xhr.onerror = function (e) {
      console.error("Error 2", xhr.statusText);
    };
          
   
    let urlEncodedData = "user=" + user + "&password=" + pwd; 
    xhr.send(urlEncodedData);
  }

  useEffect(() => {
        console.log("Q1",Document.referrer);
        console.log("Q2",Document);
        if(localStorage.getItem("ruolo")!=null) {
          /*history.replace({        
            //pathname: "/fascicoli",
            pathname: "/init",            
        });*/
        navigate("/init");
        }
        
  
}, []);

  return (
    <div className="content" style = {{alignItems: "center", display: "flex", height: "100%",  justifyContent: "center"}}>
      <div className="AppLogin">
        <img src={logo} className="logo1" alt="Logo" />
        <form className="form1" onSubmit={handleSubmit}>
          <div className="input-group">
            <label htmlFor="username">Username</label>
            <input type="username" name="username" placeholder="username" />
          </div>
          <div className="input-group">
            <label htmlFor="password">Password</label>
            <input type="password" name="password" placeholder="*******"/>
          </div>
          <button className="button11">Login</button>
        </form>
        <button className="button12" onClick={handleClick}>
          Password dimenticata?
        </button>
      </div>
      </div>
  );
  
  }  
