import React, { useState, useEffect, useContext } from "react";
//import { useHistory, useParams } from "react-router-dom";
import { useParams } from "react-router-dom";
import RouterContext from "../../context/RouterContext";
import {FormControlLabel} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import "../../App.css";
import { IconButton, Fab, Tooltip } from '@material-ui/core/';
import CircularProgress from "@material-ui/core/CircularProgress";
import * as Icons from '@material-ui/icons/';
import AddIcon from '@material-ui/icons/Add';
import PublishIcon from '@material-ui/icons/Publish';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import {ThreeDots} from 'react-loader-spinner';
import {properties} from "utils/properties";
import { List } from 'react-virtualized';
import { useNavigate } from "react-router-dom";
import Select,  {
    components,
    //MultiValueGenericProps,
    //MultiValueProps,
    //OnChangeValue,
    //Props,
  } from 'react-select';
import {
    SortableContainer,
    //SortableContainerProps,
    SortableElement,
    //SortEndHandler,
    SortableHandle,
  } from 'react-sortable-hoc';
import ContentEditable from 'react-contenteditable';


export default function UpdateAttachments() {    
    let { idDossier } = useParams();
    const [selectedItem, setSelectedItem]=useState(null);    
    const [loadingGen, setLoadingGen] = React.useState(false);   
    const [AttachmentData, setAttachmentData] = useState([]);
    const { pageRouter, setPageRouter } = useContext(RouterContext);
    //const history = useHistory();    
    const navigate = useNavigate();
    const [additionalData, setAdditionalData] = useState();    
    const meetingId = idDossier ;
    const [somethingChange, setSomethingChange]= useState("");   
    const [loading, setLoading]= React.useState(true);    
    const itemsRef = React.useRef([]);
    const itemsRefAmbito = React.useRef([]);
    const itemsRefRelazione = React.useRef([]);
    const itemsRefCheckbox = React.useRef([]);
    
    const [checkbox1State, setCheckBox1State]= React.useState(false);    
    const [checkbox2State, setCheckBox2State]= React.useState(false);    
    const [checkbox3State, setCheckBox3State]= React.useState(false);    
    const [listaPratiche, setListaPratiche]= useState();
    //const hiddenFileUpload2 = React.useRef(null);
    //let pratiche=[];
    
    
    const manyItems = [...new Array(900)].map((_, i) => ({
        //value: i,
        value: `${i}`,
        //label: `something${i}`,
        label: `${i}`,    
      }));



      function arrayMove(array, from, to) {
        const slicedArray = array.slice();
        slicedArray.splice(
          to < 0 ? array.length + to : to,
          0,
          slicedArray.splice(from, 1)[0]
        );
        return slicedArray;
      }
    
    const SortableMultiValue = SortableElement( 
        (props) => {
          // this prevents the menu from being opened/closed when the user clicks
          // on a value to begin dragging it. ideally, detecting a click (instead of
          // a drag) would still focus the control and toggle the menu, but that
          // requires some magic with refs that are out of scope for this example    
          const onMouseDown = (e) => {
            e.preventDefault();
            e.stopPropagation();
          };
          const innerProps = { ...props.innerProps, onMouseDown };
          return <components.MultiValue {...props} innerProps={innerProps} />;
        }
    );
    
    const SortableMultiValueLabel = SortableHandle(
        (props) => <components.MultiValueLabel {...props} />
      );
    
    const SortableSelect = SortableContainer(Select);
    
    const onSortEnd = ({ oldIndex, newIndex},e) => {
        if (e ){
            console.log("WER0",loadingGen, selectedItem,selectedItem.loading);                
            var ics=selectedItem;
            
            if (!loadingGen) { ics.loading=true; selectedItem.loading=true; setLoadingGen(true);
            }   
        //console.log("WER1",oldIndex, newIndex);
        //console.log("WER2",e);    
        console.log("WER1",ics.PraticheCollegate)
        //var nam=e.srcElement.parentNode.parentNode.parentNode.parentNode.firstChild.firstChild.children[1].firstChild;
        
        //console.log("WER3",selectedItem.Descrizione);
        //console.log("WER4",selectedItem.PraticheCollegate);        
        var arr3=ics.PraticheCollegate;
        var arr4=arrayMove(arr3,oldIndex,newIndex);
        console.log("WER2",ics.PraticheCollegate,arr4);
        if (JSON.stringify(arr3)!==JSON.stringify(arr4)){          
            var val=JSON.stringify(arr4);
            //var item=selectedItem;
            ics.PraticheCollegate=arr4;  
            setSelectedItem(ics);
            console.log("changed2",selectedItem.Descrizione, "Pratiche", val,selectedItem);                     
            //doPost(selectedItem.Descrizione, "Pratiche", val,selectedItem);                     
            doPost(selectedItem.Descrizione, "Pratiche", val,ics);                     
        }
        else{
            console.log("unchanged");
            selectedItem.loading=false;
            delete selectedItem.loading;                                 
            setLoading(false);  
            setLoadingGen(false);
            }
        }
    };


    const onSortEnd2 = ({ oldIndex, newIndex},e) => {
        if (e ){
            console.log("WER0-2",loadingGen, selectedItem.loading);
            console.log("WER-1",selectedItem);
            if (!loadingGen) { selectedItem.loading=true; setLoadingGen(true);}
        console.log("WER1-2",oldIndex, newIndex);
        console.log("WER2-2",e);
        console.log(selectedItem.Urls);
        //var nam=e.srcElement.parentNode.parentNode.parentNode.parentNode.firstChild.firstChild.children[1].firstChild;
        
        console.log("WER3-2",selectedItem.Descrizione);
        console.log("WER4-2",selectedItem.Urls);
        var arr3=selectedItem.Urls;
        var arr4=arrayMove(arr3,oldIndex,newIndex);
        
        if (JSON.stringify(arr3)!==JSON.stringify(arr4)){
            var val=JSON.stringify(arr4);
            var item=selectedItem;
            item.Urls=arr4;
            setSelectedItem(item);
            console.log("changed2-2",selectedItem.Descrizione, "Pratiche", val,selectedItem);                
            doPost(selectedItem.Descrizione, "Urls", val,selectedItem);
        }
        else
            console.log("unchanged");
            selectedItem.loading=false;
            delete selectedItem.loading;                                 
            setLoading(false);  
            setLoadingGen(false);
        }
        
    };

    const getSettore=function(item){
        console.log("getSettore", item)
        var nome= item.Descrizione;
        var praticheCollegate= item.PraticheCollegate;
        var vale="";
        
        if(nome.startsWith("_FZ")){
            vale=nome.substr(3, nome.indexOf(" ")-3);                                    
            return vale;
        }
        if(nome.startsWith("_FN")){
            vale=nome.substr(3, nome.indexOf(" ")-3);
            return vale;
        }
        if(nome.startsWith("_FX")){
            vale=nome.substr(3, nome.indexOf(" ")-3);
            return vale;
        }
        if(nome.startsWith("_F")){
            vale=nome.substr(2, nome.indexOf(" ")-2);
            return vale;
        }
        /*if(item.IdentificativoSezione <=100){
            vale=item.IdentificativoSezione;
            return vale;
        }*/
        //console.log("begin VALE",praticheCollegate, listaPratiche);
        //console.log("begin",praticheCollegate, pratiche);
        if(listaPratiche!=undefined){
        praticheCollegate.forEach( entry3 => {
            listaPratiche.forEach(entry4 => {
                //pratiche.forEach(entry4 => {
                if(entry3===entry4.value){
                    //console.log("found21",entry4,vale, entry4.settore);
                    if(vale!==""){
                        //console.log("found21 vale != ",entry4.value,vale, entry4.settore);
                        if(isSameMacroSector(vale,entry4.settore)!=="100")
                        {
                            vale=isSameMacroSector(vale,entry4.settore);
                        }
                        else{
                            if(vale!==entry4.settore){                                
                                vale="100";                                
                                return vale;
                            }
                        }
                    }                                
                    else{
                        //console.log("found21 VALE ",entry3.value,vale, entry3.settore);
                        vale=entry4.settore;
                    }
                }
            });
        });            
        }        
        console.log("Returning get settore",vale);
        return vale;
    }


    const getFileName=function(check1, select1, check2, check3, item){
        console.log("1+++",check1, select1, check2, check3, item);
        var nome=item.Descrizione;
        if(item.Descrizione.startsWith("_F")){
            nome=item.Descrizione.substr(item.Descrizione.indexOf(" "));                                       
            nome=nome.trim();
        }
        //console.log("nome ripulito",nome);
        console.log("missione", select1);
        if(check1===false){
            console.log("nome ripulito",nome);
            return nome;
        }        
        if(check2===true && check3==true){
            nome="_FZ"+select1.value+ " "+nome;
            console.log("nome ripulito",nome);
            return nome;
        }
        
        if(check2===true && check3==false){
            nome="_FN"+select1.value+ " "+nome;
            console.log("nome ripulito",nome);
            return nome;
        }

        if(check2===false && check3==true){
            nome="_FX"+select1.value+ " "+nome;
            console.log("nome ripulito",nome);
            return nome;
        }

        if(check2===false && check3==false){
            nome="_F"+select1.value+ " "+nome;
            console.log("nome ripulito",nome);
            return nome;
        }
        return nome;                 
    }



    
    const getColor=function(item){
        var nome= item.Descrizione;
        var praticheCollegate= item.PraticheCollegate;
        //nome = "_FN64 "+nome;
        //console.log(nome);
        //console.log("begin c",praticheCollegate, listaPratiche);
        var color="#000000" ;
        var vale="";
        
        if(nome.startsWith("_FZ")){
            vale=nome.substr(3, nome.indexOf(" ")-3);
            console.log("32",vale);
            color=fromSettoreCodiceToColor(vale);
            console.log("33",color);
            return color;
        }
        if(nome.startsWith("_FN")){
            vale=nome.substr(3, nome.indexOf(" ")-3);
            //console.log("32",vale,"-");
            color=fromSettoreCodiceToColor(vale);
            //console.log("33",color);
            return color;
        }
        if(nome.startsWith("_FX")){
            vale=nome.substr(3, nome.indexOf(" ")-3);
            //console.log("32",vale,"-");
            color=fromSettoreCodiceToColor(vale);
            //console.log("33",color);
            return color;
        }

        if(nome.startsWith("_F")){
            vale=nome.substr(2, nome.indexOf(" ")-2);
            //console.log("32",vale);
            color=fromSettoreCodiceToColor(vale);
            //console.log("33",color);
            return color;
        }
        /*if(item.IdentificativoSezione <=100){
            color=fromSettoreCodiceToColor(item.IdentificativoSezione);
            return color;
        }*/
        //console.log("begin c",praticheCollegate, listaPratiche);
        //console.log("begin",praticheCollegate, pratiche);
        if(listaPratiche!=undefined){
        praticheCollegate.forEach( entry3 => {
            listaPratiche.forEach(entry4 => {
                //pratiche.forEach(entry4 => {
                if(entry3===entry4.value){
                    //console.log("found21",entry4,vale, entry4.settore);
                    if(vale!==""){
                        //console.log("found21 vale != ",entry4.value,vale, entry4.settore);
                        if(isSameMacroSector(vale,entry4.settore)!=="100")
                        {
                            vale=isSameMacroSector(vale,entry4.settore);
                        }
                        else{
                            if(vale!==entry4.settore){
                                //console.log("found21 vale !=2 ",entry4.value,vale, entry4.settore);
                                vale="100";
                                color=fromSettoreCodiceToColor(vale);
                                //console.log("END 19",color);
                                return color;
                            }
                        }
                    }                                
                    else{
                        //console.log("found21 else ",entry3.value,vale, entry3.settore);
                        vale=entry4.settore;
                    }
                }
            });
        });            
        }
        color=fromSettoreCodiceToColor(vale);
        //console.log("EBD",listaPratiche);
        //console.log("END",color);
        return color;
    }


    const fromSettoreCodiceToColor = function(codice) {
        if (codice>=80 && codice<90)  {//PIANETA
            return ("#0084d5");
        }
        else{
            if (codice>=70 && codice<80)  {//Cultura
                return ("#ce0f69");                
            }
            else{
                if (codice>=60 && codice<70)  {//Persone
                    return ("#F0B323");
                }
                else {
                    if (codice==100)  {//Box Neutro
                        return ("#DDDDDD");
                    }
                    else{
                        if (codice>=90 && codice <=95) //Direzioni
                            return ("#FFD480");   
                        else{
                            if (codice==98) {//In evidenza
                            return ("#046A38");
                            }
                            else //non dovrebbe mai capitare
                                return ("#000000");   
                        }
                    }
                }            
            }
        }
    }   

    const isSameMacroSector= function(codice1, codice2){          
        if (codice1===codice2)
            return codice1;
        if (codice1>=80 && codice1<90 && codice2>=80 && codice2<90)  {//PIANETA
                return ("85");
            }
        else{
            if (codice1>=70 && codice1<80 && codice2>=70 && codice2<80)  {//Cultura
                return ("74");                
            }
            else{
                if (codice1>=60 && codice1<70 && codice2>=60 && codice2<70)  {//Persone
                    return ("67");
                }
                else {
                    return ("100");                     
                }            
            }
        }           
    }


    function convertIdToMissione(idi) {
        console.log("22222",idi);
        if ((idi != undefined) && (idi != '') &&(idi != ' ')){
            //if(idi< 60)
            //    return missioniValues.find(o => o.value === "100").label;
            //else
                return missioniValues.find(o => o.value === idi).label;        
        }
    }

    const missioniValues =[        
        {value: "62", label:"Abitare"},
        {value: "63", label:"Lavoro"},
        {value: "64", label:"Educazione"},
        {value: "65", label:"Comunita'"},
        {value: "66", label:"Inclusione"},
        {value: "67", label:"PERSONE"},
        {value: "68", label:"Risorse Libere Persone"},
        {value: "70", label:"Attrattivita'"},
        {value: "71", label:"Competenze"},
        {value: "72", label:"Custodia"},
        {value: "73", label:"Partecipazione"},
        {value: "74", label:"CULTURA"},
        {value: "75", label:"Risorse Libere Cultura"},
        {value: "80", label:"Ricerca"},
        {value: "81", label:"Innovazione"},
        {value: "82", label:"Benessere"},
        {value: "83", label:"Internazionalizzazione"},
        {value: "84", label:"Ambiente"},
        {value: "85", label:"PIANETA"},
        {value: "86", label:"Risorse Libere Pianeta"},
        {value: "90", label:"DIREZIONE PIANIFICAZIONE, STUDI E VALUTAZIONE"},
        {value: "95", label:"DIREZIONE INNOVAZIONE DI IMPATTO"},
        //{value: "98", label:"IN EVIDENZA"},
        {value: "98", label:"INDICE E SITUAZIONE FONDI"},
        //{value: "100",label:"PROGETTI SPECIALI / INTERSETTORIALI E PRATICHE AGGIUNTIVE"},  
        {value: "100", label:"CONVENZIONI, PROGETTI SPECIALI, ULTERIORI PRATICHE E DOCUMENTI"},    
        {value: "1", label:"Ricerca e Istruzione Superiore"},
        {value: "5", label:"Patrimonio Artistico"},
        {value: "6", label:"Arte, Attivita' e Beni Culturali"},
        {value: "7", label:"Attivita' Culturali"},
        {value: "9", label:"Sanita'"},
        {value: "10", label:"Filantropia e Territorio"},
        {value: "11", label:"Politiche Sociali"},
        {value: "12", label:"Innovazione Culturale"},
        {value: "19", label:"Volontariato"},
        {value: "21", label:"Protocollo Intesa A.C.R.I."},
        {value: "25", label:"Lasciti e Donazioni"},
        {value: "35", label:"Programma Torino e le Alpi"},
        {value: "40", label:"Programma Polo del '900"},
        {value: "45", label:"Programma ZeroSei"},
        {value: "50", label:"Programma Housing"},
        {value: "55", label:"Sperimentazione e Attuazione Piano Strategico"},
        {value: "57", label:"Innovazione di Impatto"},
        {value: "59", label:"Programma Interational Affairs"},          
    ];
    
    const getDocList= function(items) {
        var temp3=[];
        items.forEach( entry2 => {
            var rmp= entry2.substr(entry2.lastIndexOf("/")+1);            
            //console.log("----", entry2);
            //console.log("----", rmp);
            var itemX = {                                                                
                value: rmp,
                label: rmp
            };
            //var tempItem2=entry2.Richiesta.IdRichiesta;                                    
            temp3.push(itemX);
            });
        //console.log("OUT22", temp3);
        return temp3;
    }

    function handleChange3(event, item,i) {        
        console.log("HC3",event,item,i,event.value);
        console.log("WER0-3",loadingGen, selectedItem,selectedItem.loading);  
        //if (event && event.value){
        if (!loadingGen) { item.loading=true; setLoadingGen(true);}  
        //}   
        //console.log("handleChange3",event.target.checked);
        //console.log("handleChange3",event.target);
        //console.log("handleChange3",item, i );
        //console.log("ambito", itemsRefAmbito.current[i].control.checked);
        //console.log("relazione", itemsRefRelazione.current[i].control.checked);        
        //console.log("settore",itemsRef.current[i].props.value);
        //console.log("checkbox settore",itemsRefCheckbox.current[i].control.checked);
        //console.log("HC3",getFileName(itemsRefCheckbox.current[i].control.checked,itemsRef.current[i].props.value, itemsRefAmbito.current[i].control.checked, itemsRefRelazione.current[i].control.checked,  item));
        var a=getFileName(itemsRefCheckbox.current[i].control.checked, itemsRef.current[i].props.value, itemsRefAmbito.current[i].control.checked, itemsRefRelazione.current[i].control.checked, item);
        a= a.trim();
        console.log("HC3",a);
        setSelectedItem(item);
        console.log("HC3-1", selectedItem, item, a);
        //doPost(item.Descrizione, "SettoreCodice", event.value,item);    
        test2(a);
        doPost(item.Descrizione, "Titolo", a ,item);
        //console.log("BT",item.Descrizione);
               
        //console.log("settore",itemsRef.current[i].defaultValue);


        //doPost(id, "Forzare", event.target.checked);
        //console.log(getLabelFromIdMissione(id));
      }

      function handleChange4(event, item,i) {     
        console.log("HC4",event,item,i,event.value);   
        if (!loadingGen) { item.loading=true; setLoadingGen(true);}  
        //console.log("handleChange4",event.target.checked);
        
        //console.log("handleChange4",item, i );
        
        //console.log("settore",itemsRef.current[i].props.value);
        //console.log("checkbox settore",itemsRefCheckbox.current[i].control.checked);
        //console.log("ambito", itemsRefAmbito.current[i].control.checked);
        //console.log("relazione", itemsRefRelazione.current[i].control.checked);

        //console.log("HC4",getFileName(itemsRefCheckbox.current[i].control.checked,itemsRef.current[i].props.value, itemsRefAmbito.current[i].control.checked, itemsRefRelazione.current[i].control.checked,  item));
        var a=getFileName(itemsRefCheckbox.current[i].control.checked, itemsRef.current[i].props.value, itemsRefAmbito.current[i].control.checked, itemsRefRelazione.current[i].control.checked, item);
        a= a.trim();
        //console.log("HC4",a);
        setSelectedItem(item);        
        //doPost(item.Descrizione, "SettoreCodice", event.value,item);    
        test2(a);
        doPost(item.Descrizione, "Titolo", a ,item);
        //console.log("BT",item.Descrizione);
        //test(item,i);
        //doPost(id, "Forzare", event.target.checked);
        //console.log(getLabelFromIdMissione(id));
      }


      function handleChange5(event, item,i) {    
        if (!loadingGen) { item.loading=true; setLoadingGen(true);}  
        console.log("HC5",event,item,i,event.value);    
        //console.log("handleChange5",event.target.checked);
        //console.log("handleChange3",event.target);
        //console.log("handleChange5",item, i );
        
        //console.log("settore",itemsRef.current[i].props.value);
        //console.log("checkbox settore",itemsRefCheckbox.current[i].control.checked);
        //console.log("ambito", itemsRefAmbito.current[i].control.checked);
        //console.log("relazione", itemsRefRelazione.current[i].control.checked);        
        //console.log("HC5",getFileName(itemsRefCheckbox.current[i].control.checked,itemsRef.current[i].props.value, itemsRefAmbito.current[i].control.checked, itemsRefRelazione.current[i].control.checked,  item));
        var a=getFileName(itemsRefCheckbox.current[i].control.checked, itemsRef.current[i].props.value, itemsRefAmbito.current[i].control.checked, itemsRefRelazione.current[i].control.checked, item);
        a= a.trim();
        console.log("HC5",a);
        setSelectedItem(item);  
        test2(a);
        //doPost(item.Descrizione, "SettoreCodice", event.value,item);    
        doPost(item.Descrizione, "Titolo", a ,item);
        //doPost(id, "Forzare", event.target.checked);
        //console.log(getLabelFromIdMissione(id));
      }

    function checkFor_F(item) {
        //console.log("CFC", item);                
        if(item.Descrizione.startsWith("_F"))
            return true;
        else
            return false;
        //doPost(id, "Forzare", event.target.checked);
        //console.log(getLabelFromIdMissione(id));
      }

    
    

    
    const doPostToGetList = function() {
        console.log("doing sedute post to get list");
            var xhr2 = new XMLHttpRequest();                
            xhr2.open('GET', properties.productionUrl+'/rest/getSedutaReceived?idf='+meetingId,true);
            xhr2.setRequestHeader("Content-type", "application/x-www-form-urlencoded");    
            xhr2.onload = function (e) {      
              if (xhr2.readyState === 4) {
                if (xhr2.status === 200) {                              
                    console.log("OK192",xhr2);
                    var responseText = xhr2.responseText;                    
                    var obj=JSON.parse(responseText);
                    console.log("OK193",obj);                              
                    const temp1= Object.entries(obj);        
                    temp1.forEach( entry => {
                        let key= entry[0];                        
        
                        if(key==='Schede') {                                                            
                            //console.log("Step1", entry[1],new Date().getTime());
                            const sorted=entry[1].slice().sort((a, b) =>parseInt(a.Richiesta.IdRichiesta)- parseInt(b.Richiesta.IdRichiesta));                                                                                   
                            var temp3 = [];
                            //var temp2 = [];
                            sorted.forEach( entry2 => {
                                    //console.log("----", entry2.Richiesta.IdRichiesta);                                                                
                                    var itemX = {                                                                
                                        value: entry2.Richiesta.IdRichiesta,  
                                        label: entry2.Richiesta.IdRichiesta,
                                        settore: entry2.Richiesta.SettoreCodice                                        
                                    };  
                                    //var tempItem2=entry2.Richiesta.IdRichiesta;                                    
                                    temp3.push(itemX);
                                    });             
                            console.log("OUT11", temp3);                                                                        
                            setListaPratiche(temp3);      
                            //pratiche=temp3;    
                            //console.log("Out22", temp3, pratiche);                  
                        }                        
                     });                      
                } 
                else {
                  //console.error("Error 1", xhr.statusText);          
                  alert("Errore recuperando la seduta raw richiesta 1");
                  /*history.replace({                                
                    pathname: "/init",
                });*/
                navigate("/init",{ replace: true });  

                }
              }
            };
            xhr2.onerror = function (e) {
                console.error("Error 122", xhr2.statusText);
              };
              
              let urlEncodedData = null;    
              xhr2.send(urlEncodedData);
          }
    


    const doPostNoUpdate = function(scheda, campo, valore,item) {        
        var xhr = new XMLHttpRequest();        
        xhr.open('POST', properties.productionUrl+'/rest/updateAmbitoFields',true);
            xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
            xhr.onload = function (e) {      
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {                              
                        console.log("OK72",xhr);
                        //var responseText = xhr.responseText;    
                        doPostSeduta113();
                        console.log("called 2");
                        }                                    
                    else {                
                        alert("Errore aggiorndo received");  
                    }
                }
            };
        xhr.onerror = function (e) {
                console.error("Error 2", xhr.statusText);
        };
                
        let urlEncodedData = "idf=" + idDossier + "&Descrizione=" + b64EncodeUnicode(scheda)+ "&field=" + campo+ "&value=" + b64EncodeUnicode(valore); 
        console.log(urlEncodedData);
        xhr.send(urlEncodedData);

    }
    //doPostNoUpdate(item.Descrizione, "Titolo", event.target.outerText,item); 

    const doPost = function(scheda, campo, valore,item) {        
        var xhr = new XMLHttpRequest();        
        xhr.open('POST', properties.productionUrl+'/rest/updateAmbitoFields',true);
            xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
            xhr.onload = function (e) {      
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {                              
                        //console.log("OK92",xhr);
                        var responseText = xhr.responseText;                    
                        var obj=JSON.parse(responseText);
                        //console.log("OK493",obj);          
                        //console.log("Step1", new Date().getTime());    
                        const temp1= Object.entries(obj);        
                        var temp3 = [];
                        temp1.forEach( entry => {
                            let key= entry[0];                        
                            if(key==='File') {                                                                                            
                                entry[1].forEach( entry2 => {                                    
                                        temp3.push(entry2);
                                });  
                            }                
                         }); 
                         var temp4=[];
                         if(temp3!=null && temp3 != undefined){
                            console.log("TEMP31", temp3);
                            temp3.forEach( entry2 => {                                
                                if(entry2.TipoRiga !== "DL"){                                    
                                    temp4.push(entry2);
                                }
                            });                            
                            //console.log("TEMP41", temp4);  
                            //console.log("POST", selectedItem);
                            //setSelectedItem(item);                                                        
                            //showAttributes(item,item);
                            //console.log("DP",AttachmentData, temp4,item,selectedItem);
                                                      
                            temp4.sort(function(a, b) {
                                var keyA = new Date(a.Ordine),
                                keyB = new Date(b.Ordine);
                    
                                if (keyA < keyB) return -1;
                                if (keyA > keyB) return 1;
                                return 0;
                            });
                            console.log("21-10-22_2+",temp4);
                            setAttachmentData(temp4);                                                         
                            //showAttributes(item,item);
                            //getAdditionalData(getDescrizione(item.Descrizione));
                            if(campo==="Titolo"){
                                item.Descrizione=valore;
                            }   
                            item.loading=false;
                            delete item.loading;                     
                            setSelectedItem(item);
                            setLoading(false);  
                            setLoadingGen(false);
                            
                            /*
                            delete item.loading
                            console.log("POST",item);
                            setSelectedItem(item);
                            setLoading(false);  
                            setLoadingGen(false);    
                            */
                        }       
                    }                                   
                    else {                
                        alert("Errore aggiorndo received");  
                    }
                }
            };
        xhr.onerror = function (e) {
                console.error("Error 2", xhr.statusText);
        };
        //if (!loadingGen) { item.loading=true; selectedItem.loading=true; setLoadingGen(true);
        //} 
        let urlEncodedData = "idf=" + idDossier + "&Descrizione=" + b64EncodeUnicode(scheda)+ "&field=" + campo+ "&value=" + b64EncodeUnicode(valore); 
        console.log("DOPOST",urlEncodedData, item);
        xhr.send(urlEncodedData);

    
    }

    const deleteAmbito = (event,item) => {
        console.log("Delete ambito",item);   
        
        if (!loadingGen) { item.loading=true; setLoadingGen(true);}         
        let endpoint = properties.productionUrl+"/php/core/rest/deleteAmbito.php";                        
        var bodyFormData=new FormData();
                    
        bodyFormData.append('idf', idDossier);
        bodyFormData.append('Descrizione', b64EncodeUnicode(item.Descrizione));
        axios({
            method: 'post',
            url: endpoint,
            data: bodyFormData, 
            headers: {'Content-Type': 'multipart/form-data' }
        })
        .then(function (response) {
            item.loading=false;
            setLoadingGen(false);
            console.log(response);            
            //doPostSedute2();
            doPostSeduta113();
        })
        .catch(function (response) {
            console.log(response);
        });
        
    }  


    function b64EncodeUnicode(str) {
        // first we use encodeURIComponent to get percent-encoded UTF-8,
        // then we convert the percent encodings into raw bytes which
        // can be fed into btoa.
        return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
            function toSolidBytes(match, p1) {
                return String.fromCharCode('0x' + p1);
        }));
    }

    
    function handleChange111(event, id, item) {
        //SELECT PRATICHE COLLEGATE
        if (event ){
            if (!loadingGen) { item.loading=true; setLoadingGen(true);} 
            console.log("HC211",event, loadingGen,item.loading);
            //console.log("HC187",item.Descrizione, event,item);
            var arr=[];
            event.map((item2, index) => {
                //arr.push(JSON.stringify(item.value));
                arr.push(item2.value);
            });              
            var val=JSON.stringify(arr);
            console.log("HC212",val);         
            console.log("HC213",item.Descrizione, "Pratiche", val,item); 
            item.PraticheCollegate=arr;                    
            setSelectedItem(item);
            doPost(item.Descrizione, "Pratiche", val,item);                     

            //doPostNoUpdate(item.Descrizione, "Pratiche", val,item);                     
            
        }        
         
      }

      function handleChange112(event, id, item) {
        //SELECT DOCUMENTI
        if (event ){
            if (!loadingGen) { item.loading=true; setLoadingGen(true);} 
            console.log("HC211",event, loadingGen,item.loading);
            //console.log("HC187",item.Descrizione, event,item);
            var arr=[];
            event.map((item2, index) => {
                //arr.push(JSON.stringify(item.value));
                arr.push(item2.value);
            });              
            var val=JSON.stringify(arr);
            console.log("HC212",val);         
            console.log("HC213",item.Descrizione, "Urls", val,item); 
            item.Urls=arr;                    
            setSelectedItem(item);
            console.log("VER4",item, selectedItem);
            doPost(item.Descrizione, "Urls", val,item);                     
            
            //console.log(item.Descrizione, "Pratiche", val,item);                     
        }        
         
      }


    function handleChange1(event, id, item,i) {
        //SELECT MISSIONE/SETTORE         
        console.log("HC1",event,item,i,event.value);
        if (event && event.value){
            if (!loadingGen) { item.loading=true; setLoadingGen(true);} 
            console.log("EW",event);
            console.log("EW1",event.label);           
            console.log("EW2",id);        
        }        
        console.log("HC11",item.Descrizione, "swe", event.value,"swe",item);
        var a=getFileName(itemsRefCheckbox.current[i].control.checked, event, itemsRefAmbito.current[i].control.checked, itemsRefRelazione.current[i].control.checked, item);
        console.log("HC12",a);
        //doPost(item.Descrizione, "SettoreCodice", event.value,item);    
        doPost(item.Descrizione, "Titolo", a ,item);
      }

    function handleChange2(event, id,item) {
        //ORDINE
        console.log("HC2",event,item,event.value);
        if (event && event.value){
            if (!loadingGen) { item.loading=true; setLoadingGen(true);}         
        console.log("QW",event);                
        console.log("QW2",id);
        console.log("QW3",item);
        }
        console.log("HC2",item.Descrizione, event.value,item);
        doPost(item.Descrizione, "Ordine", event.value,item);    

    }
    
    /*function handleEditableChange(event, id, item) {                        
            console.log("CHANGE",event.target.value,id,item);                        
            console.log(id.name);
      }
    */

    function handleEditableBlur(event, item,i) {                        
        console.log("BLUR",event.target.outerText, item);                        
        console.log(item.Descrizione);
        if (!loadingGen) { item.loading=true; setLoadingGen(true);} 
        //var a=getFileName(itemsRefCheckbox.current[i].control.checked, event.target.outerText, itemsRefAmbito.current[i].control.checked, itemsRefRelazione.current[i].control.checked, item);
        //a= a.trim();
        var nome=event.target.outerText;
        if(item.Descrizione.startsWith("_F")){

            nome=item.Descrizione.substr(0, item.Descrizione.indexOf(" "));                                       
            nome=nome.trim();
            nome= nome +" "+event.target.outerText;
            //console.log("SUC",nome);
        }

        console.log("TEMP1",nome);        
        doPostNoUpdate(item.Descrizione, "Titolo", nome,item); 
        
        
        

        //console.log(item.Descrizione, "Titolo", event.target.outerText,item);
        //console.log(item.Descrizione, "Titolo", nome,item);
        
    }

    function handleKeyPress(event){
        if(event.keyCode === 13){
        event.target.blur();       
        }
    }

    useEffect(() => {              
        if(process.env.NODE_ENV !=='development'){            
        //CHECK AUTHENTICATION
            var xhr = new XMLHttpRequest();                
            xhr.open('POST', properties.productionUrl+'/rest/ping',true);
            xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
    
            xhr.onload = function (e) {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {                    
                        //doPostSedute(); 
                    console.log("OK",xhr);
                    //history.push({                
                    //pathname: "/login/",
                    //});
                } else {
                    console.error("Error 1", xhr);   
                    localStorage.clear();                 
                    /*history.push({                
                    pathname: "/login",
                    });*/
                    navigate("/login");  

                }
            }
            };
    
            xhr.onerror = function (e) {
                console.error("Error 2", xhr);
                localStorage.clear();
                /*history.push({                
                    pathname: "/login",
                    });*/
                    navigate("/login");  
            };
             
            let urlEncodedData = null; 
            xhr.send(urlEncodedData);
        //CHECK AUTHENTICATION
        }
                
        doPostToGetList();
        
        doPostSeduta113();

        /*setPageRouter({
            router: history.location.pathname,
            homecolor: "#FF5A60",
            settingcolor: "#879497",
            homebordercolor: "#FF5A60",
            settingbordercolor: "#FFFFFF",
            hometitlecolor: "black",
            settingtitlecolor: "#879497",
        }); */
    }, []);

  
    const getDescrizione = (descrizione) => {                                        
        var nome=descrizione;
        if(descrizione.startsWith("_F")){
            nome=descrizione.substr(descrizione.indexOf(" "));                                                   
        }  
        return nome;
    }


    const test=(item,index) => {
        console.log("INTO TEST",item.Descrizione);
        setCheckBox1State(false);        
        setCheckBox2State(false);
        setCheckBox3State(false);
        if(item.Descrizione.startsWith("_F"))
            setCheckBox1State(true);                
        if(item.Descrizione.startsWith("_FX")){
            setCheckBox2State(false);
            setCheckBox3State(true);            
        } 

        if(item.Descrizione.startsWith("_FZ")){
            setCheckBox2State(true);
            setCheckBox3State(true);            
        } 
        if(item.Descrizione.startsWith("_FN")){
            setCheckBox2State(true);
            setCheckBox3State(false);
        }     
    } 

    const test2=(descrizione) => {
        console.log("INTO TEST",descrizione);
        setCheckBox1State(false);        
        setCheckBox2State(false);
        setCheckBox3State(false);
        if(descrizione.startsWith("_F"))
            setCheckBox1State(true);                
        if(descrizione.startsWith("_FX")){
            setCheckBox2State(false);
            setCheckBox3State(true);            
        } 

        if(descrizione.startsWith("_FZ")){
            setCheckBox2State(true);
            setCheckBox3State(true);            
        } 
        if(descrizione.startsWith("_FN")){
            setCheckBox2State(true);
            setCheckBox3State(false);
        }     
    } 



//REVISIT
const doPostSeduta113 = function(items) {
    //setSomethingChange("");
    
    var xhr2 = new XMLHttpRequest();                
    xhr2.open('GET', properties.productionUrl+'/rest/getFileSedutaReceived?idf='+meetingId,true);
    xhr2.setRequestHeader("Content-type", "application/x-www-form-urlencoded");    
    xhr2.onload = function (e) {      
      if (xhr2.readyState === 4) {
        if (xhr2.status === 200) {                              
            console.log("OK92",xhr2);
            var responseText = xhr2.responseText;                    
            var obj=JSON.parse(responseText);
            console.log("OK93",obj);          
            console.log("Step1", new Date().getTime());    
            const temp1= Object.entries(obj);        
            var temp3 = [];
            temp1.forEach( entry => {
                let key= entry[0];
                //let value= entry[1];
                if(key==='File') {                                                            
                    //console.log("Step1", entry[1],new Date().getTime());
                    //console.log("Step2",entry);
                    entry[1].forEach( entry2 => {
                        //console.log("BOTCH",entry2);
                        //if(entry2.Tipologia==="Documento libero" || entry2.Tipologia==="Documento Libero"){
                            temp3.push(entry2);
                        //}                       
                    });  
                }                
             }); 
             var temp4=[];
             if(temp3!=null && temp3 != undefined){
                console.log("TEMP3", temp3);
                temp3.forEach( entry2 => {
                    
                    if(entry2.TipoRiga !== "DL"){
                        //console.log(entry2.IdentificativoSezione);
                        temp4.push(entry2);
                    }
                });                                
                //console.log("TEMP4", temp4);  
                temp4.sort(function(a, b) {
                    var keyA = new Date(a.Ordine),
                      keyB = new Date(b.Ordine);
                    
                    if (keyA < keyB) return -1;
                    if (keyA > keyB) return 1;
                    return 0;
                  });
                  
                console.log("21-10-22_2+",temp4);
                setAttachmentData(temp4); 
                //doPostToGetList();
                setLoading(false);
                setLoadingGen(false);                                
            }       
        } 
        else {
          //console.error("Error 1", xhr.statusText);          
          alert("Errore recuperando la seduta raw richiesta 2");
                 /* history.replace({                                
                    pathname: "/init",
                }); */
                navigate("/init",{ replace: true });  
        }      
      }
    };

    xhr2.onerror = function (e) {
      console.error("Error 22", xhr2.statusText);
    };
    
    let urlEncodedData = null;    
    xhr2.send(urlEncodedData);    
};

//REVISIT

const fromListToArray = function(praticheCollegate) {        
    var temp4 = [];    
    praticheCollegate.forEach( entry => {
        //console.log("QQ2",entry);
        //listaPratiche.forEach( entry2 => {
            listaPratiche.map((entry2, index) => {
            //console.log("QQ3",entry2);
            if(entry2.value===entry){
                //console.log("QQ4", "found", entry2, index);     
                temp4.push(entry2);
            }
            });            
     });     
     //console.log("QQ6", temp4);
     return temp4;            
}

    
const getAdditionalData = function(desc) {
    console.log("called gad",desc, AttachmentData);  
    //setSomethingChange("");        
    AttachmentData.forEach( entry => {      
            //console.log("test",entry,name);  
            if(getDescrizione(entry.Descrizione).trim()=== desc.trim()){                
                console.log("found Anto",entry);
                var itemX = {
                    //name: entry.name,
                    //desc: entry.Descrizione,
                    idSection: entry.mission,
                    order: entry.order,                    
                    //loading: false
                };   
                setAdditionalData(itemX);
                console.log("GAD",desc);
                if(somethingChange.trim()===desc.trim())
                    setSomethingChange("");
                else    
                    setSomethingChange(desc.trim());   
            }
    });    
}



    const showAttributes = (event,item) => {
        console.log("ShowAttributes",item.Descrizione);   
        //getAdditionalData(item.Descrizione);        
        getAdditionalData(getDescrizione(item.Descrizione));
        //console.log("Update requested2",new Date().getTime());  
    }    




    const onFileChange= (e,item ) => {   
        //console.log("ew3",e); 
        //console.log("ew4",item);
        //if (!loadingGen) { choosenItem.loading=true; setLoadingGen(true);} 
        if (!loadingGen) { item.loading=true; setLoadingGen(true);} 
        e.stopPropagation(); 
        let endpoint = properties.productionUrl+"/php/core/rest/updateAttachment.php";                
        let file=e.target.files[0];
        var bodyFormData=new FormData();
            
        bodyFormData.append('UploadedFile', file);
        //bodyFormData.append('fileName', choosenItem.name);
        bodyFormData.append('fileName', file.name);
        bodyFormData.append('idf', idDossier);

        axios({
            method: 'post',
            url: endpoint,
            data: bodyFormData, 
            headers: {'Content-Type': 'multipart/form-data' }
        })
        .then(function (response) {
                //handle success
            //choosenItem.loading=false;


            //console.log(item.Urls)            
            //var arr3=selectedItem.Urls;
            var arr3=[];
            if (selectedItem.Urls!==null)    
                arr3=selectedItem.Urls;

            //var a="https://comitato.compagniadisanpaolo.it/fileManager/files/premesse/"+idDossier+"/"+file.name;
            var a=file.name;
            arr3.push(a);            
            
            var val=JSON.stringify(arr3);
            var item2=selectedItem;
            item2.Urls=arr3;  
            console.log("VER", item2.Urls);
            setSelectedItem(item2);
            console.log("changed2",selectedItem.Descrizione, "Urls", val,selectedItem);                     
            doPost(selectedItem.Descrizione, "Urls", val,selectedItem);                     
            
            

            item.loading=false;
            setLoadingGen(false);
            console.log(response);
            e.target.files=null;
            e.target.value=null;
            //doPostSedute2();
        })
        .catch(function (response) {
                //handle error
            console.log(response);
        });    
    };



    const onFileChange2= (e ) => {           
        //FAB aggiunta ambito
        console.log("ew2",e,AttachmentData); 
         
        var xhr = new XMLHttpRequest();        
        xhr.open('POST', properties.productionUrl+'/rest/updateAmbitoFields',true);
            xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
            xhr.onload = function (e) {      
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {                              
                        console.log("OK72",xhr);
                        //var responseText = xhr.responseText;    
                        doPostSeduta113();
                        console.log("called 2");
                        }                                    
                    else {                
                        alert("Errore aggiorndo received");  
                    }
                }
            };
        xhr.onerror = function (e) {
                console.error("Error 2", xhr.statusText);
        };
              
        let urlEncodedData = "idf=" + idDossier + "&Descrizione=" + btoa(" ")+ "&field=" + "Titolo"+ "&value=" + btoa(" "); 
        console.log(urlEncodedData);
        xhr.send(urlEncodedData);
    };

    

   

const MenuList = props => {
    const rows = props.children;
    const rowRenderer = ({ key, index, isScrolling, isVisible, style }) => (
      <div key={key} style={style}>{rows[index]}</div>
    );
  
    return (
      <List
        style={{ width: '100%' }}
        width={300}
        height={300}
        rowHeight={30}
        rowCount={rows.length}
        rowRenderer={rowRenderer}
      />
    )
  }
  
  const customStyles = {
    menu: (css) => ({
        ...css,
        width: 400,               
      }),
      control: (css) => ({ ...css, display: "inline-flex " }),
    
  }

  const disableNewlines = event => {
    const keyCode = event.keyCode || event.which

    if (keyCode === 13) {
      event.returnValue = false
      if (event.preventDefault) event.preventDefault()
    }
  }
  
 /* function convertIdToMissione(idi) {    
    if ((idi != undefined) && (idi != '') &&(idi != ' '))
        return missioniValues.find(o => o.value === idi).label;        
}*/

  const pasteAsPlainText = event => {
    event.preventDefault()
    const text = event.clipboardData.getData('text/plain')
    document.execCommand('insertHTML', false, text)
  }

  const highlightAll = () => {
    setTimeout(() => {
      document.execCommand('selectAll', false, null)
      //console.log(document)
    }, 0)
  }


    return (
        <div key={uuidv4()}> 
        {loading ? (
            <div key={uuidv4()} style={{ width:"25%",   display: "flex", 
            marginLeft: "auto", marginRight: "auto" }}> 
             <ThreeDots color="#30720f" height='350' width='150'/>
             </div> 
        ):(

        
        <div className="content">  
        <Tooltip title="Crea nuovo Ambito" placement="left">
            <Fab aria-label="add"                 
                //variant="contained" 
                onClick={(event) => {onFileChange2(event) }}
                
                style={{
                    margin: 0,
                    background: "#30720f",
                    top: 'auto',
                    right: 5,
                    bottom: 25,
                    //color: '0xFF0000',
                    left: 'auto',
                    position: 'fixed',}}>                    
                    <AddIcon />
                    {/*<input id="fileupload00" type="file" name="file" ref={hiddenFileUpload2} onChange={(event)=> onFileChange2(event)} style={{ display: "none" }}/>*/}
                </Fab>                      
        </Tooltip>

            {AttachmentData.map((item, i) => (
             <div className="scheda-item" style={{ borderRightColor: getColor(item), cursor: "pointer", }} key={uuidv4()} >    
                <div className="common-list" key={i} style={{cursor: "pointer",}}>
                
                

                <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", paddingLeft: "30px",paddingBottom:"10px"}} >                    
                    
                    {/*    <div ref={el => itemsRef.current[i] =el} style={{paddingLeft: "30px", textAlign: "left",}}>*/}
                    <div style={{paddingLeft: "30px", textAlign: "left",}}>
                    {item.type==="DL" ? (                            
                            <div>                                                      
                            </div>
                            ): item.type!=undefined ? (
                            <div>                            
                            </div>
                            ):( 
                            <div> 
                                {somethingChange.trim()===getDescrizione(item.Descrizione).trim() ? (
                                    <div style={{ display: "flex", paddingLeft: "0px",paddingTop: "20px", paddingBottom:"10px", margin:"0px"}} key={uuidv4()}>  
                                        <p className= "textGreySedute" style={{ margin: "0px",marginTop:"7px"}}>Ambito:  </p> 
                                        <ContentEditable                                
                                            html={getDescrizione(item.Descrizione)}                                    
                                            className="content-editable"
                                            onKeyPress={disableNewlines}
                                            onPaste={pasteAsPlainText}
                                            onFocus={highlightAll}                                            
                                            onBlur={(event) => handleEditableBlur(event, item,i)}                                        
                                            onKeyDown={(event) => handleKeyPress(event)}
                                            style={{ marginLeft: "10px", padding:"5px",
                                            borderColor: "hsl(0, 0%, 80%)", borderRadius: "0px", borderStyle:"solid", borderWidth:"1px"}}
                                        />  
                                    </div>):( 
                                    <div style={{ display: "flex", paddingLeft: "0px",paddingTop: "20px", paddingBottom:"10px", margin:"0px"}} key={uuidv4()}>  
                                        <p className= "textGreySedute" style={{ margin: "0px",}}>Ambito:  </p> 
                                        <ContentEditable
                                            html={getDescrizione(item.Descrizione)}                                    
                                            className="content-editable"
                                            onKeyPress={disableNewlines}
                                            onPaste={pasteAsPlainText}
                                            onFocus={highlightAll}
                                            disabled={true}
                                            style={{ paddingLeft: "10px"}}                                            
                                        />
                                    </div>    )}                            
                            </div>
                            )}
                            {/*<p className= "textBlackSedute" style={{ margin: "0px",}}>{item.name}
                            </p>
                            */}
                            
                            {somethingChange.trim()===getDescrizione(item.Descrizione).trim() ? (
                                <div style={{ display: "flex", paddingLeft: "0px",paddingTop: "0px", paddingBottom:"10px", margin:"0px"}} key={uuidv4()}>  
                                <p className= "textGreySedute" style={{ margin: "0px",marginTop:"7px",paddingRight:"5px"}}> Pratiche collegate:{" "} </p>
                                <SortableSelect 
                                key={uuidv4()}     
                                useDragHandle       
                                axis="xy"
                                onSortEnd={onSortEnd}
                                index={i}                                 
                                collection={i}                                
                                isMulti                   
                                components={{
                                    // @ts-ignore We're failing to provide a required index prop to SortableElement
                                    MultiValue: SortableMultiValue,
                                    MultiValueLabel: SortableMultiValueLabel,
                                  }}    
                                  //styles={customStyles}                                    
                                  theme={(theme) => ({
                                      ...theme,
                                      borderRadius: 0,
                                      colors: {
                                        ...theme.colors,
                                        primary25: '#a2ee7d',
                                        primary: 'black',
                                      },
                                    })}       
                                options={listaPratiche}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                onChange={(event) => handleChange111(event, item.Descrizione,item)}
                                defaultValue={fromListToArray(item.PraticheCollegate)}/> 

                            </div>                             
                                ):(
                                    <div style={{ display: "flex", paddingLeft: "0px",paddingTop: "20px", paddingBottom:"10px", margin:"0px"}} key={uuidv4()}>  
                                    <p className= "textGreySedute" style={{ margin: "0px",}}> Pratiche collegate:{" "} </p>  
                                    <label key={uuidv4()} style={{paddingLeft:"5px"}}>                                      
                                        {item.PraticheCollegate.map((item2, index2) => (                                            
                                            <span className= "textBlackSedute">{index2>0 ? (","): ("")} {item2}</span>
                                        ))}
                                    </label>                                  
                                    </div>
                            )}          

                            {somethingChange.trim()===getDescrizione(item.Descrizione).trim() ? (
                                <div style={{ display: "flex", paddingLeft: "0px",paddingTop: "0px", paddingBottom:"10px", margin:"0px"}} key={uuidv4()}>  
                                <p className= "textGreySedute" style={{ margin: "0px",marginTop:"7px",paddingRight:"5px"}}> Documenti collegati:{" "} </p>
                               {/** <Select key={uuidv4()}            
                                isMulti                            
                                options={getDocList(item.Urls)}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                defaultValue={getDocList(item.Urls)}
                                /> 
                                */}

                                <SortableSelect 
                                key={uuidv4()}     
                                useDragHandle       
                                axis="xy"
                                onSortEnd={onSortEnd2}
                                index={i}                                                                                                 
                                isMulti                   
                                components={{
                                    // @ts-ignore We're failing to provide a required index prop to SortableElement
                                    MultiValue: SortableMultiValue,
                                    MultiValueLabel: SortableMultiValueLabel,
                                  }}         
                                options={getDocList(item.Urls)}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                      ...theme.colors,
                                      primary25: '#a2ee7d',
                                      primary: 'black',
                                    },
                                  })}      
                                onChange={(event) => handleChange112(event, item.Descrizione,item)}
                                defaultValue={getDocList(item.Urls)}/> 



                            </div>                             
                                ):(
                                    <div style={{ display: "flex", paddingLeft: "0px",paddingTop: "20px", paddingBottom:"10px", margin:"0px"}} key={uuidv4()}>  
                                    <p className= "textGreySedute" style={{ margin: "0px",}}> Documenti collegati:{" "} </p>  
                                    <label key={uuidv4()} style={{paddingLeft:"5px"}}>                                      
                                        {item.Urls.map((item2, index2) => (                                            
                                            <span className= "textBlackSedute">{index2>0 ? (","): ("")} {item2.substr(item2.lastIndexOf("/")+1)}</span>
                                        ))}
                                    </label>                                  
                                    </div>
                            )}   




                            </div>
                            
                        {//console.log("112244", somethingChange, getDescrizione(item.Descrizione), somethingChange===getDescrizione(item.Descrizione)),            
                        somethingChange.trim()===getDescrizione(item.Descrizione).trim() ? (
                            //console.log("112233", somethingChange, getDescrizione(item.Descrizione),item.Descrizione,selectedItem),            
                            <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", paddingLeft: "30px", marginTop:"0px", }} >                    
                            
                            <div style={{ display: "flex", paddingLeft: "0px",paddingTop: "0px", paddingBottom:"10px",paddingRight:"20px", margin:"0px",}} key={uuidv4()}>  
                            <label key={uuidv4()} className= "textGreySedute" style={{ marginTop:"10px", paddingTop:"0px",paddingRight:"5px",}}>  Ordine:{" "}                                          
                     
                            </label>                       
                                
                                <Select key={uuidv4()}    
                                    value={{
                                        label: item.Ordine,
                                        value: item.Ordine
                                      }}                            
                                    component={{MenuList}}                                                                    
                                    //defaultValue={{
                                    //          label: additionalData.order,
                                    //          value: additionalData.order
                                    //        }}
                                    onChange={(event) => handleChange2(event, item.Ordine,item, )}
                                    options={manyItems} 
                                    //styles={customStyles}                                    
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 0,
                                        colors: {
                                          ...theme.colors,
                                          primary25: '#a2ee7d',
                                          primary: 'black',
                                        },
                                      })}
                                    />
                                
                            </div>    

                            <div style={{ display: "flex",paddingLeft: "0px",paddingTop: "0px", paddingBottom:"5px",paddingRight:"20px", margin:"0px",minWidth: "500px"}} key={uuidv4()}>  
                             
                            <FormControlLabel ref={el1 => itemsRefCheckbox.current[i] =el1} key={uuidv4()} style={{paddingLeft:"0px", color:"black"}} 
                            control={<Checkbox key={uuidv4()} style ={{color: "#30720f"}} checked={checkbox1State} 
                            onChange={(event) => handleChange3(event, item,i)} />} label="Forza posizionamento nella missione: " />

                             
                            {/*<label key={uuidv4()} className= "textGreySedute" style={{ marginTop:"10px", paddingTop:"0px",paddingRight:"5px"}}>     Missione/Settore: {" "}</label> */}
                      
                                 <Select key={uuidv4()}                                        
                                    component={{MenuList}}  
                                    ref={el => itemsRef.current[i] =el}
                                    defaultValue={{
                                                  label: convertIdToMissione(getSettore(item)),
                                                  value: getSettore(item)
                                                }}     
                                    isDisabled={!checkbox1State}                            
                                    onChange={(event) => handleChange1(event, additionalData.idSection, item, i)}
                                    options={missioniValues} 
                                    styles={customStyles}                                    
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 0,
                                        colors: {
                                          ...theme.colors,
                                          primary25: '#a2ee7d',
                                          primary: 'black',
                                        },
                                      })}/>

                              
                            </div>                            
                            
                            <FormControlLabel ref={el1 => itemsRefAmbito.current[i] =el1} key={uuidv4()} style={{paddingLeft:"0px", color:"black"}} 
                            control={<Checkbox key={uuidv4()} style ={{color: "#30720f"}} disabled= {!checkbox1State} checked={checkbox2State}  
                            onChange={(event) => handleChange4(event, item, i)} />} label="Rimuovi etichetta Ambito " />

                            <FormControlLabel ref={el2 => itemsRefRelazione.current[i] =el2} key={uuidv4()} style={{paddingLeft:"0px", color:"black"}} 
                            control={<Checkbox key={uuidv4()} style ={{color: "#30720f"}} disabled= {!checkbox1State} checked={checkbox3State} 
                            onChange={(event) => handleChange5(event, item,i)} />} label="Rimuovi etichetta Relazione d'ambito (usa nome del file)" />
                            </div>
                                ): <></>}                        
                    </div>
                    
                    <div style={{ paddingRight: "0px", color: "#ff0000",display: "flex",}} >         
                    <div style={{ paddingRight: "10px", color: "#879497", display: "flex",}} >         
                          {item.loading ? (                              
                             <div style={{marginRight: "20px",}}>
                            <CircularProgress size={42} color= 'secondary' sx={{ color: 'green', position: 'absolute',top: -6,
                                left: -6, zIndex: 1,}}/>
                            </div>
                          ):( <></> )}                        

                          {item.updated > 0 ? (
                            <div
                                style={{                                    
                                    background: "#a2ee7d",
                                    marginRight: "10px",
                                    //padding: "10px 8px",
                                    padding: "15px 8px",
                                    color: "black",                                    
                                    borderRadius: "5px",
                                    fontSize: "13px",
                                }}
                            >
                                Aggiornato
                            </div>
                        ) : (
                            <></>
                        )}


                  
                    <input id={"file-upload"+i} type="file" onChange={(event)=> onFileChange(event,item)} style={{ display: "none" }} />    
                    <label htmlFor={"file-upload"+i}>      
                       <Tooltip title="Aggiungi Relazione d'ambito" placement="bottom">     
                            <IconButton color="primary" aria-label="upload" component="span" style={{ color: "black", background: "#FFFFFF",  }}  >
                                <PublishIcon />                        
                            </IconButton>    
                        </Tooltip>                                         
                    </label>
                    
                        
                    <Tooltip title="Elimina Ambito" placement="bottom">     
                    <IconButton color="primary" aria-label="upload" component="span" style={{ color: "black", background: "#FFFFFF",  }}  onClick={(event) => {deleteAmbito(event,item); }}>
                        <DeleteForeverIcon />                        
                    </IconButton>     
                    </Tooltip>                                        
                    
                                              
                        
                    </div>
                    <div style={{ paddingLeft: "0px", paddingRight: "20px", color: "#879497", }} >      
                    {item.type==="DL" ? (  
                        <Tooltip title="Espandi" placement="bottom">   
                        <IconButton color="primary" aria-label="upload" component="span" style={{ color: "black", background: "#ffffff",}} onClick={(event) => {test(item,i);setSelectedItem(item); showAttributes(event,item); }}>
                            {React.createElement(Icons['MoreHoriz'])}
                        </IconButton>
                        </Tooltip>) : item.type!=undefined ? (                        
                            <Tooltip title="Espandi" placement="bottom">   
                            <IconButton disabled={true} color="primary" aria-label="upload" component="span" style={{ color: "grey", background: "#ffffff",}} onClick={(event) => {test(item,i);setSelectedItem(item);showAttributes(event,item); }}>
                            {React.createElement(Icons['MoreHoriz'])}
                        </IconButton>
                        </Tooltip>
                        )
                        :(
                            <Tooltip title="Espandi" placement="bottom">   
                            <IconButton color="primary" aria-label="upload" component="span" style={{ color: "black", background: "#ffffff",}} onClick={(event) => {test(item,i); setSelectedItem(item); showAttributes(event,item); }}>
                            {React.createElement(Icons['MoreHoriz'])}
                        </IconButton>
                        </Tooltip>
                        )}
                    </div>
                    </div>                   
                </div>                
            
            </div>
            ))}
   
        </div>

    )}</div>    
    );
}
