import React, { useState, useEffect, useContext } from "react";
//import { useHistory, useParams } from "react-router-dom";
import { useParams } from "react-router-dom";
import RouterContext from "../../context/RouterContext";
import {updateSeduta} from "./Utils";
import "../../final.css";
import { useNavigate } from "react-router-dom";
import {properties} from "utils/properties";
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import {ThreeDots} from 'react-loader-spinner';
import { Fab, Tooltip } from '@material-ui/core/';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';

export default function DettaglioCard() {
    //path="/fascicoli/:idDossier/proposteintervento/schede/:id/:refname"
    let {idDossier, idScheda, refname } = useParams();
    const [loading, setLoading]= React.useState(true);  
    const [finalInfo, setFinalInfo] = useState([]);
    //const history = useHistory();
    const navigate = useNavigate();
    const { pageRouter, setPageRouter } = useContext(RouterContext);    
    const meetingId = idDossier;  
    const [color, setColor] = useState([]);
    
    const onClickToUpdate= (e ) => {   
        if (!loading) { setLoading(true);} 
        console.log("octu",e);         
        e.stopPropagation(); 

        axios.get(properties.productionUrl+'/postIDSF?IDS='+idScheda).then(response => {                        
            console.log("THEN",response);
            //console.log("i should go first");
        }).catch(({response}) => {            
            console.log("CATCH",response);
            
        }).finally(() => {
            console.log("FINALLY",);
            //console.log("i should go second");
            /*history.replace({                
                pathname: "/fascicoli/"+idDossier,
                });
            */            
            window.location.reload(false);   
            //setLoading(false);
        });
        
    };



    const updateUI = function(jsonData) {             
        console.log("31",jsonData, refname, idDossier, idScheda);    
        var items = [];                  
        
        //const homeFieldsItem = Object.entries(keyValuScores.components.home.fields);
        //const homeFieldsItem = Object.entries(jsonData.components.[refname].fields);
        //console.log("1",Object.entries(jsonData)["components"]);
        //console.log("2",Object.entries(jsonData.components), refname);
        //console.log("3",Object.entries(jsonData.components.refname));
        //console.log("4",Object.entries(jsonData.components)[refname]);
        //console.log("4a",Object.entries(jsonData.components)[0]);
        //console.log("4b",Object.entries(jsonData.components)[1]);
        //console.log("4c",Object.entries(jsonData.components)[2]);
        var objectInterest="";

        console.log("test",Object.entries(jsonData.components).length);
        for (var ind = 0; ind < Object.entries(jsonData.components).length; ind++) {
            if(Object.entries(jsonData.components)[ind][0] == refname)
            objectInterest=Object.entries(jsonData.components)[ind][1];
        }
        /*if(Object.entries(jsonData.components)[0][0] == refname)
            objectInterest=Object.entries(jsonData.components)[0][1];
        if(Object.entries(jsonData.components)[1][0] == refname)
            objectInterest=Object.entries(jsonData.components)[1][1];
        if(Object.entries(jsonData.components)[2][0] == refname)
            objectInterest=Object.entries(jsonData.components)[2][1];
        */
        
        //console.log("5",Object.entries(jsonData.components.refname));
        //console.log("5",Object.entries(jsonData.components)[refname]);
        //console.log("6",Object.entries(jsonData)["components"][refname]);
        //const homeFieldsItem = Object.entries(jsonData.components.refname.fields);
        //console.log("7",Object.entries(jsonData.components.refname.fields));        
        //console.log("7",Object.entries(jsonData.components.refname.fields));        
        //console.log("7",objectInterest.fields);      
        //console.log("8",objectInterest["fields"]);        
        //const homeFieldsItem = Object.entries(jsonData)["components"][refname]["fields"];
        //const homeFieldsItem = Object.entries(objectInterest.fields);
        //console.log(homeFieldsItem);        
        //console.log("31c", homeFieldsItem);         
        //const finalList = JSON.parse(objectInterest.fields);
        const finalList = Object.entries(objectInterest.fields);
        //console.log("qq41",finalList);
        /*
        let obiettivo;
        for (var i1 = 0; i1 < homeFieldsItem.length; i1 ++) {            
            if (homeFieldsItem[i1]["label"]==="Obiettivo") {
                obiettivo = homeFieldsItem[i1][1]["value"];
            }
        }        
        */     
       //finalList.forEach((element) => console.log("t123",element));

       //console.log("WE1",finalList[0]);
       //console.log("WE2",finalList[1]["label"]);
        for (var i = 0; i < finalList.length; i++) {    

            var item = {
                label: finalList[i][1]["label"],
                value: finalList[i][1]["value"],
                style: finalList[i][1]["style"],
                second: finalList[i][1]["secondValue"],
                comment: finalList[i][1]["comment"],
                reftype: refname,
                table: finalList[i][1]["table"],
            };
            //console.log("Q1",item);
            items.push(item);
        }
        //console.log("qc46", obiettivo);        
        setFinalInfo(items);
        console.log("Q1Q2",items);
        //const temp1 = Object.entries(jsonData);
        //console.log("qq46", temp1);
        /*
        let entireSpecificList;
        temp1.forEach( entry => {
                let key= entry[0];
                let value= entry[1];                               
                if(key==='sectors') {                                        
                    entireSpecificList=value;                                        
                  //console.log("qq-2",entry);
                }                
        });
        */
        setLoading(false);
        
            
    }

    const doPostSeduta2 = function() {
    
        var xhr2 = new XMLHttpRequest();                
        xhr2.open('POST', properties.productionUrl+'/rest/checkScheda',true);
        xhr2.setRequestHeader("Content-type", "application/x-www-form-urlencoded");    
        xhr2.onload = function (e) {      
            if (xhr2.readyState === 4) {
                if (xhr2.status === 200) {                              
                    //console.log("OK92",xhr2);
                    var responseText = xhr2.responseText;                    
                    var obj=JSON.parse(responseText);
                    //console.log("OK93",obj);          
                    if(obj.status==="204" ){ 
                        console.log("OK DOPOSTSEDUTE2 204",xhr2);  
                        const SchedaInfo = JSON.parse(localStorage.getItem("schedaContent"));
                        updateUI(SchedaInfo);
                    }              
                    else{
                        //status 201
                        console.log("OK DOPOSTSEDUTE2 201",xhr2);  
                        try{
                            localStorage.setItem("schedaContent", obj.content);
                            localStorage.setItem("scheda", idScheda);
                            localStorage.setItem("schedaVersion", obj.date);                                                                     
                        }
                        catch(error) {
                            console.log("LOCALSTORAGE ISSUE", error);
                        }                        
                        const SchedaInfo = JSON.parse(obj.content);
                        updateUI(SchedaInfo);
                    }
                } 
                else {
                    console.error("Error 1", xhr2.statusText);          
                    alert("Errore recuperando la scheda 2");  
                    /*history.replace({                                
                        pathname: "/editor",
                    });*/
                    navigate("/editor",{ replace: true });     
                }
            }
        };

        xhr2.onerror = function (e) {
            console.error("Error 22", xhr2.statusText);
        };
    
        let urlEncodedData = "ids=" + idScheda;
        if (localStorage.scheda===idScheda){                        
            const currentData=localStorage.getItem("schedaVersion");        
            urlEncodedData = urlEncodedData+ "&date=" + currentData;                    
        }    
        xhr2.send(urlEncodedData);
    }


    useEffect(() => {
        if(process.env.NODE_ENV !=='development' && !window.cordova){            
        //CHECK AUTHENTICATION
        //var xhr = new XMLHttpRequest();                
        //xhr.open('POST', properties.productionUrl+'/rest/ping',true);
        //xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
    
        //xhr.onload = function (e) {
        //    if (xhr.readyState === 4) {
        //        if (xhr.status === 200) {                    
        //            console.log("OK",xhr);
                    updateSeduta(meetingId,doPostSeduta2,updateUI);
        //        } else {
        //            console.error("Error 1", xhr); 
        //            localStorage.clear();                   
        //            history.push({                
        //            pathname: "/login",
        //            });
        //        }
        //    }
        //};
    
        //xhr.onerror = function (e) {
        //  console.error("Error 2", xhr);
        //  localStorage.clear();
        //  history.push({                
        //            pathname: "/login",
        //            });
        //};
             
        //let urlEncodedData = null; 
        //xhr.send(urlEncodedData);
        //CHECK AUTHENTICATION
        }
        
        if (window.cordova)
        {
            updateSeduta(meetingId,doPostSeduta2,updateUI);
        }

        /*setPageRouter({
            router: history.location.pathname,
            homecolor: "#879497",
            settingcolor: "#FF5A60",
            homebordercolor: "#FFFFFF",
            settingbordercolor: "#FF5A60",
            hometitlecolor: "#879497",
            settingtitlecolor: "black",
        });*/
    }, []);

    return (
        <div> 
        {loading ? (
            <div key={uuidv4()} style={{ width:"25%",   display: "flex", 
            marginLeft: "auto", marginRight: "auto" }}> 
             <ThreeDots color="#30720f" height='350' width='150'/>
             </div> 
        ):(
        <div className="content">
            <Tooltip title="Forza aggiornamento" placement="left">
             <Fab aria-label="add"                 
                //variant="contained" 
                onClick={(event) => {onClickToUpdate(event) }}
                
                style={{
                    margin: 0,
                    background: "#30720f",
                    top: 'auto',
                    right: 5,
                    bottom: 25,
                    //color: '0xFF0000',
                    left: 'auto',
                    position: 'fixed',}}>                    
                    <SystemUpdateAltIcon />
                    {/*<input id="fileupload00" type="file" name="file" ref={hiddenFileUpload2} onChange={(event)=> onFileChange2(event)} style={{ display: "none" }}/>*/}
                </Fab>   
                </Tooltip>     
        <div style={{
            marginLeft: "0px",
            width: "100%",
            height: "10px",            
            //background: color,            
            }}> </div>       
            {finalInfo.map((item, index) =>
                item.reftype !== "relazioneannotazioni" ? (
                    item.table && item.label !== "Allegati" ? (
                         <div
                            className="common-list"
                            key={index}
                            style={{                                
                                margin: "0px",
                                borderRadius: "0px",                                
                                //paddingLeft: "30px",                            
                            }}                         
                        >
                    {/* <li className={[activeClass, data.klass, "main-class"].join(' ')} />*/}

                            <table className={[item.label, 'table1'].join(' ')} style={{background: "white",  borderBottom: "1px solid rgb(239, 239, 239)", }}>
                                <tr>                  
                                    { item.value ? (   
                                    //console.log("trena",item.value),               
                                    <td colSpan="4" style={{background: "white" }}>
                                        <div
                                        style={{                                    
                                        textAlign: "left",
                                        //marginTop: "0px",
                                        paddingTop: "0px", 

                                        //color: "rgb(254, 173, 176)",
                                        color: "#a2ee7d",
                                        //fontWeight: "bold", 
                                        whiteSpace: 'pre-wrap',                                       
                                        }}>                                                                          <p className="text importantText"
                                                style={{
                                                marginTop: "0px",
                                                
                                            }}
                                            dangerouslySetInnerHTML={{
                                                __html: item.label+ " "+ item.value,
                                            }}
                                            />                                                    
                                       </div>
                                        
                                    </td>
                                    ):(                                     

                                    <td colSpan="4" style={{background: "white" }}>
                                        <div
                                        style={{                                    
                                        textAlign: "left",  
                                        paddingTop: "10px",                                      
                                        //color: "rgb(254, 173, 176)",
                                        //fontWeight: "bold",                                          
                                        whiteSpace: 'pre-wrap',                              
                                        }}>                                                                    
                                            <p className="text importantText" 
                                            style={{
                                                marginTop: "0px",
                                                
                                            }}
                                            dangerouslySetInnerHTML={{
                                                __html: item.label,
                                            }}
                                            />
                                       </div>
                                        
                                    </td>
                                    )}
                                </tr>


                                {item.table.map((subitem, index) =>
                                    index !== 0 || subitem[2] === "DATA" ? (
                                        //console.log("sub",subitem),
                                        <tbody>
                                        <tr>
                                            { subitem[1] === "2" && subitem[3] ?
                                            (
                                               //console.log("sub1",subitem), 
                                            <td 
                                            style={{
                                                textAlign: "left",
                                                fontSize: "16px",
                                                color: "#879497",
                                                fontWeight:"normal",
                                                background: "white", 
                                                //background: "red", 
                                                }}>{subitem[3]}
                                            </td>
                                            ) : [ subitem[1] === "0" && subitem[3] ? (
                                            //console.log("sub2",subitem),
                                            
                                            <td style={{ 
                                                textAlign: "left",
                                                fontSize: "16px",
                                                //background: "#ffeeef", 
                                                background: "#bcf3a1",
                                                //color: "#4B0307", 
                                                color: "#000000", 
                                                //fontWeight:"bold", 
                                                fontWeight:"normal",
                                                paddingTop: "15px"}}> {subitem[3]}</td>
                                            ): [ subitem[1] === "" && subitem[3] && subitem[2]!== "STRUCT" ? (
                                            //console.log("sub3",subitem),
                                            <td style={{                                                 
                                                //background: "#ffeeef", 
                                                background: "#bcf3a1",
                                                fontSize: "16px",
                                                //color: "#4B0307", 
                                                color: "#000000", 
                                                //fontWeight:"bold", 
                                                fontWeight:"normal",
                                                paddingTop: "15px"}}> {subitem[3]}</td>
                                            ): [ subitem[3] && subitem[2]!== "STRUCT" ? 
                                            (
                                            //console.log("sub4",subitem),
                                            <td style={{
                                                textAlign: "left",
                                                fontSize: "16px",
                                                background: "white", 
                                                fontWeight:"normal", 
                                                //color: "#4B0307", 
                                                color: "#000000", 
                                                 }}>{subitem[3]}</td>
                                            ) : [subitem[0]=== "-99" || subitem[2]=== "HEAD" ? 
                                            (
                                            //console.log("sub5",subitem),
                                            <td style={{ 
                                                textAlign: "left",
                                                fontSize: "16px",
                                                //background: "#ffeeef",
                                                background: "#bcf3a1",
                                                //color: "#4B0307", 
                                                color: "#000000", 
                                                //fontWeight:"bold", 
                                                fontWeight:"normal",
                                                paddingTop: "15px"}}>{subitem[3]}</td>
                                            ): 
                                             (null)]
                                            ]]]}        

                                            { subitem[1] === "2" && subitem[4] ?
                                            (

                                            <td style={{
                                                color: "#879497",
                                                fontSize: "16px",
                                                background: "white",
                                                fontWeight:"normal", }}>{subitem[4]}</td>
                                            ) : [ subitem[1] === "0" && subitem[4] ? (
                                            
                                            <td style={{ 
                                                //background: "#ffeeef", 
                                                background: "#bcf3a1",
                                                fontSize: "16px",
                                                //color: "#4B0307", 
                                                color: "#000000", 
                                                //fontWeight:"bold", 
                                                fontWeight:"normal",
                                                paddingTop: "15px"}}>{subitem[4]}</td>
                                            ): [ subitem[1] === "" && subitem[4] && subitem[2]!== "STRUCT" ? (
                                            
                                            <td style={{ 
                                                //background: "#ffeeef", 
                                                background: "#bcf3a1",
                                                //color: "#4B0307", 
                                                fontSize: "16px",
                                                color: "#000000", 
                                                //fontWeight:"bold", 
                                                fontWeight:"normal",
                                                paddingTop: "15px"}}>{subitem[4]}</td>
                                            ): [ subitem[4] && subitem[2]!== "STRUCT" ? 
                                            (
                                            <td style={{
                                                background: "white", 
                                                //color: "#4B0307", 
                                                fontSize: "16px",
                                                color: "#000000", 
                                                fontWeight:"normal", }}>{subitem[4]}</td>
                                            ) : 
                                            [ subitem[0]=== "-99" || subitem[3]=== "Questionario di approfondimento" ||subitem[2] === "HEAD" ? 
                                            (
                                            //console.log("qui", subitem),
                                            <td style={{ 
                                                //background: "#ffeeef", 
                                                background: "#bcf3a1",
                                                //color: "#4B0307", 
                                                fontSize: "16px",
                                                color: "#000000", 
                                                //fontWeight:"bold", 
                                                fontWeight:"normal",
                                                paddingTop: "15px"}}>{subitem[4]}</td>
                                            ): (null)]
                                            ]]]}                                                 
                                                   { subitem[1] === "2" && subitem[5] ?
                                            (
                                           

                                            <td style={{
                                                color: "#879497",
                                                fontSize: "16px",
                                                background: "white",
                                                fontWeight:"normal", }}>{subitem[5]}</td>
                                            ) : [ subitem[1] === "0" && subitem[5] ? (
                                           
                                            <td style={{ 
                                                //background: "#ffeeef", 
                                                background: "#bcf3a1",
                                                //color: "#4B0307", 
                                                color: "#000000", 
                                                fontSize: "16px",
                                                //fontWeight:"bold", 
                                                fontWeight:"normal",
                                                paddingTop: "15px"}}>{subitem[5]}</td>
                                            ): [ subitem[1] === "" && subitem[5] && subitem[2]!== "STRUCT" ? (
                                           
                                            <td style={{ 
                                                //background: "#ffeeef", 
                                                background: "#bcf3a1",
                                                //color: "#4B0307", 
                                                fontSize: "16px",
                                                color: "#000000", 
                                                //fontWeight:"bold", 
                                                fontWeight:"normal",
                                                paddingTop: "15px"}}>{subitem[5]}</td>
                                            ): [ subitem[5] && subitem[2]!== "STRUCT" ? 
                                            (
                                            <td style={{
                                                //color: "#4B0307", 
                                                color: "#000000", 
                                                fontSize: "16px",
                                                fontWeight:"normal",
                                                background: "white", }}>{subitem[5]}</td>
                                            ) : [  subitem[0]=== "-99"  || (subitem[2] === "HEAD" && subitem[3] !== "Domanda") ? 
                                            (
                                            <td style={{ 
                                                //background: "#ffeeef", 
                                                background: "#bcf3a1",
                                                //color: "#4B0307", 
                                                color: "#000000", 
                                                fontSize: "16px",
                                                //fontWeight:"bold", 
                                                fontWeight:"normal",
                                                paddingTop: "15px"}}>{subitem[5]}</td>
                                            ): (null)]
                                            ]]]}                               


                                            { subitem[1] === "2" && subitem[6] ?
                                            (                                           
                                            <td style={{
                                                color: "#879497",
                                                background: "white",
                                                fontSize: "16px",
                                                fontWeight:"normal", }}>{subitem[6]}</td>
                                            ) : [ subitem[1] === "0" && subitem[6] ? (
                                           
                                            <td style={{ 
                                                //background: "#ffeeef", 
                                                background: "#bcf3a1",
                                                //color: "#4B0307", 
                                                fontSize: "16px",
                                                color: "#000000", 
                                                //fontWeight:"bold", 
                                                fontWeight:"normal",
                                                paddingTop: "15px"}}>{subitem[6]}</td>
                                            ): [ subitem[1] === "" && subitem[6] && subitem[2]!== "STRUCT" ? (
                                           
                                            <td style={{ 
                                                //background: "#ffeeef", 
                                                background: "#bcf3a1",
                                                //color: "#4B0307", 
                                                color: "#000000", 
                                                fontSize: "16px",
                                                //fontWeight:"bold", 
                                                fontWeight:"normal",
                                                paddingTop: "15px"}}>{subitem[6]}</td>
                                            ): [ subitem[6] && subitem[2]!== "STRUCT" ? 
                                            (
                                            <td style={{
                                                //color: "#4B0307", 
                                                color: "#000000", 
                                                fontSize: "16px",
                                                background: "white",
                                                fontWeight:"normal", }}>{subitem[6]}</td>
                                            ) : [ subitem[6] && subitem[0]=== "-99" ? 
                                            (
                                            <td style={{ 
                                                //background: "#ffeeef", 
                                                background: "#bcf3a1",
                                                //color: "#4B0307", 
                                                color: "#000000", 
                                                fontSize: "16px",
                                                //fontWeight:"bold", 
                                                fontWeight:"normal",
                                                paddingTop: "15px"}}>{subitem[6]}</td>
                                            ):
                                            (null)]
                                            ]]]}
                                            
                                            
                                            
                                        </tr>
                                        </tbody>
                                    ) : (
                                        <></>
                                    )
                                )}                                
                            </table>
                            {item.comment ? (  
                            //console.log("cmt4", item.comment),                              
                                <div
                                    style={{                                        
                                        textAlign: "left",
                                        background: "white",
                                        padding: "25px 25px",
                                    }}
                                >
                                    
                                    <div
                                        style={{
                                            background: "white",
                                            //color: "#879497",
                                            padding: "0px 25px",
                                            //border: "1px solid #FEADB0",
                                            border: "1px solid #30720f",
                                            borderRadius: "20px",
                                            margin: "0px 0px",
                                        }}
                                    >
                                        <p className="text notImportantText">
                                            Commento:
                                        </p>
                                        <p className="text notImportantText">{item.comment}</p>
                                    </div>
                                </div>
                            ): (
                            <></>
                            )}
                        </div>

                    ) : item.label === "Allegati" ? (
                        //console.log("cmt7", item.value),
                        <div
                            style={{
                                textAlign: "left",
                                paddingLeft: "25px",
                                paddingRight: "25px",
                                background: "white",
                                paddingTop: "20px",
                                borderBottom: "1px solid rgb(239, 239, 239)",
                            }}
                            key={index}
                        >
                            <p className="text importantText"
                                style={{
                                    //color: "rgb(254, 173, 176)",
                                    //fontWeight: "bold",
                                    marginTop: "0px",
                                }}
                            >
                                {item.label}:
                            </p>
                            <div
                                style={{
                                    //paddingBottom: "20px",
                                    //color: "rgb(75, 3, 7)",
                                    color: "#30720f",
                                    whiteSpace: 'pre-wrap',
                                }}>                                
                                 <p className="text"
                                            dangerouslySetInnerHTML={{
                                                __html: item.value,
                                            }}
                                        />  

                            </div>
                            {item.table ? (
                                item.table.map((extraitem, index) =>(    

                                index !== 0 && extraitem[3] !== 'Nome Allegato'? (
                                //console.log(extraitem[3]),
                                    <div
                                        key={index}
                                        style={{
                                            //paddingBottom: "20px",
                                            //color: "rgb(75, 3, 7)",
                                            color: "#30720f",
                                        }}>

                                         <p className="text"
                                            dangerouslySetInnerHTML={{
                                                __html: extraitem[3],
                                            }}
                                        />  
                                    </div>
                                        ) : (
                                            <></>
                                        )
                                ))
                            ) : (
                                <></>
                            )}
                        </div>
                    ): item.comment ? (
                        //console.log("cmt1", item.value),
                                <div
                                    style={{
                                textAlign: "left",
                                paddingLeft: "25px",
                                paddingRight: "25px",
                                background: "white",
                                paddingTop: "20px",                                
                                borderBottom: "1px solid rgb(239, 239, 239)",
                                    }}
                                >                                
                                    <div>
                                        <p className="text importantText"
                                        //style={{ color: "#FEADB0" }}
                                        >
                                            {item.label}:
                                        </p>
                                        <p className="text"
                                            style={{
                                            //    color: "#4B0307",
                                            whiteSpace: 'pre-wrap' }}
                                            dangerouslySetInnerHTML={{
                                                __html: item.value,
                                            }}

                                         
                                        />                                         
                                    </div>
                                    <div
                                        style={{
                                            color: "#879497",
                                            padding: "0px 25px",
                                            //border: "1px solid #FEADB0",
                                            border: "1px solid #30720f",
                                            borderRadius: "20px",
                                            margin: "40px 0px",
                                        }}
                                    >
                                        <p className="text notImportantText"
                                        //style={{ fontWeight: "bold" }}
                                        >
                                            Commento:
                                        </p>                                        

                                        <p className="text notImportantText"
                                        style={{whiteSpace: 'pre-wrap' }}
                                            dangerouslySetInnerHTML={{
                                                __html: item.comment,
                                            }}
                                        />    
                                    </div>
                                </div>
                            )



                     : item.style === 1  ? (
                            //console.log ("s1", item.value),
                            <div
                                    style={{
                                        //background: "#FEADB0",
                                        background: "#a2ee7d",
                                        color: "#4B0307",
                                        textAlign: "left",
                                        padding: "0px 25px",
                                        border: "1px solid rgb(239, 239, 239)",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <p className="text"
                                            //style={{
                                            //    fontWeight: "bold",
                                            //}}
                                        >
                                            {item.label}: 
                                        </p>
                                    </div>
                                    <p className="text">{item.value}</p>
                                </div>
                             ) : (
                            //console.log("sew",item.value),
                                                <div
                            style={{
                                textAlign: "left",
                                paddingLeft: "25px",
                                paddingRight: "25px",
                                background: "white",
                                paddingTop: "20px",
                                borderBottom: "1px solid rgb(239, 239, 239)",
                            }}
                            key={index}
                            >
                            <p className="text importantText"
                                style={{
                                    //color: "rgb(254, 173, 176)",
                                    //fontWeight: "bold",
                                    marginTop: "0px",
                                }}
                            >                            
                                {item.label}:
                            </p>

                            <div
                                style={{
                                    paddingBottom: "20px",
                                    //color: "rgb(75, 3, 7)",
                                    color: "#30720f",
                                    whiteSpace: 'pre-wrap',
                                }} > 
                                <p className="text"
                                    dangerouslySetInnerHTML={{
                                                __html: item.value,
                                            }}
                                        />
                            </div>
                            </div>
                                            )



                        
                    
                ) : (
                //console.log("fre",item.value),
                    <div
                        style={{
                            textAlign: "left",
                            paddingLeft: "25px",
                            paddingRight: "25px",
                            background: "white",
                            paddingTop: "20px",
                            borderBottom: "1px solid rgb(239, 239, 239)",
                        }}
                        key={index}
                    >
                        <p className="text importantText"
                            style={{
                                //color: "rgb(254, 173, 176)",
                                //fontWeight: "bold",
                                marginTop: "0px",
                            }}
                        >
                            {item.label}:
                        </p>
                        <div
                            style={{
                                paddingBottom: "20px",
                                //color: "rgb(75, 3, 7)",
                                color: "#30720f",
                                whiteSpace: 'pre-wrap',
                            }}
                            dangerouslySetInnerHTML={{
                                __html: item.value,
                            }}
                        />
                        {item.table ? (
                            item.table.map((extraitem, index) => (
                            //console.log("uff",item),
                                <div
                                    key={index}
                                    style={{ paddingBottom: "20px" }}
                                    dangerouslySetInnerHTML={{
                                        __html: extraitem[3],
                                    }}
                                />
                            ))
                        ) : (
                            <></>
                        )}
                    </div>
                )
            )}
        </div>
     )}</div>      
    );
}
